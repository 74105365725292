import { useState, useCallback, useMemo } from 'react'
import { Alert, GridRowColumn } from '../../../../../components/BaseComponents'
import { useScrollRef } from '../../../../../utils/sharedHooks'

const useMissingAnswerValidation = (missingAnswerError: string) => {
  const [error, setError] = useState('')
  const { scrollRef, scrollToRef } = useScrollRef()

  const clearError = useCallback(() => {
    setError('')
  }, [])

  const triggerError = useCallback(() => {
    setError(missingAnswerError)
    scrollToRef()
  }, [missingAnswerError, scrollToRef])

  const errorAlert = useMemo(
    () =>
      error ? (
        <GridRowColumn>
          <Alert type="error">{error}</Alert>
          <span ref={scrollRef} />
        </GridRowColumn>
      ) : (
        <span ref={scrollRef} />
      ),
    [error, scrollRef]
  )

  return {
    errorAlert,
    clearError,
    triggerError,
  }
}

export default useMissingAnswerValidation
