import { FormikProvider, useFormik } from 'formik'
import { Grid } from 'semantic-ui-react'

import {
  kickoffTicketTags,
  kickoffTicketTasks,
  kickoffTicketTopic,
  TaxSeasonKickoffSurveyProps,
} from './YearEndKickoffSurvey'
import FormFlowFooter from '../../../../components/FormFlow/FormFlowFooter'
import {
  Card,
  FormikLabelError,
  FormikRadioButton,
  FormikTextArea,
  getFieldNames,
  GridRowColumn,
  makeReqStringSchema,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { postCreateNewTicket } from '../../../Conversations/actions'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { TAX_SEASON_KICKOFF_SURVEY } from './TskTipsSideBar'
import { selectUserName } from '../../../../selectors/user.selectors'
import { TAX_ENTITY_TYPES } from '../../taxConstants'

enum ScorpHelpAnswer {
  notWantToBeScorp = 'notWantToBeScorp',
  noLongerScorp = 'noLongerScorp',
  wantToBeScorp = 'wantToBeScorp',
  taxedAsCcorp = 'taxedAsCcorp',
  notSure = 'notSure',
  somethingElse = 'somethingElse',
}

enum NotWantToBeScorpFollowup {
  wantToRevoke = 'wantToRevoke',
  stoppedMidYear = 'stoppedMidYear',
  somethingElse = 'somethingElse',
}

enum NoLongerScorpFollowup {
  revokedScorp = 'revokedScorp',
  dissolvedBusiness = 'dissolvedBusiness',
  stoppedMidYear = 'stoppedMidYear',
  somethingElse = 'somethingElse',
}

enum WantToBeScorpFollowup {
  notHeardBack = 'notHeardBack',
  needHelp = 'needHelp',
  somethingElse = 'somethingElse',
}

const ScorpTaxEntityHelp = ({
  goToNextStep,
  goBack,
}: TaxSeasonKickoffSurveyProps) => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const userName = useReselector(selectUserName)

  const formik = useFormik({
    initialValues: {
      scorpHelpAnswer: undefined as undefined | ScorpHelpAnswer,
      notWantToBeScorp: undefined as undefined | NotWantToBeScorpFollowup,
      noLongerScorp: undefined as undefined | NoLongerScorpFollowup,
      wantToBeScorp: undefined as undefined | WantToBeScorpFollowup,
      scorpOther: '',
    },
    onSubmit: (answers) => {
      let body
      let subject
      let ticketTask
      let taxSeasonSurveySelfDescribedTaxEntity = TAX_ENTITY_TYPES.form_1120_s
      const tags = [...kickoffTicketTags]

      if (answers.scorpHelpAnswer === ScorpHelpAnswer.notWantToBeScorp) {
        subject = `Discussing S Corp revocation for ${taxYear} taxes`
        ticketTask = kickoffTicketTasks.revoke

        if (
          answers.notWantToBeScorp === NotWantToBeScorpFollowup.wantToRevoke
        ) {
          body = `${userName} is currently listed as an S-corp but wants to revoke their status.`
          tags.push('scorp-revocation')
        } else if (
          answers.notWantToBeScorp === NotWantToBeScorpFollowup.stoppedMidYear
        ) {
          body = `${userName} is currently listed as an S-corp but stopped operating as one mid year, and has not revoked their status.`
          tags.push('scorp-revocation-stopped-operating')
        } else {
          body = `${userName} is currently listed as an S-corp but they want to stop operating as an S-corp, and has not revoked their status.\nComment: ${answers.scorpOther}`
          tags.push('scorp-revocation-other')
        }
      } else if (answers.scorpHelpAnswer === ScorpHelpAnswer.noLongerScorp) {
        // User may still need to file 1120s so don't change taxSeasonSurveySelfDescribedTaxEntity in this flow
        if (answers.noLongerScorp === NoLongerScorpFollowup.revokedScorp) {
          ticketTask = kickoffTicketTasks.revoke
          subject = `Verifying your S Corp revocation for ${taxYear} taxes`
          body = `${userName} is currently listed as an S-corp but indicated they revoked their status, but may still have to file an 1120-S for ${taxYear}.`
          tags.push('listed-as-scorp-revoked-status-needs-help-filing-form')
        } else if (
          answers.noLongerScorp === NoLongerScorpFollowup.dissolvedBusiness
        ) {
          ticketTask = kickoffTicketTasks.dissolve
          subject = `Verifying the dissolution of your business entity for ${taxYear} taxes`
          body = `${userName} is currently listed as an S-corp but indicated they dissolved their business entity and are now a sole prop.`
          tags.push('listed-as-scorp-dissolved-entity-now-sole-prop')
        } else if (
          answers.noLongerScorp === NoLongerScorpFollowup.stoppedMidYear
        ) {
          ticketTask = kickoffTicketTasks.revoke
          subject = `Discussing S Corp revocation for ${taxYear} taxes`
          body = `${userName} is currently listed as an S-corp but stopped operating as one mid year, and has not revoked their status.`
          tags.push('scorp-revocation-stopped-operating')
        } else {
          ticketTask = kickoffTicketTasks.revoke
          subject = `Discussing S Corp revocation for ${taxYear} taxes`
          body = `${userName} is currently listed as an S-corp but indicated they are no longer one and needs help.\nComment: ${answers.scorpOther}`
          tags.push('listed-as-scorp-is-no-longer-other')
        }
      } else if (answers.scorpHelpAnswer === ScorpHelpAnswer.wantToBeScorp) {
        taxSeasonSurveySelfDescribedTaxEntity = TAX_ENTITY_TYPES.form_1040
        if (answers.wantToBeScorp === WantToBeScorpFollowup.notHeardBack) {
          ticketTask = kickoffTicketTasks.requestCP261
          subject = `Obtaining your CP261 for ${taxYear} taxes`
          body = `${userName} is currently listed as an S-corp but indicated they are actually a Sole Prop. They also indicated they want to become an S-corp, and has applied to do so, but hasn't heard back from the IRS.`
          tags.push('listed-as-scorp-actually-sole-prop-filed-2553')
        } else if (answers.wantToBeScorp === WantToBeScorpFollowup.needHelp) {
          ticketTask = kickoffTicketTasks.election
          subject = `Considering S Corp election for ${taxYear} taxes`
          body = `${userName} is currently listed as an S-corp but indicated they are actually a Sole Prop. They also indicated they want to become an S-corp.`
          tags.push('listed-as-scorp-actually-sole-prop-hasnt-applied')
        } else {
          ticketTask = kickoffTicketTasks.determine
          subject = `Determining your tax entity type for ${taxYear} taxes`
          body = `${userName} is currently listed as an S-corp but indicated they are a sole prop and needs additional help.\nComment: ${answers.scorpOther}`
          tags.push('listed-as-scorp-actually-sole-prop-other')
        }
      } else if (answers.scorpHelpAnswer === ScorpHelpAnswer.taxedAsCcorp) {
        taxSeasonSurveySelfDescribedTaxEntity = TAX_ENTITY_TYPES.form_1120
        ticketTask = kickoffTicketTasks.offboard
        subject = `Verifying your C Corp status for ${taxYear} taxes`
        body = `${userName} is currently listed as an S-corp but indicated they are now taxed as a C-corp.`
        tags.push('ccorp-offboard')
      } else if (answers.scorpHelpAnswer === ScorpHelpAnswer.notSure) {
        ticketTask = kickoffTicketTasks.determine
        subject = `Determining your tax entity type for ${taxYear} taxes`
        body = `${userName} is currently listed as an S-corp but has indicated they need help with confirming their tax entity.`
        tags.push('tax-entity-confusion')
      } else {
        ticketTask = kickoffTicketTasks.determine
        subject = `Determining your tax entity type for ${taxYear} taxes`
        body = `${userName} is currently listed as an S-corp but has indicated they need help with confirming their tax entity.\nComment: ${answers.scorpOther}`
        tags.push('tax-entity-confusion-other')
      }

      dispatch(
        postCreateNewTicket({
          comment: { body, htmlBody: `<p>${body}</p>` },
          internalSubmitter: true,
          ticketTopic: kickoffTicketTopic,
          ticketTask,
          subject,
          tags,
        })
      )

      const nextScreen =
        answers.scorpHelpAnswer === ScorpHelpAnswer.taxedAsCcorp
          ? TAX_SEASON_KICKOFF_SURVEY.cCorpDisqualified
          : TAX_SEASON_KICKOFF_SURVEY.helpSubmission

      goToNextStep({ taxSeasonSurveySelfDescribedTaxEntity }, nextScreen)
    },
  })

  const { values, isValid, isSubmitting, submitForm } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <Grid>
        <Grid.Row />
        <GridRowColumn>
          <Text as="display2">Let’s get you some help</Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text as="bodyLg">
            We need to get a little more information so a member of our Heard
            support team can better help you.
          </Text>
        </GridRowColumn>
        <GridRowColumn width={12}>
          <FormikLabelError
            label="Are you still an S corporation?"
            name={fieldNames.scorpHelpAnswer}
            schema={makeReqStringSchema()}
          />
        </GridRowColumn>
        <GridRowColumn short width={12}>
          <FormikRadioButton
            label="Yes, but I no longer want to be one"
            variant="default"
            value={ScorpHelpAnswer.notWantToBeScorp}
            name={fieldNames.scorpHelpAnswer}
          />
        </GridRowColumn>
        <GridRowColumn short width={12}>
          <FormikRadioButton
            label="No, I am no longer an S corporation"
            variant="default"
            value={ScorpHelpAnswer.noLongerScorp}
            name={fieldNames.scorpHelpAnswer}
          />
        </GridRowColumn>
        <GridRowColumn short width={12}>
          <FormikRadioButton
            label="No, I’m a sole proprieter, but I want to be an S corporation"
            variant="default"
            value={ScorpHelpAnswer.wantToBeScorp}
            name={fieldNames.scorpHelpAnswer}
          />
        </GridRowColumn>
        <GridRowColumn short width={12}>
          <FormikRadioButton
            label="No, I’m taxed as a C corporation"
            variant="default"
            value={ScorpHelpAnswer.taxedAsCcorp}
            name={fieldNames.scorpHelpAnswer}
          />
        </GridRowColumn>
        <GridRowColumn short width={12}>
          <FormikRadioButton
            label="I'm not sure what type of business entity I'm operating or what my tax entity type is"
            variant="default"
            value={ScorpHelpAnswer.notSure}
            name={fieldNames.scorpHelpAnswer}
          />
        </GridRowColumn>
        {values.scorpHelpAnswer === ScorpHelpAnswer.notSure && (
          <GridRowColumn width={12}>
            <Card type="subsection" backgroundColor="natural">
              <Text as="bodyLg">
                Got it. We’ll have someone from our support team reach out to
                help.
              </Text>
            </Card>
          </GridRowColumn>
        )}
        <GridRowColumn short width={12}>
          <FormikRadioButton
            label="It’s something else"
            variant="default"
            value={ScorpHelpAnswer.somethingElse}
            name={fieldNames.scorpHelpAnswer}
          />
        </GridRowColumn>
        {values.scorpHelpAnswer === ScorpHelpAnswer.notWantToBeScorp && (
          <>
            <GridRowColumn width={12}>
              <FormikLabelError
                label="What best describes your situation?"
                name={fieldNames.notWantToBeScorp}
                schema={makeReqStringSchema()}
              />
            </GridRowColumn>
            <GridRowColumn short width={12}>
              <FormikRadioButton
                label="I’m an S corporation now but want to revoke my election"
                variant="default"
                value={NotWantToBeScorpFollowup.wantToRevoke}
                name={fieldNames.notWantToBeScorp}
              />
            </GridRowColumn>
            {values.notWantToBeScorp ===
              NotWantToBeScorpFollowup.wantToRevoke && (
              <GridRowColumn width={12}>
                <Card type="subsection" backgroundColor="natural">
                  <Text as="bodyLg">
                    Got it. We’ll have someone from our Support team reach out
                    to help.
                    <br />
                    <br />
                    Please be advised, if you elected as an S corporation,
                    revoking the election takes time, and the process may not be
                    completed in time for the {taxYear} tax year.
                  </Text>
                </Card>
              </GridRowColumn>
            )}
            <GridRowColumn short width={12}>
              <FormikRadioButton
                label={`I stopped operating as an S corporation mid-year in ${taxYear} but haven't revoked my election or dissolved my business entity`}
                variant="default"
                value={NotWantToBeScorpFollowup.stoppedMidYear}
                name={fieldNames.notWantToBeScorp}
              />
            </GridRowColumn>
            {values.notWantToBeScorp ===
              NotWantToBeScorpFollowup.stoppedMidYear && (
              <GridRowColumn width={12}>
                <Card type="subsection" backgroundColor="natural">
                  <Text as="bodyLg">
                    Got it. We’ll have someone from our Support team reach out
                    to help.
                    <br />
                    <br />
                    Please be advised, if you elected as an S corporation,
                    revoking the election takes time, and the process may not be
                    completed in time for the {taxYear} tax year.
                  </Text>
                </Card>
              </GridRowColumn>
            )}
            <GridRowColumn short width={12}>
              <FormikRadioButton
                label="It’s something else"
                variant="default"
                value={NotWantToBeScorpFollowup.somethingElse}
                name={fieldNames.notWantToBeScorp}
              />
            </GridRowColumn>
            {values.notWantToBeScorp ===
              NotWantToBeScorpFollowup.somethingElse && (
              <GridRowColumn width={12}>
                <FormikTextArea
                  label="Let our Support team know how we can help"
                  placeholder="Please explain your situation (required)"
                  name={fieldNames.scorpOther}
                  schema={makeReqStringSchema()}
                />
              </GridRowColumn>
            )}
          </>
        )}
        {values.scorpHelpAnswer === ScorpHelpAnswer.noLongerScorp && (
          <>
            <GridRowColumn width={12}>
              <FormikLabelError
                label="What best describes your situation?"
                name={fieldNames.noLongerScorp}
                schema={makeReqStringSchema()}
              />
            </GridRowColumn>
            <GridRowColumn short width={12}>
              <FormikRadioButton
                label={`I revoked my S corporation election but I'm not sure if I still need to file as an S corporation for ${taxYear}`}
                variant="default"
                value={NoLongerScorpFollowup.revokedScorp}
                name={fieldNames.noLongerScorp}
              />
            </GridRowColumn>
            {values.noLongerScorp === NoLongerScorpFollowup.revokedScorp && (
              <GridRowColumn width={12}>
                <Card type="subsection" backgroundColor="natural">
                  <Text as="bodyLg">
                    Got it. We’ll have someone from our Support team reach out
                    to help.
                    <br />
                    <br />
                    Please be advised, if you elected as an S corporation,
                    revoking the election takes time, and the process may not be
                    completed in time for the {taxYear} tax year.
                  </Text>
                </Card>
              </GridRowColumn>
            )}
            <GridRowColumn short width={12}>
              <FormikRadioButton
                label={`I dissolved my business entity and went back to operating as an unincorporated sole proprietor in ${taxYear}`}
                variant="default"
                value={NoLongerScorpFollowup.dissolvedBusiness}
                name={fieldNames.noLongerScorp}
              />
            </GridRowColumn>
            {values.noLongerScorp ===
              NoLongerScorpFollowup.dissolvedBusiness && (
              <GridRowColumn width={12}>
                <Card type="subsection" backgroundColor="natural">
                  <Text as="bodyLg">
                    Got it. We’ll have someone from our Support team reach out
                    to help.
                    <br />
                    <br />
                    Please be advised, if you elected as an S corporation,
                    revoking the election takes time, and the process may not be
                    completed in time for the {taxYear} tax year.
                  </Text>
                </Card>
              </GridRowColumn>
            )}
            <GridRowColumn short width={12}>
              <FormikRadioButton
                label={`I stopped operating as an S corporation mid-year in ${taxYear} but haven't revoked my election or dissolved my business entity`}
                variant="default"
                value={NoLongerScorpFollowup.stoppedMidYear}
                name={fieldNames.noLongerScorp}
              />
            </GridRowColumn>
            {values.noLongerScorp === NoLongerScorpFollowup.stoppedMidYear && (
              <GridRowColumn width={12}>
                <Card type="subsection" backgroundColor="natural">
                  <Text as="bodyLg">
                    Got it. We’ll have someone from our Support team reach out
                    to help.
                    <br />
                    <br />
                    Please be advised, if you elected as an S corporation,
                    revoking the election takes time, and the process may not be
                    completed in time for the {taxYear} tax year.
                  </Text>
                </Card>
              </GridRowColumn>
            )}
            <GridRowColumn short width={12}>
              <FormikRadioButton
                label="It’s something else"
                variant="default"
                value={NoLongerScorpFollowup.somethingElse}
                name={fieldNames.noLongerScorp}
              />
            </GridRowColumn>
            {values.noLongerScorp === NoLongerScorpFollowup.somethingElse && (
              <GridRowColumn width={12}>
                <FormikTextArea
                  label="Let our Support team know how we can help"
                  placeholder="Please explain your situation (required)"
                  name={fieldNames.scorpOther}
                  schema={makeReqStringSchema()}
                />
              </GridRowColumn>
            )}
          </>
        )}
        {values.scorpHelpAnswer === ScorpHelpAnswer.wantToBeScorp && (
          <>
            <GridRowColumn width={12}>
              <FormikLabelError
                label={`Have you filed for an S corporation election in ${taxYear}?`}
                name={fieldNames.wantToBeScorp}
                schema={makeReqStringSchema()}
              />
            </GridRowColumn>
            <GridRowColumn short width={12}>
              <FormikRadioButton
                label="Yes, but I never heard back from the IRS"
                variant="default"
                value={WantToBeScorpFollowup.notHeardBack}
                name={fieldNames.wantToBeScorp}
              />
            </GridRowColumn>
            {values.wantToBeScorp === WantToBeScorpFollowup.notHeardBack && (
              <GridRowColumn width={12}>
                <Card type="subsection" backgroundColor="natural">
                  <Text as="bodyLg">
                    You should receive a Form CP261 as confirmation. Once you
                    do, upload it to Heard right away to proceed with the rest
                    of your Tax Season accounting and tax activities.
                  </Text>
                </Card>
              </GridRowColumn>
            )}
            <GridRowColumn short width={12}>
              <FormikRadioButton
                label="No, I need help with this"
                variant="default"
                value={WantToBeScorpFollowup.needHelp}
                name={fieldNames.wantToBeScorp}
              />
            </GridRowColumn>
            {values.wantToBeScorp === WantToBeScorpFollowup.needHelp && (
              <GridRowColumn width={12}>
                <Card type="subsection" backgroundColor="natural">
                  <Text as="bodyLg">
                    You’ll need to submit an S corporation application (Form
                    2553) to submit a formal request to the IRS.
                    <br />
                    <br />
                    If you have any questions on this process, you can reach out
                    via Messages.
                  </Text>
                </Card>
              </GridRowColumn>
            )}

            <GridRowColumn short width={12}>
              <FormikRadioButton
                label="It’s something else"
                variant="default"
                value={WantToBeScorpFollowup.somethingElse}
                name={fieldNames.wantToBeScorp}
              />
            </GridRowColumn>
            {values.wantToBeScorp === WantToBeScorpFollowup.somethingElse && (
              <GridRowColumn width={12}>
                <FormikTextArea
                  label="Let our Support team know how we can help"
                  placeholder="Please explain your situation (required)"
                  name={fieldNames.scorpOther}
                  schema={makeReqStringSchema()}
                />
              </GridRowColumn>
            )}
          </>
        )}
        {values.scorpHelpAnswer === ScorpHelpAnswer.somethingElse && (
          <GridRowColumn width={12}>
            <FormikTextArea
              label="Let our Support team know how we can help"
              placeholder="Please explain your situation (required)"
              name={fieldNames.scorpOther}
              schema={makeReqStringSchema()}
            />
          </GridRowColumn>
        )}
        <FormFlowFooter
          onBack={() => goBack(TAX_SEASON_KICKOFF_SURVEY.taxEntity)}
          onForward={submitForm}
          continueDisabled={isSubmitting || !isValid}
        />
      </Grid>
    </FormikProvider>
  )
}

export default ScorpTaxEntityHelp
