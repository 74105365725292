import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, List } from 'semantic-ui-react'

import {
  Alert,
  Button,
  Checkbox,
  GridRowColumn,
  Modal,
} from '../../../../components/BaseComponents'
import {
  SUBMIT_TAX_CHECKLIST_KEY,
  submitTaxChecklist,
} from './taxChecklistQuestion.actions'
import {
  getFetchError,
  getIsFetching,
  invalidateFetch,
} from '../../../../reducers/fetch'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { Colors } from '../../../../styles/theme'
import { select1040FormForYear } from '../annualTaxFilingForms.selector'
import { getAnnualTaxFilingForYearSelector } from '../annualTaxFilings.selector'

const ApproveAndSubmitModal = ({
  open,
  close,
}: {
  open: boolean
  close: () => void
}) => {
  const isSubmitting = useReselector(getIsFetching, SUBMIT_TAX_CHECKLIST_KEY)
  const error = useReselector(getFetchError, SUBMIT_TAX_CHECKLIST_KEY)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const personalFilingForm = useReselector(select1040FormForYear, taxYear)
  const taxFiling = useReselector(getAnnualTaxFilingForYearSelector, taxYear)
  const requestedExtension = Boolean(taxFiling?.extensionRequestedAt)
  const [confirmedReview, setConfirmedReview] = useState(false)
  const submit = useCallback(async () => {
    const submitted = await submitTaxChecklist()(dispatch)

    if (submitted) {
      close()
      navigate('/taxes/annual')
    }
  }, [dispatch, close, navigate])

  const closeAndReset = () => {
    dispatch(invalidateFetch(SUBMIT_TAX_CHECKLIST_KEY))
    setConfirmedReview(false)
    close()
  }

  return (
    <Modal size="tiny" open={open} onClose={closeAndReset} closeIcon>
      <Modal.Header>Share with tax preparer</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn style={{ backgroundColor: Colors.stone40 }}>
            <List
              bulleted
              style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
            >
              <List.Item>
                I have thoroughly reviewed all of my answers for accuracy.
              </List.Item>
              <List.Item>
                The more complete my Tax Questionnaire is, the more efficiently
                and effectively my tax preparer can work.
              </List.Item>
              {requestedExtension ? (
                <List.Item>
                  Tax preparation will begin once all accounting and bookkeeping
                  tasks, and extension request are completed.
                </List.Item>
              ) : (
                <List.Item>
                  Tax preparation will begin once all accounting and bookkeeping
                  tasks are completed.
                </List.Item>
              )}
              {!personalFilingForm && (
                <List.Item>
                  I will need to file my Personal Taxes (Form 1040)
                </List.Item>
              )}
            </List>
          </GridRowColumn>
          <GridRowColumn>
            <Checkbox
              onClick={() => setConfirmedReview(!confirmedReview)}
              label="I have read and understand all statements above."
              variant="default"
            />
          </GridRowColumn>
          {error && (
            <GridRowColumn>
              <Alert type="error">
                {error.message ?? 'Something went wrong!'}
              </Alert>
            </GridRowColumn>
          )}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          variant="actionLink"
          onClick={closeAndReset}
          style={{ marginRight: 32 }}
        >
          Cancel
        </Button>
        <Button
          onClick={submit}
          disabled={isSubmitting || !confirmedReview}
          loading={isSubmitting}
        >
          Approve
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ApproveAndSubmitModal
