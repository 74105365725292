import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Text from '../../../../components/BaseComponents/Text'
import { Alert, Icon } from '../../../../components/BaseComponents'
import { LandingPage } from '../../../../components/LandingPage'
import './styles.scss'

export const Intro = ({ taxYear }: { taxYear: string }) => {
  return (
    <LandingPage
      imageSrc="https://heard-images.s3.amazonaws.com/assets/books_shadow.svg"
      imageAlt="books"
      imageWidth={300}
      imageHeight={300}
      title={`Let's close your ${taxYear} books!`}
    >
      <Text style={{ marginTop: '16px' }}>
        In this process, you’ll get your books ready so our bookkeeping team can
        close them for the year.
      </Text>

      <Text style={{ marginTop: '16px' }}>
        This takes about <strong>30 minutes</strong>. Your progress is
        automatically saved, so you can take a break and return at any time.
      </Text>

      <Alert
        style={{ marginTop: '32px' }}
        title="Why is this important?"
        customIcon={<Icon icon={solid('info-circle')} />}
        contentStyle={{ textAlign: 'left' }}
      >
        The numbers in your bookkeeping go directly into your tax return.
        Filling in missing information and fixing errors reduces the odds of an
        audit on your tax return.
      </Alert>
    </LandingPage>
  )
}
