import { Image } from 'semantic-ui-react'

import {
  Accordion,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectCurrentAnnualTaxYear,
  selectTaxDetailsByYear,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { selectTqFilingIsReadOnly } from '../annualTaxFilings.selector'
import {
  select1120sFormForYear,
  selectIsTwoFormFiler,
} from '../annualTaxFilingForms.selector'
import {
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../../utils/dateHelpers'

const TaxChecklistFaq = () => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const readOnly = useReselector(selectTqFilingIsReadOnly, taxYear)
  const businessForm = useReselector(select1120sFormForYear, taxYear)
  const isTwoFormFiler = useReselector(selectIsTwoFormFiler, taxYear)
  const taxDetails = useReselector(selectTaxDetailsByYear, taxYear)

  const dueDate = formatISOFromUTC(
    businessForm || isTwoFormFiler
      ? taxDetails?.form_1120_s_tq_due_date
      : taxDetails?.form_1040_tq_due_date,
    DATE_FORMATS_LUXON.DISPLAY_LONG
  )

  if (readOnly) {
    return null
  }

  return (
    <>
      <GridRowColumn spacer={2} width={12}>
        <Text as="h2">Learn more</Text>
      </GridRowColumn>
      <GridRowColumn spacer={2} width={12}>
        <Accordion
          title="What do I need to complete this checklist?"
          content={
            <Text>
              <ul style={{ paddingLeft: 10, marginTop: 0 }}>
                <li>Access to all business bank accounts</li>
                <li>All the tax forms you’ve received so far</li>
                <li>
                  Additional details around your business expenses and owner’s
                  investments
                </li>
              </ul>
            </Text>
          }
          variant="text"
        />
      </GridRowColumn>
      <GridRowColumn spacer={2} width={12}>
        <Accordion
          title="Is my progress saved?"
          content={
            <Text>
              Yes, your progress is automatically saved, so feel free to exit
              and come back.
            </Text>
          }
          variant="text"
        />
      </GridRowColumn>
      <GridRowColumn spacer={2} width={12}>
        <Accordion
          title="What if I don’t know how to answer something?"
          content={
            <>
              <Text>
                Use the &#39;Unsure, follow-up with tax preparer&#39; checkbox
                to alert your tax preparer that you need guidance, regardless of
                whether you&apos;ve answered the question.
              </Text>
              <br />
              <Image
                style={{ width: 518, height: 257 }}
                src="https://heard-images.s3.amazonaws.com/assets/tqQuestion.svg"
              />
            </>
          }
          variant="text"
        />
      </GridRowColumn>
      <GridRowColumn spacer={2} width={12}>
        <Accordion
          title="What if I don’t have a form or document?"
          content={
            <>
              <Text>
                Check out the ‘View instructions’ link we’ve provided for each
                document for help on how to find or request documents.
                <br />
                Document not relevant, or still having trouble locating? You can
                check &#39;Unsure, or don&apos;t have&#39; to flag this for
                discussion with your tax preparer later.
              </Text>
              <br />
              <Image
                style={{ width: 518, height: 147 }}
                src="https://heard-images.s3.amazonaws.com/assets/docUpload.svg"
              />
            </>
          }
          variant="text"
        />
      </GridRowColumn>
      <GridRowColumn spacer={2} width={12}>
        <Accordion
          title="What if I don’t finish my questionnaire before the deadline?"
          content={
            <Text>
              If you haven&apos;t completed your questionnaire by {dueDate},
              you&apos;ll be prompted to file a tax extension. Filing an
              extension is penalty-free and gives you more time to complete your
              return. Once filed, your new deadlines will automatically update
              in Heard.
              <br />
              <br />
              Keep in mind that while an extension gives you more time to file,
              any taxes owed are still due by the original IRS deadline to avoid
              penalties and interest. Your tax preparer can help estimate any
              payment needed with your extension.
            </Text>
          }
          variant="text"
        />
      </GridRowColumn>
    </>
  )
}

export default TaxChecklistFaq
