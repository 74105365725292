import axios from 'axios'

import { Dispatch, GetState, ReduxState } from '../../utils/typeHelpers'
import {
  receiveAdminUserDeletion,
  receiveUserFinancialProfile,
  setAdminAllUsers,
  setAdminSingleUser,
  updateAdminSingleUser,
  updateUserRoles,
  UserWithAdminInfo,
} from '../../reducers/admin/allUsersReducer'
import { FinancialProfile, User } from '../../reducers/auth/userReducer'
import { fetchIfNeededWrapper, fetchWrapper } from '../../reducers/fetch'
import { parseErrorFromCatch } from '../../utils/errorHelpers'
import { UpdateUserPayload } from '../userActions'
import { keyBy } from 'lodash'

export const FETCH_ALL_USERS_KEY = 'FETCH_ALL_USERS_KEY'
export const FETCH_ALL_ADMINS_KEY = 'FETCH_ALL_ADMINS_KEY'

export const fetchAllUsersIfNeeded = (
  forceFetch?: boolean,
  params?: { includeFinancialProfile?: boolean }
) =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_ALL_USERS_KEY,
    defaultErrorMessage: 'There was an error fetching all users.',
    alwaysFetch: Boolean(forceFetch),
    fetchFunction: async (dispatch) => {
      await axios
        .get<UserWithAdminInfo[]>('/finances/api/v1/admin/users', { params })
        .then(({ data }) => dispatch(setAdminAllUsers(keyBy(data, 'id'))))

      // Admin flag comes back with this call and is required to populate admin pages
      await fetchAllAdmins()(dispatch)
    },
  })

export const fetchAllAdmins = () =>
  fetchWrapper({
    fetchKey: FETCH_ALL_ADMINS_KEY,
    defaultErrorMessage: 'There was an error fetching all administrators.',
    fetchFunction: (dispatch) =>
      axios
        .get<UserWithAdminInfo[]>('/finances/api/v1/admin/all')
        .then((json) => {
          dispatch(
            setAdminAllUsers(
              keyBy(
                json.data.map((data) => ({ ...data, admin: true })),
                'id'
              )
            )
          )
          return json.data
        }),
  })

export const shouldFetchSingleUser = (state: ReduxState, userId: number) =>
  !state.admin.allUsers.byId[userId]

export const adminFetchSingleUserIfNeeded =
  (userId: number) => (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchSingleUser(getState(), userId)) {
      return dispatch(adminFetchSingleUser(userId))
    } else {
      return Promise.resolve()
    }
  }

export const FETCH_ADMIN_SINGLE_USER_KEY = 'FETCH_ADMIN_SINGLE_USER_KEY'
export const adminFetchSingleUser = (userId: number | string) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error fetching single user.',
    fetchKey: FETCH_ADMIN_SINGLE_USER_KEY,
    fetchFunction: (dispatch) =>
      axios
        .get<UserWithAdminInfo | ''>(`/finances/api/v1/admin/users/${userId}`)
        .then((json) => {
          // Api returns an empty string if a user isn't found.  Have the fetch wrapper handle this
          if (!json.data) {
            throw new Error('User not found')
          }

          dispatch(setAdminSingleUser(json.data))
          return json.data
        }),
  })

export const adminDeleteUser = (id: number) =>
  fetchWrapper({
    defaultErrorMessage: 'Unable to delete user.',
    defaultValue: false,
    fetchFunction: (dispatch) =>
      axios.delete<string>(`/finances/api/v1/admin/users/${id}`).then(() => {
        dispatch(receiveAdminUserDeletion(id))
        return true
      }),
  })

export const adminUpdateUserWithId =
  (userId: number, data: Partial<UpdateUserPayload>) => (dispatch: Dispatch) =>
    axios
      .post<User>(`/finances/api/v1/admin/users/${userId}`, data)
      .then((json) => {
        dispatch(updateAdminSingleUser(json.data))
        return { error: null, data: json.data }
      })
      .catch((err) => {
        return { error: err.message, data: null }
      })

export const createUserRoleWithId = (
  userId: number,
  data: {
    userId: number
    roleId: number
    isDefaultBookkeeper: boolean | null
    isDefaultManager: boolean | null
    avatarUrl: string | null
    checkinUrl: string | null
    createdAt?: string
    updatedAt?: string
  }
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error upserting userRole',
    fetchFunction: async (dispatch) => {
      const json = await axios.post<Pick<User, 'id' | 'roles'>>(
        `/finances/api/v1/admin/user_roles/${userId}`,
        data
      )
      dispatch(updateUserRoles(json.data))
      return true
    },
  })

export const updateUserRoleWithId =
  (
    userId: number,
    data: {
      userId: number
      roleId: number
      isDefaultBookkeeper: boolean | null
      isDefaultManager: boolean | null
      avatarUrl: string | null
      checkinUrl: string | null
      createdAt?: string
      updatedAt?: string
    }
  ) =>
  async (dispatch: Dispatch) => {
    const url = `/finances/api/v1/admin/user_roles/${userId}/roles/${data.roleId}`

    try {
      const json = await axios.put<
        Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'roles'>
      >(url, data)
      dispatch(updateUserRoles(json.data))
      return true
    } catch (err) {
      return { error: parseErrorFromCatch(err) }
    }
  }

export const removeUserRoleWithId = (userId: number, roleId: number) =>
  fetchWrapper({
    defaultErrorMessage: 'Error deleting userRole',
    fetchFunction: async (dispatch) => {
      const json = await axios.delete<
        Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'roles'>
      >(`/finances/api/v1/admin/user_roles/${userId}/roles/${roleId}`)
      dispatch(updateUserRoles(json.data))
      return true
    },
  })

export const updateUserFinancialProfile = (
  financialProfileId: number,
  data: Partial<FinancialProfile>
) =>
  fetchWrapper({
    defaultErrorMessage: 'There was an error updating financial profile.',
    fetchFunction: (dispatch) =>
      axios
        .post<FinancialProfile>(
          `/finances/api/v1/admin/financial_profiles/${financialProfileId}`,
          data
        )
        .then((json) => {
          dispatch(receiveUserFinancialProfile(json.data))
          return json.data
        }),
  })
