import { useCallback, useContext, useMemo } from 'react'
import { DateTime } from 'luxon'
import { Link } from '../../../../../components/BaseComponents'
import BookkeepingReviewSection from '../bookkeeping-review-section'
import {
  EndOfYearReviewStepStatus,
  MissingBankStatementsStep,
  EndOfYearAdminReviewStep,
  MissingBankStatementsStepContext,
} from '../../types'
import { TEST_IDS } from '../helpers'
import SimpleRow from '../simple-row'
import { AdminYearEndReviewContext } from '../context'
import UpdatedRow from '../updated-row'
import './styles.scss'

const { pending, userActionRequired, complete } = EndOfYearReviewStepStatus
const STEP_TYPE = EndOfYearAdminReviewStep.uploadMissingStatements

type MissingBankStatementsSectionProps = {
  userId: number
  step: MissingBankStatementsStep | null
}

const MissingBankStatementsSection = ({
  userId,
  step,
}: MissingBankStatementsSectionProps) => {
  const { taxYear, updateStep, openStatementReUploadModal } = useContext(
    AdminYearEndReviewContext
  )

  const nextYear = useMemo(() => {
    const intYear = parseInt(taxYear)
    return Number.isNaN(intYear) ? DateTime.now().year + 1 : intYear + 1
  }, [taxYear])

  const onConfirmClick = useCallback(() => {
    const status = step?.status === complete ? pending : complete
    updateStep({
      status,
      step: STEP_TYPE,
      context:
        status === complete
          ? {
              ...step?.context,
              missingStatements: false,
              requestNextYear: false,
              adminNote: undefined,
            }
          : undefined,
    })
  }, [step, updateStep])

  const updateStepUsing = useCallback(
    async (context: MissingBankStatementsStepContext) => {
      const status = context.missingStatements ? userActionRequired : pending
      await updateStep({
        status,
        step: STEP_TYPE,
        context: {
          ...context,
          adminNote: status === pending ? '' : context.adminNote,
        },
      })
    },
    [updateStep]
  )

  const onMissingStatementsCallback = useCallback(
    async (adminNote: string) => {
      const context = step?.context ?? {
        missingStatements: false,
        requestNextYear: false,
      }
      await updateStepUsing({
        ...context,
        adminNote,
        missingStatements: true, // must be true if callback is executed
      })
    },
    [step?.context, updateStepUsing]
  )

  return (
    <BookkeepingReviewSection
      sectionTitle="Upload missing bank statements"
      className="eoy-admin__missing-bank-statements"
      status={step?.status ?? pending}
      updatedByUser={step?.context?.updatedByUser}
      confirmationTitle="Confirmed"
      confirmationDescription={
        step?.status === userActionRequired ? (
          <strong>Requesting reupload of bank statement(s).</strong>
        ) : null
      }
      minHeight={130}
      testId="missing-bank-statements"
      onConfirmClick={onConfirmClick}
    >
      {step?.context?.updatedByUser && (
        <UpdatedRow
          description="User has uploaded new statements"
          testId={TEST_IDS.missingStatementsUpdatedRow}
        />
      )}

      <SimpleRow
        description="Check if the user is missing a statement"
        checkboxLabel="Incorrect/missing"
        checked={step?.context?.missingStatements ?? false}
        testId={TEST_IDS.checkIfMissingBankStatements}
        onCheckboxClick={() =>
          step?.context?.missingStatements
            ? updateStepUsing({
                ...step.context,
                missingStatements: false,
                requestNextYear: false,
              })
            : openStatementReUploadModal(
                step?.context?.adminNote ?? null,
                onMissingStatementsCallback
              )
        }
      />

      {step?.context?.missingStatements && (
        <SimpleRow
          description={`Check if ${nextYear} statements are needed`}
          checkboxLabel={`Request ${nextYear} statements`}
          checked={step?.context?.requestNextYear ?? false}
          testId={TEST_IDS.checkIfNextYearStatementsNeeded}
          onCheckboxClick={() =>
            updateStepUsing({
              ...step.context,
              requestNextYear: !step?.context?.requestNextYear,
            })
          }
        />
      )}

      <Link
        className="eoy-admin__missing-bank-statements__view-in-documents"
        to={`/admin/finances/records/${userId}?tab=documents`}
        size="small"
      >
        View in Documents &rarr;
      </Link>
    </BookkeepingReviewSection>
  )
}

export default MissingBankStatementsSection
