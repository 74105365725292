import { CSSProperties } from 'react'
import styled from 'styled-components'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core'

import { Colors } from '../../styles/theme'
import CircularWrapper, { CircularWrapperProps } from './CircularWrapper'
import './BaseComponents.scss'

export interface BaseIconProps extends Omit<FontAwesomeIconProps, 'icon'> {
  icon: IconDefinition
  color?: keyof typeof Colors
}

const Icon = ({ color, size = 'lg', ...rest }: BaseIconProps) => (
  <FontAwesomeIcon {...rest} size={size} color={color && Colors[color]} />
)

interface StackedProps extends BaseIconProps {
  backgroundIcon: IconDefinition
  backgroundColor?: keyof typeof Colors
  backgroundSize?: SizeProp
  backgroundHeight?: number | string
  margin?: string | number
  marginRight?: string | number
  marginLeft?: string | number
  marginTop?: string | number
  marginBottom?: string | number
  className?: string
  title?: string
}

export const StackedIcon = styled(
  ({
    color,
    size = 'lg',
    backgroundIcon,
    backgroundColor,
    backgroundSize = '4x',
    className,
    title,
    ...rest
  }: StackedProps) => (
    <span className={className} title={title}>
      <FontAwesomeIcon
        className="faStackedBackground"
        icon={backgroundIcon}
        color={backgroundColor && Colors[backgroundColor]}
        size={backgroundSize}
      />
      <FontAwesomeIcon
        className="faStackedIcon"
        {...rest}
        size={size}
        color={color && Colors[color]}
      />
    </span>
  )
)(({ marginRight, backgroundHeight = '3em' }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  verticalAlign: 'middle',
  height: backgroundHeight,
  width: backgroundHeight,
  marginRight,

  '.faStackedIcon': {
    lineHeight: 'inherit',
    left: 0,
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
  },

  '.faStackedBackground': {
    left: 0,
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
  },
}))

interface IconButtonProps extends BaseIconProps {
  className?: string
  hitBoxPadding?: number
  onClick?: () => void
  style?: CSSProperties
}

export const IconButton = styled(
  ({ className, style, ...rest }: IconButtonProps) => (
    <span className={className} style={style}>
      <Icon {...rest} role="button" aria-hidden={false} />
    </span>
  )
)(({ hitBoxPadding = 15 }) => ({
  svg: {
    cursor: 'pointer',
    margin: hitBoxPadding * -1,
    padding: hitBoxPadding,
  },
}))

type BorderedIconProps = BaseIconProps & CircularWrapperProps

export const BorderedIcon = ({
  height,
  wrapperColor,
  borderColor,
  ...rest
}: BorderedIconProps) => (
  <CircularWrapper
    height={height}
    wrapperColor={wrapperColor}
    borderColor={borderColor}
  >
    <Icon {...rest} />
  </CircularWrapper>
)

export default Icon
