import {
  YearEndModuleStatusOptions,
  YearEndModuleType,
} from '../yearEndModuleStatus.slice'
import YearEndModuleBaseCard, {
  defaultDeadlineApproaching,
  getDeadlineApproachingDaysUntilDueDate,
  UpcomingStatusTextWrapper,
  YearEndModuleStatusDynamicDataParams,
} from './YearEndModuleStatusBaseCard'
import { Link, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import { TaxFormType } from '../../Taxes/AnnualTaxes/Questionnaires/constants'
import {
  getModuleNameCopy,
  useModuleActionButtonBehavior,
} from '../yearEndModuleStatus.helpers'

const getDescription = (
  status: YearEndModuleStatusOptions,
  annualTaxFormNeeds: Array<TaxFormType> | null
) => {
  const baseDescription = (
    <>
      We’ll collect all the information and documents needed so your tax
      preparer can file your taxes quickly and accurately. This will cover:
      <ul>
        {annualTaxFormNeeds?.includes(TaxFormType.form1120s) && (
          <li>
            <b>Business Tax Return</b> (Form 1120-S)
          </li>
        )}
        {annualTaxFormNeeds?.includes(TaxFormType.form1040) && (
          <li>
            <b>Personal Tax Return</b> (Form 1040)
          </li>
        )}
      </ul>
    </>
  )
  switch (status) {
    case YearEndModuleStatusOptions.upNext:
    case YearEndModuleStatusOptions.earlyStart:
    case YearEndModuleStatusOptions.inProgress: {
      return baseDescription
    }
    case YearEndModuleStatusOptions.upcoming: {
      return (
        <UpcomingStatusTextWrapper
          moduleType={YearEndModuleType.taxQuestionnaire}
        >
          {baseDescription}
        </UpcomingStatusTextWrapper>
      )
    }
    case YearEndModuleStatusOptions.overdue: {
      return (
        <>
          <Text as="bodySm">
            Until you file an extension,{' '}
            <b>you’re at risk of failure-to-file penalties</b>. You’ll need to
            complete this as soon as possible to minimize those penalties.
          </Text>
          <br />
          <Text as="bodySm">
            We’ll collect all the information and documents needed so your tax
            preparer can file your taxes quickly and accurately.
          </Text>
        </>
      )
    }
    case YearEndModuleStatusOptions.complete: {
      return 'We’ve received your Tax Questionnaire. We’ll connect you to a tax preparer soon.'
    }
    default:
      return null
  }
}

const TaxQuestionnaireCard = ({
  status,
  dueDate,
  taxYear,
  substepIdentifier,
  annualTaxFormNeeds,
}: YearEndModuleStatusDynamicDataParams & {
  annualTaxFormNeeds: Array<TaxFormType> | null
}) => {
  const description = getDescription(status, annualTaxFormNeeds)
  const { onActionClick } = useModuleActionButtonBehavior(
    YearEndModuleType.taxQuestionnaire,
    status
  )
  const daysUntilDue = getDeadlineApproachingDaysUntilDueDate(dueDate) || 0
  return (
    <YearEndModuleBaseCard
      key={YearEndModuleType.taxQuestionnaire}
      taxYear={taxYear}
      status={status}
      dueDate={dueDate}
      header={getModuleNameCopy(YearEndModuleType.taxQuestionnaire)}
      imageUrl="https://heard-images.s3.amazonaws.com/assets/checklist-new.svg"
      description={<Text as="bodySm">{description}</Text>}
      substepIdentifier={substepIdentifier}
      timer={{
        unit: 'hours',
        value: '1-2',
      }}
      deadlineApproaching={{
        ...defaultDeadlineApproaching,
        alertBody: (
          <Text as="bodySm">
            {daysUntilDue >= 0 ? 'After that, y' : 'Y'}ou’ll need to file an
            extension for your taxes.{' '}
            <Link
              href="https://support.joinheard.com/hc/en-us/articles/20989977806231-Annual-Tax-FAQs"
              newPage
              size="small"
              style={{
                textDecoration: 'underline',
                color: Colors.charcoal,
                textDecorationColor: Colors.charcoal,
              }}
            >
              More info
            </Link>
          </Text>
        ),
      }}
      button={{
        onActionClick,
      }}
    />
  )
}

export default TaxQuestionnaireCard
