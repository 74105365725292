import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Divider, Icon } from 'semantic-ui-react'
import { isFunction } from 'lodash'

import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { selectCurrentAnnualTaxYear } from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import Text from '../../../components/BaseComponents/Text'
import { NavButtons } from '../end-of-year-review/nav-buttons'
import { IStep, IStepState, STEPS } from './step-map'
import './styles.scss'
import {
  userGetBookkeepingStep,
  userSubmitBookkeepingModule,
  userUpdateBookkeepingStepContext,
} from '../end-of-year-review/actions'

type StepContext = Record<string, unknown>

export const ReviewAndCloseContainer = () => {
  const [currentStep, setCurrentStep] = useState<IStep | null>(null)
  const [currentStepIdx, setCurrentStepIdx] = useState<number>(0)
  const [userSteps, setUserSteps] = useState<IStep[]>([])
  const [prevStep, setPrevStep] = useState<string>('')
  const [isStepComplete, setIsStepComplete] = useState<boolean>(true)
  const [isPreventCall, setIsPreventCall] = useState<boolean>(false)
  const [stepContext, setStepContext] = useState<StepContext>({})
  const year = useReselector(selectCurrentAnnualTaxYear)
  const location = useLocation()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const path = location.pathname.split('/')
    const stepRoute = path[5]
    const stepIndex = userSteps.findIndex((step) => step.route === stepRoute)
    if (stepIndex !== -1) {
      setCurrentStepIdx(stepIndex)
    }
  }, [location.pathname, userSteps])

  const setReviewIndex = (route: string) => {
    const stepIndex = userSteps.findIndex((step) => step.route === route)
    if (stepIndex !== -1) {
      setCurrentStepIdx(stepIndex)
    }
  }

  const userBookkeepingStatus = useMemo(() => {
    return {}
  }, [])

  const stepState = useMemo<IStepState>(() => {
    return {
      lastEoyBookkeepingScreen: '/base/year/step_slug',
      taxYear: year,
    }
  }, [year])

  useEffect(() => {
    const steps = STEPS.filter((step) => {
      return step.isRequired(userBookkeepingStatus, stepState)
    })
    setUserSteps(steps)
  }, [userBookkeepingStatus, stepState])

  useEffect(() => {
    if (!isPreventCall) {
      setIsStepComplete(userSteps[currentStepIdx]?.isAlwaysComplete || false)
    }
    const prevStep =
      currentStepIdx === 0
        ? 'taxes/annual'
        : userSteps[currentStepIdx - 1]?.route

    setPrevStep(prevStep)
    setIsPreventCall(false)
  }, [currentStepIdx, userSteps, isPreventCall])

  useEffect(() => {
    const fetchStep = async (stepId: string | undefined) => {
      if (stepId) {
        const step = await userGetBookkeepingStep(stepId)(dispatch)
        setStepContext(step?.context ?? {})
      }
    }
    const currentStepId = userSteps?.[currentStepIdx]?.id
    fetchStep(currentStepId)
  }, [currentStepIdx, userSteps, dispatch])

  const updateStepContext = useCallback(
    async (factor: number) => {
      const fromStepId = userSteps[currentStepIdx]?.id
      const toStepId = userSteps[currentStepIdx + factor]?.id

      if (fromStepId && toStepId) {
        const updatedStep = await userUpdateBookkeepingStepContext(
          fromStepId,
          toStepId,
          stepContext
        )(dispatch)
        if (updatedStep?.context) {
          setStepContext((prev) => ({ ...prev, ...updatedStep.context }))
          setCurrentStep(userSteps[currentStepIdx + factor])
        }
        return Boolean(updatedStep)
      }
      return true
    },
    [userSteps, currentStepIdx, stepContext, dispatch]
  )

  type AutoSaveArgument =
    | StepContext
    | ((prevStepContext: StepContext) => StepContext)
  const autoSaveStepContext = useCallback(
    async (arg: AutoSaveArgument) => {
      const newStepContext = isFunction(arg) ? arg(stepContext) : arg
      const fromStepId = userSteps[currentStepIdx]?.id
      if (fromStepId) {
        const updatedStep = await userUpdateBookkeepingStepContext(
          fromStepId,
          fromStepId,
          newStepContext
        )(dispatch)
        if (updatedStep?.context) {
          setStepContext((prev) => ({ ...prev, ...updatedStep.context }))
        }
      }
    },
    [userSteps, currentStepIdx, stepContext, dispatch]
  )

  const handlePrevStep = useCallback(async () => {
    const success = await updateStepContext(-1)
    if (success) {
      setCurrentStepIdx((prev) => prev - 1)
      if (prevStep !== 'taxes/annual') {
        navigate(`/taxes/annual/review-and-close-books/${year}/${prevStep}`)
      } else {
        navigate('/taxes/annual')
      }
    }
  }, [updateStepContext, prevStep, year, navigate])

  const handlSaveAndExit = useCallback(async () => {
    const success = await updateStepContext(0)
    if (success) {
      navigate('/taxes/annual')
    }
  }, [navigate, updateStepContext])

  const setIsStepCompleteFun = useCallback((isComplete: boolean) => {
    setIsStepComplete(isComplete)
  }, [])

  const getCurrentStepComponent = () => {
    if (currentStepIdx === null || userSteps.length === 0) {
      return STEPS[0].component
    }
    return userSteps[currentStepIdx].component
  }

  const getCurrentProps = () => {
    if (userSteps?.[currentStepIdx] === null) {
      return {}
    }
    if (userSteps[currentStepIdx]?.route === 'first-submission') {
      return {
        userSteps,
        setReviewIndex,
        setIsStepComplete,
      }
    }

    if (userSteps?.[currentStepIdx]?.props !== null) {
      return {
        ...(userSteps[currentStepIdx]?.props(
          userBookkeepingStatus,
          stepState
        ) || {}),
      }
    }

    return {}
  }

  return (
    <div className="eoy-review_wrapper">
      <header className="header">
        <Text>{year} End of Year Review</Text>
        <div className="autosave-wrapper">
          <Icon name="check" style={{ color: '#457634' }} />
          <Text style={{ color: '#457634' }}>Autosaved</Text>
        </div>
      </header>

      <div className="step-body">
        {/* @ts-expect-error temp type issue resoltion - ticket hea-5043 */}
        {React.createElement(getCurrentStepComponent(), {
          ...getCurrentProps(),
          ...(currentStep?.isAlwaysComplete
            ? {}
            : { setIsStepComplete: setIsStepCompleteFun }),
          ...{
            stepContext,
            setStepContext: autoSaveStepContext,
          },
        })}
      </div>

      {!userSteps[currentStepIdx]?.submitText && (
        <Divider style={{ margin: 0 }} />
      )}

      <NavButtons
        isStepComplete={isStepComplete}
        stepBack={handlePrevStep}
        stepForward={async () => {
          await userSubmitBookkeepingModule()(dispatch)
          navigate('/taxes/annual')
        }}
        saveAndExit={handlSaveAndExit}
        isSubmit={userSteps[currentStepIdx]?.isSubmit}
        submitText={userSteps[currentStepIdx]?.submitText}
      />
    </div>
  )
}
