import { FinancialAccount } from '../../../reducers/finances/financialAccountsReducer'
import { Transaction } from '../../../reducers/admin/allTransactions.slice'
import { ProviderState } from '../../Provider/provider.reducer'
import {
  MissingStatement,
  UserDocument,
} from '../../UserDocuments/userDocuments.slice'
import { Intro } from './intro'
import { AllDone } from './all-done'
import { OtherExpenses } from './other-expenses'
import { OtherIncome } from './other-income'
import { UnclarifiedTransactions } from './unclarified-transactions'
import { BusinessExpenseInPersonal } from './business-expense-in-personal-account'
import { BusinessIncomeInPersonal } from './business-income-in-personal-account'
import { OwnersInvestment } from './owners-investments'
import { OwnersDistribution } from './owners-distribution'
import { DisconnectedAccounts } from './disconnected-accounts'
import { DocumentsIntro } from './documents-intro'
import { PayrollDocuments } from './payroll-documents'
import { ContractorDocuments } from './contractor-documents'
import { Review } from './review'
import { MissingStatements } from './missing-bank-statements'
import { ProcessingFeesDocuments } from './processing-fees-documents'
import { FixedAssetDepreciationDocuments } from './fixed-asset-depreciation'
import { PreviousTaxReturn } from './previous-tax-return'
import { ReviewDocuments } from './review-document-uploads'

export interface IUserBookkeepingStatus {
  financialAccounts: FinancialAccount[]
  missingBankStatements: MissingStatement[] | undefined
  isMissingStatements: boolean
  unclarifiedTransactions: {
    uncategorized: Transaction[]
    bookkeeperClarification: Transaction[]
  }
  otherExpenses: Transaction[]
  otherIncome: Transaction[]
  investmentTransactions: Transaction[]
  distributionTransactions: Transaction[]
  isSCorp: boolean
  reviewSteps?: IStep[]
  payrollAndContractorTxns?: Transaction[]
  processingFees: Transaction[]
  assetsOver2500: Transaction[]
  isMultiYearSCorp: boolean
  documents: UserDocument[]
}

export interface IStepState {
  lastEoyBookkeepingScreen: string
  providers: ProviderState
  taxYear: string
  hasHeardPayroll: boolean
  isOtherExpensesStepRequired: boolean
  isOtherIncomeStepRequired: boolean
  isOtherTransactionRequired: boolean
  isInvestmentTransactionStepRequired: boolean
  isOwnerDistributionStepRequired: boolean
}

export interface IStep {
  id: string | undefined // Identifier for the step in the backend
  name: string
  route: string
  component: unknown
  props:
    | ((
        userBookkeepingStatus: IUserBookkeepingStatus,
        stepState: IStepState
      ) => unknown)
    | null
  isRequired: (
    userBookkeepingStatus: IUserBookkeepingStatus,
    stepState: IStepState
  ) => boolean
  isAlwaysComplete: boolean
  finalReviewGroup?: string
  reviewGroupLabel?: string
  isSubmit?: boolean
}

export interface IProvider {
  id: number
  name: string
  createdAt: string
  updatedAt: string
}

export interface IProviders {
  [id: number]: IProvider
}

export enum HeardPayrollDocumentStatus {
  uploaded = 'uploaded',
  pending = 'pending',
  error = 'error',
}

export const HEARD_PROVIDER_NAME = 'Heard'

export const STEPS: IStep[] = [
  {
    id: 'landing',
    name: 'Landing',
    route: 'intro',
    component: Intro,
    props: (
      _userBookkeepingStatus: IUserBookkeepingStatus,
      stepState: IStepState
    ) => {
      return { taxYear: stepState.taxYear }
    },
    isRequired: () => true,
    isAlwaysComplete: true,
  },
  {
    id: 'disconnected-accounts',
    name: 'Disconnected Accounts',
    route: 'review-disconnected-accounts',
    component: DisconnectedAccounts,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return {
        financialAccounts: userBookkeepingStatus.financialAccounts,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const { financialAccounts } = userBookkeepingStatus
      return (
        financialAccounts.filter(
          (account) => account.accounts.needsReconnection
        ).length > 0
      )
    },
    isAlwaysComplete: false,
    finalReviewGroup: 'accounts',
    reviewGroupLabel: 'CONNECTED ACCOUNTS',
  },
  {
    id: 'upload-missing-statements',
    name: 'Missing Bank Statements',
    route: 'upload-missing-bank-statements',
    component: MissingStatements,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return {
        missingStatements: userBookkeepingStatus.missingBankStatements,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const { isMissingStatements } = userBookkeepingStatus
      return isMissingStatements
    },
    isAlwaysComplete: false,
    finalReviewGroup: 'accounts',
    reviewGroupLabel: 'CONNECTED ACCOUNTS',
  },
  {
    id: 'unclarified-transactions',
    name: 'Unclarified Transactions',
    route: 'categorize-transactions',
    component: UnclarifiedTransactions,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return {
        uncatTransactions:
          userBookkeepingStatus.unclarifiedTransactions.uncategorized,
        bookkeeperClarification:
          userBookkeepingStatus.unclarifiedTransactions.bookkeeperClarification,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus, stepState) => {
      const {
        unclarifiedTransactions: { uncategorized },
      } = userBookkeepingStatus
      const { isOtherTransactionRequired } = stepState

      return uncategorized.length > 0 || !isOtherTransactionRequired
    },
    isAlwaysComplete: false,
    finalReviewGroup: 'books',
    reviewGroupLabel: 'CLOSE YOUR BOOKS',
  },
  {
    id: 'other-income',
    name: 'Other Income',
    route: 'other-income',
    component: OtherIncome,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return {
        otherIncomes: userBookkeepingStatus.otherIncome,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus, stepState) => {
      const { otherIncome } = userBookkeepingStatus
      const { isOtherIncomeStepRequired } = stepState
      return otherIncome.length > 0 || !isOtherIncomeStepRequired
    },
    isAlwaysComplete: true,
    finalReviewGroup: 'books',
    reviewGroupLabel: 'CLOSE YOUR BOOKS',
  },
  {
    id: 'other-expenses',
    name: 'Other Expenses',
    route: 'other-expenses',
    component: OtherExpenses,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return {
        otherExpenses: userBookkeepingStatus.otherExpenses,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus, stepState) => {
      const { otherExpenses } = userBookkeepingStatus
      const { isOtherExpensesStepRequired } = stepState
      return otherExpenses.length > 0 || !isOtherExpensesStepRequired
    },
    isAlwaysComplete: true,
    finalReviewGroup: 'books',
    reviewGroupLabel: 'CLOSE YOUR BOOKS',
  },
  {
    id: 'personal-account-business-income',
    name: 'Business Income in Personal Accounts',
    route: 'business-income-in-personal',
    component: BusinessIncomeInPersonal,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: true,
    finalReviewGroup: 'books',
    reviewGroupLabel: 'CLOSE YOUR BOOKS',
  },
  {
    id: 'personal-account-business-expense',
    name: 'Business Expenses in Personal Accounts',
    route: 'business-expenses-in-personal',
    component: BusinessExpenseInPersonal,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: true,
    finalReviewGroup: 'books',
    reviewGroupLabel: 'CLOSE YOUR BOOKS',
  },
  {
    id: 'owner-investments',
    name: "Owner's Investments",
    route: 'review-owners-investments',
    component: OwnersInvestment,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return {
        investmentTransactions: userBookkeepingStatus.investmentTransactions,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus, stepState) => {
      return (
        userBookkeepingStatus.investmentTransactions.length > 0 ||
        !stepState.isInvestmentTransactionStepRequired
      )
    },
    isAlwaysComplete: true,
    finalReviewGroup: 'books',
    reviewGroupLabel: 'CLOSE YOUR BOOKS',
  },
  {
    id: 'owner-distributions',
    name: "Owner's Distributions",
    route: 'review-owners-distributions',
    component: OwnersDistribution,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return {
        distributionTransactions:
          userBookkeepingStatus.distributionTransactions,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus, stepState) => {
      return (
        userBookkeepingStatus.distributionTransactions.length > 0 ||
        !stepState.isOwnerDistributionStepRequired
      )
    },
    isAlwaysComplete: true,
    finalReviewGroup: 'books',
    reviewGroupLabel: 'CLOSE YOUR BOOKS',
  },
  {
    id: 'docs-intro',
    name: 'Documents Intro',
    route: 'upload-documents-intro',
    component: DocumentsIntro,
    props: null,
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const {
        payrollAndContractorTxns = [],
        processingFees,
        assetsOver2500,
        isMultiYearSCorp,
      } = userBookkeepingStatus
      const isPayrollAndContractorEnabled = payrollAndContractorTxns?.length > 0
      const isProcessingFeesEnabled = processingFees?.length > 0
      const isAssetsOver2500Enabled = assetsOver2500?.length > 0

      return (
        isPayrollAndContractorEnabled ||
        isProcessingFeesEnabled ||
        isAssetsOver2500Enabled ||
        isMultiYearSCorp
      )
    },
    isAlwaysComplete: true,
    finalReviewGroup: 'docs',
    reviewGroupLabel: 'YEAR-END DOCUMENTS',
  },
  {
    id: 'docs-payroll',
    name: 'Payroll',
    route: 'payroll',
    component: PayrollDocuments,
    props: ({ documents }: IUserBookkeepingStatus, stepState) => {
      return {
        documents,
        providers: stepState.providers,
        hasHeardPayroll: stepState.hasHeardPayroll,
        taxYear: stepState.taxYear,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const { payrollAndContractorTxns = [] } = userBookkeepingStatus
      return payrollAndContractorTxns?.length > 0
    },
    isAlwaysComplete: true,
    finalReviewGroup: 'docs',
    reviewGroupLabel: 'YEAR-END DOCUMENTS',
  },
  {
    id: 'docs-contractor-payments',
    name: 'Contractor Payments',
    route: 'contractor-payments',
    component: ContractorDocuments,
    props: ({ documents }: IUserBookkeepingStatus, stepState) => {
      return {
        documents,
        providers: stepState.providers,
        hasHeardPayroll: stepState.hasHeardPayroll,
        taxYear: stepState.taxYear,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const { payrollAndContractorTxns = [] } = userBookkeepingStatus
      return payrollAndContractorTxns?.length > 0
    },
    isAlwaysComplete: true,
    finalReviewGroup: 'docs',
    reviewGroupLabel: 'YEAR-END DOCUMENTS',
  },
  {
    id: 'docs-processing-fees',
    name: 'Processing fees',
    route: 'processing-fees',
    component: ProcessingFeesDocuments,
    props: (userBookkeepingStatus: IUserBookkeepingStatus, stepState) => {
      return {
        documents: userBookkeepingStatus.documents,
        processingFees: userBookkeepingStatus.processingFees,
        taxYear: stepState.taxYear,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const { processingFees } = userBookkeepingStatus
      return processingFees?.length > 0
    },
    isAlwaysComplete: true,
  },
  {
    id: 'docs-assets-over-2500',
    name: 'Fixed Asset Depreciation',
    route: 'fixed-asset-depreciation',
    component: FixedAssetDepreciationDocuments,
    props: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      return {
        documents: userBookkeepingStatus.documents,
        transactions: userBookkeepingStatus.assetsOver2500,
      }
    },
    isRequired: (userBookkeepingStatus: IUserBookkeepingStatus) => {
      const { assetsOver2500 } = userBookkeepingStatus
      return assetsOver2500.length > 0
    },
    isAlwaysComplete: false,
  },
  {
    id: 'docs-previous-tax-return',
    name: 'Previous Year Tax Return',
    route: 'previous-tax-return',
    component: PreviousTaxReturn,
    props: (userBookkeepingStatus: IUserBookkeepingStatus, stepState) => {
      return {
        documents: userBookkeepingStatus.documents,
        taxYear: stepState.taxYear,
      }
    },
    isRequired: (userBookkeepingStatus) => {
      return userBookkeepingStatus.isMultiYearSCorp
    },
    isAlwaysComplete: true,
  },
  {
    id: 'docs-review',
    name: 'Upload documents',
    route: 'review-document-uploads',
    component: ReviewDocuments,
    props: (userBookkeepingStatus: IUserBookkeepingStatus, stepState) => {
      return {
        documents: userBookkeepingStatus.documents,
        providers: stepState.providers,
        processingFees: userBookkeepingStatus.processingFees,
        assetsOver2500: userBookkeepingStatus.assetsOver2500,
        taxYear: stepState.taxYear,
        isMultiYearSCorp: userBookkeepingStatus.isMultiYearSCorp,
      }
    },
    isRequired: () => true,
    isAlwaysComplete: false,
  },
  {
    id: 'initial-submission-review',
    name: 'Review',
    route: 'first-submission',
    component: Review,
    props: null,
    isRequired: () => true,
    isSubmit: true,
    isAlwaysComplete: false,
  },
  {
    id: undefined,
    name: 'All Done',
    route: 'all-done',
    component: AllDone,
    props: null,
    isRequired: () => true,
    isAlwaysComplete: true,
  },
]
