import { createSelector } from 'reselect'

import { ReduxState } from '../../../../../../utils/typeHelpers'
import { AllEoyReviewStepState } from './allEoyReviewSteps.slice'
import {
  getAllEoyReviewSteps,
  selectEoyReviewStepForIdentifier,
} from './endOfYearReviewSteps.selector'
import {
  SubStepIdentifiers,
  TAX_QUESTIONNAIRE_STEPS_1040_WITHOUT_DOCS,
  TAX_QUESTIONNAIRE_SECTION_STEPS_1120S_WITHOUT_DOCS,
  UPLOAD_DOCUMENTS,
} from './stepProgress.helpers'
import {
  StepStatus,
  UserEoyReviewProgress,
} from './userEndOfYearReviewProgress.slice'
import { getAnnualTaxFilingForms } from '../../../annualTaxFilingForms.selector'
import { TAX_ENTITY_TYPES } from '../../../../taxConstants'

export const getUserEoyReviewProgress = (state: ReduxState) =>
  state.userEoyReviewProgress

export const getUserEoyReviewProgressArray = createSelector(
  [getUserEoyReviewProgress],
  (userProgress): UserEoyReviewProgress[] => Object.values(userProgress)
)

// Gets progresses for the substeps of a tax checklist step
export const selectUserEoyReviewProgressForChecklistStep = createSelector(
  [
    getUserEoyReviewProgress,
    getAllEoyReviewSteps,
    (_: unknown, relevantIdentifiers: SubStepIdentifiers[]) =>
      relevantIdentifiers,
  ],
  (progressesById, stepsByIdentifier, relevantIdentifiers) => {
    const relevantStepIds = Object.values(stepsByIdentifier)
      .filter((step) => relevantIdentifiers.includes(step.identifier))
      .map((step) => step.id)

    return Object.values(progressesById).filter((progress) =>
      relevantStepIds.includes(progress.endOfYearReviewStepId)
    )
  }
)

export const selectUserEoyReviewProgressStatusForChecklistSteps =
  createSelector(
    [selectUserEoyReviewProgressForChecklistStep],
    (progressesForStep) => {
      if (progressesForStep.length === 0) {
        //progresses would have been created if they started the step
        return StepStatus.notStarted
      } else if (
        progressesForStep.every((progress) => Boolean(progress.completedAt))
      ) {
        return StepStatus.completed
      }
      return StepStatus.inProgress
    }
  )

export const selectUserEoyReviewProgressForSubstepIdentifier = createSelector(
  getUserEoyReviewProgress,
  selectEoyReviewStepForIdentifier,
  (progressesById, subStep) => {
    return Object.values(progressesById).find(
      (progress) => progress.endOfYearReviewStepId === subStep?.id
    )
  }
)

const progressCompleteForStep = (
  step: SubStepIdentifiers,
  allSteps: AllEoyReviewStepState,
  progresses: UserEoyReviewProgress[]
) =>
  Boolean(
    progresses.find(
      (progress) =>
        allSteps[step]?.id === progress.endOfYearReviewStepId &&
        progress.completedAt
    )
  )

export const selectQuarterlyPaymentsStepComplete = createSelector(
  getUserEoyReviewProgressArray,
  getAllEoyReviewSteps,
  (userProgresses, allSteps) => {
    return progressCompleteForStep(
      SubStepIdentifiers.addMissingQTEPayments,
      allSteps,
      userProgresses
    )
  }
)

export const selectAllTQTasksExceptUploadDocumentsAreCompleteForYear =
  createSelector(
    getUserEoyReviewProgressArray,
    getAllEoyReviewSteps,
    getAnnualTaxFilingForms,
    (_: unknown, _year: string, formId: number | string) => formId,
    (userProgresses, allSteps, forms, formId) => {
      const is1120s =
        forms[formId]?.formType.name === TAX_ENTITY_TYPES.form_1120_s
      const allTasksExceptUploadDocuments = is1120s
        ? TAX_QUESTIONNAIRE_SECTION_STEPS_1120S_WITHOUT_DOCS
        : TAX_QUESTIONNAIRE_STEPS_1040_WITHOUT_DOCS
      return allTasksExceptUploadDocuments.every((substeps) =>
        substeps.every((step) =>
          progressCompleteForStep(step, allSteps, userProgresses)
        )
      )
    }
  )

export const selectDocumentsUploadStepCompleteForYear = createSelector(
  getUserEoyReviewProgressArray,
  getAllEoyReviewSteps,
  (userProgresses, allSteps) =>
    UPLOAD_DOCUMENTS.every((step) =>
      progressCompleteForStep(step, allSteps, userProgresses)
    )
)
