import { ReactNode } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

import { Button, Icon, Popup, Text } from './index'
import { ButtonProps } from './Button'

export interface TileSelectorProps extends ButtonProps {
  icon: IconDefinition
  active: boolean
  text: ReactNode
  popup?: {
    title: string
    body: ReactNode
  }
  subtext?: string
  width?: number
  height?: number
}

const TileSelector = ({
  icon,
  text,
  popup,
  subtext,
  width,
  height = 128,
  ...rest
}: TileSelectorProps) => {
  return (
    <Button variant="toggle" style={{ height, width }} {...rest}>
      <Icon icon={icon} color={rest.active ? 'green' : 'oak'} size="2x" />
      <br />
      <Text as="h3" textAlign="center">
        {text}
        {popup && (
          <Popup
            content={
              <>
                <Text as="h3">{popup.title}</Text>
                {popup.body}
              </>
            }
          />
        )}
      </Text>
      {subtext && (
        <Text as="bodySm" color="darkGray" textAlign="center">
          {subtext}
        </Text>
      )}
    </Button>
  )
}

export default TileSelector
