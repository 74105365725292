import { Grid, Image, List } from 'semantic-ui-react'
import { GridRowColumn, Icon, Text } from '../../../components/BaseComponents'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import './style.scss'
import { TAX_ENTITY_TYPES } from '../../Taxes/taxConstants'
import { useReselector } from '../../../utils/sharedHooks'
import { getFinancialProfile } from '../../../selectors/user.selectors'

const OptInAndSaveCard = () => {
  const fp = useReselector(getFinancialProfile)
  const isSCorp = fp?.taxEntityType === TAX_ENTITY_TYPES.form_1120_s

  return (
    <div id="optInAndSave">
      <div className="image">
        <Image
          src="https://heard-images.s3.us-west-1.amazonaws.com/assets/piggy_bank_pink.svg"
          width={135}
        />
      </div>
      <div className="content">
        <Grid>
          <GridRowColumn>
            <Text as="h2">Opt into taxes and save</Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text as="bodySm" color="darkGray">
              Unlock savings when you do your taxes with Heard.
            </Text>
          </GridRowColumn>
          <GridRowColumn short>
            <List className="checkmark-list">
              <List.Item>
                <div>
                  <Icon icon={regular('check')} color="accentGreen" />
                  <Text>Up to 50% off catch up bookkeeping</Text>
                </div>
              </List.Item>
              {isSCorp && (
                <List.Item>
                  <div>
                    <Icon icon={regular('check')} color="accentGreen" />
                    <div>
                      <Text>3 months of payroll for free</Text>
                      <Text color="darkGray">
                        When you sign-up for an Annual Plan
                      </Text>
                    </div>
                  </div>
                </List.Item>
              )}
              <List.Item>
                <div>
                  <Icon icon={regular('check')} color="accentGreen" />
                  <div>
                    <Text>50% off 1:1 live tax consult</Text>
                    <Text color="darkGray">
                      When you sign-up for an Annual Plan
                    </Text>
                  </div>
                </div>
              </List.Item>
              <List.Item>
                <div>
                  <Icon icon={regular('check')} color="accentGreen" />
                  <Text>
                    Complimentary introductory financial planning call with a
                    Certified Financial Planner
                  </Text>
                </div>
              </List.Item>
              <List.Item>
                <div>
                  <Icon icon={regular('check')} color="accentGreen" />
                  <Text>Unlimited state tax return filings</Text>
                </div>
              </List.Item>
            </List>
          </GridRowColumn>
        </Grid>
      </div>
    </div>
  )
}

export default OptInAndSaveCard
