import { useEffect, useMemo, useState } from 'react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FinancialAccount } from '../../../../reducers/finances/financialAccountsReducer'
import Card from '../../../../components/BaseComponents/Card'
import Icon from '../../../../components/BaseComponents/Icon'
import Text from '../../../../components/BaseComponents/Text'
import { StepHeaders } from '../../../../components/StepHeader'
import { FinancialAccountCard } from '../../../../components/Finances/Accounts/FinancialAccountCard'
import { DisconnectedAccountsStepContext } from '../types'
import './styles.scss'

interface IDisconnectedAccountsProps {
  financialAccounts?: FinancialAccount[]
  setIsStepComplete: (value: boolean) => void
  stepContext: DisconnectedAccountsStepContext
  setStepContext: React.Dispatch<
    React.SetStateAction<DisconnectedAccountsStepContext>
  >
}

export const DisconnectedAccounts = ({
  financialAccounts = [],
  setIsStepComplete,
  stepContext,
  setStepContext,
}: IDisconnectedAccountsProps) => {
  // These are the accounts that were disconnected before any reconnection attempts
  const [relevantAccountIds, setRelevantAccountIds] = useState<number[] | null>(
    null
  )

  // Up-to-date count of disconnected accounts
  const disconnectedCount = useMemo(
    () =>
      financialAccounts.filter((account) => account.accounts.needsReconnection)
        .length,
    [financialAccounts]
  )

  // We only want this effect to take action the first time financialAccounts changes
  useEffect(() => {
    if (financialAccounts.length && relevantAccountIds === null) {
      setRelevantAccountIds(() =>
        financialAccounts
          .filter((a) => a.accounts.needsReconnection)
          .map((a) => a.id)
      )
    }
  }, [financialAccounts, relevantAccountIds, setRelevantAccountIds])

  const toggleSkipAccountReconnect = (accountId: number) => {
    setStepContext((prev) => {
      const cannotReconnectAccountIds =
        prev.cannotReconnectAccountIds?.includes(accountId)
          ? prev.cannotReconnectAccountIds.filter((id) => id !== accountId)
          : [...(prev.cannotReconnectAccountIds ?? []), accountId]
      return { ...prev, cannotReconnectAccountIds }
    })
  }

  useEffect(() => {
    setIsStepComplete?.(
      disconnectedCount === stepContext?.cannotReconnectAccountIds?.length
    )
  }, [
    disconnectedCount,
    stepContext?.cannotReconnectAccountIds,
    setIsStepComplete,
  ])

  return (
    <StepHeaders
      customColor="green"
      imageSrc="https://heard-images.s3.amazonaws.com/assets/bank-yellow.svg"
      imageAlt="bank"
      imageWidth={180}
      imageHeight={180}
      title="Reconnect your accounts"
      description="Don’t worry, disconnections can happen on occasion. Please reconnect any
      accounts you plan on using."
    >
      <Text style={{ marginTop: '16px' }}>
        If you’re having trouble connecting an account, check the box.
      </Text>

      {disconnectedCount === 0 ? (
        <Card backgroundColor="lightGreen" fullWidth>
          <div className="all-reconnected">
            <Icon icon={solid('check')} color="accentGreen" size="2x" />

            <div className="reconnected-text">
              <Text as="h3">All accounts have been reconnected</Text>
              <Text>You may skip to the next step</Text>
            </div>
          </div>
        </Card>
      ) : (
        financialAccounts
          .filter((account) => relevantAccountIds?.includes(account.id))
          .map((account) => (
            <FinancialAccountCard
              key={account.id}
              account={account}
              skipAccountReconnect={
                stepContext.cannotReconnectAccountIds?.includes(account.id) ??
                false
              }
              toggleSkipAccountReconnect={toggleSkipAccountReconnect}
            />
          ))
      )}
    </StepHeaders>
  )
}
