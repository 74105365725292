import { useCallback, useEffect, useMemo, useState, createElement } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Divider, Icon } from 'semantic-ui-react'
import moment from 'moment'
import { DateTime } from 'luxon'
import { isFunction } from 'lodash'

import { useReselector, useFetchResponse } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { fetchFinancialAccountsIfNeeded } from '../../../actions/financialAccountActions'
import { fetchPlaidItemsIfNeeded } from '../../../actions/plaidItemActions'
import { selectActivePlaidFinancialAccounts } from '../../../selectors/financeSelectors'
import { selectCurrentAnnualTaxYear } from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { fetchProvidersIfNeeded } from '../../Provider/provider.actions'
import { getAllProviders } from '../../Provider/provider.selectors'
import {
  getMissingStatements,
  MissingStatement,
  fetchUserDocuments,
} from '../../UserDocuments/userDocuments.slice'
import { getSCorpSetUpStatuses } from '../../Dashboard/SCorpUpsell/sCorpActions.selector'
import {
  selectUncategorizedTransactionsNotNeedClarification,
  selectUserTransactionsByCategoryIdentifierForYear,
} from '../../Transactions/transactions.selectors'
import { fetchFilteredUserTransactions } from '../../Transactions/transactions.slice'
import { fetchTransactionCategoriesIfNeeded } from '../../Reports/reports.slice'
import {
  TransactionCategoryIdentifier,
  Transaction,
} from '../../../reducers/admin/allTransactions.slice'
import { TRANSACTION_CATEGORY_IDENTIFIERS } from '../../Transactions/constants'
import { getMerchantNames } from './processing-fees-documents/documents-map'
import { ProgressBarNoTitles } from '../../../components/BaseComponents'
import Text from '../../../components/BaseComponents/Text'
import { NavButtons } from './nav-buttons'
import { IStep, IStepState, STEPS } from './step-map'
import './styles.scss'
import {
  userGetBookkeepingStep,
  userGetProcessingFees,
  userSubmitBookkeepingModule,
  userUpdateBookkeepingStepContext,
} from './actions'
import { selectIsPayrollActive } from '../../Payroll/payroll.selectors'
import { fetchPayrollProfileIfNeeded } from '../../Payroll/payrollActions'
import { getFinancialProfile } from '../../../selectors/user.selectors'
import { TAX_ENTITY_TYPES } from '../../Taxes/taxConstants'
import { selectUserDocumentList } from '../../UserDocuments/userDocuments.selector'

type StepContext = Record<string, unknown>

interface IUnclarifiedTransactions {
  uncategorized: Transaction[]
  bookkeeperClarification: Transaction[]
}

export const EndOfYearReviewContainer = () => {
  const [currentStep, setCurrentStep] = useState<IStep | null>(null)
  const [currentStepIdx, setCurrentStepIdx] = useState<number>(0)
  const [userSteps, setUserSteps] = useState<IStep[]>([])
  const [prevStep, setPrevStep] = useState<string>('')
  const [nextStep, setNextStep] = useState<string>('')
  const [isStepComplete, setIsStepComplete] = useState<boolean>(true)
  const [isPreventCall, setIsPreventCall] = useState<boolean>(false)
  const [stepContext, setStepContext] = useState<StepContext>({})
  const [missingStatements, setMissingStatements] = useState<
    MissingStatement[] | undefined
  >()
  const [isMissingStatements, setIsMissingStatements] = useState<boolean>(false)
  const [isOtherExpensesStepRequired, setIsOtherExpensesStepRequired] =
    useState<boolean>(true)
  const [isOtherIncomeStepRequired, setisOtherIncomeStepRequired] =
    useState<boolean>(true)
  const [isOtherTransactionRequired, setisOtherTransactionStepRequired] =
    useState<boolean>(true)
  const [
    isInvestmentTransactionStepRequired,
    setInvestmentTransactionStepRequired,
  ] = useState<boolean>(true)
  const [isOwnerDistributionStepRequired, setisOwnerDistributionStepRequired] =
    useState<boolean>(true)
  const year = useReselector(selectCurrentAnnualTaxYear)
  const location = useLocation()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const path = location.pathname.split('/')
    const stepRoute = path[5]
    const stepIndex = userSteps.findIndex((step) => step.route === stepRoute)
    if (stepIndex !== -1) {
      setCurrentStepIdx(stepIndex)
    }
  }, [location.pathname, userSteps])

  const setReviewIndex = (route: string) => {
    const stepIndex = userSteps.findIndex((step) => step.route === route)
    if (stepIndex !== -1) {
      setCurrentStepIdx(stepIndex)
    }
  }

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchFinancialAccountsIfNeeded())
      await dispatch(fetchPlaidItemsIfNeeded())
    }

    dispatch(fetchTransactionCategoriesIfNeeded())
    dispatch(fetchPayrollProfileIfNeeded())
    dispatch(fetchUserDocuments())

    fetch()
  }, [dispatch])

  useEffect(() => {
    dispatch(
      fetchFilteredUserTransactions({
        startDate: moment().year(parseInt(year)).startOf('year'),
        endDate: moment().year(parseInt(year)).endOf('year'),
      })
    )
  }, [dispatch, year])

  useEffect(() => {
    dispatch(fetchProvidersIfNeeded({ alwaysFetch: true }))
  }, [dispatch])

  const financialAccounts = useReselector(selectActivePlaidFinancialAccounts)

  const financialProfile = useReselector(getFinancialProfile)

  const unclarifiedTransactions = useReselector(
    selectUncategorizedTransactionsNotNeedClarification,
    year
  )

  const otherExpenses = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.other_expenses,
    year
  )

  const otherIncome = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.other_income,
    year
  )

  const investmentTransactions = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.owners_investments,
    year
  )

  const distributionTransactions = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.owners_distribution,
    year
  )

  const equipmentOver2500 = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.equipment_gte_2500,
    year
  )

  const computerHardwareOver2500 = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.computer_hardware_gte_2500,
    year
  )

  const furnitureOver2500 = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.furniture_gte_2500,
    year
  )

  const providers = useReselector(getAllProviders)

  const assetsOver2500 = useMemo(
    () => [
      ...equipmentOver2500,
      ...computerHardwareOver2500,
      ...furnitureOver2500,
    ],
    [equipmentOver2500, computerHardwareOver2500, furnitureOver2500]
  )

  const fetchingStatements = useFetchResponse(getMissingStatements)
  useEffect(() => {
    const filterStatementsByYear = (statements: string[], year: number) => {
      return statements.filter((statement) => {
        const statementDate = new Date(statement)
        return statementDate.getFullYear() === year
      })
    }
    const statements = fetchingStatements || []

    const statementsFiltered = statements.map((statement) => ({
      ...statement,
      statements: filterStatementsByYear(statement.statements, parseInt(year)),
    }))

    const filteredAccount = []
    for (const statement of statementsFiltered) {
      if (statement.statements.length > 0) {
        filteredAccount.push(statement)
      }
    }
    const filteredStatements = filteredAccount

    setMissingStatements(filteredStatements)
    if (filteredStatements && filteredStatements?.length > 0) {
      setIsMissingStatements(true)
    }
  }, [fetchingStatements, year])

  useEffect(() => {
    if (otherExpenses.length > 0) {
      setIsOtherExpensesStepRequired(false)
    }
    if (otherIncome.length > 0) {
      setisOtherIncomeStepRequired(false)
    }
    if (unclarifiedTransactions.length > 0) {
      setisOtherTransactionStepRequired(false)
    }
    if (investmentTransactions.length > 0) {
      setInvestmentTransactionStepRequired(false)
    }
    if (distributionTransactions.length > 0) {
      setisOwnerDistributionStepRequired(false)
    }
  }, [
    otherExpenses,
    otherIncome,
    unclarifiedTransactions,
    investmentTransactions,
    distributionTransactions,
  ])

  const processingFees = useFetchResponse(
    userGetProcessingFees,
    year,
    [],
    getMerchantNames()
  )

  const payrollAndContractorCategoryIdentifiers: string[] = [
    TRANSACTION_CATEGORY_IDENTIFIERS.payrollContractors,
    TRANSACTION_CATEGORY_IDENTIFIERS.payrollEmployeeBenefits,
    TRANSACTION_CATEGORY_IDENTIFIERS.payrollExpensesAndFees,
    TRANSACTION_CATEGORY_IDENTIFIERS.payrollPayable,
    TRANSACTION_CATEGORY_IDENTIFIERS.payrollReimbursableExpenses,
    TRANSACTION_CATEGORY_IDENTIFIERS.payrollSalariesAndWages,
    TRANSACTION_CATEGORY_IDENTIFIERS.payrollSutaExpense,
    TRANSACTION_CATEGORY_IDENTIFIERS.payrollTaxes,
    TRANSACTION_CATEGORY_IDENTIFIERS.payrollWorkersCompensation,
  ]

  const payrollAndContractorTxns = useFetchResponse(
    userGetProcessingFees,
    year,
    payrollAndContractorCategoryIdentifiers
  )

  const isSCorp = Object.keys(useReselector(getSCorpSetUpStatuses)).length !== 0

  const isMultiYearSCorp = useMemo(() => {
    if (!financialProfile) return false
    const { taxEntityType, scorpVerifiedAt, entityChangeElectionDate } =
      financialProfile
    if (
      taxEntityType !== TAX_ENTITY_TYPES.form_1120_s ||
      !scorpVerifiedAt ||
      !entityChangeElectionDate
    )
      return false
    const startOfYear = DateTime.fromObject(
      { year: parseInt(year), month: 1, day: 1 },
      { zone: 'utc' }
    )
    const electionDate = DateTime.fromISO(entityChangeElectionDate, {
      zone: 'utc',
    })
    return electionDate < startOfYear
  }, [financialProfile, year])

  const getUnclarifiedTransactions = (
    transactions: Transaction[]
  ): IUnclarifiedTransactions =>
    transactions.reduce(
      (acc, transaction) => {
        acc.uncategorized.push(transaction)
        return acc
      },
      {
        bookkeeperClarification: [] as Transaction[],
        uncategorized: [] as Transaction[],
      }
    )

  const hasPayroll = useReselector(selectIsPayrollActive)

  const documents = useReselector(selectUserDocumentList)

  const userBookkeepingStatus = useMemo(() => {
    return {
      financialAccounts,
      missingBankStatements: missingStatements,
      isMissingStatements,
      unclarifiedTransactions: getUnclarifiedTransactions(
        unclarifiedTransactions
      ),
      otherExpenses,
      otherIncome,
      isSCorp,
      investmentTransactions,
      distributionTransactions,
      payrollAndContractorTxns,
      processingFees,
      assetsOver2500,
      isMultiYearSCorp,
      documents,
    }
  }, [
    financialAccounts,
    unclarifiedTransactions,
    otherExpenses,
    otherIncome,
    isSCorp,
    isMissingStatements,
    investmentTransactions,
    distributionTransactions,
    payrollAndContractorTxns,
    missingStatements,
    processingFees,
    assetsOver2500,
    isMultiYearSCorp,
    documents,
  ])

  const stepState = useMemo<IStepState>(() => {
    return {
      lastEoyBookkeepingScreen: '/base/year/step_slug',
      providers,
      taxYear: year,
      hasHeardPayroll: hasPayroll,
      isOtherExpensesStepRequired,
      isOtherIncomeStepRequired,
      isOtherTransactionRequired,
      isInvestmentTransactionStepRequired,
      isOwnerDistributionStepRequired,
    }
  }, [
    providers,
    year,
    hasPayroll,
    isOtherExpensesStepRequired,
    isOtherIncomeStepRequired,
    isOtherTransactionRequired,
    isInvestmentTransactionStepRequired,
    isOwnerDistributionStepRequired,
  ])

  useEffect(() => {
    const steps = STEPS.filter((step) => {
      return step.isRequired(userBookkeepingStatus, stepState)
    })
    setUserSteps(steps)
  }, [userBookkeepingStatus, stepState])

  useEffect(() => {
    if (!isPreventCall) {
      setIsStepComplete(userSteps[currentStepIdx]?.isAlwaysComplete || false)
    }
    if (userSteps[currentStepIdx]?.route === 'upload-missing-bank-statements') {
      if (missingStatements?.length === 0) {
        setIsStepComplete(true)
      }
    }

    if (userSteps[currentStepIdx]?.route === 'categorize-transactions') {
      if (unclarifiedTransactions?.length === 0) {
        setIsStepComplete(true)
      }
    }

    const prevStep =
      currentStepIdx === 0
        ? 'taxes/annual'
        : userSteps[currentStepIdx - 1]?.route
    const nextStep =
      currentStepIdx === userSteps.length - 1
        ? 'taxes/annual'
        : userSteps[currentStepIdx + 1]?.route

    setPrevStep(prevStep)
    setNextStep(nextStep)
    setIsPreventCall(false)
  }, [
    currentStepIdx,
    userSteps,
    isPreventCall,
    missingStatements?.length,
    unclarifiedTransactions,
  ])

  useEffect(() => {
    const fetchStep = async (stepId: string | undefined) => {
      if (stepId) {
        const step = await userGetBookkeepingStep(stepId)(dispatch)
        setStepContext(step?.context ?? {})
      }
    }
    const currentStepId = userSteps?.[currentStepIdx]?.id
    fetchStep(currentStepId)
  }, [currentStepIdx, userSteps, dispatch])

  const updateStepContext = useCallback(
    async (factor: number) => {
      const fromStepId = userSteps[currentStepIdx]?.id
      const toStepId = userSteps[currentStepIdx + factor]?.id

      if (fromStepId && toStepId) {
        const updatedStep = await userUpdateBookkeepingStepContext(
          fromStepId,
          toStepId,
          stepContext
        )(dispatch)
        if (updatedStep?.context) {
          setStepContext((prev) => ({ ...prev, ...updatedStep.context }))
          setCurrentStep(userSteps[currentStepIdx + factor])
        }
        return Boolean(updatedStep)
      }
      return true
    },
    [userSteps, currentStepIdx, stepContext, dispatch]
  )

  type AutoSaveArgument =
    | StepContext
    | ((prevStepContext: StepContext) => StepContext)
  const autoSaveStepContext = useCallback(
    async (arg: AutoSaveArgument) => {
      const newStepContext = isFunction(arg) ? arg(stepContext) : arg
      const fromStepId = userSteps[currentStepIdx]?.id
      if (fromStepId) {
        const updatedStep = await userUpdateBookkeepingStepContext(
          fromStepId,
          fromStepId,
          newStepContext
        )(dispatch)
        if (updatedStep?.context) {
          setStepContext((prev) => ({ ...prev, ...updatedStep.context }))
        }
      }
    },
    [userSteps, currentStepIdx, stepContext, dispatch]
  )

  const handlePrevStep = useCallback(async () => {
    const success = await updateStepContext(-1)
    if (success) {
      setCurrentStepIdx((prev) => prev - 1)
      if (prevStep !== 'taxes/annual') {
        navigate(
          `/taxes/annual/complete-year-end-bookkeeping/${year}/${prevStep}`
        )
      } else {
        navigate('/taxes/annual')
      }
    }
  }, [updateStepContext, prevStep, year, navigate])

  const handleNextStep = useCallback(async () => {
    const stepContextUpdated = await updateStepContext(1)

    // Submit the bookkeeping module if the current step is a submit step
    let submittedOrSkipped = true
    if (userSteps[currentStepIdx]?.isSubmit && stepContextUpdated) {
      submittedOrSkipped = await userSubmitBookkeepingModule()(dispatch)
    }

    if (stepContextUpdated && submittedOrSkipped) {
      setCurrentStepIdx((prev) => prev + 1)
      if (nextStep !== 'taxes/annual') {
        navigate(
          `/taxes/annual/complete-year-end-bookkeeping/${year}/${nextStep}`
        )
      } else {
        navigate('/taxes/annual')
      }
    }
  }, [
    nextStep,
    year,
    navigate,
    updateStepContext,
    currentStepIdx,
    userSteps,
    dispatch,
  ])

  const handlSaveAndExit = useCallback(async () => {
    const success = await updateStepContext(0)
    if (success) {
      navigate('/taxes/annual')
    }
  }, [navigate, updateStepContext])

  const setIsStepCompleteFun = useCallback(
    async (isComplete: boolean, isMissingBank = false) => {
      if (isMissingBank) {
        setIsPreventCall(true)
        const filterStatementsByYear = (statements: string[], year: number) => {
          return statements.filter((statement) => {
            const statementDate = new Date(statement)
            return statementDate.getFullYear() === year
          })
        }
        const fetchingStatements =
          (await getMissingStatements()(dispatch)) || []
        const statementsFiltered = fetchingStatements.map((statement) => ({
          ...statement,
          statements: filterStatementsByYear(
            statement.statements,
            parseInt(year)
          ),
        }))
        const filteredAccount = []
        for (const statement of statementsFiltered) {
          if (statement.statements.length > 0) {
            filteredAccount.push(statement)
          }
        }
        const filteredStatements = filteredAccount
        setMissingStatements(filteredStatements)
        if (filteredStatements?.length === 0) {
          setIsStepComplete(true)
        }
      } else {
        setIsStepComplete(isComplete)
      }
    },
    [dispatch, year]
  )

  const getCurrentStepComponent = () => {
    if (currentStepIdx === null || userSteps.length === 0) {
      return STEPS[0].component
    }
    return userSteps[currentStepIdx].component
  }

  const getCurrentProps = () => {
    if (userSteps?.[currentStepIdx] === null) {
      return {}
    }
    if (userSteps[currentStepIdx]?.route === 'first-submission') {
      return {
        userSteps,
        setReviewIndex,
        setIsStepComplete,
      }
    }

    if (userSteps?.[currentStepIdx]?.props !== null) {
      return {
        ...(userSteps[currentStepIdx]?.props(
          userBookkeepingStatus,
          stepState
        ) || {}),
      }
    }

    return {}
  }

  return (
    <div className="eoy-review_wrapper">
      <header className="header">
        <Text>{year} End of Year Review</Text>
        <div className="autosave-wrapper">
          <Icon name="check" style={{ color: '#457634' }} />
          <Text style={{ color: '#457634' }}>Autosaved</Text>
        </div>
      </header>

      <ProgressBarNoTitles
        currentStep={currentStepIdx}
        totalSteps={userSteps.length}
      />

      <div className="step-body">
        {/* @ts-expect-error temp type issue resolution - ticket hea-5043 */}
        {createElement(getCurrentStepComponent(), {
          ...getCurrentProps(),
          ...(currentStep?.isAlwaysComplete
            ? {}
            : { setIsStepComplete: setIsStepCompleteFun }),
          ...{
            stepContext,
            setStepContext: autoSaveStepContext,
          },
        })}
      </div>

      <Divider style={{ margin: 0 }} />

      <NavButtons
        isStepComplete={isStepComplete}
        stepBack={handlePrevStep}
        stepForward={handleNextStep}
        saveAndExit={handlSaveAndExit}
        isSubmit={userSteps[currentStepIdx]?.isSubmit}
      />
    </div>
  )
}
