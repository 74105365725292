import { useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { DateTime } from 'luxon'

import {
  Alert,
  Button,
  Card,
  GridRowColumn,
  Modal,
  Text,
  TextArea,
} from '../../../components/BaseComponents'
import CurrencyFormatLabel from '../../../components/shared/CurrencyFormatLabel'
import { TransactionOrSplit } from '../../../reducers/admin/allTransactions.slice'
import { centsToDollars } from '../../../utils/currencyHelpers'
import {
  UPDATE_USER_TRANSACTION_KEY,
  updateUserTransaction,
} from '../transactions.slice'
import { TransactionReceiptsPanel } from './transaction-receipts-panel'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import {
  invalidateFetch,
  selectErrorsForKeys,
  selectIsFetchingForKeys,
} from '../../../reducers/fetch'
import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'

export const TransactionNoteModal = ({
  open,
  close,
  transaction,
  followUpYear,
}: {
  open: boolean
  close: () => void
  transaction: TransactionOrSplit
  followUpYear?: string
}) => {
  const dispatch = useAppDispatch()
  const [transactionNote, setTransactionNote] = useState(
    transaction.notes || ''
  )
  const updating = useReselector(selectIsFetchingForKeys, [
    UPDATE_USER_TRANSACTION_KEY,
  ])
  const errors = useReselector(selectErrorsForKeys, [
    UPDATE_USER_TRANSACTION_KEY,
  ])
  const saveNote = async () => {
    dispatch(invalidateFetch(UPDATE_USER_TRANSACTION_KEY))

    const updateTransactionObject: {
      notes: string
      requestedClarificationAt?: string | null
      notifiedRequestedClarificationAt?: string | null
      notesLastUpdatedAt?: string
    } = {
      notes: transactionNote,
      notesLastUpdatedAt: new Date().toISOString(),
    }

    if (followUpYear) {
      updateTransactionObject.requestedClarificationAt = null
      updateTransactionObject.notifiedRequestedClarificationAt = null
    }

    const updated = await updateUserTransaction(
      transaction.id,
      updateTransactionObject,
      followUpYear
    )(dispatch)

    if (updated) {
      close()
    }
  }

  return (
    <Modal size="tiny" open={open} onClose={close} closeIcon>
      <Modal.Header>Add a note</Modal.Header>
      <Modal.Content>
        <Grid>
          {errors.length > 0 && (
            <GridRowColumn>
              <Alert type="error">
                There was an error saving this note! Please try again.
              </Alert>
            </GridRowColumn>
          )}
          <GridRowColumn>
            <Card backgroundColor="stone">
              <Grid>
                <GridRowColumn>
                  <Text as="eyebrow" color="darkGray">
                    Transaction
                  </Text>
                </GridRowColumn>
                <GridRowColumn short>
                  <Text>
                    Date:{' '}
                    {DateTime.fromISO(transaction.date).toFormat(
                      DATE_FORMATS_LUXON.DISPLAY_SHORT
                    )}
                  </Text>
                </GridRowColumn>
                <GridRowColumn short>
                  <Text>Description: {transaction.description}</Text>
                </GridRowColumn>
                <GridRowColumn short>
                  <Text>
                    Amount:{' '}
                    <CurrencyFormatLabel
                      value={centsToDollars(transaction.amountInCents)}
                    />
                  </Text>
                </GridRowColumn>
              </Grid>
            </Card>
          </GridRowColumn>
          <GridRowColumn>
            <TextArea
              label="Note"
              rows={4}
              placeholder="What was this for? How was this used for business purposes? Please be descriptive as possible."
              value={transactionNote}
              onChange={setTransactionNote}
              required
            />
          </GridRowColumn>
          <GridRowColumn>
            <Text as="h3">Attach Receipt</Text>
          </GridRowColumn>
          <GridRowColumn short>
            <Text as="bodySm">
              Attaching a receipt is highly recommended. This helps us
              categorize appropriately.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <TransactionReceiptsPanel transaction={transaction} />
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="actionLink" onClick={close}>
          Cancel
        </Button>
        <Button onClick={saveNote} disabled={updating} loading={updating}>
          Save Note
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default TransactionNoteModal
