import { JSX, useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import {
  useFormFlow,
  useSetScreen,
} from '../../../../../../components/FormFlow/formFlow'
import {
  DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  FETCH_USER_TAX_QUESTIONNAIRE_KEY,
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  fetchUserTaxQuestionnaire,
  postUpdateTaxQuestionnaireResponses,
} from '../../taxChecklistQuestion.actions'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  useLoadedFlagValue,
  useReselector,
  useScrollRef,
} from '../../../../../../utils/sharedHooks'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import {
  invalidateFetch,
  selectErrorsForKeys,
  selectIsFetchingForKeys,
} from '../../../../../../reducers/fetch'
import {
  Alert,
  GridRowColumn,
  Loader,
} from '../../../../../../components/BaseComponents'
import IncomeAndLiabilitiesPanel from './IncomeAndLiabilitiesPanel'
import {
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
} from '../../../annualTaxFilingForms.slice'
import RentalPropertyPanel from './RentalPropertyPanel'
import RetirementContributedPanel from './RetirementContributedPanel'
import IncomeAndLiabilitiesReviewPanel from './ReviewPanel'
import { selectTaxListQuestion } from '../../taxChecklist.selectors'
import { TaxListQuestionId } from '../../service'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import PageHeader from '../../../../../../components/shared/PageHeader'
import InvestmentsPanel from './InvestmentsPanel'
import LessCommonScenariosPanel from './LessCommonScenariosPanel'
import useProgressSteps, {
  StepUpdates,
} from '../../Shared/ReviewStepsandProgresses/useProgressSteps'
import { NON_THERAPY_INCOME_AND_LIABILITIES } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import {
  filterNulls,
  useAppDispatch,
} from '../../../../../../utils/typeHelpers'
import { selectTqFormIsReadOnly } from '../../../annualTaxFilings.selector'
import { FEATURE_FLAG_KEYS } from '../../../../../OpenFeature'
import { fetchUserDocuments } from '../../../../../UserDocuments/userDocuments.slice'
import { fetchTaxUserDocumentsIfNeeded } from '../../../taxUserDocuments.slice'
import { fetchUserDocumentCategoriesIfNeeded } from '../../../../../Admin/UserDocumentCategories/userDocumentCategories.slice'

export enum INCOME_LIABILITIES_SCREENS {
  incomeAndLiabilitiesIntro = 'income-liabilities-intro',
  rentalProperty = 'rental-property',
  investments = 'investments',
  retirementContributed = 'retirement-contributed',
  lessCommonScenarios = 'less-common-scenarios',
  review = 'review',
}

export interface IncomeAndLiabilitiesStepProps {
  goToNextStep: (
    data: Partial<TaxChecklistResponse | undefined>[] | null,
    newScreen: INCOME_LIABILITIES_SCREENS | null,
    stepUpdates?: StepUpdates,
    getNextScreen?: () => INCOME_LIABILITIES_SCREENS
  ) => Promise<boolean>
  goBack: (newScreen: INCOME_LIABILITIES_SCREENS | null) => void
  nextScreen?: INCOME_LIABILITIES_SCREENS | null
  previousScreen?: INCOME_LIABILITIES_SCREENS | null
}

const IncomeAndLiabilities = () => {
  const dispatch = useAppDispatch()
  const { formId } = useParams()
  const readOnly = useReselector(selectTqFormIsReadOnly, formId)
  const backLink = '/taxes/annual/tax_checklist/'
  const { setScreen, currentScreen } = useSetScreen({
    backLink,
    reviewScreen: INCOME_LIABILITIES_SCREENS.review,
  })
  const { scrollRef, scrollToRef } = useScrollRef()

  const { updateProgressData, fetchKeys: progressFetchKeys } = useProgressSteps(
    { steps: NON_THERAPY_INCOME_AND_LIABILITIES }
  )

  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const updateError = useReselector(selectErrorsForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
    ...progressFetchKeys,
  ])

  const isFetching = useReselector(selectIsFetchingForKeys, [
    FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
    FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
    FETCH_USER_TAX_QUESTIONNAIRE_KEY(taxYear),
    ...progressFetchKeys,
  ])

  const saveFormData = useCallback(
    async (
      data: Partial<TaxChecklistResponse | undefined>[] | null,
      newScreen: INCOME_LIABILITIES_SCREENS | null,
      stepUpdates: StepUpdates = {}
    ) => {
      dispatch(invalidateFetch(UPSERT_USER_TAX_QUESTIONNAIRE_KEY))
      dispatch(invalidateFetch(DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY))

      const parsedData = data && filterNulls(data)

      if (parsedData?.length) {
        const res = await dispatch(
          postUpdateTaxQuestionnaireResponses(parsedData)
        )
        if (res) {
          const progressUpdatesSuccessful =
            await updateProgressData(stepUpdates)
          if (!progressUpdatesSuccessful) {
            return false
          }
          setScreen(newScreen)
          return true
        }
      } else {
        const progressUpdatesSuccessful = await updateProgressData(stepUpdates)
        if (!progressUpdatesSuccessful) {
          return false
        }
        setScreen(newScreen)
        return true
      }
      return false
    },
    [dispatch, setScreen, updateProgressData]
  )

  const props = useMemo(() => {
    return {
      goToNextStep: saveFormData,
      goBack: setScreen,
    }
  }, [saveFormData, setScreen])

  const questionRentalProperty = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.own_rental_property,
    taxYear
  )
  const questionInvestments = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.own_investments,
    taxYear
  )
  const questionRetirementContributed = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.retirement_contributed,
    taxYear
  )

  const hasRentals = questionRentalProperty?.responses?.[0]?.value === true
  const hasInvestments = questionInvestments?.responses?.[0]?.value === true
  const hasRetirementContributed =
    questionRetirementContributed?.responses?.[0]?.value === true

  const screenConfig = useMemo(() => {
    const newScreens: {
      component: () => JSX.Element
      screenName: INCOME_LIABILITIES_SCREENS
      progressText?: string
      shouldShow?: boolean
      step?: number
      order?: number
    }[] = [
      {
        component: () => <IncomeAndLiabilitiesPanel {...props} />,
        screenName: INCOME_LIABILITIES_SCREENS.incomeAndLiabilitiesIntro,
      },
      {
        component: () => <LessCommonScenariosPanel {...props} />,
        screenName: INCOME_LIABILITIES_SCREENS.lessCommonScenarios,
        progressText: 'Less Common Scenarios',
      },
      {
        component: () => <IncomeAndLiabilitiesReviewPanel {...props} />,
        screenName: INCOME_LIABILITIES_SCREENS.review,
        progressText: 'Review',
      },
    ]
    if (hasRentals) {
      newScreens.splice(1, 0, {
        component: () => <RentalPropertyPanel {...props} />,
        screenName: INCOME_LIABILITIES_SCREENS.rentalProperty,
        progressText: 'Rental Properties',
      })
    }
    if (hasInvestments) {
      const startAt = hasRentals ? 2 : 1
      newScreens.splice(startAt, 0, {
        component: () => <InvestmentsPanel {...props} />,
        screenName: INCOME_LIABILITIES_SCREENS.investments,
        progressText: 'Investments',
      })
    }
    if (hasRetirementContributed) {
      let startAt = 1
      if (hasRentals && hasInvestments) {
        startAt = 3
      } else if (hasRentals || hasInvestments) {
        startAt = 2
      }
      newScreens.splice(startAt, 0, {
        component: () => <RetirementContributedPanel {...props} />,
        screenName: INCOME_LIABILITIES_SCREENS.retirementContributed,
        progressText: 'Retirement',
      })
    }
    const newScreensWithStep = newScreens.map((s, i) => ({
      ...s,
      step: i - 1,
    }))
    return newScreensWithStep
  }, [hasInvestments, hasRentals, hasRetirementContributed, props])

  const { progressBar, content } = useFormFlow({
    steps: screenConfig.flatMap((s) =>
      s.progressText ? [s.progressText] : []
    ),
    screens: screenConfig,
  })

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchUserDocuments())
    dispatch(fetchTaxUserDocumentsIfNeeded())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchUserTaxQuestionnaire(taxYear))
  }, [taxYear, dispatch])

  useEffect(() => {
    if (!currentScreen) {
      setScreen(INCOME_LIABILITIES_SCREENS.incomeAndLiabilitiesIntro, true)
    }
  }, [setScreen, currentScreen])

  useEffect(() => {
    scrollToRef()
  }, [currentScreen, scrollToRef])

  const navigate = useNavigate()
  const enable2024TaxChecklist = useLoadedFlagValue(
    FEATURE_FLAG_KEYS.enable2024TaxChecklist,
    false
  )
  useEffect(() => {
    if (enable2024TaxChecklist === false) {
      navigate('/taxes/annual')
    }
  }, [enable2024TaxChecklist, navigate])

  return (
    <>
      <span ref={scrollRef} />
      <PageHeader
        backControl={
          readOnly
            ? { link: backLink, text: 'Back to Personal Tax Checklist' }
            : undefined
        }
        header={`${taxYear} Personal Tax Questionnaire: Income and Liabilities`}
      />
      <Grid>
        <Loader loading={isFetching} />
        {!readOnly &&
          !isFetching &&
          currentScreen !==
            INCOME_LIABILITIES_SCREENS.incomeAndLiabilitiesIntro && (
            <GridRowColumn>{progressBar}</GridRowColumn>
          )}
        {updateError.length > 0 && (
          <GridRowColumn>
            <Alert type="error">{updateError[0].message}</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn>{content}</GridRowColumn>
      </Grid>
    </>
  )
}

export default IncomeAndLiabilities
