import { useParams } from 'react-router-dom'
import { Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import {
  FormikScrollOnError,
  GridRowColumn,
  Popup,
  Text,
} from '../../../../../../components/BaseComponents'
import { TaxListQuestionId, useTaxListQuestionRes } from '../../service'
import { LifeChangesStepProps } from '.'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { skipQuestionId, useSkipQuestion } from '../../Shared/SkipQuestion'
import UploadDocumentSubSection from '../../Documents/UploadDocumentSubSection'
import { UserDocumentCategoryIdentifier } from '../../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { tqBigSpace, tqSmallSpace } from '../../helpers'
import { TQYesNoResponse } from '../../Shared/FormHelpers'

export const miscQuestionIds = [
  TaxListQuestionId.audited_last_year,
  TaxListQuestionId.offer_in_compromise,
  TaxListQuestionId.irs_installment_plan,
  TaxListQuestionId.debt_cancellation,
  TaxListQuestionId.paid_house_employee,
]

const MiscQuestionsPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: LifeChangesStepProps) => {
  const { formId: formIdParam } = useParams()
  const formId = Number(formIdParam)

  const auditedQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.audited_last_year,
  })

  const offerInCompromiseQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.offer_in_compromise,
  })

  const installmentPlanQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.irs_installment_plan,
  })

  const debtCancellationQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.debt_cancellation,
  })

  const houseEmployeeQR = useTaxListQuestionRes({
    formId,
    questionId: TaxListQuestionId.paid_house_employee,
  })

  const skippedQuestions = useSkipQuestion(miscQuestionIds)

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.audited_last_year]: auditedQR.initialResponse,
      [TaxListQuestionId.offer_in_compromise]:
        offerInCompromiseQR.initialResponse,
      [TaxListQuestionId.irs_installment_plan]:
        installmentPlanQR.initialResponse,
      [TaxListQuestionId.debt_cancellation]: debtCancellationQR.initialResponse,
      [TaxListQuestionId.paid_house_employee]: houseEmployeeQR.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData = [
        auditedQR.getResponseData(values),
        offerInCompromiseQR.getResponseData(values),
        installmentPlanQR.getResponseData(values),
        debtCancellationQR.getResponseData(values),
        houseEmployeeQR.getResponseData(values),
      ]

      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.miscellaneousQuestions],
      })
    },
  })

  const { values, isSubmitting, submitForm } = formik

  return (
    <FormikProvider value={formik}>
      <FormikScrollOnError />
      <Grid>
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Miscellaneous Questions
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg" textAlign="center">
            We just have a few more questions to ask about your year.
          </Text>
        </GridRowColumn>
        <TQYesNoResponse questionResponse={auditedQR} skippable />
        <GridRowColumn short {...tqSmallSpace}>
          <Divider />
        </GridRowColumn>
        <TQYesNoResponse questionResponse={offerInCompromiseQR} skippable />
        <GridRowColumn short {...tqSmallSpace}>
          <Divider />
        </GridRowColumn>
        <TQYesNoResponse questionResponse={installmentPlanQR} skippable />
        <GridRowColumn short {...tqSmallSpace}>
          <Divider />
        </GridRowColumn>
        <TQYesNoResponse questionResponse={debtCancellationQR} skippable />
        <GridRowColumn short {...tqSmallSpace}>
          <Divider />
        </GridRowColumn>

        <TQYesNoResponse
          questionResponse={houseEmployeeQR}
          skippable
          afterLabel={
            <Popup
              content={
                <Grid>
                  <GridRowColumn>
                    <Text as="h3">Household Employees</Text>
                  </GridRowColumn>
                  <GridRowColumn short>
                    <Text>
                      Household employees include: housekeepers, maids,
                      babysitters, gardeners, and others who perform household
                      work in or around your private residence as your employee.
                    </Text>
                  </GridRowColumn>
                  <GridRowColumn>
                    <Text>
                      Household employees do <b>NOT</b> include repairmen,
                      plumbers, contractors and other business people who
                      provide their services as independent contractors.
                    </Text>
                  </GridRowColumn>
                  <GridRowColumn>
                    <Text>
                      Household workers are your employees if you can control
                      not only the work they do, but also how they do it.
                    </Text>
                  </GridRowColumn>
                </Grid>
              }
            />
          }
        />

        <GridRowColumn short {...tqSmallSpace}>
          <Divider />
        </GridRowColumn>
        <UploadDocumentSubSection
          categories={[
            values[TaxListQuestionId.offer_in_compromise]
              ? UserDocumentCategoryIdentifier.offerInCompromise
              : undefined,
          ]}
        />

        <Grid.Row />
        <FormFlowFooter
          continueDisabled={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default MiscQuestionsPanel
