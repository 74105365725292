import { useParams } from 'react-router-dom'
import { Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import {
  FormikInput,
  FormikScrollOnError,
  getFieldNames,
  GridRowColumn,
  makeNumberSchema,
  Text,
} from '../../../../../../components/BaseComponents'
import { TaxListQuestionId, useTaxListQuestionRes } from '../../service'
import { IncomeAndLiabilitiesStepProps } from '.'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import UploadDocumentSubSection from '../../Documents/UploadDocumentSubSection'
import { UserDocumentCategoryIdentifier } from '../../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import SkipQuestion, {
  createSkipSchema,
  skipQuestionId,
  useSkipQuestion,
} from '../../Shared/SkipQuestion'
import { TQYesNoResponse } from '../../Shared/FormHelpers'
import { tqBigSpace, tqSmallSpace } from '../../helpers'

export const investmentsQuestionIds = [
  TaxListQuestionId.investments_bought_sold,
  TaxListQuestionId.number_of_brokerage_statements,
  TaxListQuestionId.received_dividends,
]

const InvestmentsPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: IncomeAndLiabilitiesStepProps) => {
  const { formId: formIdParam } = useParams()
  const formId = Number(formIdParam)
  const investmentsBoughtSoldQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.investments_bought_sold,
    formId,
  })

  const numberOfBrokerageStatementsQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.number_of_brokerage_statements,
    formId,
  })

  const receivedDividendsQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.received_dividends,
    formId,
  })

  const skippedQuestions = useSkipQuestion(investmentsQuestionIds)

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.investments_bought_sold]:
        investmentsBoughtSoldQR.initialResponse,
      [TaxListQuestionId.number_of_brokerage_statements]:
        numberOfBrokerageStatementsQR.initialResponse,
      [TaxListQuestionId.received_dividends]:
        receivedDividendsQR.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const responseData = [
        investmentsBoughtSoldQR.getResponseData(values),
        numberOfBrokerageStatementsQR.getResponseData(values),
        receivedDividendsQR.getResponseData(values),
      ]
      return goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.investments],
      })
    },
  })

  const { values, submitForm, isSubmitting } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <FormikScrollOnError />
      <Grid>
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Investments
          </Text>
        </GridRowColumn>

        <TQYesNoResponse questionResponse={investmentsBoughtSoldQR} skippable />

        <GridRowColumn {...tqSmallSpace}>
          <FormikInput
            name={fieldNames[TaxListQuestionId.number_of_brokerage_statements]}
            label={numberOfBrokerageStatementsQR.label}
            componentType="number"
            schema={createSkipSchema(
              values,
              TaxListQuestionId.number_of_brokerage_statements,
              makeNumberSchema({ allowedDecimals: 0 })
            )}
            prefix=""
            placeholder="Enter number"
            decimalScale={0}
            fullWidth
          />
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace} short>
          <SkipQuestion
            questionId={TaxListQuestionId.number_of_brokerage_statements}
          />
        </GridRowColumn>

        <TQYesNoResponse questionResponse={receivedDividendsQR} skippable />

        <GridRowColumn short {...tqSmallSpace}>
          <Divider />
        </GridRowColumn>
        <UploadDocumentSubSection
          categories={[
            values[TaxListQuestionId.investments_bought_sold]
              ? UserDocumentCategoryIdentifier.form1099b
              : undefined,
            ...(values[TaxListQuestionId.received_dividends]
              ? [
                  UserDocumentCategoryIdentifier.form1099div,
                  UserDocumentCategoryIdentifier.form1099int,
                ]
              : []),
          ]}
        />
        <Grid.Row />
        <FormFlowFooter
          continueDisabled={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default InvestmentsPanel
