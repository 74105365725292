import { Grid } from 'semantic-ui-react'
import { EndOfYearReviewStepStatus, ExtendedStepStatus } from '../../types'
import { Checkbox, Text } from '../../../../../components/BaseComponents'
import { TEST_IDS } from '../helpers'
import './styles.scss'
import { useMemo } from 'react'

const { complete, userActionRequired } = EndOfYearReviewStepStatus

export type BookkeepingReviewSectionProps = {
  sectionTitle: string
  status: ExtendedStepStatus
  updatedByUser?: boolean
  className?: string
  confirmationTitle?: string
  confirmationDescription?: React.ReactNode
  onConfirmClick?: () => void
  testId?: string
  minHeight?: number
  children: React.ReactNode
}
const BookkeepingReviewSection = ({
  sectionTitle,
  status,
  className,
  updatedByUser = false,
  confirmationTitle,
  confirmationDescription,
  onConfirmClick,
  testId,
  minHeight = 106,
  children,
}: BookkeepingReviewSectionProps) => {
  const extendedStatus = useMemo(() => {
    if (updatedByUser && status !== complete) return 'updated_by_user'
    return status
  }, [updatedByUser, status])

  return (
    <Grid.Row
      className={`eoy-admin__bk-review-section eoy-admin_status_${extendedStatus} ${className}`}
    >
      <Grid.Column
        className="eoy-admin__bk-review-section__title-col"
        width={3}
      >
        <div className="eoy-admin__bk-review-section__title-col-content">
          <Text as="h3" testId={TEST_IDS.bkReviewSectionTitle(testId)}>
            {sectionTitle}
          </Text>
        </div>
      </Grid.Column>

      <Grid.Column
        className="eoy-admin__bk-review-section__content-col"
        width={10}
        style={{ minHeight }}
      >
        {children}
      </Grid.Column>

      <Grid.Column
        className="eoy-admin__bk-review-section__confirm-col"
        width={3}
      >
        {onConfirmClick && (
          <div className="eoy-admin__bk-review-section__confirm-wrapper">
            <Checkbox
              label={confirmationTitle}
              checked={status === complete}
              disabled={status === userActionRequired}
              onClick={() => status !== userActionRequired && onConfirmClick()}
            />
            {confirmationDescription && (
              <Text
                as="bodyXs"
                className="eoy-admin__bk-review-section__confirmation-description"
                testId={TEST_IDS.bkReviewConfirmDescription(testId)}
              >
                {confirmationDescription}
              </Text>
            )}
          </div>
        )}
      </Grid.Column>
    </Grid.Row>
  )
}

export default BookkeepingReviewSection
