import {
  Accordion,
  Alert,
  Card,
  Link,
  Loader,
  Text,
} from '../../../../../components/BaseComponents'
import { FetchError } from '../../../../../reducers/fetch'
import {
  EndOfYearReviewSubmission,
  EndOfYearSubmissionStatus,
} from '../../types'
import './styles.scss'

export type SubmissionsPanelContentProps = {
  submissions: EndOfYearReviewSubmission[] | null
  isFetching: boolean
  fetchError: FetchError | undefined
}
const SubmissionsPanelContent = ({
  submissions,
  isFetching,
  fetchError,
}: SubmissionsPanelContentProps) => {
  return (
    <Card className="eoy-admin__submission-panel" fullWidth>
      <Text as="h1">Submissions</Text>

      {fetchError && (
        <Alert
          className="eoy-admin__submission-panel__error-alert"
          type="error"
        >
          {fetchError.message}
        </Alert>
      )}

      {!submissions || isFetching ? (
        <Loader />
      ) : submissions.length === 0 ? (
        <Alert className="eoy-admin__submission-panel__not-found" type="info">
          No submissions found
        </Alert>
      ) : (
        submissions.map((submission) => (
          <Accordion
            className="eoy-admin__submission-panel__submission-accordion"
            key={submission.id}
            title={submission.year}
            content={
              <div>
                <Text
                  className="eoy-admin__submission-panel__submission-year"
                  as="bodyMd"
                >
                  <span className="eoy-admin__submission-panel__label">
                    Year-End Bookkeeping:
                  </span>{' '}
                  {submission.status ===
                  EndOfYearSubmissionStatus.inProgress ? (
                    'Pending initial submission'
                  ) : (
                    <Link
                      to={`/admin/finances/records/${submission.userId}/submissions/${submission.year}`}
                    >
                      View Summary &rarr;
                    </Link>
                  )}
                </Text>
              </div>
            }
          />
        ))
      )}
    </Card>
  )
}

export default SubmissionsPanelContent
