import { Divider, Grid } from 'semantic-ui-react'
import {
  Accordion,
  Alert,
  Button,
  FormikCheckbox,
  FormikDropdown,
  FormikInput,
  getFieldName,
  GridRowColumn,
  Link,
  makeReqStringSchema,
  Modal,
  Popup,
  Text,
} from '../../../components/BaseComponents'
import {
  convertUtcToLocalDate,
  DATE_FORMATS_LUXON,
} from '../../../utils/dateHelpers'
import { useMemo } from 'react'
import {
  ADMIN_UPDATE_ANNUAL_TAX_FILING_KEY,
  AdminAnnualTaxFiling,
  updateAdminAnnualTaxFiling,
} from './adminAnnualTaxFilings.slice'
import {
  AnnualTaxFilingForm,
  TaxfyleJob,
  TaxfyleJobStatus,
} from '../../Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import { TAX_ENTITY_TYPES } from '../../Taxes/taxConstants'
import { ExtensionReason } from '../../Taxes/AnnualTaxes/annualTaxFilings.slice'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { getFetchError } from '../../../reducers/fetch'
import { FormikProvider, useFormik } from 'formik'
import ExtensionPaymentsForm from './ExtensionPaymentsForm'
import TaxFilingFormUploadModal, {
  useTaxFilingFormUploadModal,
} from './TaxFilingFormUploadModal'
import { TaxfyleHistoryContent } from './TaxfyleJobHistoryModal'

const getExtensionFilingStatusForForm = (form?: AnnualTaxFilingForm) => {
  if (!form) {
    return 'Not applicable'
  } else if (!form.extensionFiledAt) {
    return 'Incomplete'
  } else if (form.extensionAccepted) {
    return `Accepted (${convertUtcToLocalDate(form.extensionFiledAt)?.toFormat(DATE_FORMATS_LUXON.DISPLAY_SHORT)})`
  } else {
    return `Rejected (${convertUtcToLocalDate(form.extensionFiledAt)?.toFormat(DATE_FORMATS_LUXON.DISPLAY_SHORT)})`
  }
}

const AdminExtensionInfoModal = ({
  filing,
  filingForms = [],
  extensionTaxfyleJob,
  refreshData,
  open,
  close,
}: {
  filing: AdminAnnualTaxFiling
  filingForms?: AnnualTaxFilingForm[]
  extensionTaxfyleJob?: TaxfyleJob | null
  refreshData: () => void
  open: boolean
  close: () => void
}) => {
  const user = filing.annualTaxFilings
  const canUpdateExtended = useMemo(
    () =>
      !filing.optedOutAt &&
      (!extensionTaxfyleJob ||
        TaxfyleJobStatus.underConstruction === extensionTaxfyleJob.jobStatus),
    [extensionTaxfyleJob, filing.optedOutAt]
  )
  const error = useReselector(getFetchError, ADMIN_UPDATE_ANNUAL_TAX_FILING_KEY)
  const dispatch = useAppDispatch()
  const {
    modalOpen,
    setModalOpen,
    modalView,
    setModalView,
    modalUserContext,
    modalFilingFormIdContext,
    modalFilingFormTypeNameContext,
    modalFilingFormExtensionPaymentsContext,
    filingFormIsExtendedContext,
    closeModal,
    handleModalOpen,
  } = useTaxFilingFormUploadModal()

  const formik = useFormik({
    initialValues: {
      isExtended: Boolean(filing.extensionRequestedAt),
      extensionReason: filing.extensionReason,
      isExempted: Boolean(filing.exemptedFromExtensionAt),
      exemptionReason: filing.exemptedFromExtensionReason,
    },
    onSubmit: async (values) => {
      const updates: Partial<AdminAnnualTaxFiling> = {}
      // only update if values changed
      if (values.isExtended !== Boolean(filing.extensionRequestedAt)) {
        updates.extensionRequestedAt = values.isExtended
          ? new Date().toISOString()
          : null
        updates.extensionReason = values.isExtended
          ? values.extensionReason
          : null
      }
      if (values.isExempted !== Boolean(filing.exemptedFromExtensionAt)) {
        updates.exemptedFromExtensionAt = values.isExempted
          ? new Date().toISOString()
          : null
        updates.exemptedFromExtensionReason = values.isExempted
          ? values.exemptionReason
          : null
      }
      const res = await updateAdminAnnualTaxFiling(filing.id, updates)(dispatch)
      if (res) {
        close()
        return true
      }
      return false
    },
  })

  const { values, submitForm, isSubmitting, isValid } = formik

  const extensionReasonOptions = Object.values(ExtensionReason)
    .map((reason) => ({
      key: reason,
      text: reason,
      value: reason,
    }))
    .filter((reason) =>
      [
        ExtensionReason.lateJoiner,
        ExtensionReason.tskOverdue,
        ExtensionReason.taxEntityUnconfirmed,
        ExtensionReason.bookkeepingTasksIncomplete,
        ExtensionReason.booksNotLocked,
      ].includes(reason.value)
    )

  const extensionSurveyStatus = useMemo(() => {
    if (!filing.extensionRequestedAt) {
      return null
    } else if (!filing.extensionStartedAt) {
      return 'Not Started'
    } else if (filing.extensionRequestSurveyCompletedAt) {
      return `Completed (${convertUtcToLocalDate(filing.extensionRequestSurveyCompletedAt)?.toFormat(DATE_FORMATS_LUXON.DISPLAY_SHORT)})`
    } else {
      return `In Progress (${convertUtcToLocalDate(filing.extensionStartedAt)?.toFormat(DATE_FORMATS_LUXON.DISPLAY_SHORT)})`
    }
  }, [
    filing.extensionRequestedAt,
    filing.extensionStartedAt,
    filing.extensionRequestSurveyCompletedAt,
  ])

  const businessFilingForm = useMemo(
    () =>
      filingForms.find(
        (form) => form.formType.name === TAX_ENTITY_TYPES.form_1120_s
      ),
    [filingForms]
  )
  const personalFilingForm = filingForms.find(
    (form) => form.formType.name === TAX_ENTITY_TYPES.form_1040
  )

  const businessExtensionFilingStatus = useMemo(
    () => getExtensionFilingStatusForForm(businessFilingForm),
    [businessFilingForm]
  )
  const personalExtensionFilingStatus = useMemo(
    () => getExtensionFilingStatusForForm(personalFilingForm),
    [personalFilingForm]
  )

  return (
    <Modal size="small" open={open} onClose={close} closeIcon>
      <Modal.Header>
        <Text as="h1">
          Extension Information for{' '}
          {`${user?.firstName} ${user?.lastName} (${user?.id})`}
        </Text>
      </Modal.Header>
      <Modal.Content>
        <Grid>
          {filing.optedOutAt && (
            <GridRowColumn>
              <Alert>This user is opted out of taxes</Alert>
            </GridRowColumn>
          )}
          {canUpdateExtended && (
            <FormikProvider value={formik}>
              <GridRowColumn>
                <FormikCheckbox
                  name={getFieldName<typeof formik.values>('isExtended')}
                  label={
                    <Text>
                      Is extended?
                      <Popup content="Puts the user into the extension flow." />
                    </Text>
                  }
                  toggle
                  disabled={!canUpdateExtended}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue(
                        getFieldName<typeof formik.values>('isExempted'),
                        false
                      )
                      formik.setFieldValue(
                        getFieldName<typeof formik.values>('exemptionReason'),
                        null
                      )
                    } else {
                      formik.setFieldValue(
                        getFieldName<typeof formik.values>('extensionReason'),
                        null
                      )
                    }
                  }}
                />
              </GridRowColumn>
              {values.isExtended &&
                values.isExtended !== Boolean(filing.extensionRequestedAt) && (
                  <GridRowColumn>
                    <FormikDropdown
                      name={getFieldName<typeof formik.values>(
                        'extensionReason'
                      )}
                      options={extensionReasonOptions}
                      label="Extension Reason"
                      schema={makeReqStringSchema({
                        field: 'extension reason',
                        required: values.isExtended,
                      })}
                      disabled={!values.isExtended}
                    />
                  </GridRowColumn>
                )}
              <GridRowColumn>
                <FormikCheckbox
                  name={getFieldName<typeof formik.values>('isExempted')}
                  label={
                    <Text>
                      Exempt from forced extensions?
                      <Popup content="Exempts the user from being forced into the extension flow. This will be turned off automatically for the next round of forced extensions." />
                    </Text>
                  }
                  toggle
                  disabled={values.isExtended}
                />
              </GridRowColumn>
              {values.isExempted && (
                <GridRowColumn>
                  <FormikInput
                    name={getFieldName<typeof formik.values>('exemptionReason')}
                    label="Exemption Reason"
                    disabled={!values.isExempted}
                    schema={makeReqStringSchema({
                      field: 'exemption reason',
                      required: values.isExempted,
                    })}
                  />
                </GridRowColumn>
              )}
            </FormikProvider>
          )}
          {filing.extensionRequestedAt && (
            <>
              <GridRowColumn>
                <Text as="h3">Extension Requested</Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>
                  {filing.extensionRequestedAt
                    ? `Yes (${convertUtcToLocalDate(filing.extensionRequestedAt)?.toFormat(DATE_FORMATS_LUXON.DISPLAY_SHORT)})`
                    : 'No'}
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text as="h3">Extension Request Reason</Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>
                  {filing.extensionReason ? filing.extensionReason : 'unknown'}
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text as="h3">Extension Survey Status</Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>{extensionSurveyStatus}</Text>
              </GridRowColumn>
              <Divider />
              <GridRowColumn>
                <Text as="h3">Taxfyle Job</Text>
              </GridRowColumn>
              <GridRowColumn short>
                {!extensionTaxfyleJob && <Text>Not available</Text>}
                {extensionTaxfyleJob && (
                  <Link
                    href={`${process.env.TAXFYLE_BASE_URL}/jobs/${extensionTaxfyleJob.jobId}`}
                    newPage
                  >
                    Job portal
                  </Link>
                )}
              </GridRowColumn>
              {extensionTaxfyleJob && (
                <>
                  <GridRowColumn>
                    <Accordion
                      variant="text"
                      title="View Activity"
                      content={
                        <TaxfyleHistoryContent
                          taxfyleJobId={extensionTaxfyleJob.jobId}
                          createdAt={extensionTaxfyleJob.createdAt}
                          open={open}
                        />
                      }
                    />
                  </GridRowColumn>
                  <GridRowColumn>
                    <Text as="h3">Job Status</Text>
                  </GridRowColumn>
                  <GridRowColumn short>
                    <Text>{extensionTaxfyleJob.jobStatus}</Text>
                  </GridRowColumn>
                  <GridRowColumn>
                    <Text as="h3">Job Step</Text>
                  </GridRowColumn>
                  <GridRowColumn short>
                    <Text>{extensionTaxfyleJob.jobStep ?? 'No Job Step'}</Text>
                  </GridRowColumn>
                </>
              )}
              <Divider />
              <GridRowColumn>
                <Text as="h3">Business Extension</Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>{businessExtensionFilingStatus}</Text>
              </GridRowColumn>
              {businessFilingForm?.extensionAccepted && (
                <>
                  <GridRowColumn>
                    <Text as="h3">Business Extension Payments</Text>
                  </GridRowColumn>
                  <GridRowColumn short>
                    <ExtensionPaymentsForm
                      key={`${businessFilingForm.id}-extension-payments-form`}
                      paymentInfo={businessFilingForm.extensionPayments}
                      isEditing={false}
                      userId={businessFilingForm.userId}
                      filingFormId={businessFilingForm.id}
                      filingFormType={businessFilingForm.formType.name}
                      closeModal={closeModal}
                      isFormExtended={Boolean(filing.extensionRequestedAt)}
                    />
                  </GridRowColumn>
                  <GridRowColumn>
                    <Button
                      variant="link"
                      onClick={() =>
                        handleModalOpen(
                          'Extension Information',
                          `${user?.firstName} ${user?.lastName}`,
                          businessFilingForm.id,
                          businessFilingForm.formType.name,
                          businessFilingForm.extensionPayments,
                          Boolean(filing.extensionRequestedAt)
                        )
                      }
                    >
                      Update payment
                    </Button>
                  </GridRowColumn>
                </>
              )}
              <GridRowColumn>
                <Divider />
              </GridRowColumn>
              <GridRowColumn>
                <Text as="h3">Personal Extension</Text>
              </GridRowColumn>
              <GridRowColumn short>
                <Text>{personalExtensionFilingStatus}</Text>
              </GridRowColumn>
              {personalFilingForm?.extensionAccepted && (
                <>
                  <GridRowColumn>
                    <Text as="h3">Personal Extension Payments</Text>
                  </GridRowColumn>
                  <GridRowColumn>
                    <ExtensionPaymentsForm
                      key={`${personalFilingForm.id}-extension-payments-form`}
                      paymentInfo={personalFilingForm.extensionPayments}
                      isEditing={false}
                      userId={personalFilingForm.userId}
                      filingFormId={personalFilingForm.id}
                      filingFormType={personalFilingForm.formType.name}
                      closeModal={closeModal}
                      isFormExtended={Boolean(filing.extensionRequestedAt)}
                    />
                  </GridRowColumn>
                  <GridRowColumn>
                    <Button
                      variant="link"
                      onClick={() =>
                        handleModalOpen(
                          'Extension Information',
                          `${user?.firstName} ${user?.lastName}`,
                          personalFilingForm.id,
                          personalFilingForm.formType.name,
                          personalFilingForm.extensionPayments,
                          Boolean(filing.extensionRequestedAt)
                        )
                      }
                    >
                      Update payment
                    </Button>
                  </GridRowColumn>
                </>
              )}
            </>
          )}
          {user && (
            <TaxFilingFormUploadModal
              modalOpen={modalOpen}
              closeModal={() => setModalOpen(false)}
              modalView={modalView}
              setModalView={setModalView}
              modalUserContext={modalUserContext}
              filingId={filing.id}
              refreshData={refreshData}
              filingFormId={modalFilingFormIdContext}
              filingFormTypeName={modalFilingFormTypeNameContext}
              taxFilingUserId={user.id}
              filingFormExtensionPayments={
                modalFilingFormExtensionPaymentsContext
              }
              filingFormIsExtended={filingFormIsExtendedContext}
            />
          )}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        {error && <Alert type="error">{error.message}</Alert>}
        <Button
          onClick={close}
          variant="actionLink"
          style={{ marginRight: 32 }}
        >
          Cancel
        </Button>
        <Button disabled={isSubmitting || !isValid} onClick={submitForm}>
          {canUpdateExtended ? 'Update' : 'Close'}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AdminExtensionInfoModal
