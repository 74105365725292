import { Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { DateTime } from 'luxon'

import {
  FormikDateInput,
  FormikLabelError,
  FormikRadioToggleButton,
  GridRowColumn,
  Text,
  makeDateSchema,
  makeReqStringSchema,
  getFieldNames,
  FormikScrollOnError,
} from '../../../../../../components/BaseComponents'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectTaxListQuestionOptionByOptionId } from '../../taxChecklist.selectors'
import {
  TaxListOptionId,
  TaxListQuestionId,
  useTaxListQuestionRes,
} from '../../service'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { DATE_FORMATS } from '../../../../../../utils/dateHelpers'
import { LifeChangesStepProps } from '.'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import UploadDocumentSubSection from '../../Documents/UploadDocumentSubSection'
import { UserDocumentCategoryIdentifier } from '../../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import SkipQuestion, {
  createSkipSchema,
  skipQuestionId,
  useSkipQuestion,
} from '../../Shared/SkipQuestion'
import { tqBigSpace, tqSmallSpace } from '../../helpers'
import { select1040FormForYear } from '../../../annualTaxFilingForms.selector'

export const soldHomeQuestionIds = [
  TaxListQuestionId.home_sale_date,
  TaxListQuestionId.home_sale_residence_type,
]

const SoldHomePanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: LifeChangesStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const form1040 = useReselector(select1040FormForYear, taxYear)
  const formId = form1040?.id

  const saleDateQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.home_sale_date,
    formId,
  })

  const residenceType = useTaxListQuestionRes({
    questionId: TaxListQuestionId.home_sale_residence_type,
    formId,
  })

  const residencyTypeOptionPrimary = useReselector(
    selectTaxListQuestionOptionByOptionId,
    TaxListQuestionId.home_sale_residence_type,
    taxYear,
    TaxListOptionId.primary_residence
  )
  const residencyTypeOptionSecondary = useReselector(
    selectTaxListQuestionOptionByOptionId,
    TaxListQuestionId.home_sale_residence_type,
    taxYear,
    TaxListOptionId.secondary_residence
  )
  const residencyTypeOptionRental = useReselector(
    selectTaxListQuestionOptionByOptionId,
    TaxListQuestionId.home_sale_residence_type,
    taxYear,
    TaxListOptionId.rental_home
  )

  const skippedQuestions = useSkipQuestion(soldHomeQuestionIds)

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.home_sale_date]: saleDateQR.initialResponse,
      [TaxListQuestionId.home_sale_residence_type]:
        residenceType.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData = [
        saleDateQR.getResponseData(values),
        residenceType.getResponseData(values),
      ]

      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.soldHome],
      })
    },
  })

  const { values, isSubmitting, submitForm } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <FormikScrollOnError />
      <Grid>
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Sold a Home
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace}>
          <FormikDateInput
            minDate={DateTime.fromISO(taxYear).startOf('year').toJSDate()}
            maxDate={DateTime.fromISO(taxYear).endOf('year').toJSDate()}
            fullWidth
            name={fieldNames[TaxListQuestionId.home_sale_date]}
            label={saleDateQR.label}
            schema={createSkipSchema(
              values,
              TaxListQuestionId.home_sale_date,
              makeDateSchema({
                field: 'date',
                format: DATE_FORMATS.INPUT,
                strict: true,
              })
            )}
          />
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace} short>
          <SkipQuestion questionId={TaxListQuestionId.home_sale_date} />
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace}>
          <FormikLabelError
            name={fieldNames[TaxListQuestionId.home_sale_residence_type]}
            label={residenceType.label}
            schema={createSkipSchema(
              values,
              TaxListQuestionId.home_sale_residence_type,
              makeReqStringSchema({ field: 'selection' })
            )}
          />
        </GridRowColumn>
        <GridRowColumn short {...tqSmallSpace}>
          <FormikRadioToggleButton
            fullWidth
            name={fieldNames[TaxListQuestionId.home_sale_residence_type]}
            value={residencyTypeOptionPrimary?.id.toString() ?? ''}
          >
            {residencyTypeOptionPrimary?.text}
          </FormikRadioToggleButton>
        </GridRowColumn>
        <GridRowColumn short {...tqSmallSpace}>
          <FormikRadioToggleButton
            fullWidth
            name={fieldNames[TaxListQuestionId.home_sale_residence_type]}
            value={residencyTypeOptionSecondary?.id.toString() ?? ''}
          >
            {residencyTypeOptionSecondary?.text}
          </FormikRadioToggleButton>
        </GridRowColumn>
        <GridRowColumn short {...tqSmallSpace}>
          <FormikRadioToggleButton
            fullWidth
            name={fieldNames[TaxListQuestionId.home_sale_residence_type]}
            value={residencyTypeOptionRental?.id.toString() ?? ''}
          >
            {residencyTypeOptionRental?.text}
          </FormikRadioToggleButton>
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace} short>
          <SkipQuestion
            questionId={TaxListQuestionId.home_sale_residence_type}
          />
        </GridRowColumn>
        <GridRowColumn short {...tqSmallSpace}>
          <Divider />
        </GridRowColumn>

        <UploadDocumentSubSection
          categories={[
            UserDocumentCategoryIdentifier.homeSaleDocuments,
            values[TaxListQuestionId.home_sale_residence_type] ===
            residencyTypeOptionRental?.id
              ? UserDocumentCategoryIdentifier.homeDepreciationSchedule
              : undefined,
          ]}
        />
        <Grid.Row />
        <FormFlowFooter
          continueDisabled={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default SoldHomePanel
