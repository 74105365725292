import { useCallback, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { chunk } from 'lodash'

import { TileSelectorProps } from '../../../../../components/BaseComponents/TileSelector'
import {
  Button,
  Checkbox,
  GridRowColumn,
  Text,
  TileSelector,
} from '../../../../../components/BaseComponents'
import { TaxListQuestionId } from '../service'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectTaxListQuestionResponsesByQuestionIds } from '../taxChecklist.selectors'
import { TaxChecklistResponse } from '../taxChecklistQuestion.slice'
import {
  REVIEW_QUERY_PARAM,
  useSetScreen,
} from '../../../../../components/FormFlow/formFlow'
import { SkipPopup, skipQuestionWrapperStyle } from './SkipQuestion'

const tileColumnStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: 16,
}

const NoneApply = ({
  width,
  selected,
  setSelection,
  ...rest
}: Pick<TileProps, 'width'> & {
  selected: boolean
  setSelection: () => void
}) => {
  return (
    <Button
      variant="toggle"
      style={{ height: 83, width }}
      onClick={setSelection}
      active={selected}
      {...rest}
    >
      <Text as="h3" textAlign="center">
        None of these apply to me
      </Text>
    </Button>
  )
}

interface TileProps extends Omit<TileSelectorProps, 'active'> {
  questionId: TaxListQuestionId
  response?: Partial<TaxChecklistResponse>
}

export const useTiles = ({
  tileWidth = 162,
  tileHeight = 150,
}: {
  tileWidth?: number
  tileHeight?: number
} = {}) => {
  const { formId } = useParams()
  const { currentScreen, setScreen } = useSetScreen()
  const [tiles, setTiles] = useState<TileProps[]>([])
  const [noneApplySelected, setNoneApplySelected] = useState(false)
  const [searchParams] = useSearchParams()
  const reviewing = searchParams.get(REVIEW_QUERY_PARAM) === 'true'

  const questionResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    tiles.map((tile) => tile.questionId),
    Number(formId)
  )

  const maxColumns =
    tiles.length < 3 ? tiles.length : tiles.length === 4 ? 2 : 3
  const tileGap = 16

  const tileClicked = useCallback(
    (
      questionId: TaxListQuestionId,
      checked: boolean,
      needsAssistance: boolean
    ) => {
      const newTileSelections = tiles.map((tile) => {
        if (tile.questionId === questionId) {
          return {
            ...tile,
            response: {
              ...(tile.response ?? {}),
              needsAssistance,
              value: checked,
            },
          }
        }
        return tile
      })
      setTiles(newTileSelections)
      setNoneApplySelected(false)
      if (reviewing) {
        // If coming from a review screen, puts the user back into the normal
        // form flow since tile selections usually have conditional follow-up screens
        setScreen(currentScreen, true)
      }
    },
    [tiles, currentScreen, setScreen, reviewing]
  )

  const clearTiles = useCallback(() => {
    const newSelections = tiles.map((tile) => ({
      ...tile,
      response: {
        ...(tile.response ?? {}),
        value: false,
      },
    }))
    setTiles(newSelections)
    setNoneApplySelected((sn) => !sn)
  }, [tiles])

  const tilesToResponses = useCallback(
    () =>
      tiles.flatMap((tile) => {
        const oldResponse = questionResponses.find(
          (r) => r.questionId === tile.questionId
        )

        if (
          tile.response?.value !== oldResponse?.value ||
          tile.response?.needsAssistance !== oldResponse?.needsAssistance
        ) {
          return {
            id: tile.response?.id,
            questionId: tile.questionId,
            annualTaxFilingFormId: Number(formId),
            value: tile.response?.value ?? false,
            needsAssistance: tile.response?.needsAssistance ?? false,
          }
        }
        return []
      }),
    [tiles, formId, questionResponses]
  )

  const anyResponseSelectedOrNeedsHelp = useMemo(
    () =>
      tiles.some((s) =>
        Boolean(s.response?.value || s.response?.needsAssistance)
      ),
    [tiles]
  )

  const tileSelections = useMemo(
    () =>
      chunk(tiles, 3).map((tileRow) => (
        <GridRowColumn
          key={tileRow.map((tile) => tile.questionId).join('-')}
          columnStyle={tileColumnStyle}
        >
          {tileRow.map(({ questionId, response, ...rest }) => {
            const needsHelp = Boolean(response?.needsAssistance)
            const selected = Boolean(response?.value)

            const needsHelpCopy = needsHelp ? 'Follow-up' : 'Request follow-up'

            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: tileWidth,
                }}
                key={questionId}
              >
                <TileSelector
                  {...rest}
                  active={selected}
                  width={tileWidth}
                  height={tileHeight}
                  onClick={() => tileClicked(questionId, !selected, needsHelp)}
                />
                <div
                  style={{
                    ...skipQuestionWrapperStyle(needsHelp),
                    marginTop: 10,
                  }}
                >
                  <Checkbox
                    label={needsHelpCopy}
                    variant="default"
                    checked={needsHelp}
                    onClick={() =>
                      tileClicked(questionId, selected, !needsHelp)
                    }
                  />
                  {needsHelp && <SkipPopup />}
                </div>
              </div>
            )
          })}
        </GridRowColumn>
      )),
    [tileClicked, tileHeight, tileWidth, tiles]
  )

  const noneSelection = useMemo(
    () => (
      <GridRowColumn short columnStyle={tileColumnStyle}>
        <NoneApply
          width={tileWidth * maxColumns + tileGap * (maxColumns - 1)}
          selected={noneApplySelected}
          setSelection={clearTiles}
        />
      </GridRowColumn>
    ),
    [clearTiles, maxColumns, noneApplySelected, tileWidth]
  )

  return {
    tiles,
    setTiles,
    tilesToResponses,
    anyResponseSelectedOrNeedsHelp,
    noneApplySelected,
    tileSelections,
    noneSelection,
  }
}
