import { Fragment } from 'react'
import { Alert } from '../../../../../components/BaseComponents'
import { KeyedFetchError } from '../../types'
import { TEST_IDS } from '../helpers'

const Errors = ({ errors }: { errors: KeyedFetchError[] }) => {
  if (errors.filter((error) => error.message).length === 0) {
    return null
  }

  return (
    <Alert type="error" title="Error">
      {errors
        .filter((error) => error.message)
        .map(({ key, message }, idx) => (
          <Fragment key={key}>
            <span data-testid={TEST_IDS.error(key)}>{message}</span>
            {idx !== errors.length - 1 && (
              <>
                <br />
                <br />
              </>
            )}
          </Fragment>
        ))}
    </Alert>
  )
}

export default Errors
