import axios from 'axios'
import { normalize } from 'normalizr'
import { Moment } from 'moment'

import { transactionListSchema, transactionSchema } from '../../schema'
import {
  PostTransactionPayload,
  receiveAdminTransactionsByCategory,
  receiveAdminAllTransactions,
  Transaction,
  TransactionResponseEntities,
} from '../../reducers/admin/allTransactions.slice'
import { Dispatch } from '../../utils/typeHelpers'
import { fetchIfNeededWrapper, fetchWrapper } from '../../reducers/fetch'
import { fetchUserTransactionsByCategoryId } from '../../features/Transactions/transactions.slice'
import { receiveJournalEntries } from '../../reducers/admin/journalEntryReducer'

export const fetchUserAllTransactionsIfNeeded = (
  userId: number | string,
  alwaysFetch = false
) =>
  fetchIfNeededWrapper({
    defaultErrorMessage: 'Error fetching all transactions',
    fetchKey: `FETCH_USER_ALL_TRANSACTIONS_KEY_${userId}`,
    alwaysFetch,
    fetchFunction: (dispatch) =>
      axios
        .get<{
          count: number
          userId: string
          transactions: Transaction[]
        }>(`/finances/api/v1/admin/transactions/${userId}`)
        .then((json) => {
          const normalizedData = normalize<
            Transaction,
            TransactionResponseEntities,
            string[]
          >(json.data.transactions, transactionListSchema)
          dispatch(receiveAdminAllTransactions(normalizedData))
          if (normalizedData.entities.journalEntries) {
            dispatch(
              receiveJournalEntries(normalizedData.entities.journalEntries)
            )
          }
          return json.data
        }),
  })

/*
  Update transaction for user
*/

export const PUT_ADMIN_UPDATE_USER_TRANSACTION_KEY =
  'PUT_ADMIN_UPDATE_USER_TRANSACTION_KEY'

export const adminUpdateUserTransaction = (
  transactionId: number | string,
  data: PostTransactionPayload
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error update transaction',
    defaultValue: false,
    fetchKey: PUT_ADMIN_UPDATE_USER_TRANSACTION_KEY,
    fetchFunction: (dispatch) => {
      return axios
        .put<Transaction>(
          `/finances/api/v1/admin/transactions/${transactionId}`,
          data
        )
        .then((json) => {
          const normalizedData = normalize<
            Transaction,
            TransactionResponseEntities,
            string[]
          >(json.data, transactionSchema)
          dispatch(receiveAdminAllTransactions(normalizedData))
          if (normalizedData.entities.journalEntries) {
            dispatch(
              receiveJournalEntries(normalizedData.entities.journalEntries)
            )
          }
          return true
        })
    },
  })

export const ADMIN_USER_TRANSACTIONS_BY_CATEGORY_ID_KEY =
  'ADMIN_USER_TRANSACTIONS_BY_CATEGORY_ID_KEY'

export const fetchAdminUserTransactionsByCategoryId = ({
  id,
  limit,
  startDate,
  endDate,
  userId,
}: {
  id: number | null
  limit: string | null
  startDate: Moment | undefined
  endDate: Moment | undefined
  userId: number
}) =>
  fetchWrapper({
    fetchKey: `${ADMIN_USER_TRANSACTIONS_BY_CATEGORY_ID_KEY}${userId}${id}`,
    defaultErrorMessage: 'Error updating transaction.',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<{
        transactionCategoryId: number
        transactionIds: number[]
        count: number
      }>('/finances/api/v1/admin/transactions/by_category', {
        params: {
          startDate: startDate?.format('YYYY-MM-DD'),
          endDate: endDate?.format('YYYY-MM-DD'),
          limit,
          userId,
          transactionCategoryId: id,
        },
      })
      dispatch(
        receiveAdminTransactionsByCategory({
          ...json.data,
          userId,
        })
      )
    },
  })

// Used by the profit and loss reports.  It wraps the admin and user transaction by category calls.  User id sent if is admin
export const fetchTransactionsByCategoryId =
  ({
    id,
    limit,
    startDate,
    endDate,
    userId,
  }: {
    id: number | null
    limit: string | null
    startDate: Moment | undefined
    endDate: Moment | undefined
    userId: number | null
  }) =>
  async (dispatch: Dispatch) => {
    if (userId) {
      await fetchAdminUserTransactionsByCategoryId({
        id,
        limit,
        startDate,
        endDate,
        userId,
      })(dispatch)
    } else {
      await fetchUserTransactionsByCategoryId({
        id,
        limit,
        startDate,
        endDate,
      })(dispatch)
    }
  }

export const FETCH_SINGLE_TRANSACTION_KEY = 'FETCH_SINGLE_TRANSACTION_KEY'
export const fetchSingleTransaction = (id: number | string) =>
  fetchWrapper({
    fetchKey: FETCH_SINGLE_TRANSACTION_KEY,
    defaultErrorMessage: 'Error in retrieving single transaction',
    defaultValue: false as const,
    fetchFunction: async (dispatch: Dispatch) => {
      const json = await axios.get<Transaction>(
        `/finances/api/v1/admin/transactions/single/${id}`
      )
      const normalizedData = normalize<
        Transaction,
        TransactionResponseEntities,
        string[]
      >(json.data, transactionSchema)
      dispatch(receiveAdminAllTransactions(normalizedData))
      return json
    },
  })

const FETCH_UPDATES_DISABLED_KEY = 'FETCH_UPDATES_DISABLED_KEY'
export const fetchTransactionsUpdatesDisabled = (userId: string | number) =>
  fetchWrapper({
    fetchKey: FETCH_UPDATES_DISABLED_KEY,
    defaultErrorMessage: 'Error in retrieving single transaction',
    defaultValue: false as const,
    fetchFunction: async () => {
      const json = await axios.get<{ message: string | null }>(
        `/finances/api/v1/admin/transactions/updates_disabled/${userId}`
      )
      return json.data
    },
  })
