import { Component, CSSProperties, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { DateTime } from 'luxon'
import Moment from 'react-moment'
import {
  Breadcrumb,
  Button,
  Card,
  Confirm,
  Container,
  Dimmer,
  Divider,
  Grid,
  Header,
  Label,
  Loader,
  Menu,
  Popup,
  Tab,
  Table,
} from 'semantic-ui-react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { adminFetchSingleUser } from '../../../actions/admin/adminAllUsersActions'
import { fetchTransactionCounts } from '../../../actions/adminActions'
import { fetchAdminUsersDocuments } from '../../../actions/admin/userDocumentsActions'
import {
  fetchTransactionCountsSinceLastReconciliation,
  fetchUserFinancialAccounts,
  getDateOfLastTransaction,
} from '../../../actions/admin/adminFinancialAccountActions'
import {
  fetchAdminUsersBookkeepingReports,
  updateUsersBookkeepingReportStatus,
} from '../../../actions/admin/bookkeepingReportActions'
import BookkeepingStatusLabel from '../UserRecord/BookkeepingStatusLabel'
import BookkeepingStatusCard from './BookkeepingStatusCard'
import TransactionTable from '../TransactionTable'
import PaginatedTransactionTable from '../PaginatedTransactionTable'
import ProfitAndLossTable from './ProfitAndLossTable'
import UserRecordNotes from '../UserRecord/UserRecordNotes'
import ReconciliationModal, {
  ReconciliationModalProps,
} from '../Reconciliation/Screens/ReconciliationModal'
import { toUTCDateString } from '../Reconciliation/service'
import {
  clearAndUpdateTransactionFilters,
  clearTransactionFilters,
} from '../../../reducers/finances/transactionFiltersReducer'
import {
  Dispatch,
  ReduxState,
  wrapDispatch,
  filterNulls,
  useAppDispatch,
} from '../../../utils/typeHelpers'
import {
  FinancialAccountWithAdminInfo,
  Reconciliation,
} from '../../../reducers/admin/financialAccountsReducer'
import {
  getUserById,
  selectAdminUserState,
} from '../../../selectors/user.selectors'
import { withRouter } from '../../../utils/routeHelpers'
import UserBreadcrumbs from '../UserRecord/UserBreadcrumbs'
import {
  getUserFinancialAccounts,
  selectSortedFinancialAccounts,
} from '../../../selectors/financeSelectors'
import { Alert, Text, Button as HeardButton, Icon } from '../../BaseComponents'
import SmartRuleCreationModal from '../../../features/Admin/SmartRuleCreation/SmartRuleCreationModal'
import { useReselector } from '../../../utils/sharedHooks'
import {
  selectBookkeepingReportsById,
  selectBookkeepingReportStatus,
} from '../../../selectors/bookkeepingReportsSelectors'
import TransactionCountsCard from './TransactionCountsCard'
import {
  BookkeepingReport,
  BookkeepingReportStatus,
} from '../../../reducers/finances/bookkeepingReportsReducer'
import { selectUserDocumentsByReportId } from '../../../features/UserDocuments/userDocuments.selector'
import {
  DATE_FORMATS,
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
  isoToUTCDateTime,
} from '../../../utils/dateHelpers'
import DownloadPayrollReportMenuItem from './DownloadPayrollReportMenuItem'
import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import UserAccountCanceledAlert from '../UserRecord/UserAccountStates/UserAccountCanceledAlert'
import UserStateLabel from '../UserRecord/UserAccountStates/UserStateLabel'
import { Colors } from '../../../styles/theme'
import { useBooleanFlagDetails, OpenFeature } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../features/OpenFeature'
import { OnboardingAnswers } from '../UserRecord/OnboardingAnswers'
import AutoReconciliationLoadingIndicator, {
  AutoReconciliationLoadingIndicatorProps,
} from '../Reconciliation/Components/AutoReconciliationModal'
import {
  FIRST_BALANCE_CHANGE_CAPTURED,
  FIRST_ELIGIBLE_REPORTING_PERIOD,
} from '../../../constants/autoReconciliationConstants'
import { VirtualStatement } from '../Reconciliation/Components/VirtualStatement'
import {
  getVirtualStatement,
  VirtualStatementData,
} from '../../../actions/admin/accountReconciliationActions'
import AutoReconciliationRecoveryModal, {
  AutoReconciliationRecoveryModalProps,
} from '../Reconciliation/Components/AutoReconciliationRecoveryModal'
import { findCurrentReconciliation, getRecoverabilityMetadata } from './helpers'
import { PROBLEMATIC_INSTITUTIONS } from '../../../constants/businessConstants'
import { AdminExcludeReportsButton } from '../../Finances/Accounts/AdminConfirmModals'

const DocumentsTable = ({
  userId,
  reportId,
}: {
  userId: string
  reportId?: string
}) => {
  const accounts = useReselector(getUserFinancialAccounts, userId)
  const releaseStatementUpload = useBooleanFlagDetails(
    FEATURE_FLAG_KEYS.multiStatementUpload,
    false
  )
  const statements = useReselector(
    selectUserDocumentsByReportId,
    userId,
    reportId
  )

  const filteredAccounts = useMemo(() => {
    if (!accounts) return []
    return filterNulls(Object.values(accounts)).filter((account) => {
      if (account.inactive) return false
      return (
        // filter out accounts where a statement exists with that financial account id
        !statements.some((statement) =>
          statement?.statement?.statementFinancialAccounts?.some(
            (statementAccount) =>
              statementAccount.financialAccountId === account.id
          )
        )
      )
    })
  }, [accounts, statements])

  const renderAccountsTable = () => {
    return (
      <>
        {filteredAccounts.map((account) => {
          return (
            <Table.Row
              key={account.id}
              style={{ backgroundColor: Colors.lightOrange }}
            >
              <Table.Cell>
                <Text as="bodyXs">
                  <b> {account.plaidInstitutionName} </b>
                </Text>
                <Text as="bodyXs">
                  {account?.name}:{account?.mask}
                </Text>
              </Table.Cell>
              <Table.Cell>
                {account.statementPermissions === 'plaid_statement' ? (
                  <Text as="bodyXs"> Plaid Statements </Text>
                ) : account.bankAccessEnabledAt ? (
                  <Text as="bodyXs">Limited Bank Access</Text>
                ) : (
                  <Text as="bodyXs"> Manual User Upload </Text>
                )}
              </Table.Cell>
              <Table.Cell> N/A </Table.Cell>
            </Table.Row>
          )
        })}
      </>
    )
  }
  const renderTable = () => {
    if (statements && statements.length > 0) {
      return statements.map(
        ({
          id,
          statementFinancialAccountId,
          signedUrl,
          fileDescription,
          createdAt,
          statement,
        }) => {
          const firstAccount = statement?.statementFinancialAccounts?.[0]
          const institution = firstAccount
            ? accounts?.[firstAccount.financialAccountId]?.plaidInstitutionName
            : null
          const isLBA = firstAccount
            ? accounts?.[firstAccount.financialAccountId]?.bankAccessEnabledAt
            : false
          return (
            <Table.Row key={id}>
              {releaseStatementUpload && (
                <Table.Cell>
                  {institution && (
                    <Text as="bodyXs">
                      <b> {institution} </b>
                    </Text>
                  )}
                  {accounts &&
                    statement?.statementFinancialAccounts?.map(
                      ({ financialAccountId }) => {
                        return (
                          <Text
                            key={financialAccountId}
                            as="bodyXs"
                            style={{ marginBottom: 6 }}
                          >
                            {accounts[financialAccountId]?.name}:
                            {accounts[financialAccountId]?.mask}
                          </Text>
                        )
                      }
                    )}
                </Table.Cell>
              )}

              {!releaseStatementUpload && (
                <Table.Cell>
                  {statementFinancialAccountId && accounts
                    ? `${accounts[statementFinancialAccountId]?.name}: ${accounts[statementFinancialAccountId]?.mask}`
                    : null}
                </Table.Cell>
              )}
              <Table.Cell>
                <a
                  href={signedUrl || undefined}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    overflowWrap: 'anywhere',
                    fontSize: 12,
                  }}
                >
                  {isLBA ? 'Limited Bank Access' : fileDescription}
                </a>
              </Table.Cell>
              <Table.Cell>
                <Moment format={DATE_FORMATS.DISPLAY_SHORT}>{createdAt}</Moment>
              </Table.Cell>
            </Table.Row>
          )
        }
      )
    }
    return (
      <Table.Row>
        <Table.Cell colSpan="3" textAlign="center">
          <Header as="h6">No Documents</Header>
        </Table.Cell>
      </Table.Row>
    )
  }
  return (
    <Table striped compact="very">
      {statements && statements.length > 0 && (
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Account</Table.HeaderCell>
            <Table.HeaderCell width={4}>File Name</Table.HeaderCell>
            <Table.HeaderCell width={2}>Uploaded</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      )}
      <Table.Body>
        {renderTable()}
        {renderAccountsTable()}
      </Table.Body>
    </Table>
  )
}

const StatementCard = ({
  reportId,
  userId,
}: {
  reportId?: string
  userId: string
}) => {
  return (
    <Card fluid>
      <Card.Content>
        <Header as="h5">Statements</Header>
        <DocumentsTable userId={userId} reportId={reportId} />
      </Card.Content>
    </Card>
  )
}

const TIME_FORMAT = 'h:mm:ss a'

// 6 total states, 3 are derived
const statuses = (currentRecon?: Reconciliation, count?: number) => {
  const date = currentRecon?.updatedAt
  const subtitleDate = date
    ? toUTCDateString({
        value: date,
        format: DATE_FORMATS.DISPLAY_LONG,
      })
    : ''
  const popupDate = date
    ? toUTCDateString({ value: date, format: DATE_FORMATS.DISPLAY_SHORT })
    : ''
  const popupTime = date
    ? toUTCDateString({ value: date, format: TIME_FORMAT })
    : ''

  return {
    complete: {
      color: 'green' as const,
      label: 'complete',
      subtitle: null,
      type: 'complete',
      currentRecon,
      popupText: null,
    },
    incomplete: {
      color: 'red' as const,
      label: 'incomplete',
      subtitle: `Resume from ${subtitleDate}`,
      type: 'incomplete',
      popupText: `Last edited on ${popupDate} at ${popupTime} UTC`,
      currentRecon,
    },
    blocked: {
      color: 'red' as const,
      label: 'blocked',
      subtitle: 'Blocked: Reconcile previous month first',
      type: 'blocked',
      popupText: `Last attempted on ${popupDate} at ${popupTime} UTC`,
      currentRecon,
    },
    // derived - - -
    not_reconciled: {
      color: 'yellow' as const,
      label: 'not reconciled',
      subtitle: 'This acct has not been reconciled this month',
      type: 'not_reconciled',
      popupText: "Click 'Reconcile' to reconcile this month",
      currentRecon,
    },
    needs_updating: {
      color: 'yellow' as const,
      label: 'needs updating',
      type: 'needs_updating',
      subtitle: `There are ${count || 0} new transaction(s)`,
      popupText: `${
        count || 0
      } transaction(s) have arrived since you last reconciled on ${popupDate} at ${popupTime} UTC`,
      currentRecon,
    },
    never_reconciled: {
      color: 'red' as const,
      label: 'never reconciled',
      subtitle: 'This acct has never been reconciled',
      type: 'never_reconciled',
      popupText: "Click 'Reconcile' to begin your first reconciliation",
      currentRecon,
    },
    // - - - end derived
  }
}

const getAccountStatus = (
  account: FinancialAccountWithAdminInfo,
  report: BookkeepingReport,
  newTransactionCounts: { [key: number]: number }
) => {
  let status

  if (account.reconciliations.length === 0) {
    status = statuses().never_reconciled
    return status
  }

  const currentRecon = findCurrentReconciliation(
    report,
    account.reconciliations
  )

  if (!currentRecon) {
    status = statuses().not_reconciled
    return status
  }

  status = statuses(currentRecon)[currentRecon.status]

  if (currentRecon.status === 'complete') {
    // status is complete in DB, but need to check if new transactions exist
    const count = newTransactionCounts[account.id]

    if (count > 0) {
      // if new transactions exist, set status to 'needs updating'
      status = statuses(currentRecon, count).needs_updating
    }
  }

  return status
}

const ReconciliationAction = ({
  status,
  onAction,
  toggleVirtualStatement,
  disabled,
  actionText,
}: {
  status: ReturnType<typeof getAccountStatus>
  onAction: () => void
  toggleVirtualStatement: (reconciliationId?: number) => void
  disabled: boolean
  actionText: string
}) => {
  const currentRecon = status.currentRecon
  const ReconciliationText = ({
    text,
    date,
    color,
    style = {},
  }: {
    text: string
    date?: string | null
    color?: 'green' | 'red'
    style?: CSSProperties
  }) => (
    <div style={style}>
      <Text color={color} style={{ fontSize: 14, fontWeight: 500 }}>
        {text}
      </Text>
      {date && (
        <Text as="bodyXs" color={color}>
          {formatISOFromUTC(date, DATE_FORMATS_LUXON.DISPLAY_SHORT)}
        </Text>
      )}
    </div>
  )

  const ViewVirtualStatement = ({
    reconciliationId,
    style = {},
    toggleVirtualStatement,
  }: {
    reconciliationId: number
    style?: CSSProperties
    toggleVirtualStatement: (reconciliationId?: number) => void
  }) => (
    <HeardButton
      variant="secondaryLink"
      style={{ color: Colors.black, fontSize: 12, ...style }}
      onClick={() => toggleVirtualStatement(reconciliationId)}
    >
      View virtual bank statement
    </HeardButton>
  )

  return (
    <Card.Content style={{ margin: '0 auto' }}>
      {status.type === 'complete' && (
        <Grid>
          <Grid.Row>
            <Grid.Column width={2}>
              <Icon
                icon={solid('circle-check')}
                color="accentGreen"
                size="xl"
              />
            </Grid.Column>
            <Grid.Column width={14}>
              {currentRecon?.type === 'auto' && (
                <>
                  <ReconciliationText
                    text="Auto-reconciled"
                    date={currentRecon.createdAt}
                    color="green"
                  />
                  <ViewVirtualStatement
                    reconciliationId={currentRecon.id}
                    toggleVirtualStatement={toggleVirtualStatement}
                  />
                </>
              )}
              {currentRecon?.type === 'manual' && (
                <ReconciliationText
                  text="Manually Reconciled"
                  date={currentRecon.createdAt}
                  color="green"
                  style={{ paddingRight: 40 }}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      {status.type !== 'complete' && (
        <>
          {currentRecon?.type === 'auto' &&
            currentRecon.status === 'complete' && (
              <>
                <Icon
                  icon={solid('circle-check')}
                  color="accentGreen"
                  size="xl"
                  style={{ display: 'inline-block', paddingRight: 5 }}
                />
                <ReconciliationText
                  text="Auto-reconciled"
                  color="green"
                  style={{ display: 'inline-block', paddingBottom: 5 }}
                />
              </>
            )}
          {currentRecon?.type === 'auto' &&
            currentRecon.status === 'incomplete' && (
              <>
                <Icon
                  icon={solid('circle-xmark')}
                  color="red"
                  size="xl"
                  style={{ display: 'inline-block', paddingRight: 5 }}
                />
                <ReconciliationText
                  text="Auto-reconciliation failed"
                  color="red"
                  style={{ display: 'inline-block', paddingBottom: 5 }}
                />
              </>
            )}
          <Button
            color="blue"
            onClick={onAction}
            fluid={currentRecon?.type === 'auto'}
            disabled={disabled}
          >
            {actionText}
          </Button>
          {currentRecon?.type === 'auto' && (
            <ViewVirtualStatement
              reconciliationId={currentRecon.id}
              style={{ paddingTop: 5 }}
              toggleVirtualStatement={toggleVirtualStatement}
            />
          )}
        </>
      )}
    </Card.Content>
  )
}

const AccountSumCards = ({
  userId,
  financialAccounts,
  newTransactionCounts,
  report,
  onReconcileClick,
  toggleVirtualStatement,
  bookkeepingComplete,
}: {
  userId: string
  financialAccounts: FinancialAccountWithAdminInfo[]
  newTransactionCounts: { [key: number]: number }
  report: BookkeepingReport
  bookkeepingComplete: boolean
  onReconcileClick: (
    formType: string,
    account: FinancialAccountWithAdminInfo,
    reconType: 'auto' | 'manual' | 'recover'
  ) => void
  toggleVirtualStatement: (reconciliationId?: number) => void
}) => {
  const user = useReselector(getUserById, userId)
  const [datesOfLastTransaction, setDatesOfLastTransaction] = useState<{
    [id: number]: string
  }>({})
  const [manualTransactionCounts, setManualTransactionCounts] = useState<{
    [key: number]: number
  }>({})
  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetchLastTransactionDates = async () => {
      const accountIds = financialAccounts.map((account) => account.id)
      const newDates: { [id: number]: string } = {}
      for (const accountId of accountIds) {
        const res = await getDateOfLastTransaction(accountId)(dispatch)
        if (res) {
          newDates[accountId] = res.date
        }
      }
      setDatesOfLastTransaction(newDates)
    }
    fetchLastTransactionDates()
  }, [financialAccounts, dispatch])

  useEffect(() => {
    const fetchManualCounts = async () => {
      const manualCounts = await fetchTransactionCountsSinceLastReconciliation(
        userId,
        {
          manual: true,
          endDate: report.endDate,
        }
      )(dispatch)
      if (manualCounts) {
        setManualTransactionCounts(manualCounts)
      }
    }

    fetchManualCounts()
  }, [userId, report.endDate, dispatch])

  useEffect(() => {
    if (!user) return
    const currentContext = OpenFeature.getContext()
    // only set context if current context already exists
    if (!currentContext || Object.keys(currentContext).length === 0) return
    // make sure current context is included in new context
    OpenFeature.setContext({
      ...currentContext,
      reconUserEmail: user.email,
    })
  }, [user])

  const style = {
    card: { position: 'relative' },
    inactive: { opacity: '0.5' },
    label: { margin: '2px' },
    subtitle: { textAlign: 'center' as const, textTransform: 'none' as const },
  }

  return (
    <Card.Group itemsPerRow={4}>
      {financialAccounts.map((account) => {
        const status = getAccountStatus(account, report, newTransactionCounts)

        // Cannot auto-recon before launch date or for future reporting periods
        const eligibleReportingPeriod =
          report.date >= FIRST_ELIGIBLE_REPORTING_PERIOD &&
          DateTime.fromISO(report.endDate).toJSDate() <= new Date()

        const eligibleManualReconciliation = account.reconciliations.some(
          (recon) =>
            recon.status === 'complete' &&
            recon.type === 'manual' &&
            isoToUTCDateTime(recon.endingBalanceDate) >
              FIRST_BALANCE_CHANGE_CAPTURED
        )

        const { recoverable, recovering } = getRecoverabilityMetadata(
          report,
          account.reconciliations
        )

        const noCurrentReconOrRecoverable = !status.currentRecon || recoverable

        const isDisconnected = account.accounts?.needsReconnection

        const dateOfLastTransaction = datesOfLastTransaction[account.id]

        const noRecentTransactions =
          dateOfLastTransaction &&
          DateTime.now().diff(DateTime.fromISO(dateOfLastTransaction), 'days')
            .days > 7

        const isQuietlyDisconnected =
          noRecentTransactions &&
          PROBLEMATIC_INSTITUTIONS.includes(account?.plaidInstitutionId ?? '')

        const hasManualTransactions = manualTransactionCounts[account.id] > 0

        const errorState =
          isDisconnected || isQuietlyDisconnected || hasManualTransactions

        const formattedDateOfLastTransaction =
          dateOfLastTransaction &&
          DateTime.fromISO(dateOfLastTransaction).toFormat(
            DATE_FORMATS_LUXON.DISPLAY_SHORT
          )

        const canAutoRecon =
          account.accountType === 'plaid' &&
          eligibleReportingPeriod &&
          eligibleManualReconciliation &&
          noCurrentReconOrRecoverable &&
          !errorState

        return (
          <Card
            style={
              account.inactive
                ? style.inactive
                : {
                    backgroundColor: errorState
                      ? Colors.lightRed
                      : Colors.white,
                  }
            }
            fluid
            key={account.id}
          >
            <Card.Content>
              {account.inactive && (
                <Label style={style.label} color="orange">
                  inactive account
                </Label>
              )}
              {account.statementPermissions === 'plaid_statement' ? (
                <Label style={style.label} color="blue">
                  Plaid Statement
                </Label>
              ) : account.bankAccessEnabledAt ? (
                <Label style={style.label} color="violet">
                  LBA
                </Label>
              ) : null}
              <Label
                style={style.label}
                color={account.accountType === 'plaid' ? 'green' : 'red'}
              >
                {account.accountType}
              </Label>
              {isDisconnected && (
                <Label style={style.label} color="red">
                  Disconnected
                </Label>
              )}
              <Label
                style={style.label}
                basic={status.type === 'never_reconciled'}
                color={status.color}
              >
                {status.label}
              </Label>
              {account.inactive && (
                <Label style={style.label} color="brown">
                  {account.excludeFromReports
                    ? 'excluded from reports'
                    : 'included in reports'}
                </Label>
              )}
            </Card.Content>
            <Card.Content>
              <Card.Header>
                <Header as="h4" style={{ paddingLeft: 0 }}>
                  {account.plaidInstitutionName}
                </Header>
              </Card.Header>
              <Card.Description>
                <p>
                  <b>{account.name}:</b> {account.mask}
                </p>
                <p>
                  {account.type}&nbsp;—&nbsp;{account.subtype}
                </p>
              </Card.Description>
            </Card.Content>
            {(errorState || noRecentTransactions) && (
              <Card.Content extra>
                {(isDisconnected || noRecentTransactions) &&
                  !hasManualTransactions && (
                    <>
                      <Text as="bodyXs" color="red">
                        <b>Error: </b>
                        {isDisconnected
                          ? 'Disconnected'
                          : 'Over 7 days since last transaction'}
                      </Text>
                      <Text as="bodyXs" color="red">
                        <b>Date of last transaction:</b>{' '}
                        {formattedDateOfLastTransaction}
                      </Text>
                    </>
                  )}
                {hasManualTransactions && (
                  <Text as="bodySm" color="red">
                    Manual transaction(s) added since last reconciliation
                  </Text>
                )}
              </Card.Content>
            )}
            {!errorState && status.popupText && status.subtitle && (
              <Card.Content extra>
                <Popup
                  inverted
                  disabled={account.inactive}
                  content={`id ${account.id}: ${status.popupText}`}
                  trigger={
                    <p color={status.color} style={style.subtitle}>
                      {status.subtitle}
                    </p>
                  }
                />
              </Card.Content>
            )}

            {!account.inactive && (
              <ReconciliationAction
                status={status}
                actionText={
                  canAutoRecon ? 'Auto Reconcile' : 'Manually Reconcile'
                }
                onAction={() => {
                  // Passing "status.type" instead of "reconciliation.status"
                  // b/c reconciliation.status does not include derived types [needs_updating, never_reconciled]
                  onReconcileClick(
                    status.type,
                    account,
                    recoverable ? 'recover' : canAutoRecon ? 'auto' : 'manual'
                  )
                }}
                toggleVirtualStatement={toggleVirtualStatement}
                disabled={
                  (bookkeepingComplete && !recovering) ||
                  status.type === 'blocked'
                }
              />
            )}
            {account.inactive && (
              <AdminExcludeReportsButton
                userId={parseInt(userId)}
                account={account}
              />
            )}
          </Card>
        )
      })}
    </Card.Group>
  )
}

const getReportTransactionQueryProps = (
  userId: number,
  report: BookkeepingReport | undefined
) => {
  const startDate = report
    ? moment(report.startDate, 'YYYY-MM').startOf('month').format('MM-DD-YYYY')
    : undefined
  const endDate = report
    ? moment(report.endDate, 'YYYY-MM').endOf('month').format('MM-DD-YYYY')
    : undefined
  return {
    userId,
    startDate,
    endDate,
  }
}

type ParentProps = {
  params: { userId: string; reportId: string }
}
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type StateProps = ReturnType<typeof mapStateToProps>

type Props = ParentProps & DispatchProps & StateProps

interface State {
  loading: boolean
  inProgressOpen: boolean
  completeOpen: boolean
  reconciledOpen: boolean
  doneOpen: boolean
  smartRuleOpen: boolean
  reconciliationModalProps: ReconciliationModalProps | null
  newTransactionCounts: { [key: number]: number }
  showNewTransactionTable: boolean
  autoReconciliationLoadingIndicatorProps: AutoReconciliationLoadingIndicatorProps | null
  virtualStatementData: VirtualStatementData | null
  autoReconciliationRecoveryModalProps: AutoReconciliationRecoveryModalProps | null
}

const UserRecordHeader = ({ user }: { user?: UserWithAdminInfo }) => {
  const userState = useReselector(selectAdminUserState, user?.id)
  if (!userState) {
    return null
  }

  return (
    <div>
      <Header as="h3" {...(userState === 'unpaid' && { color: 'red' })}>
        <UserStateLabel user={user} />
        <UserAccountCanceledAlert user={user} />
      </Header>
    </div>
  )
}

class MonthlyBooksPanel extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      loading: true,
      inProgressOpen: false,
      completeOpen: false,
      reconciledOpen: false,
      doneOpen: false,
      smartRuleOpen: false,
      reconciliationModalProps: null,
      newTransactionCounts: {},
      showNewTransactionTable: false,
      autoReconciliationLoadingIndicatorProps: null,
      virtualStatementData: null,
      autoReconciliationRecoveryModalProps: null,
    }
  }

  async componentDidMount() {
    const {
      params: { userId },
      report,
    } = this.props

    const [newTransactionCounts] = await Promise.all([
      this.props.fetchTransactionCountsSinceLastReconciliation(userId),
      this.props.adminFetchSingleUser(userId),
      this.props.clearAndUpdateTransactionFilters(
        getReportTransactionQueryProps(Number(userId), report)
      ),
      this.props.fetchAdminUsersBookkeepingReports(userId),
      this.props.fetchAdminUsersDocuments(userId),
      this.props.fetchUserFinancialAccounts(userId),
    ])

    this.setState({ loading: false })

    if (newTransactionCounts) {
      this.setState({ newTransactionCounts })
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      params: { userId },
      report,
    } = this.props

    if (report && report?.id !== prevProps.report?.id) {
      this.props.clearAndUpdateTransactionFilters(
        getReportTransactionQueryProps(Number(userId), report)
      )
    }
  }

  componentWillUnmount() {
    this.props.clearTransactionFilters()
  }

  handleBooksUpdate = async (status: BookkeepingReportStatus) => {
    const {
      params: { reportId },
    } = this.props

    if (!reportId) {
      return
    }
    await this.props.updateUsersBookkeepingReportStatus(reportId, {
      status,
      bookkeepingReportId: reportId,
    })
  }

  toggleVirtualStatement = async (reconciliationId?: number) => {
    if (this.state.virtualStatementData || !reconciliationId) {
      this.setState({ virtualStatementData: null })
      return
    }
    const virtualStatementData =
      await this.props.getVirtualStatement(reconciliationId)

    if (virtualStatementData) {
      this.setState({ virtualStatementData })
    } else {
      // skipcq: JS-0052 - will be removed later
      alert(`Error fetching virtual statement for ${reconciliationId}`)
    }
  }

  renderProgressButton() {
    const { status } = this.props

    if (status === BookkeepingReportStatus.done) {
      return (
        <Button
          size="mini"
          className="headerButton"
          secondary
          disabled={false}
          onClick={() => this.setState({ inProgressOpen: true })}
        >
          1. Re-start Books
        </Button>
      )
    } else if (
      status === BookkeepingReportStatus.plaid_error ||
      status === BookkeepingReportStatus.missing_info
    ) {
      return (
        <Button
          size="mini"
          className="headerButton"
          secondary
          disabled={false}
          onClick={() =>
            this.handleBooksUpdate(BookkeepingReportStatus.in_progress)
          }
        >
          1. Start Books
        </Button>
      )
    }

    return (
      <Button
        size="mini"
        className="headerButton"
        secondary
        disabled={status !== 'not_started'}
        onClick={() => this.setState({ inProgressOpen: true })}
      >
        1. Start Books
      </Button>
    )
  }
  renderCompletedButton() {
    const { status } = this.props

    if (status !== BookkeepingReportStatus.in_progress) {
      return (
        <Button size="mini" className="headerButton" secondary disabled>
          2. Bookkeeping Complete
        </Button>
      )
    }
    return (
      <Button
        size="mini"
        className="headerButton"
        secondary
        onClick={() => this.setState({ completeOpen: true })}
      >
        2. Bookkeeping Complete
      </Button>
    )
  }

  renderActionsBar() {
    const {
      inProgressOpen,
      completeOpen,
      reconciledOpen,
      doneOpen,
      smartRuleOpen,
    } = this.state
    const { report, status, user } = this.props

    const completeBooksDisabled =
      !report?.reconciledAt ||
      status === BookkeepingReportStatus.in_progress ||
      status === BookkeepingReportStatus.done
    return (
      <Menu secondary size="mini">
        <Menu.Menu position="right">
          <Menu.Item>
            {this.renderProgressButton()}
            <Confirm
              size="mini"
              cancelButton="Cancel"
              confirmButton="Confirm Start"
              content="Are you sure you want to start these books?"
              open={inProgressOpen}
              onCancel={() => this.setState({ inProgressOpen: false })}
              onConfirm={() => {
                this.handleBooksUpdate(BookkeepingReportStatus.in_progress)
                this.setState({ inProgressOpen: false })
              }}
            />
          </Menu.Item>
          <Menu.Item>
            {this.renderCompletedButton()}
            <Confirm
              size="mini"
              cancelButton="Cancel"
              confirmButton="Confirm Completion"
              content="Are you sure you want to complete these books?"
              open={completeOpen}
              onCancel={() => this.setState({ completeOpen: false })}
              onConfirm={() => {
                this.handleBooksUpdate(BookkeepingReportStatus.categorized)
                this.setState({ smartRuleOpen: true, completeOpen: false })
              }}
            />
            {user && (
              <SmartRuleCreationModal
                open={smartRuleOpen}
                close={() => this.setState({ smartRuleOpen: false })}
                user={user}
              />
            )}
          </Menu.Item>

          <Menu.Item>
            <Button
              size="mini"
              className="headerButton"
              secondary
              disabled={status !== 'categorized'}
              onClick={() => this.setState({ reconciledOpen: true })}
            >
              3. Mark as Reconciled
            </Button>

            <Confirm
              size="mini"
              cancelButton="Cancel"
              confirmButton="Confirm Reconciliation"
              content={
                'This marks the books as reconciled,\u00A0meaning that you have gone through and cross referenced transactions with their bank accounts.'
              }
              open={reconciledOpen}
              onCancel={() => this.setState({ reconciledOpen: false })}
              onConfirm={() => {
                this.handleBooksUpdate(BookkeepingReportStatus.reconciled)
                this.setState({ reconciledOpen: false })
              }}
            />
          </Menu.Item>

          <Menu.Item>
            <Button
              size="mini"
              className="headerButton"
              secondary
              disabled={completeBooksDisabled}
              onClick={() => this.setState({ doneOpen: true })}
            >
              4. Mark as Done
            </Button>
            <Confirm
              size="mini"
              cancelButton="Cancel"
              confirmButton="Confirm Completion"
              content="Are you sure you want to set report as DONE? The user will see this on their Monthly Bookkeeping email."
              open={doneOpen}
              onCancel={() => this.setState({ doneOpen: false })}
              onConfirm={() => {
                this.handleBooksUpdate(BookkeepingReportStatus.done)
                this.setState({ doneOpen: false })
              }}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    )
  }

  renderBooks() {
    const {
      newTransactionCounts,
      loading,
      reconciliationModalProps,
      autoReconciliationLoadingIndicatorProps,
      showNewTransactionTable,
      autoReconciliationRecoveryModalProps,
    } = this.state
    const {
      user,
      params: { userId, reportId },
      report,
      accounts,
      status,
    } = this.props

    const bookkeepingComplete = status === BookkeepingReportStatus.done

    const onRecoverModalClose = async (
      reconciliation: Reconciliation | null
    ) => {
      this.setState({ autoReconciliationRecoveryModalProps: null })
      if (reconciliation) {
        const virtualStatementData = await this.props.getVirtualStatement(
          reconciliation.id
        )
        if (virtualStatementData) {
          this.setState({ virtualStatementData })
        }
      }
      const newTransactionCounts =
        await this.props.fetchTransactionCountsSinceLastReconciliation(userId)
      if (newTransactionCounts) {
        this.setState({ newTransactionCounts })
      }
    }

    const onReconcileClick = (
      formType: string,
      account: FinancialAccountWithAdminInfo,
      reconType: 'auto' | 'manual' | 'recover'
    ) => {
      // Prevent reconciliation for a "never_reconciled" account which does not have any transactions yet
      if (formType === 'never_reconciled') {
        const count = newTransactionCounts[account.id]

        if (count === 0) {
          alert(
            'This account does not have any transactions yet. Please force pull, or retry reconciling at a later time'
          )
          return
        }
      }

      if (!user || !report) {
        return
      }

      if (reconType === 'auto') {
        this.setState({
          autoReconciliationLoadingIndicatorProps: {
            account,
            report,
            open: true,
            onCompleted: async (reconciliation) => {
              this.setState({ autoReconciliationLoadingIndicatorProps: null })
              if (reconciliation) {
                // Check to see if reconciliation is recoverable
                const { recoverable, previousReconciliation } =
                  getRecoverabilityMetadata(report, [
                    reconciliation, // Have to manually add the new reconciliation to the list
                    ...account.reconciliations,
                  ])

                if (reconciliation.status === 'incomplete' && recoverable) {
                  this.setState({
                    autoReconciliationRecoveryModalProps: {
                      userId,
                      reportId,
                      account,
                      reconciliation,
                      previousReconciliation,
                      open: true,
                      close: onRecoverModalClose,
                    },
                  })
                } else {
                  const virtualStatementData =
                    await this.props.getVirtualStatement(reconciliation.id)
                  if (virtualStatementData) {
                    this.setState({ virtualStatementData })
                  }
                }

                const newTransactionCounts =
                  await this.props.fetchTransactionCountsSinceLastReconciliation(
                    userId
                  )
                if (newTransactionCounts) {
                  this.setState({ newTransactionCounts })
                }
              }
            },
          },
        })
      } else if (reconType === 'recover') {
        const { reconciliation, previousReconciliation } =
          getRecoverabilityMetadata(report, account.reconciliations)
        this.setState({
          autoReconciliationRecoveryModalProps: {
            userId,
            reportId,
            account,
            reconciliation,
            previousReconciliation,
            open: true,
            close: onRecoverModalClose,
          },
        })
      } else {
        this.setState({
          reconciliationModalProps: {
            account,
            formType,
            open: true,
            userId: user?.id,
            bookkeepingReport: report,
            close: () => {
              this.setState({ reconciliationModalProps: null })
            },
            onSumMatch: async () => {
              const newTransactionCounts =
                await this.props.fetchTransactionCountsSinceLastReconciliation(
                  userId
                )
              if (newTransactionCounts) {
                this.setState({ newTransactionCounts })
              }
            },
          },
        })
      }
    }

    return (
      <>
        {bookkeepingComplete && (
          <Alert type="warn" style={{ marginBottom: 10 }}>
            You must &quot;Re-start Books&quot; before making any changes.
            <br />
            <Text as="bodySm">
              Books completed
              {report?.completedAt &&
                ` on ${DateTime.fromISO(report.completedAt, { zone: 'utc' }).toFormat(DATE_FORMATS_LUXON.DISPLAY_SHORT)}`}
            </Text>
          </Alert>
        )}
        <Grid stackable doubling>
          <Grid.Row stretched>
            <Grid.Column width={6}>
              {userId && <StatementCard userId={userId} reportId={reportId} />}
            </Grid.Column>
            <Grid.Column width={4}>
              <TransactionCountsCard
                userId={userId}
                reportId={reportId}
                loading={loading}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              {report && <BookkeepingStatusCard report={report} />}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />
        {report && (
          <AccountSumCards
            userId={userId}
            financialAccounts={accounts}
            newTransactionCounts={newTransactionCounts}
            report={report}
            onReconcileClick={onReconcileClick}
            toggleVirtualStatement={this.toggleVirtualStatement}
            bookkeepingComplete={bookkeepingComplete}
          />
        )}
        {reconciliationModalProps && (
          <ReconciliationModal {...reconciliationModalProps} />
        )}
        {autoReconciliationLoadingIndicatorProps && (
          <AutoReconciliationLoadingIndicator
            {...autoReconciliationLoadingIndicatorProps}
          />
        )}
        {autoReconciliationRecoveryModalProps && (
          <AutoReconciliationRecoveryModal
            {...autoReconciliationRecoveryModalProps}
          />
        )}
        <Divider />

        <Dimmer.Dimmable dimmed={bookkeepingComplete}>
          <Dimmer active={bookkeepingComplete} verticalAlign="top">
            <Text as="h2" style={{ color: '#ffffff' }}>
              You must &quot;Re-start Books&quot; to view transactions.
            </Text>
          </Dimmer>

          {!showNewTransactionTable && <TransactionTable userId={user?.id} />}
          {showNewTransactionTable && user && (
            <PaginatedTransactionTable user={user} />
          )}
        </Dimmer.Dimmable>

        <VirtualStatement
          toggleOpen={() => this.toggleVirtualStatement()}
          onReconcileClick={onReconcileClick}
          data={this.state.virtualStatementData}
          bookkeepingComplete={bookkeepingComplete}
        />
      </>
    )
  }

  renderPnL() {
    const {
      report,
      params: { userId },
    } = this.props
    if (!userId) {
      return null
    }

    return (
      <ProfitAndLossTable
        startDate={report?.startDate}
        endDate={report?.endDate}
        userId={userId}
      />
    )
  }

  render() {
    const { showNewTransactionTable } = this.state
    const {
      user,
      report,
      status,
      params: { userId, reportId },
    } = this.props

    const membership = user?.memberships?.length
      ? user.memberships.filter((membership) => membership.isPrimary)[0]
      : null

    return (
      <Container id="adminPanel">
        <UserBreadcrumbs userId={userId} pageName="Monthly Books">
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>
            <Moment format={DATE_FORMATS.MONTH_YEAR}>{report?.date}</Moment>
          </Breadcrumb.Section>
        </UserBreadcrumbs>
        <Divider />
        <UserRecordHeader user={user} />
        <div className="flexbox">
          <div>
            <Header as="h3">
              <Link to={`/admin/finances/records/${userId}`}>
                <Button circular icon="arrow left" />
              </Link>
              <Moment format={DATE_FORMATS.MONTH_YEAR}>{report?.date}</Moment>{' '}
              Books: {user?.firstName} {user?.lastName}
              {membership?.status === 'unpaid' ? (
                <Label color="red">Unpaid</Label>
              ) : (
                <BookkeepingStatusLabel reportId={reportId} />
              )}
            </Header>
          </div>

          <div>{this.renderActionsBar()}</div>
        </div>

        <br />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          <Menu secondary size="mini">
            <Menu.Menu>
              <Menu.Item>
                <Button
                  size="mini"
                  className="headerButton"
                  secondary
                  onClick={() =>
                    this.setState({
                      showNewTransactionTable: !showNewTransactionTable,
                    })
                  }
                >
                  {showNewTransactionTable
                    ? 'Show Old Transaction Table'
                    : 'Show New Transaction Table'}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button
                  size="mini"
                  className="headerButton"
                  secondary
                  onClick={() =>
                    this.handleBooksUpdate(BookkeepingReportStatus.plaid_error)
                  }
                  disabled={status === BookkeepingReportStatus.plaid_error}
                >
                  Mark Plaid Error
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button
                  size="mini"
                  className="headerButton"
                  secondary
                  onClick={() =>
                    this.handleBooksUpdate(BookkeepingReportStatus.missing_info)
                  }
                  disabled={status === BookkeepingReportStatus.missing_info}
                >
                  Mark Missing Information
                </Button>
              </Menu.Item>
              <DownloadPayrollReportMenuItem
                reportId={reportId}
                userId={userId}
              />
            </Menu.Menu>
          </Menu>
        </div>
        <br />
        {membership?.status === 'unpaid' && (
          <Alert type="error">
            This user&apos;s account is defunct — do not work on their books.
          </Alert>
        )}
        <br />

        {report ? (
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={[
              { menuItem: 'Books', render: () => this.renderBooks() },
              { menuItem: 'Profit and Loss', render: () => this.renderPnL() },
              {
                menuItem: 'User Record Notes',
                render: () =>
                  userId && <UserRecordNotes userId={Number(userId)} />,
              },
              {
                menuItem: 'Onboarding',
                render: () => <OnboardingAnswers userId={Number(userId)} />,
              },
            ]}
          />
        ) : (
          <Dimmer active inverted>
            <Loader inverted>Loading report</Loader>
          </Dimmer>
        )}
      </Container>
    )
  }
}

const mapStateToProps = (state: ReduxState, props: ParentProps) => ({
  user: getUserById(state, props.params.userId),
  report: selectBookkeepingReportsById(state, props.params.reportId),
  accounts: selectSortedFinancialAccounts(state, props.params.userId),
  status: selectBookkeepingReportStatus(state, props.params.reportId),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTransactionCounts: wrapDispatch(dispatch, fetchTransactionCounts),
  clearTransactionFilters: wrapDispatch(dispatch, clearTransactionFilters),
  clearAndUpdateTransactionFilters: wrapDispatch(
    dispatch,
    clearAndUpdateTransactionFilters
  ),
  fetchAdminUsersBookkeepingReports: wrapDispatch(
    dispatch,
    fetchAdminUsersBookkeepingReports
  ),
  updateUsersBookkeepingReportStatus: wrapDispatch(
    dispatch,
    updateUsersBookkeepingReportStatus
  ),
  fetchAdminUsersDocuments: wrapDispatch(dispatch, fetchAdminUsersDocuments),
  adminFetchSingleUser: wrapDispatch(dispatch, adminFetchSingleUser),
  fetchUserFinancialAccounts: wrapDispatch(
    dispatch,
    fetchUserFinancialAccounts
  ),
  fetchTransactionCountsSinceLastReconciliation: wrapDispatch(
    dispatch,
    fetchTransactionCountsSinceLastReconciliation
  ),
  getVirtualStatement: wrapDispatch(dispatch, getVirtualStatement),
})

const ConnectedMonthlyBooksPanel = connect<
  StateProps,
  DispatchProps,
  ParentProps,
  ReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(MonthlyBooksPanel)

export default withRouter(ConnectedMonthlyBooksPanel)
