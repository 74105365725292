import { Grid } from 'semantic-ui-react'
import { Accordion, Link, Text } from '../../../../../components/BaseComponents'
import { formatCurrencyFromCents } from '../../../../../utils/currencyHelpers'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../../utils/dateHelpers'
import { TEST_IDS } from '../helpers'
import { DepreciableAssetTransaction } from '../../types'
import './styles.scss'

export type TransactionAccordionProps = {
  transactions: DepreciableAssetTransaction[] | null
}
const TransactionAccordion = ({ transactions }: TransactionAccordionProps) => {
  if (!transactions || transactions.length === 0) {
    return null
  }

  return (
    <Accordion
      className="eoy-admin__asset-depreciation-documents__transactions"
      title={`View ${transactions.length} transactions`}
      content={
        <Grid padded>
          {transactions.map((transaction) => (
            <Grid.Row
              key={transaction.id.toString()}
              className="eoy-admin__asset-depreciation-documents__transaction-row"
            >
              <Grid.Column width={3}>
                <Text as="bodySm" testId={TEST_IDS.transactionAccordionDate}>
                  {isoToUTCDateTime(transaction.date).toFormat(
                    DATE_FORMATS_LUXON.DISPLAY_SHORT
                  )}
                </Text>
              </Grid.Column>
              <Grid.Column width={6}>
                <Text
                  as="bodySm"
                  testId={TEST_IDS.transactionAccordionDescription}
                >
                  {transaction.description}
                </Text>

                {transaction?.receipts?.map(
                  ({ id, fileDescription, signedUrl }) => (
                    <Link
                      key={id}
                      href={signedUrl ?? ''}
                      className="eoy-admin__asset-depreciation-documents__file"
                    >
                      {fileDescription}
                    </Link>
                  )
                )}
              </Grid.Column>
              <Grid.Column width={4}>
                <Text
                  as="bodySm"
                  className="eoy-admin__asset-depreciation-documents__transaction-category"
                  testId={TEST_IDS.transactionAccordionCategory}
                >
                  {transaction?.transactionCategory?.name}
                </Text>
              </Grid.Column>
              <Grid.Column widescreen={3} textAlign="right">
                <Text as="bodySm" testId={TEST_IDS.transactionAccordionAmount}>
                  {formatCurrencyFromCents(transaction.amountInCents)}
                </Text>
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      }
      variant="text"
    />
  )
}

export default TransactionAccordion
