import { useCallback, useMemo } from 'react'
import { Grid, List } from 'semantic-ui-react'
import { useFormik, FormikProvider } from 'formik'
import { useSearchParams } from 'react-router-dom'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Form1040DetailsProps } from '.'
import {
  GridRowColumn,
  Text,
  makeReqStringSchema,
  FormikDropdown,
  Card,
  Accordion,
  Link,
  Icon,
  getFieldNames,
  DropdownValue,
  FormikScrollOnError,
} from '../../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  TaxListOptionId,
  TaxListQuestionId,
  useTaxListQuestionRes,
} from '../../service'
import { selectSpouseResponses } from '../../taxChecklist.selectors'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { deleteTaxQuestionnaireResponses } from '../../taxChecklistQuestion.actions'
import { getCurrentUser } from '../../../../../../selectors/user.selectors'
import { FILING_STATUSES, isFilingStatusType } from '../../../../taxConstants'
import { logSentryError } from '../../../../../../utils/sentryHelpers'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import {
  REVIEW_QUERY_PARAM,
  useSetScreen,
} from '../../../../../../components/FormFlow/formFlow'
import { select1040FormForYear } from '../../../annualTaxFilingForms.selector'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'
import SkipQuestion, {
  skipQuestionId,
  useSkipQuestion,
} from '../../Shared/SkipQuestion'
import { NeedHelpTip } from '../../NeedHelpTip'
import { TQYesNoResponse } from '../../Shared/FormHelpers'
import { tqBigSpace, tqSmallSpace } from '../../helpers'

export const filingStatusQuestionIds = [
  TaxListQuestionId.filing_status,
  TaxListQuestionId.filing_status_changed,
]

const getFilingStatusCopy = (
  filingStatus: FILING_STATUSES | null,
  taxYear: string
) => {
  switch (filingStatus) {
    case FILING_STATUSES['single']: {
      return {
        title: 'Single',
        text: (
          <GridRowColumn short>
            <Text as="bodyLg">
              You may use this filing status if you are unmarried or legally
              separated/divorced.
            </Text>
          </GridRowColumn>
        ),
      }
    }
    case FILING_STATUSES['married_filing_jointly']: {
      return {
        title: 'Married Filing Jointly',
        text: (
          <GridRowColumn short>
            <Text as="bodyLg">
              You are married and plan to file a single return for you and your
              spouse. This will reflect income and deductions for both parties.
              Filing jointly typically results in the greatest tax deductions
              unless one of you has a large student loan debt or tax liability.
            </Text>
          </GridRowColumn>
        ),
      }
    }
    case FILING_STATUSES['married_filing_separately']: {
      return {
        title: 'Married Filing Separately',
        text: (
          <GridRowColumn short>
            <Text as="bodyLg">
              You are married and plan to file an annual tax return separate
              from that of your spouse. This will keep income and deductions
              between spouses separate. Filing separately when one spouse
              carries a large debt or tax liability typically results in a lower
              tax liability for both of you.
            </Text>
          </GridRowColumn>
        ),
      }
    }
    case FILING_STATUSES['head_of_household']: {
      return {
        title: 'Head of Household',
        text: (
          <>
            <GridRowColumn style={{ padding: 0 }}>
              <Text as="bodyLg">This means you:</Text>
            </GridRowColumn>
            <List bulleted style={{ marginTop: 0 }}>
              <List.Item>
                <Text as="bodyLg">
                  Were unmarried for the {taxYear} tax year
                </Text>
              </List.Item>
              <List.Item>
                <Text as="bodyLg">
                  Have a qualifying child or dependent living in your home for
                  more than half the year
                </Text>
              </List.Item>
              <List.Item>
                <Text as="bodyLg">
                  Were responsible for paying more than half of all household
                  expenses
                </Text>
              </List.Item>
            </List>
          </>
        ),
      }
    }
    case FILING_STATUSES['qualifying_widow']: {
      return {
        title: 'Qualifying Widow(er)',
        text: (
          <GridRowColumn short>
            <Text as="bodyLg">
              You may file as a Qualifying Widow(er) if your spouse has passed
              away, allowing you to use the tax rates as if you were filing
              jointly. You may use this status for up to two years following
              your spouse&apos;s passing.
            </Text>
          </GridRowColumn>
        ),
      }
    }
    default:
      return null
  }
}

const FilingStatusCard = ({
  filingStatus,
}: {
  filingStatus: FILING_STATUSES | null
}) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const copy = getFilingStatusCopy(filingStatus, taxYear)
  if (!copy) return null
  return (
    <GridRowColumn {...tqSmallSpace}>
      <Card type="subsection">
        <Text as="h2">{copy.title}</Text>
        <br />
        <br />
        <Text> {copy.text}</Text>
      </Card>
    </GridRowColumn>
  )
}

const FilingStatsFaq = () => {
  const supportLink =
    'https://support.joinheard.com/hc/en-us/articles/16123320231959-Should-my-spouse-and-I-file-our-taxes-together-or-separately'
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  return (
    <Accordion
      style={{ padding: 0 }}
      backgroundColor="white"
      title="What is a filing status, and why are they important?"
      variant="text"
      content={
        <Grid>
          <GridRowColumn short>
            <Text as="bodyLg">Your filing status dictates the...</Text>
          </GridRowColumn>
          <List bulleted>
            <List.Item>
              <Text as="bodyLg">Calculation of income tax</Text>
            </List.Item>
            <List.Item>
              <Text as="bodyLg">Amount of standard deductions</Text>
            </List.Item>
            <List.Item>
              <Text as="bodyLg">
                Allowance or limitation of certain credits and deductions
              </Text>
            </List.Item>
          </List>
          <GridRowColumn>
            <Text as="bodyLg">
              For help,{' '}
              <Link newPage href={supportLink}>
                click here{' '}
                <Icon
                  style={{ marginLeft: 2 }}
                  size="1x"
                  icon={regular('arrow-up-right-from-square')}
                />
              </Link>{' '}
              to learn more about how to identify your filing status.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              *People can be eligible for more than one status. It&apos;s
              generally advised to use the status that results in the lowest tax
              payment.
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="h3">Filing Statuses</Text>
          </GridRowColumn>
          {Object.values(FILING_STATUSES).map((fs) => {
            const copy = getFilingStatusCopy(fs, taxYear)
            return (
              <GridRowColumn key={fs} style={{ padding: 0 }}>
                <Accordion
                  style={{
                    marginTop: 0,
                    padding: 0,
                    borderTop: '1px solid #E0E0E0',
                  }}
                  backgroundColor="white"
                  title={copy?.title}
                  content={copy?.text}
                />
              </GridRowColumn>
            )
          })}
        </Grid>
      }
    />
  )
}

const FilingStatusPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
  taxYear,
}: Form1040DetailsProps) => {
  const dispatch = useAppDispatch()
  const form1040 = useReselector(select1040FormForYear, taxYear)

  const financialProfile = useReselector(getCurrentUser)?.financialProfile
  const [searchParams] = useSearchParams()
  const { currentScreen, setScreen } = useSetScreen()
  const reviewing = searchParams.get(REVIEW_QUERY_PARAM) === 'true'

  const filingStatusQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.filing_status,
    formId: Number(form1040?.id),
  })
  const filingStatusChangedQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.filing_status_changed,
    formId: Number(form1040?.id),
  })
  const allSpouseResponses = useReselector(selectSpouseResponses)
  const skippedQuestions = useSkipQuestion(filingStatusQuestionIds)

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.filing_status]: filingStatusQR.initialResponse,
      [TaxListQuestionId.filing_status_changed]:
        filingStatusChangedQR.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      let copyFpFilingStatus: string | null = null
      if (
        financialProfile?.filingStatus &&
        values[TaxListQuestionId.filing_status_changed] !==
          filingStatusChangedQR.currentVal
      ) {
        responseData.push({
          id: filingStatusChangedQR.responseId,
          annualTaxFilingFormId: form1040?.id,
          questionId: TaxListQuestionId.filing_status_changed,
          value: values[TaxListQuestionId.filing_status_changed],
        })
        if (values[TaxListQuestionId.filing_status_changed] === false) {
          copyFpFilingStatus = financialProfile?.filingStatus?.toUpperCase()
        }
      }

      const needsAssistance =
        values[skipQuestionId].includes(TaxListQuestionId.filing_status) || null

      if (
        copyFpFilingStatus ||
        values[TaxListQuestionId.filing_status] !== filingStatusQR.currentVal ||
        needsAssistance !== filingStatusQR.skipped
      ) {
        responseData.push({
          id: filingStatusQR.responseId,
          annualTaxFilingFormId: form1040?.id,
          questionId: TaxListQuestionId.filing_status,
          value: copyFpFilingStatus ?? values[TaxListQuestionId.filing_status],
          needsAssistance,
        })
      }
      let success = true
      const incompleteSteps: SubStepIdentifiers[] = []
      const needsSpouseInfo =
        values[TaxListQuestionId.filing_status] ===
          TaxListOptionId.married_filing_jointly ||
        values[TaxListQuestionId.filing_status] ===
          TaxListOptionId.qualifying_widow
      if (!needsSpouseInfo && allSpouseResponses?.length > 0) {
        success = Boolean(
          await deleteTaxQuestionnaireResponses(
            allSpouseResponses.map((r) => r.id)
          )(dispatch)
        )
      }
      if (needsSpouseInfo && allSpouseResponses.length === 0) {
        incompleteSteps.push(
          SubStepIdentifiers.updateBusinessAndHealthcareInformation,
          SubStepIdentifiers.spouseAndDependentInformation
        )
      }
      if (success) {
        await goToNextStep(responseData, nextScreen ?? null, {
          completedSteps: [SubStepIdentifiers.filingStatus],
          incompleteSteps,
        })
      }
    },
  })

  const { values, isSubmitting, submitForm } = formik
  const fieldNames = getFieldNames(formik)

  const filingStatusChangedValue =
    values[TaxListQuestionId.filing_status_changed]
  const filingStatusValue = values[TaxListQuestionId.filing_status]

  const typeSafeFilingStatus = useMemo(() => {
    if (typeof filingStatusValue !== 'string') return null
    const lowerCaseFilingStatus = filingStatusValue?.toLowerCase()
    if (!isFilingStatusType(lowerCaseFilingStatus)) {
      logSentryError('Invalid filing status type')
      return null
    }

    return (
      (filingStatusChangedValue === false && financialProfile?.filingStatus
        ? financialProfile.filingStatus
        : lowerCaseFilingStatus) ?? financialProfile?.filingStatus
    )
  }, [
    filingStatusChangedValue,
    filingStatusValue,
    financialProfile?.filingStatus,
  ])

  /**
   * If coming from the review screen, puts the user back into the normal
   * form flow since there are conditional questions on other screens
   */
  const returnToRegularFlow = useCallback(
    (changedVal: DropdownValue | DropdownValue[] | undefined) => {
      if (reviewing && changedVal === TaxListOptionId.married_filing_jointly) {
        setScreen(currentScreen, true)
      }
    },
    [reviewing, currentScreen, setScreen]
  )

  return (
    <FormikProvider value={formik}>
      <FormikScrollOnError />
      <Grid>
        <GridRowColumn {...tqBigSpace}>
          <NeedHelpTip />
        </GridRowColumn>
        <Grid.Row />
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Filing Status
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg">
            Please update the following information for {taxYear}. These answers
            will appear on your tax return.
          </Text>
        </GridRowColumn>
        {financialProfile?.filingStatus ? (
          <TQYesNoResponse questionResponse={filingStatusChangedQR} />
        ) : (
          <GridRowColumn {...tqSmallSpace}>
            <Text>
              Select your filing status. For help picking your status, check out
              the FAQs below.
            </Text>
          </GridRowColumn>
        )}
        {(!financialProfile?.filingStatus ||
          values[TaxListQuestionId.filing_status_changed]) && (
          <>
            <GridRowColumn {...tqSmallSpace}>
              <FormikDropdown
                placeholder="Select filing status"
                name={fieldNames[TaxListQuestionId.filing_status]}
                label={filingStatusQR.label}
                options={filingStatusQR.options?.map((o) => ({
                  text: o.text,
                  value: o.id,
                  key: o.id,
                }))}
                schema={makeReqStringSchema()}
                fullWidth
                onChange={returnToRegularFlow}
              />
            </GridRowColumn>
            <GridRowColumn {...tqSmallSpace} short>
              <SkipQuestion questionId={TaxListQuestionId.filing_status} />
            </GridRowColumn>
          </>
        )}
        <FilingStatusCard filingStatus={typeSafeFilingStatus} />

        <GridRowColumn {...tqBigSpace}>
          <Text as="h2">Filing Status FAQs</Text>
          <br />
          <FilingStatsFaq />
        </GridRowColumn>

        <FormFlowFooter
          loading={isSubmitting}
          continueDisabled={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
        />
      </Grid>
    </FormikProvider>
  )
}

export default FilingStatusPanel
