import { useCallback, useEffect, useMemo, useState } from 'react'
import { Divider } from 'semantic-ui-react'
import { UploadDocumentType } from '../../../../constants/businessConstants'
import {
  Accordion,
  Card,
  Dropdown,
  Text,
} from '../../../../components/BaseComponents'
import { DocumentUploadCard } from '../../../../components/FileUpload/DocumentUploadCard'
import { StepHeaders } from '../../../../components/StepHeader'
import FileUploadModal, {
  UploadedFile,
} from '../../../../components/FileUpload/FileUploadModal'
import {
  PrefixedUserDocumentCategoryIdentifier,
  UserDocumentCategoryIdentifier,
} from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import {
  HEARD_PROVIDER_NAME,
  HeardPayrollDocumentStatus,
  IProviders,
} from '../step-map'
import { getInstructionsLink } from '../documents-map'
import { NotRelevantDocuments } from '../not-relevant-documents'
import { UserDocument } from '../../../UserDocuments/userDocuments.slice'
import { useDocuments } from '../hooks'
import { ContractorDocumentsStepContext } from '../types'
import './styles.scss'

const dropdownOptions = [
  {
    id: 1,
    text: 'Gusto',
    value: 'Gusto' as const,
  },
  {
    id: 2,
    text: 'ADP',
    value: 'ADP' as const,
  },
  {
    id: 3,
    text: 'Quickbooks',
    value: 'Quickbooks' as const,
  },
  {
    id: 4,
    text: 'Other',
    value: 'Other' as const,
  },
]

const form1099nec = UserDocumentCategoryIdentifier.form1099nec
const { BOOKKEEPING, OTHER } = UploadDocumentType

export interface IContractorDocumentsProps {
  documents: UserDocument[]
  providers: IProviders
  taxYear: string
  hasHeardPayroll: boolean
  stepContext: ContractorDocumentsStepContext
  setStepContext: (context: ContractorDocumentsStepContext) => void
}

export const ContractorDocuments = ({
  documents,
  providers,
  taxYear,
  hasHeardPayroll = false,
  stepContext,
  setStepContext,
}: IContractorDocumentsProps) => {
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false)
  const [heardContractorPaymentsStatus, setHeardContractorPaymentsStatus] =
    useState<HeardPayrollDocumentStatus | null>(null)
  const [fileUpload, setFileUpload] = useState<{
    type: UploadDocumentType
    provider: number | null
    userDocumentInternalName?: string
  }>({ type: OTHER, provider: null })

  const contractorPaymentsReport = useMemo(
    () =>
      PrefixedUserDocumentCategoryIdentifier.contractorPaymentsReport(taxYear),
    [taxYear]
  )

  const onPayrollProviderChange = useCallback(
    (val: string[]) => {
      const filteredDocuments = stepContext?.documents?.filter(
        (doc) => doc.notRelevant && !val.includes(doc.provider ?? '')
      )
      setStepContext({
        ...stepContext,
        documents: filteredDocuments,
        payrollProviders: val,
      })
    },
    [stepContext, setStepContext]
  )

  const handleFileUpload = (
    type: UploadDocumentType = OTHER,
    userDocumentInternalName?: string,
    provider?: string
  ) => {
    setIsFileUploadModalOpen(true)

    if (!userDocumentInternalName || !provider) {
      return
    }

    const providerId = Object.values(providers).find(
      (p) => p.name === provider
    )?.id

    setFileUpload({
      provider: providerId || null,
      type,
      userDocumentInternalName,
    })
  }

  useEffect(() => {
    if (hasHeardPayroll) {
      const heardProviderId = Object.values(providers).find(
        (p) => p.name === HEARD_PROVIDER_NAME
      )?.id

      if (heardProviderId) {
        const paymentsDoc = stepContext.documents?.find(
          (doc) =>
            [contractorPaymentsReport, form1099nec].includes(
              doc.categoryCode
            ) && doc.provider === HEARD_PROVIDER_NAME
        )
        if (paymentsDoc && !paymentsDoc.integrationError) {
          setHeardContractorPaymentsStatus(HeardPayrollDocumentStatus.uploaded)
        } else if (paymentsDoc?.integrationError) {
          setHeardContractorPaymentsStatus(HeardPayrollDocumentStatus.error)
        } else {
          setHeardContractorPaymentsStatus(HeardPayrollDocumentStatus.pending)
        }
      }
    }
  }, [providers, hasHeardPayroll, stepContext, contractorPaymentsReport])

  const onFileUploaded = useCallback(
    (uploadedFile: UploadedFile) => {
      if (fileUpload.type === OTHER) {
        const otherDocumentIds = [
          ...(stepContext.otherDocumentIds ?? []),
          uploadedFile.id,
        ]
        setStepContext({ ...stepContext, otherDocumentIds })
      } else {
        const documentId = uploadedFile.id
        const categoryCode = fileUpload.userDocumentInternalName ?? ''
        const provider =
          Object.values(providers).find((p) => p.id === fileUpload.provider)
            ?.name ?? 'Other'

        // Replace or add the document to the list
        const documents = [
          ...(stepContext.documents?.filter(
            (doc) =>
              !(doc.categoryCode === categoryCode && doc.provider === provider)
          ) ?? []),
          {
            documentId,
            categoryCode,
            provider,
          },
        ]

        setStepContext({ ...stepContext, documents })
      }
    },
    [fileUpload, providers, stepContext, setStepContext]
  )

  const { filterDocuments, getStatus } = useDocuments({
    stepContextDocuments: stepContext?.documents,
    userDocuments: documents,
  })

  return (
    <div className="eoybk__contractor__container">
      <FileUploadModal
        close={() => setIsFileUploadModalOpen(false)}
        documentType={UploadDocumentType.BOOKKEEPING}
        open={isFileUploadModalOpen}
        extraPayload={{
          providerId: fileUpload.provider,
        }}
        categoryInternalName={fileUpload.userDocumentInternalName}
        userFacing
        setUploadedFile={onFileUploaded}
        isUploadOnly
      />

      <StepHeaders
        customColor="yellow"
        description="If you pay contractors through a payroll provider, you need to upload a Contractor Payment Report for each provider."
        imageSrc="https://heard-images.s3.amazonaws.com/assets/writing_check.svg"
        imageAlt="stacked checkbooks"
        imageWidth={180}
        imageHeight={180}
        kickerText="YEAR-END DOCUMENTS"
        title="Contractor payments"
      />

      <div>
        <Text>
          Which payroll provider(s) did you use to pay contractors in 2024?
        </Text>

        <Text as="bodyXs" className="eoybk__contractor__multi-provider">
          If you used multiple, select all providers you used.
        </Text>

        <Dropdown
          placeholder="Select payroll provider(s)"
          options={dropdownOptions}
          multiple
          clearable
          fullWidth
          value={stepContext?.payrollProviders ?? []}
          onChange={onPayrollProviderChange}
        />

        {stepContext?.payrollProviders &&
          stepContext.payrollProviders.length > 0 && (
            <Card backgroundColor="stone40">
              <Text as="eyebrow" color="darkGray">
                DOCUMENTS YOU NEED
              </Text>

              <Text>
                Based on your answer, you’ll need to upload a contractor payment
                report from:
              </Text>

              <ul>
                {stepContext.payrollProviders.map((provider) => (
                  <li
                    key={provider}
                    className="eoybk__contractor__provider-uploads"
                  >
                    <strong>{provider}</strong>
                  </li>
                ))}
              </ul>

              <Text>
                Please contact your provider’s support if you need assistance
                locating your documents.
              </Text>
            </Card>
          )}
      </div>

      <Accordion
        className="eoybk__contractor__accordion-text"
        title="What if my payroll provider isn’t listed?"
        content={
          <Text>
            We need Contractor Payment Reports for all payroll providers you
            used in 2024. Please download it from your payroll provider’s
            website and upload it under “Other documents” below.
          </Text>
        }
        variant="text"
      />

      <Divider className="eoybk__contractor__divider" />

      <Text as="h2">Upload Contractor Payment Reports</Text>

      <Text>
        If you don’t have a document on hand, you may skip for now. You’ll be
        able to view instructions and upload everything at the end.
      </Text>

      <div className="eoybk__contractor__document-cards">
        {hasHeardPayroll &&
          (heardContractorPaymentsStatus === 'uploaded' ? (
            <DocumentUploadCard
              name="Heard Payroll - Contractor Payment Report or 1099-NEC"
              description="Since you filed your 1099-NEC with us, we'll automatically pull this document for you. You don't need to do anything else!"
              status="success"
              readOnly
              hideNoUploadIndicator
            />
          ) : heardContractorPaymentsStatus === 'pending' ? (
            <DocumentUploadCard
              name="Heard Payroll - Contractor Payment Report or 1099-NEC"
              description="Since you filed your 1099-NEC with us, we'll automatically pull this document for you. You don't need to do anything else!"
              explanation={
                <>
                  <strong>Note: </strong>Payroll providers typically release
                  your Contractor Payment Report in late January. We&apos;ll
                  start your bookkeeping once we have it in hand.
                </>
              }
              status="success"
              readOnly
              hideNoUploadIndicator
            />
          ) : heardContractorPaymentsStatus === 'error' ? (
            <DocumentUploadCard
              name="Heard Payroll - Contractor Payment Report or 1099-NEC"
              description="This totals all payments made to 1099 independent contractors."
              explanation="Due to an error, we weren't able to pull this document. Please upload it manually."
              status={getStatus(contractorPaymentsReport, HEARD_PROVIDER_NAME)}
              documents={filterDocuments(
                contractorPaymentsReport,
                HEARD_PROVIDER_NAME
              )}
              handleUploadBtn={() =>
                handleFileUpload(
                  BOOKKEEPING,
                  contractorPaymentsReport,
                  HEARD_PROVIDER_NAME
                )
              }
            />
          ) : null)}

        <NotRelevantDocuments
          stepContext={stepContext}
          setStepContext={setStepContext}
          renderDocumentUploadCards={(isMarkedNotRelevant, handleNotRelevant) =>
            stepContext?.payrollProviders?.map((provider) => (
              <DocumentUploadCard
                key={`${provider}_documents`}
                name={`${provider} - Contractor Payment Report`}
                description="This summarizes your business’ total salary payments and withholding amounts."
                href={getInstructionsLink(
                  contractorPaymentsReport,
                  provider,
                  taxYear
                )}
                notRelevant={isMarkedNotRelevant?.(
                  contractorPaymentsReport,
                  provider
                )}
                setChecked={() =>
                  handleNotRelevant?.(contractorPaymentsReport, provider)
                }
                status={getStatus(
                  contractorPaymentsReport,
                  provider,
                  isMarkedNotRelevant
                )}
                documents={filterDocuments(contractorPaymentsReport, provider)}
                handleUploadBtn={() =>
                  handleFileUpload(
                    BOOKKEEPING,
                    contractorPaymentsReport,
                    provider
                  )
                }
              />
            ))
          }
        />

        <DocumentUploadCard
          name="Other documents"
          description="Upload any other documents you’d like to share with the bookkeeping team here."
          status="disabled"
          documents={documents.filter((doc) =>
            (stepContext?.otherDocumentIds ?? []).includes(doc.id)
          )}
          handleUploadBtn={() => handleFileUpload()}
        />
      </div>
    </div>
  )
}
