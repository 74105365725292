export enum AnnualTaxFilingStepStatus {
  upcoming = 'upcoming',
  comingSoon = 'coming-soon',
  notStarted = 'not-started',
  inProgress = 'in-progress',
  readyForReview = 'ready-for-review',
  readyToSign = 'ready-to-sign',
  completeLocked = 'complete-locked',
  completeUnlocked = 'complete-unlocked',
}

export enum TaxChecklistPaths {
  checkYourDetails = 'check-your-details',
  checkYourDetailsReadOnly = `${checkYourDetails}?screen=review`,
  checkYourDetailsPersonal = 'check-your-details/personal',
  checkYourDetailsPersonalReadOnly = `${checkYourDetailsPersonal}?screen=review`,
  documents = 'documents',
  documentsReadOnly = `${documents}?screen=review`,
  addMissingQtePayments = 'add-missing-qte-payments',
  addMissingQtePaymentsReadOnly = `${addMissingQtePayments}?screen=review`,
  lifeChanges = 'life-changes',
  lifeChangesReadOnly = `${lifeChanges}?screen=review`,
  deductionsAndCredits = 'deductions-and-credits',
  deductionsAndCreditsReadOnly = `${deductionsAndCredits}?screen=review`,
  incomeAndLiabilities = 'income-and-liabilities',
  incomeAndLiabilitiesReadOnly = `${incomeAndLiabilities}?screen=review`,
  payments = 'payments',
}

export const ADDITIONAL_1040_COST = 450
