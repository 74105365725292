import { useEffect, useMemo, useState } from 'react'
import { useFetchResponse } from '../../../utils/sharedHooks'
import { userGetBookkeepingStep } from '../end-of-year-review/actions'
import {
  EndOfYearAdminReviewStep,
  FinalReviewStepContext,
} from '../end-of-year-review/types'
import { useParams } from 'react-router'
import { DateTime } from 'luxon'

export const useFinalReviewStepContext = () => {
  const [finalReviewContext, setFinalReviewContext] = useState<
    FinalReviewStepContext | undefined
  >()
  const finalReviewStep = useFetchResponse(
    userGetBookkeepingStep,
    EndOfYearAdminReviewStep.finalReview
  )

  useEffect(() => {
    if (finalReviewStep) {
      setFinalReviewContext(finalReviewStep.context)
    }
  }, [finalReviewStep])

  return {
    finalReviewContext,
    setFinalReviewContext,
  }
}

export const useYearFromParams = () => {
  const { year } = useParams()

  return useMemo(() => {
    const now = DateTime.utc()
    const taxYearDefault =
      now.month < 10 ? now.minus({ year: 1 }).year : now.year
    return year ?? taxYearDefault.toString()
  }, [year])
}
