import { useEffect } from 'react'
import { AnySchema } from 'yup'
import { useField } from 'formik'
import { uniq } from 'lodash'

import {
  Checkbox,
  FormikCheckboxMulti,
  Popup,
  Text,
} from '../../../../../components/BaseComponents'
import { TaxListQuestionId } from '../service'
import { selectTaxListQuestionsByIds } from '../taxChecklist.selectors'
import { useReselector } from '../../../../../utils/sharedHooks'
import { filterNulls } from '../../../../../utils/typeHelpers'
import { TaxChecklistResponseValue } from '../taxChecklistQuestion.slice'
import { Colors } from '../../../../../styles/theme'

export const skipQuestionId = 'TQ_SKIP_QUESTION'

export const skipQuestionWrapperStyle = (needsHelp: boolean) => ({
  width: '100%',
  backgroundColor: needsHelp ? Colors.natural : Colors.stone40,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 10,
})

export const useSkipQuestion = (questionIds: TaxListQuestionId[]) => {
  const questions = useReselector(selectTaxListQuestionsByIds, questionIds)

  return {
    initialValue: filterNulls(
      questions
        .filter((q) => q.responses?.[0]?.needsAssistance)
        .map((q) => q.question?.id)
    ),
  }
}

export const createSkipSchema = <T extends AnySchema>(
  values: {
    [skipQuestionId]: TaxListQuestionId[]
  } & Record<string, TaxChecklistResponseValue | undefined>,
  questionId: TaxListQuestionId,
  schema: T
) => (!values[skipQuestionId].includes(questionId) ? schema : undefined)

export const SkipPopup = () => (
  <Popup
    content={
      <>
        <Text as="h3">Tax preparer follow-up</Text>
        <br />
        <Text>
          If you&apos;re unsure, checking the “Unsure, request tax preparer
          follow-up” box will flag this question for your tax preparer.
          <br />
          <br />
          After you&apos;ve completed the tax questionnaire, your tax preparer
          will receive your completed questionnaire and documents, including the
          questions you’ve flagged for follow-up. You&apos;ll be able to connect
          with your tax preparer through a chat portal where you can discuss
          your concerns.
        </Text>
      </>
    }
  />
)

export const getCopy = (needsHelp: boolean) => {
  if (needsHelp) {
    return 'Tax preparer will follow-up'
  } else {
    return 'Request tax preparer follow-up'
  }
}

const SkipQuestion = ({
  questionId,
  overrideCopy,
  requiresFollowup,
}: {
  questionId: TaxListQuestionId
  overrideCopy?: string
  requiresFollowup?: boolean
}) => {
  const [skipField, skipMeta, skipHelpers] = useField({ name: skipQuestionId })

  const needsHelp =
    Array.isArray(skipMeta.value) && skipMeta.value.includes(questionId)

  const [questionField] = useField({ name: questionId })

  useEffect(() => {
    if (
      requiresFollowup &&
      questionField.value &&
      Array.isArray(skipField.value) &&
      !skipField.value.includes(questionId)
    ) {
      skipHelpers.setValue(uniq([...skipField.value, questionId]))
    }
  }, [
    questionField.value,
    questionId,
    requiresFollowup,
    skipField.value,
    skipHelpers,
  ])

  const showCheckbox = !requiresFollowup || !questionField.value

  return (
    <div style={skipQuestionWrapperStyle(needsHelp)}>
      {showCheckbox ? (
        <FormikCheckboxMulti
          name={skipQuestionId}
          label={overrideCopy || getCopy(needsHelp)}
          value={questionId}
          variant="default"
        />
      ) : (
        <Text as="bodyLg">
          Your tax preparer will follow-up for more details
        </Text>
      )}

      {needsHelp && <SkipPopup />}
    </div>
  )
}

export const SkipQuestionCheckbox = ({
  needsHelp,
  setChecked,
}: {
  needsHelp: boolean
  setChecked: (checked: boolean) => void
}) => {
  return (
    <div style={skipQuestionWrapperStyle(needsHelp)}>
      <Checkbox
        label={getCopy(needsHelp)}
        variant="default"
        checked={needsHelp}
        onChange={setChecked}
      />
      {needsHelp && <SkipPopup />}
    </div>
  )
}

export default SkipQuestion
