import { useCallback, useContext, useMemo } from 'react'
import { findIndex } from 'lodash'
import { UserDocument } from '../../../../UserDocuments/userDocuments.slice'
import {
  EndOfYearReviewStepStatus,
  AssetDepreciationDocumentsStep,
  Receipt,
  DepreciableAssetTransaction,
  EndOfYearAdminReviewStep,
  IFixedAssetDepreciationStepContext,
} from '../../types'
import { AdminYearEndReviewContext } from '../context'
import { useAdminDocuments } from '../hooks'
import BookkeepingReviewSection from '../bookkeeping-review-section'
import { DocumentRows, DocumentRow } from '../document-row'
import OtherDocuments from '../other-documents'
import TransactionAccordion from '../transaction-accordion'
import StepNotRelevant from '../step-not-relevant'
import { migrateAssetStepContext } from '../helpers'

const { pending, userActionRequired, complete } = EndOfYearReviewStepStatus
const STEP_TYPE = EndOfYearAdminReviewStep.docsAssetsOver2500

export type AssetDepreciationSectionProps = {
  step: AssetDepreciationDocumentsStep | null
  userDocuments: UserDocument[]
  depreciableAssets: DepreciableAssetTransaction[] | null
}
const AssetDepreciationDocumentsSection = ({
  step,
  userDocuments,
  depreciableAssets,
}: AssetDepreciationSectionProps) => {
  const { updateStep } = useContext(AdminYearEndReviewContext)

  const receipts = useMemo(() => {
    const migratedContext = migrateAssetStepContext(step?.context)
    return migratedContext.receipts ?? []
  }, [step?.context])

  const { getFileIfExists } = useAdminDocuments<Receipt>({
    step: STEP_TYPE,
    stepContextDocuments: receipts,
    userDocuments,
  })

  // Remove receipts that cannot be found in user documents
  const filteredReceiptsWithFile = useMemo(
    () =>
      receipts
        .map((r) => {
          const file = getFileIfExists(r)
          return { ...r, file }
        })
        .filter((receiptWithFile) => receiptWithFile.file),
    [receipts, getFileIfExists]
  )

  const onConfirmClick = useCallback(() => {
    const status = step?.status === complete ? pending : complete
    const receiptDocs =
      status === complete
        ? receipts?.map((receipt) => ({
            ...receipt,
            requestReUpload: false,
            adminNote: '',
          }))
        : undefined
    updateStep({
      status,
      step: STEP_TYPE,
      context: receiptDocs
        ? {
            ...step?.context,
            receipts: receiptDocs,
            receiptIds: undefined, // Transitioning to receipts property
          }
        : undefined,
    })
  }, [step, updateStep, receipts])

  const replaceReceipt = (
    receipt: Receipt,
    receipts?: Receipt[]
  ): Receipt[] => {
    const copy = [...(receipts ?? [])]
    const index = findIndex(
      copy,
      (doc) => doc.documentId === receipt.documentId
    )

    if (index === -1) {
      return [...copy, receipt]
    }

    copy.splice(index, 1, receipt)
    return copy
  }

  const updateStepUsing = useCallback(
    (context: IFixedAssetDepreciationStepContext) => {
      const stepStatus = context.receipts?.some((doc) => doc.requestReUpload)
        ? userActionRequired
        : pending
      updateStep({
        step: STEP_TYPE,
        status: stepStatus,
        context: { ...context },
      })
    },
    [updateStep]
  )

  const stepHasDocuments = useMemo(
    () =>
      filteredReceiptsWithFile.length ||
      step?.context?.otherDocumentIds?.length,
    [step?.context, filteredReceiptsWithFile]
  )

  const updatedByUser = useMemo(
    () => filteredReceiptsWithFile.some((r) => r.updatedByUser),
    [filteredReceiptsWithFile]
  )

  return (
    <BookkeepingReviewSection
      sectionTitle="Assets over $2,500"
      className="eoy-admin__asset-depreciation-documents"
      status={step?.status ?? EndOfYearReviewStepStatus.pending}
      updatedByUser={updatedByUser}
      confirmationTitle="Adjustments completed"
      confirmationDescription=""
      minHeight={70}
      testId="asset-documents"
      onConfirmClick={step ? onConfirmClick : undefined}
    >
      {step ? (
        <>
          <DocumentRows>
            {filteredReceiptsWithFile.map((receipt) => (
              <DocumentRow
                key={`asset_doc_${receipt.documentId}`}
                description="Receipt"
                document={{ ...receipt, file: undefined }}
                file={receipt.file}
                testId={`receipt_${receipt.documentId}`}
                onRequestReUpload={(updatedDoc) => {
                  updateStepUsing({
                    ...step.context,
                    receipts: replaceReceipt(updatedDoc, receipts),
                    receiptIds: undefined,
                  })
                }}
              />
            ))}
          </DocumentRows>

          <OtherDocuments
            userDocuments={userDocuments.filter((ud) =>
              step.context?.otherDocumentIds?.some((id) => id === ud.id)
            )}
            showHeader
          />

          {!stepHasDocuments && (
            <StepNotRelevant text="User did not upload any receipts." />
          )}

          <TransactionAccordion transactions={depreciableAssets} />
        </>
      ) : (
        <StepNotRelevant />
      )}
    </BookkeepingReviewSection>
  )
}

export default AssetDepreciationDocumentsSection
