import { UserDocument } from '../../../../UserDocuments/userDocuments.slice'
import { EndOfYearReviewStepStatus, OtherDocumentsStep } from '../../types'
import BookkeepingReviewSection from '../bookkeeping-review-section'
import OtherDocuments from '../other-documents'
import StepNotRelevant from '../step-not-relevant'

export type OtherDocumentsSectionProps = {
  step: OtherDocumentsStep | null
  userDocuments: UserDocument[]
}
const OtherDocumentsSection = ({
  step,
  userDocuments,
}: OtherDocumentsSectionProps) => {
  return (
    <BookkeepingReviewSection
      sectionTitle="Other documents"
      className="eoy-admin__other-documents"
      status={step?.status ?? EndOfYearReviewStepStatus.pending}
      minHeight={70}
      testId="other-documents"
    >
      {step?.context?.documentIds?.length ? (
        <OtherDocuments
          userDocuments={userDocuments.filter((ud) =>
            step.context.documentIds?.some((id) => id === ud.id)
          )}
          showHeader={false}
        />
      ) : (
        <StepNotRelevant />
      )}
    </BookkeepingReviewSection>
  )
}

export default OtherDocumentsSection
