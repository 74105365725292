import { Grid, GridColumn } from 'semantic-ui-react'
import { Fragment, useCallback, useContext, useMemo } from 'react'
import {
  Checkbox,
  Link,
  Pill,
  Text,
} from '../../../../../components/BaseComponents'
import { AdminYearEndReviewContext } from '../context'
import { Document, DocumentFile, Receipt } from '../../types'
import { NOT_RELEVANT_REASONS, TEST_IDS } from '../helpers'
import './styles.scss'

export type DocumentRowProps<T extends Document | Receipt> = {
  description: string
  document: T
  file?: DocumentFile
  onRequestReUpload?: (document: T) => void
  testId?: string
  children?: React.ReactNode
}

export const DocumentRow = <T extends Document | Receipt>({
  description,
  file,
  document,
  onRequestReUpload,
  testId,
  children,
}: DocumentRowProps<T>) => {
  const { openDocumentReUploadModal } = useContext(AdminYearEndReviewContext)

  const onReuploadDocumentCallback = useCallback(
    (adminNote: string) => {
      const updatedDocument = {
        ...document,
        requestReUpload: true,
        adminNote,
      } as T
      onRequestReUpload?.(updatedDocument)
      return Promise.resolve()
    },
    [document, onRequestReUpload]
  )

  const onRequestReuploadClick = useCallback(() => {
    if (onRequestReUpload) {
      if (document.requestReUpload) {
        onRequestReUpload({
          ...document,
          requestReUpload: false,
          adminNote: '',
        })
      } else {
        openDocumentReUploadModal(
          document.adminNote ?? null,
          onReuploadDocumentCallback
        )
      }
    }
  }, [
    document,
    openDocumentReUploadModal,
    onRequestReUpload,
    onReuploadDocumentCallback,
  ])

  const notRelevant = useMemo(() => {
    if ('notRelevant' in document) {
      return document.notRelevant
    }
    return undefined
  }, [document])

  const retainLineBreaks = (value?: string): React.ReactNode =>
    value?.split(/\n/).map((token, idx, tokens) => (
      <Fragment key={idx /* skipcq: JS-0437 */}>
        {token}
        {idx + 1 < tokens.length && <br />}
      </Fragment>
    ))

  return (
    <Grid.Row
      className={`eoy-admin__document-row${document.updatedByUser ? ' updated' : ''}`}
    >
      {document.updatedByUser && (
        <Grid.Column
          width={16}
          className="eoy-admin__document-row__updated-pill-row"
        >
          <Pill color="purple">Reuploaded by user</Pill>
        </Grid.Column>
      )}
      <Grid.Column width={4} className="eoy-admin__document-row__left">
        <Text
          as="bodySm"
          className="eoy-admin__document-row__title"
          testId={TEST_IDS.documentRowTitle(testId)}
        >
          {description}
        </Text>
      </Grid.Column>
      <Grid.Column
        width={onRequestReUpload ? 8 : 10}
        className="eoy-admin__document-row__center"
      >
        {notRelevant ? (
          <>
            <Text
              as="bodySm"
              className="eoy-admin__document-row__not-relevant-reason"
              testId={TEST_IDS.documentRowNotRelevantReason(testId)}
            >
              Not relevant -{' '}
              {
                NOT_RELEVANT_REASONS.find(
                  (nr) => nr.value === notRelevant.reason
                )?.text
              }
            </Text>
            <Text
              as="bodyXs"
              className="eoy-admin__document-row__not-relevant-context"
              testId={TEST_IDS.documentRowNotRelevantContext(testId)}
            >
              Context: {notRelevant.context}
            </Text>
          </>
        ) : file ? (
          <Link href={file.href}>{file.name}</Link>
        ) : (
          children
        )}
      </Grid.Column>
      {onRequestReUpload && (
        <GridColumn
          width={4}
          className="eoy-admin__document-row__right"
          textAlign="right"
        >
          <Checkbox
            label="Request reupload"
            checked={document.requestReUpload}
            onClick={onRequestReuploadClick}
          />
        </GridColumn>
      )}
      {document.adminNote && (
        <Grid.Column width={16}>
          <Text
            as="bodySm"
            className="eoy-admin__document-row__notes"
            testId={TEST_IDS.documentRowNote(testId)}
          >
            <span>Note for user: </span>&quot;
            {retainLineBreaks(document.adminNote)}&quot;
          </Text>
        </Grid.Column>
      )}
    </Grid.Row>
  )
}

export const DocumentRows = ({ children }: { children: React.ReactNode }) => (
  <Grid className="eoy-admin__document-rows">{children}</Grid>
)
