import { TextArea } from '../../../../../components/BaseComponents'
import {
  GridRowColumn,
  makeGridConfig,
} from '../../../../../components/BaseComponents/Grid'
import { TextAreaProps } from '../../../../../components/BaseComponents/TextArea'

const CommentArea = (props: TextAreaProps) => (
  <GridRowColumn {...makeGridConfig([8, 14], true)}>
    <TextArea {...props} />
  </GridRowColumn>
)

export default CommentArea
