import { ReactNode, useEffect, useState } from 'react'
import { Loader } from 'semantic-ui-react'

import { useAppDispatch } from '../../../utils/typeHelpers'
import LearnWithHeard from './LearnWithHeard'
import styled from 'styled-components'
import {
  useIsDeviceWidth,
  DeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { Colors } from '../../../styles/theme'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../OpenFeature'
import { fetchUserCatchUpBookkeepingStatus } from '../../CatchUpBookkeepingStatus/catchUpBookkeepingStatus.slice'
import { useReselector } from '../../../utils/sharedHooks'
import { selectUserShouldSeeOnboardingDashboard } from '../../Onboarding/UserOnboardingSteps/onboarding.selectors'
import { isFreeTrialPromoCode } from '../../../selectors/user.selectors'
import { useNavigate } from 'react-router-dom'
import FreeTrialGettingStarted from './FreeTrialOnboardingDashboard/FreeTrialGettingStarted'
import RecommendedForYou from './FreeTrialOnboardingDashboard/RecommendedForYou'
import SupportForYou from './FreeTrialOnboardingDashboard/SupportForYou'
import GettingStarted from './GettingStarted'

const OnboardingDashboardWrapper = styled(
  ({ children, className }: { children: ReactNode; className?: string }) => {
    const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
    const isTablet = useIsDeviceWidth(DeviceWidth.tablet)
    const mobileOrTabletClass = `${isMobile && 'mobile'} ${isTablet && 'tablet'}`
    const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)
    return (
      <div className={`${className} ${mobileOrTabletClass}`}>
        <div className={`getting-started ${mobileOrTabletClass}`}>
          {hasFreeTrialPromoCode ? (
            <FreeTrialGettingStarted />
          ) : (
            <GettingStarted />
          )}
        </div>
        <div className={`additional-onboarding-content ${mobileOrTabletClass}`}>
          {children}
        </div>
      </div>
    )
  }
)({
  '&&&': {
    '.getting-started': {
      backgroundColor: Colors.moss,
      padding: '64px 40px 64px 40px',
      '&.tablet': {
        marginTop: '16px',
        padding: '64px 24px 64px 24px',
      },
      '&.mobile': {
        marginTop: '16px',
        padding: '32px 16px 32px 16px',
      },
    },
    '.additional-onboarding-content': {
      padding: '56px 40px',
      '&.tablet': {
        padding: '40px 24px',
      },
      '&.mobile': {
        padding: '40px 16px 24px 16px',
      },
    },
  },
})

const OnboardingDashboard = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const shouldShowAhaMomentsMVP = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.ahaMomentsMVP,
    false
  )
  const userShouldSeeOnboardingDashboard = useReselector(
    selectUserShouldSeeOnboardingDashboard
  )
  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)

  useEffect(() => {
    async function batchFetch() {
      await Promise.all([fetchUserCatchUpBookkeepingStatus()(dispatch)])
      setLoading(false)
    }
    batchFetch()
  }, [dispatch])

  useEffect(() => {
    if (!userShouldSeeOnboardingDashboard) {
      navigate('/dashboard')
    }
  }, [navigate, userShouldSeeOnboardingDashboard])

  if (loading) {
    return <Loader active />
  }

  if (hasFreeTrialPromoCode) {
    return (
      <OnboardingDashboardWrapper>
        <RecommendedForYou />
        <br />
        <br />
        <br />
        <SupportForYou />
      </OnboardingDashboardWrapper>
    )
  } else {
    return (
      <OnboardingDashboardWrapper>
        {shouldShowAhaMomentsMVP && <LearnWithHeard />}
      </OnboardingDashboardWrapper>
    )
  }
}

export default OnboardingDashboard
