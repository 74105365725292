import { useCallback, useState } from 'react'
import { Grid } from 'semantic-ui-react'

import {
  Button,
  Card,
  Checkbox,
  GridRowColumn,
  Link,
  Modal,
  Text,
} from '../../../../components/BaseComponents'
import { getAnnualTaxFilingForYearSelector } from '../annualTaxFilings.selector'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  needContractorFilingOption,
  updateAnnualTaxFilings,
} from '../annualTaxFilings.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { selectCurrentAnnualTaxDetails } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../../utils/dateHelpers'

const Ten99OptOutModal = ({
  year,
  open,
  onClose,
  optIn,
}: {
  year: string
  open: boolean
  onClose: () => void
  optIn: boolean
}) => {
  const dispatch = useAppDispatch()
  const [confirmationChecked, setConfirmationChecked] = useState(false)
  const filing = useReselector(getAnnualTaxFilingForYearSelector, year)
  const annualTaxDetails = useReselector(selectCurrentAnnualTaxDetails)
  const taxDueDate = () => {
    return `Note that 1099-NEC forms are due to the IRS on Jan 31, 2025. If you would like Heard to file your 1099-NECs for you, you will need to submit your information by ${annualTaxDetails?.ten99NECFormsDueAt ? isoToUTCDateTime(annualTaxDetails?.ten99NECFormsDueAt).toFormat(DATE_FORMATS_LUXON.DISPLAY_SHORT) : 'ASAP'}.`
  }
  const onConfirm = useCallback(async () => {
    if (!filing?.id) {
      return
    }
    const filingValue = optIn
      ? needContractorFilingOption.yes
      : needContractorFilingOption.no
    const res = await updateAnnualTaxFilings(filing?.id, {
      needContractorFiling: filingValue,
    })(dispatch)
    if (res) {
      onClose()
      location.reload()
    }
  }, [dispatch, filing?.id, optIn, onClose])
  if (!filing) {
    return null
  }

  const headerText = () => {
    if (optIn) {
      return 'Opt into 1099-NEC Filing'
    } else {
      return 'Opt out of 1099-NEC Filing'
    }
  }
  const subText = () => {
    if (optIn) {
      return `In order for Heard to file your ${year} Form 1099-NEC Filings, please check the box below.`
    } else {
      return `To opt out of Heard preparing the ${year} Form 1099-NEC Filing for you, please check the box below.`
    }
  }
  const checkboxText = () => {
    if (optIn) {
      return `I understand that by opting in, Heard will help me file Form 1099-NECs for ${year}.`
    } else {
      return `I understand that by opting out, Heard will not file a Form 1099-NECs form on my behalf for ${year}.`
    }
  }
  return (
    <Modal open={open} onClose={onClose} closeIcon size="small">
      <Modal.Header>{headerText()}</Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Text as="bodyMd">{subText()}</Text>
            <br />
            <Text as="bodyMd">{taxDueDate()}</Text>
          </GridRowColumn>
          <GridRowColumn>
            <Card type="subsection" backgroundColor="natural">
              <Text as="h3">
                Who needs a 1099-NEC? (must fit all criteria below)
              </Text>
              <Text>
                <ul>
                  <li>
                    Their tax entity is a LLC (single-member or partnership) or
                    a sole proprietor. See box 3 on their W-9.
                  </li>
                  <li>
                    You’ve paid them <b>over $600</b> in {year}
                  </li>
                  <li>
                    You’ve paid them with <b>cash, checks or bank transfers</b>
                  </li>
                </ul>
              </Text>
            </Card>
          </GridRowColumn>
          <GridRowColumn>
            <Text>
              Still have questions? Read our{' '}
              <Link
                href="https://support.joinheard.com/hc/en-us/articles/18872262968727"
                newPage
              >
                FAQs
              </Link>
            </Text>
          </GridRowColumn>

          <GridRowColumn>
            <Checkbox
              label={checkboxText()}
              checked={confirmationChecked}
              onChange={setConfirmationChecked}
            />
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!confirmationChecked} onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default Ten99OptOutModal
