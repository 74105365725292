import { useState } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useBooleanFlagValue } from '@openfeature/react-sdk'

import {
  Card,
  Pill,
  Text,
  Link,
  IconButton,
} from '../../../../../components/BaseComponents'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  select1120sFormForYear,
  selectIsTwoFormFiler,
} from '../../annualTaxFilingForms.selector'
import { getAnnualTaxFilingForYearSelector } from '../../annualTaxFilings.selector'
import { FEATURE_FLAG_KEYS } from '../../../../OpenFeature'

const BANNER_CLOSED_KEY = (year: string) =>
  `one_form_extension_notice_closed_${year}`

const OneFormExtensionNotice = () => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const businessForm = useReselector(select1120sFormForYear, taxYear)
  const isTwoFormFiler = useReselector(selectIsTwoFormFiler, taxYear)
  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    taxYear
  )

  const optInFlagOn = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enable2024OptOutFlow,
    false
  )

  const [noticeDismissed, setNoticeDismissed] = useState(
    localStorage.getItem(BANNER_CLOSED_KEY(taxYear)) === 'true'
  )

  // This notice should only display for users with extended business forms that aren't 2 form filers
  if (
    !optInFlagOn ||
    noticeDismissed ||
    isTwoFormFiler ||
    !businessForm ||
    !annualTaxFiling?.extensionRequestedAt
  ) {
    return null
  }

  return (
    <Card type="subsection" backgroundColor="stone">
      <IconButton
        icon={regular('close')}
        style={{ position: 'absolute', top: 10, right: 10 }}
        color="darkGray"
        size="lg"
        onClick={() => {
          localStorage.setItem(BANNER_CLOSED_KEY(taxYear), 'true')
          setNoticeDismissed(true)
        }}
      />
      <Pill>reminder</Pill>
      <br />
      <Text as="h3">
        We are only filing an extension for your business tax return
      </Text>
      <br />
      <Text as="bodySm">
        We recommend that you also file an extension for your personal taxes
        with your tax preparer. If you prefer, Heard can prepare both your
        personal tax extension request and filing.
      </Text>
      <br />
      {/*todo this should require opt in*/}
      <Link
        to="/taxes/annual/extension_request/personal"
        style={{ justifyContent: 'start' }}
      >
        File personal extension with Heard -&gt;
      </Link>
    </Card>
  )
}

export default OneFormExtensionNotice
