import { useContext, useMemo } from 'react'
import { Button, Text } from '../../../../../components/BaseComponents'
import {
  EndOfYearAdminReviewStep,
  EndOfYearReviewStepStatus,
  EndOfYearReviewSubmission,
  EndOfYearSubmissionStatus,
} from '../../types'
import { AdminYearEndReviewContext } from '../context'
import { stepIsNot, stepStatusIs } from '../helpers'
import './styles.scss'

const { finalReview, docsOther } = EndOfYearAdminReviewStep
const { pending, complete } = EndOfYearReviewStepStatus

type SubmitSectionState = {
  callToAction: string
  buttonLabel: string
  buttonDisabled: boolean
}

export type SubmitSectionProps = {
  submission: EndOfYearReviewSubmission | undefined | null
}
const SubmitSection = ({ submission }: SubmitSectionProps) => {
  const { openConfirmModal } = useContext(AdminYearEndReviewContext)

  const state: SubmitSectionState = useMemo(() => {
    const finalReviewStep = submission?.steps?.find(
      (step) => step.step === finalReview
    )

    const allInitialStepsComplete =
      submission?.steps
        ?.filter(stepIsNot([docsOther, finalReview]))
        ?.every(stepStatusIs(complete)) ?? false

    if (finalReviewStep || allInitialStepsComplete) {
      return {
        callToAction:
          "Send the user's books back for final review of income and expenses. (No follow-ups)",
        buttonLabel: 'Handoff for Final Review',
        buttonDisabled: finalReviewStep?.status === pending,
      }
    }

    const hasPending =
      submission?.steps
        ?.filter(stepIsNot([docsOther]))
        ?.some(stepStatusIs(pending)) ?? false

    return {
      callToAction:
        'Any unchecked items will be flagged to the user as needing follow-up.',
      buttonLabel: 'Request Follow-up from User',
      buttonDisabled: hasPending,
    }
  }, [submission])

  if (
    !submission ||
    submission.status !== EndOfYearSubmissionStatus.waitingOnHeard
  ) {
    return null
  }

  return (
    <section className="eoy-admin__submit">
      <Text as="bodySm" className="eoy-admin__submit__cta">
        {state.callToAction}
      </Text>
      <Button
        className="eoy-admin__submit__button"
        onClick={openConfirmModal}
        disabled={state.buttonDisabled}
      >
        {state.buttonLabel}
      </Button>
    </section>
  )
}

export default SubmitSection
