import { Text } from '../../../../../components/BaseComponents'
import { TEST_IDS } from '../helpers'
import './styles.scss'

const StepNotRelevant = ({ text }: { text?: string }) => {
  return (
    <div className="eoy-admin__step-note-relevant">
      <Text as="bodySm" color="darkGray" testId={TEST_IDS.stepNoRelevant}>
        {text ?? "This document type isn't relevant to the user."}
      </Text>
    </div>
  )
}

export default StepNotRelevant
