import { useCallback, useMemo, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

export const defaultMessagesURL = `https://${process.env.VITE_ZENDESK_SUBDOMAIN}.zendesk.com/hc/en-us/requests?status=answered`

export const useZendeskSso = () => {
  const [searchParams] = useSearchParams()

  const returnToParam = searchParams.get('return_to')

  const actionUrl = returnToParam
    ? `https://${process.env.VITE_ZENDESK_SUBDOMAIN}.zendesk.com/access/jwt?return_to=${encodeURIComponent(returnToParam)}`
    : `https://${process.env.VITE_ZENDESK_SUBDOMAIN}.zendesk.com/access/jwt`

  const formRef = useRef<HTMLFormElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const zendeskForm = useMemo(
    () => (
      <form ref={formRef} action={actionUrl} method="post">
        <input ref={inputRef} type="hidden" name="jwt" />
      </form>
    ),
    [actionUrl]
  )

  const submitForm = useCallback((value: string) => {
    // Shouldn't happen if the form is initialized
    if (!inputRef.current) {
      return
    }

    // This must be done via secondary form https://support.zendesk.com/hc/en-us/articles/4408845838874-Enabling-JWT-single-sign-on#ariaid-title13
    inputRef.current.value = value

    // Navigates user to zendesk sso.  On success will navigate user back to Heard if returnToParam set.
    // User redux will be populated via session call
    formRef.current?.submit()
  }, [])

  return {
    zendeskForm,
    submitForm,
  }
}
