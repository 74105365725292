import { useParams } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'

import {
  GridRowColumn,
  Text,
} from '../../../../../../components/BaseComponents'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectTaxListQuestion } from '../../taxChecklist.selectors'
import { TaxListQuestionId } from '../../service'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { LIFE_CHANGES_SCREENS, LifeChangesStepProps } from '.'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import ReviewSubSection from '../../Shared/ReviewSubSection'
import { soldHomeQuestionIds } from './SoldHomePanel'
import { lifeChangesQuestionIds } from './LifeChangesPanel'
import { purchasedHomeQuestionIds } from './PurchasedHomePanel'
import { miscQuestionIds } from './MiscQuestionsPanel'
import { useNavigateWithLocation } from '../../../../../../utils/routeHelpers'
import { selectTqFormIsReadOnly } from '../../../annualTaxFilings.selector'
import CommentArea from '../../../components/CommentArea'
import { useReviewPanelComment } from '../../../helpers'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { tqBigSpace } from '../../helpers'

const LifeChangesReviewPanel = ({
  goBack,
  previousScreen,
}: LifeChangesStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const navigate = useNavigateWithLocation()
  const { formId } = useParams()
  const readOnly = useReselector(selectTqFormIsReadOnly, formId)
  const questionSoldHome = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.sold_home,
    taxYear
  )
  const questionPurchasedHome = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_purchase,
    taxYear
  )
  const { comment, saveComment, updateComment } = useReviewPanelComment(
    SubStepIdentifiers.bigLifeChanges
  )

  return (
    <Grid>
      <GridRowColumn
        columnStyle={{ display: 'flex', justifyContent: 'center' }}
      >
        <Image src="https://heard-images.s3.amazonaws.com/assets/magnifying-glass-checkmark.svg" />
      </GridRowColumn>
      <GridRowColumn>
        <Text as="display2" textAlign="center">
          Review
        </Text>
      </GridRowColumn>
      {!readOnly && (
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg">
            Review your answers for accuracy. Click edit to go back to the
            previous section.
          </Text>
        </GridRowColumn>
      )}
      {readOnly && (
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg">
            You can no longer edit your Personal Tax Questionnaire responses,
            but you can view them anytime.
          </Text>
        </GridRowColumn>
      )}
      <Grid.Row />
      <ReviewSubSection
        title="Tell us about your year"
        questionIds={lifeChangesQuestionIds}
        editScreen={LIFE_CHANGES_SCREENS.lifeChanges}
      />
      {questionSoldHome?.responses?.[0]?.value && (
        <ReviewSubSection
          title="Sold a home"
          questionIds={soldHomeQuestionIds}
          editScreen={LIFE_CHANGES_SCREENS.soldHome}
        />
      )}
      {questionPurchasedHome?.responses?.[0]?.value && (
        <ReviewSubSection
          title="Purchased a home"
          questionIds={purchasedHomeQuestionIds}
          editScreen={LIFE_CHANGES_SCREENS.purchasedHome}
        />
      )}
      <ReviewSubSection
        title="Miscellaneous Questions"
        questionIds={miscQuestionIds}
        editScreen={LIFE_CHANGES_SCREENS.miscQuestions}
      />
      {!readOnly && (
        <CommentArea
          label="Add a comment (optional)"
          placeholder="Add a note or ask a question to provide additional context for your tax preparer"
          value={comment}
          onChange={updateComment}
        />
      )}
      {!readOnly && (
        <FormFlowFooter
          isSubmit
          onBack={() => goBack(previousScreen ?? null)}
          onForward={() => {
            saveComment()
            navigate('/taxes/annual/tax_checklist/')
          }}
        />
      )}
    </Grid>
  )
}

export default LifeChangesReviewPanel
