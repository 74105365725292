import { useCallback, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  Card,
  Dropdown,
  GridRowColumn,
  Icon,
  Text,
} from '../../../components/BaseComponents'
import CurrencyFormatLabel from '../../../components/shared/CurrencyFormatLabel'
import TransactionCategoryDropdown from '../../../components/shared/TransactionCategoryDropdown'
import type { Colors } from '../../../styles/theme'
import { centsToDollars } from '../../../utils/currencyHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { getUserTransactionById } from '../transactions.selectors'
import {
  deleteManualTransaction,
  updateUserTransaction,
} from '../transactions.slice'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../utils/dateHelpers'
import { TransactionNoteModal } from './transaction-note-modal'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { TransactionRuleCategoryType } from '../../../reducers/admin/transactionRulesReducer'

export const TransactionCard = ({
  transactionId,
  backgroundColor = 'stone',
  variant = 'default',
  editAction,
  deleteAction,
  followUpYear,
}: {
  transactionId: number
  backgroundColor?: keyof typeof Colors
  variant?: 'default' | 'edit'
  editAction?: (transactionId: number) => void
  deleteAction?: (transactionId: number) => void
  followUpYear?: string
}) => {
  const dispatch = useAppDispatch()
  const transaction = useReselector(getUserTransactionById, transactionId)
  const [modalOpen, setModalOpen] = useState(false)

  const updateTransactionCategoryId = useCallback(
    (value?: number) => {
      const updateTransactionObject: {
        type?: TransactionRuleCategoryType
        transactionCategoryId?: number | null
        requestedClarificationAt?: string | null
        notifiedRequestedClarificationAt?: string | null
      } = {
        transactionCategoryId: value || null,
        // Auto set the type as business if categorized
        type: value ? TransactionRuleCategoryType.business : undefined,
      }
      if (followUpYear) {
        updateTransactionObject.requestedClarificationAt = null
        updateTransactionObject.notifiedRequestedClarificationAt = null
      }

      updateUserTransaction(
        transactionId,
        updateTransactionObject,
        followUpYear
      )(dispatch)
    },
    [dispatch, followUpYear, transactionId]
  )

  const updateTransactionType = useCallback(
    (type: TransactionRuleCategoryType) => {
      const updateTransactionObject: {
        type: TransactionRuleCategoryType
        transactionCategoryId?: number | null
        requestedClarificationAt?: string | null
        notifiedRequestedClarificationAt?: string | null
      } = {
        type,
        // Clear category if transaction is personal
        transactionCategoryId: type === 'personal' ? null : undefined,
      }
      if (followUpYear) {
        updateTransactionObject.requestedClarificationAt = null
        updateTransactionObject.notifiedRequestedClarificationAt = null
      }

      updateUserTransaction(
        transactionId,
        updateTransactionObject,
        followUpYear
      )(dispatch)
    },
    [dispatch, followUpYear, transactionId]
  )

  const onDelete = useCallback(async () => {
    await deleteManualTransaction(transactionId)(dispatch)
    deleteAction?.(transactionId)
  }, [deleteAction, dispatch, transactionId])

  if (!transaction) {
    return null
  }

  return (
    <Card padding={24} backgroundColor={backgroundColor} type="subsection">
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={3}>
            <Text>
              {isoToUTCDateTime(transaction.date).toFormat(
                DATE_FORMATS_LUXON.DISPLAY_SHORT_YEAR
              )}
            </Text>
          </Grid.Column>
          <Grid.Column width={4}>
            <Text>{transaction.description}</Text>
          </Grid.Column>
          <Grid.Column width={2}>
            <Text>
              <CurrencyFormatLabel
                value={centsToDollars(transaction.amountInCents)}
              />
            </Text>
          </Grid.Column>
          <Grid.Column width={2}>
            <Dropdown
              options={[
                {
                  text: 'Business',
                  value: TransactionRuleCategoryType.business,
                },
                {
                  text: 'Personal',
                  value: TransactionRuleCategoryType.personal,
                },
              ]}
              // There's extra logic in here on how to handle split transactions but they should be filtered out before this is hit
              value={
                transaction.type === null || transaction.type === 'split'
                  ? undefined
                  : transaction.type
              }
              disabled={transaction.type === 'split'}
              onChange={updateTransactionType}
              variant="text"
              placeholder="Select Type"
            />
          </Grid.Column>
          <Grid.Column width={3}>
            {transaction.type === 'personal' ? (
              <Text>
                <b>N/A</b>
              </Text>
            ) : (
              <TransactionCategoryDropdown
                variant="text"
                clearable={false}
                optionStyle="normal"
                onChange={updateTransactionCategoryId}
                value={transaction.transactionCategoryId || undefined}
                fullWidth
                disabled={variant === 'edit'}
                transactionDate={transaction.date}
              />
            )}
          </Grid.Column>
          {variant === 'edit' && (
            <Grid.Column
              width={2}
              style={{
                display: 'flex',
                gap: 24,
                flexDirection: 'row',
                justifyContent: 'right',
              }}
            >
              <Icon
                icon={regular('edit')}
                onClick={() => editAction?.(transaction.id)}
              />
              <Icon icon={regular('trash-can')} onClick={onDelete} />
            </Grid.Column>
          )}
          {variant === 'default' && (
            <Grid.Column width={2} textAlign="right">
              <Button onClick={() => setModalOpen(true)} variant="link">
                {transaction?.notes ? 'Edit Note' : 'Add Note'}
              </Button>
              <TransactionNoteModal
                transaction={transaction}
                open={modalOpen}
                close={() => setModalOpen(false)}
                followUpYear={followUpYear}
              />
            </Grid.Column>
          )}
        </Grid.Row>
        {transaction.notes && (
          <GridRowColumn short>
            <Text as="bodySm" color="darkGray">
              <i>{transaction.notes}</i>
            </Text>
          </GridRowColumn>
        )}
      </Grid>
    </Card>
  )
}

export default TransactionCard
