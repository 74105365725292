import { Grid, Divider } from 'semantic-ui-react'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { DateTime } from 'luxon'
import { NavButtons } from '../../end-of-year-review/nav-buttons'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import {
  Card,
  GridRowColumn,
  Text,
  Alert,
  Icon,
} from '../../../../components/BaseComponents'
import { StepHeaders } from '../../../../components/StepHeader'
import DeleteDocumentConfirm from '../../../UserDocuments/sharedComponents/Modals/DeleteDocumentConfirm'
import { MissingStatementCard } from '../../../../components/Finances/Accounts/MissingStatementCard'
import { useNavigate, useParams } from 'react-router-dom'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import styles from './styles.module.scss'
import { useViewMissingStatementsList } from '../../../../components/shared/ViewMissingStatementsAccordion'
import {
  fetchUserDocuments,
  getMissingStatements,
  MissingStatement,
} from '../../../UserDocuments/userDocuments.slice'
import {
  userGetBookkeepingStep,
  userUpdateBookkeepingStepContext,
} from '../../end-of-year-review/actions'
import {
  EndOfYearAdminReviewStep,
  MissingBankStatementsStepContext,
} from '../../end-of-year-review/types'
import { useReselector } from '../../../../utils/sharedHooks'
import { fetchFinancialAccountsIfNeeded } from '../../../../actions/financialAccountActions'
import { selectActivePlaidFinancialAccounts } from '../../../../selectors/financeSelectors'
import { selectUserStatements } from '../../../UserDocuments/userDocuments.selector'

const filterMissingStatements = (
  missingStatements: MissingStatement[] | undefined,
  year: string,
  missingStatementsForNextYear: MissingStatement[]
) => {
  const filterStatementsByYear = (statements: string[], year: number) => {
    return statements.filter((statement) => {
      const statementDate = DateTime.fromISO(statement, { zone: 'utc' })
      return statementDate.year === year
    })
  }

  const statementsFiltered = (missingStatements ?? []).map((statement) => ({
    ...statement,
    statements: filterStatementsByYear(
      statement.statements,
      parseInt(year ?? '')
    ),
  }))

  const flattened = statementsFiltered.flatMap((statement) =>
    statement.statements.length > 0 ? [statement] : []
  )

  // Merge missing statements for next year with the current year's missing statements
  for (const missingStatement of missingStatementsForNextYear) {
    const accountStatements = flattened.find(
      (acct) =>
        acct.financial_institution_name ===
          missingStatement.financial_institution_name &&
        acct.name === missingStatement.name &&
        acct.mask === missingStatement.mask
    )
    if (accountStatements) {
      accountStatements.statements.push(missingStatement.statements[0])
    } else {
      flattened.push(missingStatement)
    }
  }

  return flattened
}

export const FollowUpOnStatements = () => {
  const { year } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [missingStatements, setMissingStatements] = useState<
    MissingStatement[]
  >([])
  const [stepContext, setStepContext] = useState<
    MissingBankStatementsStepContext | undefined
  >()
  const [uploadedDocIds, setUploadedDocIds] = useState<number[]>([])

  const nextYear = useMemo(
    () => (year ? String(parseInt(year) + 1) : DateTime.now().year.toString()),
    [year]
  )

  const financialAccounts = useReselector(selectActivePlaidFinancialAccounts)
  const statements = useReselector(selectUserStatements)

  // Check user documents to see if there are any missing statements for next year
  // when the bookkeeper requests them. January is the only month needed.
  const missingStatementsForNextYear = useMemo(() => {
    if (!stepContext?.requestNextYear) return []

    const missingNextYearStatements: MissingStatement[] = []
    for (const account of financialAccounts) {
      const hasStatement = statements.some(
        (doc) =>
          doc.statement?.statementMonth === `${nextYear}-01` &&
          doc.statement?.statementFinancialAccounts?.some(
            (sfa) => sfa.financialAccountId === account.id
          )
      )
      if (!hasStatement) {
        missingNextYearStatements.push({
          logo: 'https://heard-images.s3.amazonaws.com/assets/piggy-bank.png',
          financial_institution_name: account.plaidInstitutionName,
          name: account.name,
          mask: account.mask,
          statements: [`${nextYear}-01-01T00:00:00.000Z`],
        })
      }
    }
    return missingNextYearStatements
  }, [stepContext?.requestNextYear, statements, financialAccounts, nextYear])

  const getStatementStep = useCallback(async () => {
    const statementStep = await userGetBookkeepingStep(
      EndOfYearAdminReviewStep.uploadMissingStatements
    )(dispatch)
    if (statementStep?.context) {
      setStepContext(statementStep.context)
    }
  }, [dispatch])

  const onComplete = useCallback(async () => {
    await userUpdateBookkeepingStepContext(
      EndOfYearAdminReviewStep.uploadMissingStatements,
      EndOfYearAdminReviewStep.uploadMissingStatements,
      {
        ...stepContext,
        updatedByUser: true,
      }
    )(dispatch)
    navigate(`/taxes/annual/review-and-close-books/${year}/follow-up`)
  }, [dispatch, navigate, stepContext, year])

  useEffect(() => {
    if (stepContext?.requestNextYear) {
      dispatch(fetchFinancialAccountsIfNeeded())
      dispatch(fetchUserDocuments())
    }
  }, [dispatch, stepContext?.requestNextYear])

  useEffect(() => {
    getStatementStep()
  }, [getStatementStep])

  useEffect(() => {
    const fetch = async (year: string) => {
      const fetchingStatements = (await getMissingStatements()(dispatch)) || []
      setMissingStatements(
        filterMissingStatements(
          fetchingStatements,
          year,
          missingStatementsForNextYear
        )
      )
    }
    if (year) {
      fetch(year)
    }
  }, [dispatch, year, missingStatementsForNextYear, uploadedDocIds])

  const formattedStatements = useViewMissingStatementsList({
    neededStatements: missingStatements,
  })

  const onUploadOrDelete = useCallback(
    (id: number, isDelete: boolean) => {
      if (isDelete) {
        setUploadedDocIds((prev) => prev.filter((docId) => docId !== id))
      } else {
        setUploadedDocIds((prev) => [...prev, id])
      }
    },
    [setUploadedDocIds]
  )

  return (
    <div className="eoy-review_wrapper">
      <header className="header">
        <Text>{year} End of Year Review</Text>
        <div className="autosave-wrapper">
          <Icon
            name="check"
            style={{ color: '#457634' }}
            icon={solid('check')}
          />
          <Text style={{ color: '#457634' }}>Autosaved</Text>
        </div>
      </header>
      <Grid>
        <GridRowColumn centerContent className="header-container">
          <StepHeaders
            imageSrc="https://heard-images.s3.amazonaws.com/assets/upload-statement.png"
            imageAlt="uploadStatement"
            imageWidth={180}
            imageHeight={180}
            title="Upload missing bank statements"
            description=""
            customColor="green"
          >
            <Grid>
              <GridRowColumn className="align-left">
                <Text>
                  We need these to reconcile your books. Please upload all the
                  statements listed below.
                </Text>
              </GridRowColumn>
              <GridRowColumn className="align-left">
                {formattedStatements}
              </GridRowColumn>

              <Grid.Row />

              {(stepContext?.adminNote ?? []).length > 0 && (
                <GridRowColumn width={12} className="tooltip-grid">
                  <Alert
                    title="Note from Bookkeeper"
                    style={{ width: '520px', padding: '40px' }}
                    customIcon="none"
                    contentStyle={{ textAlign: 'left' }}
                    type="acknowledge"
                  >
                    <Text style={{ color: '#AC590C', marginTop: '20px' }}>
                      {stepContext?.adminNote}
                    </Text>
                  </Alert>
                </GridRowColumn>
              )}

              <GridRowColumn className={styles.container}>
                <MissingStatementCard onUploadOrDelete={onUploadOrDelete} />
              </GridRowColumn>

              {missingStatements.length === 0 &&
                missingStatementsForNextYear.length === 0 && (
                  <Card backgroundColor="lightGreen" fullWidth>
                    <div className={styles.allUploaded}>
                      <Icon
                        icon={solid('check')}
                        color="accentGreen"
                        size="2x"
                      />

                      <div className={styles.uploadedText}>
                        <Text as="h3">All statements uploaded</Text>
                        <Text>
                          Thanks! You&apos;ve uploaded all missing statements.
                        </Text>
                      </div>
                    </div>
                  </Card>
                )}
            </Grid>
          </StepHeaders>
        </GridRowColumn>
        <GridRowColumn>
          <div className="alert-footer">
            <Alert
              title="Why is this important?"
              style={{ width: '520px' }}
              customIcon={<Icon icon={solid('info-circle')} />}
              contentStyle={{ textAlign: 'left' }}
            >
              Reconciliation is a standard practice to ensure all transactions
              are accounted for. We use your bank statements to check that your
              balances match what we have in your books.
            </Alert>
          </div>
        </GridRowColumn>
        <DeleteDocumentConfirm />
      </Grid>
      <Divider style={{ marginTop: 50 }} />

      <NavButtons
        isStepComplete={missingStatements.length === 0}
        stepBack={() => {
          navigate(`/taxes/annual/review-and-close-books/${year}/follow-up`)
        }}
        stepForward={() => {
          onComplete()
        }}
        saveAndExit={() => {
          navigate('/taxes/annual')
        }}
      />
    </div>
  )
}
