import { useBooleanFlagValue } from '@openfeature/react-sdk'

import { Alert } from '../BaseComponents'
import { FEATURE_FLAG_KEYS } from '../../features/OpenFeature'
import { Colors } from '../../styles/theme'

const useTransactionMaintenanceBanner = () => {
  const maintenanceMessage = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.disableTransactionUpdates,
    false
  )

  if (!maintenanceMessage) {
    return null
  }

  return (
    <Alert style={{ borderRadius: 0 }} type="announcement">
      Your transactions are under maintenance and can&apos;t be updated at this
      time. Please wait a few minutes and try again.
    </Alert>
  )
}

const TransactionMaintenanceBanner = () => {
  const banner = useTransactionMaintenanceBanner()
  return banner
}

export const MaintenanceBannerWithBorder = () => {
  const shouldUse = useTransactionMaintenanceBanner()
  if (!shouldUse) {
    return null
  }

  return (
    <div style={{ border: `3px solid ${Colors.black}` }}>
      <TransactionMaintenanceBanner />
    </div>
  )
}

export default TransactionMaintenanceBanner
