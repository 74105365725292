import { useCallback, useContext, useMemo } from 'react'
import {
  PrefixedUserDocumentCategoryIdentifier,
  UserDocumentCategoryIdentifier,
} from '../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { Document, DocumentFile } from '../../types'
import { AdminYearEndReviewContext } from '../context'
import { DocumentRow, DocumentRowProps } from '../document-row'

const { formW3, form1099nec } = UserDocumentCategoryIdentifier

const WAITING = 'Waiting on documents from payroll provider'
const INTEGRATION_ERROR =
  'Error downloading documents from payroll provider. Request therapist reupload.'

export type HeardPayrollRowProps = {
  categoryCodes: string[]
  stepDocuments: Document[] | undefined
  getFileIfExists: (doc: Document) => DocumentFile | undefined
  onRequestReUpload: (document: Document) => void
}
const HeardPayrollRow = ({
  categoryCodes,
  stepDocuments,
  getFileIfExists,
  onRequestReUpload,
}: HeardPayrollRowProps) => {
  const { taxYear } = useContext(AdminYearEndReviewContext)

  const payrollJournal = useMemo(
    () => PrefixedUserDocumentCategoryIdentifier.payrollJournal(taxYear),
    [taxYear]
  )

  const contractorPaymentsReport = useMemo(
    () =>
      PrefixedUserDocumentCategoryIdentifier.contractorPaymentsReport(taxYear),
    [taxYear]
  )

  const getDescription = useCallback(
    (categoryCode: string) => {
      switch (categoryCode) {
        case formW3:
          return 'Form W3'
        case payrollJournal:
          return 'Payroll Journal'
        case form1099nec:
          return '1099-NEC'
        case contractorPaymentsReport:
          return 'Contractor Payment Report'
        default:
          return categoryCode
      }
    },
    [payrollJournal, contractorPaymentsReport]
  )

  const documentRowProps = useMemo<
    DocumentRowProps<Document> | undefined
  >(() => {
    if (categoryCodes.length === 0) {
      return undefined
    }

    const document = stepDocuments?.find(
      (doc) =>
        doc.provider === 'Heard' && categoryCodes.includes(doc.categoryCode)
    )
    const file = document ? getFileIfExists(document) : undefined

    return {
      description: `Heard - ${categoryCodes.map(getDescription).join(' or ')}`,
      document: document ?? {
        categoryCode: categoryCodes[0],
        provider: 'Heard',
      },
      file,
      testId: `Heard_${categoryCodes.join('_')}`,
      onRequestReUpload: document?.integrationError
        ? onRequestReUpload
        : undefined,
      children: file
        ? undefined
        : document?.integrationError
          ? INTEGRATION_ERROR
          : WAITING,
    }
  }, [
    categoryCodes,
    stepDocuments,
    getFileIfExists,
    getDescription,
    onRequestReUpload,
  ])

  if (!documentRowProps) {
    return null
  }

  return <DocumentRow {...documentRowProps} />
}

export default HeardPayrollRow
