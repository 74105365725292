import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid, Loader } from 'semantic-ui-react'

import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import {
  useFormFlow,
  useSetScreen,
} from '../../../../../../components/FormFlow/formFlow'
import {
  invalidateFetch,
  selectErrorsForKeys,
  selectIsFetchingForKeys,
} from '../../../../../../reducers/fetch'
import {
  useLoadedFlagValue,
  useReselector,
  useScrollRef,
} from '../../../../../../utils/sharedHooks'
import {
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  FETCH_USER_TAX_QUESTIONNAIRE_KEY,
  postUpdateTaxQuestionnaireResponses,
  fetchUserTaxQuestionnaire,
} from '../../taxChecklistQuestion.actions'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import {
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
} from '../../../annualTaxFilingForms.slice'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TaxListQuestionId, useTaxListQuestionRes } from '../../service'
import DeductionsAndCreditsPanel from './DeductionsAndCreditsPanel'
import {
  GridRowColumn,
  Alert,
} from '../../../../../../components/BaseComponents'
import PageHeader from '../../../../../../components/shared/PageHeader'
import {
  CREATE_USER_EOY_REVIEW_PROGRESS_KEY,
  UPDATE_USER_EOY_REVIEW_PROGRESS_KEY,
  DELETE_USER_EOY_REVIEW_PROGRESS_KEY,
  fetchUserEoyReviewProgress,
} from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import OwnedHomePanel from './OwnedHomePanel'
import {
  DEDUCTIONS_AND_CREDITS,
  SubStepIdentifiers,
} from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { fetchAllEoyReviewStepsIfNeeded } from '../../Shared/ReviewStepsandProgresses/allEoyReviewSteps.slice'
import HadBusinessMileagePanel from './HadBusinessMileagePanel'
import OwnedFarmPanel from './OwnedFarmPanel'
import CharitableContributionsPanel from './CharitableContributionsPanel'
import EducationPanel from './EducationPanel'
import {
  filterNulls,
  useAppDispatch,
} from '../../../../../../utils/typeHelpers'
import DependentCareExpensesPanel from './DependentCareExpensesPanel'
import DeductionsAndCreditsReviewPanel from './ReviewPanel'
import HomeOfficePanel from './HomeOfficePanel'
import HomeOfficeDeductionsSimplifiedPanel from './HomeOfficeSimplifiedPanel'
import HomeOfficeDeductionsStandardStep1Panel from './HomeOfficeStandardStep1Panel'
import HomeOfficeDeductionsStandardStep2Panel from './HomeOfficeStandardStep2Panel'
import useProgressSteps, {
  StepUpdates,
} from '../../Shared/ReviewStepsandProgresses/useProgressSteps'
import { selectTqFormIsReadOnly } from '../../../annualTaxFilings.selector'
import { FEATURE_FLAG_KEYS } from '../../../../../OpenFeature'
import { fetchUserDocuments } from '../../../../../UserDocuments/userDocuments.slice'
import { fetchTaxUserDocumentsIfNeeded } from '../../../taxUserDocuments.slice'
import { fetchUserDocumentCategoriesIfNeeded } from '../../../../../Admin/UserDocumentCategories/userDocumentCategories.slice'

export enum DEDUCTIONS_AND_CREDITS_SCREENS {
  deductionsAndCredits = 'deductions-and-credits',
  ownedHome = 'owned-home',
  homeOffice = 'home-office',
  homeOfficeSimplified = 'home-office-simplified',
  homeOfficeStandardStep1 = 'home-office-standard-step-1',
  homeOfficeStandardStep2 = 'home-office-standard-step-2',
  businessMileage = 'business-mileage',
  farm = 'farm',
  education = 'education',
  dependentCare = 'dependent-care',
  charitableContributions = 'charitable-contributions',
  review = 'review',
  skipNavigation = 'skip-navigation',
}

export interface DeductionsAndCreditsStepProps {
  goToNextStep: (
    data: Partial<TaxChecklistResponse | undefined>[] | null,
    newScreen: DEDUCTIONS_AND_CREDITS_SCREENS | null,
    stepUpdates?: StepUpdates,
    getNextScreen?: () => DEDUCTIONS_AND_CREDITS_SCREENS
  ) => Promise<boolean>
  goBack: (
    newScreen: DEDUCTIONS_AND_CREDITS_SCREENS | null,
    subStepIdentifier?: SubStepIdentifiers
  ) => void
  nextScreen?: DEDUCTIONS_AND_CREDITS_SCREENS | null
  previousScreen?: DEDUCTIONS_AND_CREDITS_SCREENS | null
}

const DeductionsAndCredits = () => {
  const dispatch = useAppDispatch()
  const { formId: formIdParam } = useParams()
  const formId = Number(formIdParam)
  const { scrollRef, scrollToRef } = useScrollRef()

  const readOnly = useReselector(selectTqFormIsReadOnly, formId)
  const backLink = '/taxes/annual/tax_checklist/'
  const { setScreen, currentScreen } = useSetScreen({
    backLink,
    reviewScreen: DEDUCTIONS_AND_CREDITS_SCREENS.review,
  })

  const { updateProgressData, fetchKeys: progressFetchKeys } = useProgressSteps(
    { steps: DEDUCTIONS_AND_CREDITS }
  )

  const taxYear = useReselector(selectCurrentAnnualTaxYear)

  const updateError = useReselector(selectErrorsForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
    ...progressFetchKeys,
  ])

  const isFetching = useReselector(selectIsFetchingForKeys, [
    FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
    FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
    FETCH_USER_TAX_QUESTIONNAIRE_KEY(taxYear),
    ...progressFetchKeys,
  ])

  const saveFormData = useCallback(
    async (
      data: Partial<TaxChecklistResponse | undefined>[] | null,
      newScreen: DEDUCTIONS_AND_CREDITS_SCREENS | null,
      stepUpdates: StepUpdates = {}
    ) => {
      dispatch(invalidateFetch(UPSERT_USER_TAX_QUESTIONNAIRE_KEY))
      dispatch(invalidateFetch(DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY))
      dispatch(invalidateFetch(CREATE_USER_EOY_REVIEW_PROGRESS_KEY))
      dispatch(invalidateFetch(UPDATE_USER_EOY_REVIEW_PROGRESS_KEY))
      dispatch(invalidateFetch(DELETE_USER_EOY_REVIEW_PROGRESS_KEY))

      const parsedData = data && filterNulls(data)

      if (parsedData?.length) {
        const res = await dispatch(
          postUpdateTaxQuestionnaireResponses(parsedData)
        )
        if (!res) {
          return false
        }
      }

      const progressUpdatesSuccessful = await updateProgressData(stepUpdates)
      if (!progressUpdatesSuccessful) {
        return false
      }
      if (newScreen !== DEDUCTIONS_AND_CREDITS_SCREENS.skipNavigation) {
        setScreen(newScreen)
      }
      return true
    },
    [dispatch, setScreen, updateProgressData]
  )

  const setBackScreen = useCallback(
    async (
      previousScreen: DEDUCTIONS_AND_CREDITS_SCREENS | null,
      subStepIdentifier?: SubStepIdentifiers
    ) => {
      //if the current previousScreen is one of the conditional home office screens, send them to the base home office screen instead
      let updateSucceeded = true
      if (subStepIdentifier) {
        updateSucceeded = await updateProgressData({
          incompleteSteps: [subStepIdentifier],
        })
      }
      if (updateSucceeded) {
        const backScreen =
          previousScreen &&
          [
            DEDUCTIONS_AND_CREDITS_SCREENS.homeOfficeSimplified,
            DEDUCTIONS_AND_CREDITS_SCREENS.homeOfficeStandardStep1,
            DEDUCTIONS_AND_CREDITS_SCREENS.homeOfficeStandardStep2,
          ].includes(previousScreen)
            ? DEDUCTIONS_AND_CREDITS_SCREENS.homeOffice
            : previousScreen
        setScreen(backScreen)
      }
    },
    [setScreen, updateProgressData]
  )

  const props = useMemo(() => {
    return {
      goToNextStep: saveFormData,
      goBack: setBackScreen,
    }
  }, [saveFormData, setBackScreen])

  const ownedHomeQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.owned_home,
    formId,
  })

  const workedFromHomeQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.worked_from_home,
    formId,
  })

  const hadBusinessMileageQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.had_business_mileage,
    formId,
  })

  const ownedFarmQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.owned_farm,
    formId,
  })

  const hadCollegeStudentsQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.had_college_students,
    formId,
  })

  const paidDependentCareQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.paid_dependent_expenses,
    formId,
  })

  const charitableContributionQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.charitable_contribution,
    formId,
  })

  const applicableScreens = useMemo(() => {
    const newScreens: {
      component: () => JSX.Element
      screenName: string
      progressText?: string
      step: number
      order?: number
      noText?: boolean
    }[] = [
      {
        component: () => <DeductionsAndCreditsPanel {...props} />,
        screenName: DEDUCTIONS_AND_CREDITS_SCREENS.deductionsAndCredits,
        step: -1,
      },
    ]

    let step = 0
    if (ownedHomeQR.currentVal) {
      newScreens.push({
        component: () => <OwnedHomePanel {...props} />,
        screenName: DEDUCTIONS_AND_CREDITS_SCREENS.ownedHome,
        progressText: 'Home Owner',
        step: step++,
      })
    }
    if (workedFromHomeQR.currentVal) {
      newScreens.push({
        component: () => <HomeOfficePanel {...props} />,
        screenName: DEDUCTIONS_AND_CREDITS_SCREENS.homeOffice,
        progressText: 'Home Office',
        step,
      })
      newScreens.push({
        component: () => <HomeOfficeDeductionsSimplifiedPanel {...props} />,
        screenName: DEDUCTIONS_AND_CREDITS_SCREENS.homeOfficeSimplified,
        noText: true,
        step,
      })
      newScreens.push({
        component: () => <HomeOfficeDeductionsStandardStep1Panel {...props} />,
        screenName: DEDUCTIONS_AND_CREDITS_SCREENS.homeOfficeStandardStep1,
        noText: true,
        step,
      })
      newScreens.push({
        component: () => <HomeOfficeDeductionsStandardStep2Panel {...props} />,
        screenName: DEDUCTIONS_AND_CREDITS_SCREENS.homeOfficeStandardStep2,
        noText: true,
        step: step++,
      })
    }
    if (hadBusinessMileageQR.currentVal) {
      newScreens.push({
        component: () => <HadBusinessMileagePanel {...props} />,
        screenName: DEDUCTIONS_AND_CREDITS_SCREENS.businessMileage,
        progressText: 'Business Mileage',
        step: step++,
      })
    }
    if (ownedFarmQR.currentVal) {
      newScreens.push({
        component: () => <OwnedFarmPanel {...props} />,
        screenName: DEDUCTIONS_AND_CREDITS_SCREENS.farm,
        progressText: 'Farm',
        step: step++,
      })
    }
    if (hadCollegeStudentsQR.currentVal) {
      newScreens.push({
        component: () => <EducationPanel {...props} />,
        screenName: DEDUCTIONS_AND_CREDITS_SCREENS.education,
        progressText: 'Education',
        step: step++,
      })
    }
    if (paidDependentCareQR.currentVal) {
      newScreens.push({
        component: () => <DependentCareExpensesPanel {...props} />,
        screenName: DEDUCTIONS_AND_CREDITS_SCREENS.dependentCare,
        progressText: 'Dependent Care',
        step: step++,
      })
    }
    if (charitableContributionQR.currentVal) {
      newScreens.push({
        component: () => <CharitableContributionsPanel {...props} />,
        screenName: DEDUCTIONS_AND_CREDITS_SCREENS.charitableContributions,
        progressText: 'Charity',
        step: step++,
      })
    }
    newScreens.push({
      component: () => <DeductionsAndCreditsReviewPanel {...props} />,
      screenName: DEDUCTIONS_AND_CREDITS_SCREENS.review,
      progressText: 'Review',
      step: step++,
    })
    return newScreens
  }, [
    charitableContributionQR.currentVal,
    hadBusinessMileageQR.currentVal,
    hadCollegeStudentsQR.currentVal,
    ownedFarmQR.currentVal,
    ownedHomeQR.currentVal,
    paidDependentCareQR.currentVal,
    props,
    workedFromHomeQR.currentVal,
  ])

  const { progressBar, content } = useFormFlow({
    steps: applicableScreens.flatMap((s) =>
      !s.noText && s.progressText ? s.progressText : []
    ),
    screens: applicableScreens,
  })

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchAllEoyReviewStepsIfNeeded())
    dispatch(fetchUserDocuments())
    dispatch(fetchTaxUserDocumentsIfNeeded())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch])

  useEffect(() => {
    const prevYear = Number(taxYear) - 1
    dispatch(fetchUserEoyReviewProgress(taxYear))
    dispatch(fetchUserTaxQuestionnaire(taxYear))
    dispatch(fetchUserTaxQuestionnaire(prevYear.toString()))
  }, [dispatch, taxYear])

  useEffect(() => {
    if (!currentScreen) {
      setScreen(DEDUCTIONS_AND_CREDITS_SCREENS.deductionsAndCredits, true)
    }
  }, [setScreen, currentScreen])

  useEffect(() => {
    scrollToRef()
  }, [currentScreen, scrollToRef])

  const navigate = useNavigate()
  const enable2024TaxChecklist = useLoadedFlagValue(
    FEATURE_FLAG_KEYS.enable2024TaxChecklist,
    false
  )
  useEffect(() => {
    if (enable2024TaxChecklist === false) {
      navigate('/taxes/annual')
    }
  }, [enable2024TaxChecklist, navigate])

  return (
    <>
      <span ref={scrollRef} />
      <PageHeader
        backControl={
          readOnly
            ? { link: backLink, text: 'Back to Personal Tax Checklist' }
            : undefined
        }
        header={`${taxYear} Personal Tax Questionnaire: Deductions and Credits`}
      />
      <Grid>
        <Loader loading={isFetching} />
        {!readOnly &&
          !isFetching &&
          currentScreen !==
            DEDUCTIONS_AND_CREDITS_SCREENS.deductionsAndCredits && (
            <GridRowColumn>{progressBar}</GridRowColumn>
          )}
        {updateError.length > 0 && (
          <GridRowColumn>
            <Alert type="error">{updateError[0].message}</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn>{content}</GridRowColumn>
      </Grid>
    </>
  )
}

export default DeductionsAndCredits
