import { useEffect, useState } from 'react'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FinancialAccount } from '../../../../reducers/finances/financialAccountsReducer'
import Card from '../../../BaseComponents/Card'
import Checkbox from '../../../BaseComponents/Checkbox'
import Icon, { StackedIcon } from '../../../BaseComponents/Icon'
import Text from '../../../BaseComponents/Text'
import PlaidLink from '../../../shared/plaid/PlaidLink'
import styles from './styles.module.scss'

export const FinancialAccountCard = ({
  account,
  skipAccountReconnect,
  toggleSkipAccountReconnect,
}: {
  account: FinancialAccount
  skipAccountReconnect: boolean
  toggleSkipAccountReconnect: (acccountId: number) => void
}) => {
  const [isConnected, setIsConnected] = useState(false)
  const [isReconnectReconciled, setIsReconnectReconciled] = useState(false)

  useEffect(() => {
    setIsConnected(!account?.accounts?.needsReconnection)
  }, [account?.accounts?.needsReconnection])

  useEffect(() => {
    setIsReconnectReconciled(isConnected || skipAccountReconnect)
  }, [skipAccountReconnect, isConnected])

  return (
    <Card
      key={account.id.toString()}
      backgroundColor={isReconnectReconciled ? 'lightGreen' : 'stone40'}
      fullWidth
    >
      <div className={styles.accountCard}>
        <div className={styles.topRow}>
          <StackedIcon
            icon={regular('bank')}
            color="white"
            backgroundIcon={solid('circle')}
            backgroundColor="forest"
            marginRight={16}
          />

          <div className={styles.accountName}>
            {account.plaidInstitutionName} - {account.mask}
          </div>

          {skipAccountReconnect ? (
            <div className={styles.isChecked}>
              <Checkbox
                id={`cant-reconnect-${account.id}`}
                label="I can't reconnect this account"
                checked={skipAccountReconnect}
                onClick={() => toggleSkipAccountReconnect(account.id)}
              />
            </div>
          ) : isConnected ? (
            <div className={styles.reconnectedText}>
              <Text color="accentGreen" style={{ marginRight: 5 }}>
                Reconnected{' '}
              </Text>
              <Icon icon={regular('check')} color="accentGreen" />
            </div>
          ) : (
            <PlaidLink account={account} redirect={false} disconnected />
          )}
        </div>

        {!skipAccountReconnect && !isConnected && (
          <div className={styles.cantReconnectAccount}>
            <Checkbox
              id={`cant-reconnect-${account.id}`}
              label="I can't reconnect this account"
              checked={skipAccountReconnect}
              onClick={() => toggleSkipAccountReconnect(account.id)}
            />
          </div>
        )}
      </div>
    </Card>
  )
}
