import { useEffect, useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import { Grid, Divider, Loader } from 'semantic-ui-react'
import * as yup from 'yup'
import { isEmpty } from 'lodash'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Alert,
  Button,
  Card,
  FormikCheckbox,
  FormikDropdown,
  FormikInput,
  FormikLocationSearchInput,
  getFieldNames,
  GridRowColumn,
  Icon,
  makeNumberSchema,
  makeReqStringSchema,
  Modal,
  Popup,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { STATES } from '../../../../constants/locationConstants'
import {
  getAboundTaxDocForPayerByContractorId,
  getCurrentAboundPayer,
  selectAboundContractorsByUUID,
  selectContractorIsFromPreviousTaxYears,
} from '../aboundAnnualTaxFilings.selector'
import {
  AboundContractor,
  AboundTaxDocument,
  AboundTaxDocumentDataToSend,
  ABOUND_CONTRACTOR_UPDATE_KEY,
  ABOUND_TAX_FILINGS_CREATE_KEY,
  ABOUND_TAX_FILINGS_UPDATE_KEY,
  createAboundTaxDocumentRequest,
  fetchAboundContractors,
  fetchAboundPayer,
  fetchAboundTaxDocumentsForPayer,
  updateAboundContractorRequest,
  updateAboundTaxDocumentRequest,
  FETCH_ABOUND_PAYER_KEY,
  correctAboundTaxDocumentRequest,
} from '../aboundAnnualTaxFilings.slice'
import {
  centsToDollars,
  dollarsToCents,
} from '../../../../utils/currencyHelpers'
import File1099sPage from './File1099sPage'
import { getIsFetchingOrNotStarted } from '../../../../reducers/fetch'
import Ten99FilingErrors from './Ten99FilingErrors'
import { identificationNumberProps } from '../../../../components/BaseComponents/Input'
import { Colors } from '../../../../styles/theme'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../OpenFeature'
import Edit1099ModalConfirmation from './Edit1099ModalConfirmation'
import { TIN_VERIFICATION_STATUS } from './constants'

// const statesWithExtraRequirements = ['AL', 'DE', 'KS', 'MI', 'ND', 'OR', 'PA']

const commonYupSchema = {
  firstName: makeReqStringSchema({ field: 'first name' }),
  lastName: makeReqStringSchema({ field: 'last name' }),
  businessName: yup.string().nullable(),
  address: makeReqStringSchema({ field: 'address' }),
  city: makeReqStringSchema({ field: 'city' }),
  state: makeReqStringSchema({ field: 'state' }),
  postalCode: makeNumberSchema({
    field: 'postalCode',
    numDigits: 5,
    allowLeadingZero: true,
  }),
  country: makeReqStringSchema({ field: 'country' }),
  compensation: makeNumberSchema({
    field: 'compensation',
    allowedDecimals: 2,
    minNumber: 600,
  }),
  email: yup.string().nullable(),
  permissionToEmailForm: yup.boolean().nullable(),
  permissionToMailForm: yup.boolean().nullable(),
}

const compensationPopup = (
  <Popup
    content={
      <Grid>
        <GridRowColumn>
          <Text as="h3">How to calculate the total paid to a contractor</Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            You can check all transactions within your <b>Profit & Loss</b>{' '}
            related to a specific contractor. Then, you&apos;ll add up all of
            those transactions to get the sum total.
          </Text>
        </GridRowColumn>
      </Grid>
    }
    trigger={
      <Icon
        icon={regular('question-circle')}
        style={{ marginLeft: 8, color: Colors.mediumGray }}
      />
    }
  />
)

const payerStateIdPopup = (
  <Popup
    content={
      <Grid>
        <GridRowColumn>
          <Text as="h3">Payer State ID</Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            This is the identification number assigned by a state to the
            respective business and is required when the payer reports state tax
            withheld from the payee&apos;s income on their 1099 Forms.
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            If you live in Alabama, Delaware, Kansas, Mississippi, North Dakota,
            Oregon, or Pennsylvania, you&apos;ll need to enter your Payer State
            ID.
          </Text>
        </GridRowColumn>
      </Grid>
    }
    trigger={
      <Icon
        icon={regular('question-circle')}
        style={{ marginLeft: 8, color: Colors.mediumGray }}
      />
    }
  />
)

const NoMailModalConfirmation = ({
  validateNoMailingOpen,
  onClose,
  setValidatedNoMailingNeeded,
  setMailingToTrue,
}: {
  validateNoMailingOpen: boolean
  onClose: () => void
  setValidatedNoMailingNeeded: (arg0: boolean) => void
  setMailingToTrue: () => void
}) => {
  const onClicked = () => {
    setValidatedNoMailingNeeded(true)
    onClose()
  }

  return (
    <Modal
      open={validateNoMailingOpen}
      onClose={onClose}
      closeIcon
      size="small"
    >
      <Modal.Header>
        Are you sure you do not want Heard to mail this 1099-NEC Form for you?
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <GridRowColumn>
            <Card type="subsection" backgroundColor="natural">
              <Text as="bodyLg">
                The IRS requires that you send this 1099-NEC Form to your
                contractor(s) by Jan 31, 2025. We highly recommend allowing
                Heard to help you do so.
              </Text>
            </Card>
          </GridRowColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={onClicked}>
          No, I will mail the form to the contractor myself
        </Button>
        <Button
          onClick={() => {
            setMailingToTrue()
            onClicked()
          }}
        >
          Yes, I will allow Heard to mail the form for me
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const ContractorDetailForm = ({
  contractor,
  ten99,
  isEditState,
  isCorrectionState,
  tinError,
  goToPrevStep,
}: {
  contractor: AboundContractor
  ten99?: AboundTaxDocument
  isEditState: boolean
  isCorrectionState: boolean
  tinError: boolean
  goToPrevStep: () => void
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const payerProfile = useReselector(getCurrentAboundPayer)
  const [processingRequests, setProcessingRequests] = useState(false)
  const [ssnType, setSsnType] = useState('password')
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const [validateNoMailingOpen, setValidateNoMailingOpen] = useState(false)
  const [validatedNoMailingNeeded, setValidatedNoMailingNeeded] =
    useState(false)
  // const [updateMailingToTrue, setUpdateMailingToTrue] = useState(false)
  const [submitEditModalOpen, setSubmitEditModalOpen] = useState(false)
  const [submitCorrectionModalOpen, setSubmitCorrectionModalOpen] =
    useState(false)
  const needsPayerStateId = false
  // Dec 30 '24 decision to not require payer state id re:sunish
  // statesWithExtraRequirements.includes(
  //   payerProfile?.state || ''
  // )
  const verifiedTin =
    contractor.tinVerificationStatus === TIN_VERIFICATION_STATUS.match

  const validationSchema = useMemo(() => {
    if (verifiedTin && needsPayerStateId) {
      return yup.object({
        ...commonYupSchema,
        payerStateId: makeReqStringSchema({ field: 'payer state id' }),
        taxIdNumber: yup.string().nullable(),
      })
    } else if (needsPayerStateId) {
      return yup.object({
        ...commonYupSchema,
        payerStateId: makeReqStringSchema({ field: 'payer state id' }),
        taxIdNumber: makeNumberSchema({
          field: 'tax identification number',
          numDigits: 9,
          allowLeadingZero: true,
        }),
      })
    } else if (verifiedTin) {
      return yup.object({
        ...commonYupSchema,
        taxIdNumber: yup.string().nullable(),
      })
    } else {
      return yup.object({
        ...commonYupSchema,
        taxIdNumber: makeNumberSchema({
          field: 'tax identification number',
          numDigits: 9,
          allowLeadingZero: true,
        }),
      })
    }
  }, [needsPayerStateId, verifiedTin])
  const formik = useFormik({
    initialValues: {
      firstName: contractor.firstName || '',
      lastName: contractor.lastName || '',
      address: contractor.address || '',
      address2: contractor.address2 || '',
      city: contractor.city || '',
      state: contractor.state || '',
      postalCode: contractor.postalCode || '',
      country: contractor.country || 'US',
      taxIdNumber: '',
      payerStateId: ten99?.payerStateId || '',
      filingState: ten99?.filingState || '',
      businessName: contractor.businessName || '',
      compensation: centsToDollars(ten99?.compensationInCents) || 0,
      email: contractor.email || '',
      permissionToEmailForm: contractor.permissionToEmailForm || false,
      permissionToMailForm: contractor.permissionToMailForm || false,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: async ({
      firstName,
      lastName,
      businessName,
      address,
      address2,
      city,
      state,
      postalCode,
      country,
      taxIdNumber,
      compensation,
      payerStateId,
      email,
      permissionToEmailForm,
      permissionToMailForm,
    }) => {
      // Update contractor information and verify TIN
      const contractorProfileData = {
        firstName,
        lastName,
        address,
        address2,
        city,
        state,
        postalCode,
        country,
        taxIdNumber: taxIdNumber.replace(/\D/g, ''),
        email,
        permissionToEmailForm,
        permissionToMailForm,
      } as AboundContractor

      if (businessName && !isEmpty(businessName)) {
        contractorProfileData.businessName = businessName
      }
      const ten99Data = {
        compensationInCents: dollarsToCents(compensation),
        contractorProfileId: contractor.id,
        filingState: payerProfile?.state,
      } as AboundTaxDocumentDataToSend
      if (payerStateId) {
        ten99Data.payerStateId = payerStateId
      }

      setProcessingRequests(true)
      const updatedContractor = await updateAboundContractorRequest(
        contractor.id,
        contractorProfileData
      )(dispatch)
      let createOrUpdated1099 = null

      // If in correction state, submit a correction
      if (isCorrectionState) {
        createOrUpdated1099 = await correctAboundTaxDocumentRequest(
          ten99Data,
          ten99?.id
        )(dispatch)
        // If in Edit State or has existing ten99, update existing request
      } else if ((isEditState && ten99) || ten99?.id) {
        createOrUpdated1099 = await updateAboundTaxDocumentRequest(
          ten99.id,
          ten99Data
        )(dispatch)
        // Otherwise, create or update 1099 Form
      } else {
        createOrUpdated1099 =
          await createAboundTaxDocumentRequest(ten99Data)(dispatch)
      }
      setProcessingRequests(false)

      // If in the creation or update of a Contractor
      if (updatedContractor && createOrUpdated1099) {
        // If currently in edit step, we want to show confirmation of edit submission. Otherwise, continue on
        if (isEditState) {
          navigate('/taxes/annual/file_1099_nec/done?edit=true')
        } else if (isCorrectionState) {
          navigate('/taxes/annual/file_1099_nec/done?correction=true')
        } else {
          goToPrevStep()
        }
      }
    },
  })

  const { submitForm } = formik

  const setMailingToTrue = () => {
    formik.setFieldValue('permissionToMailForm', true)
  }

  const fieldNames = getFieldNames(formik)
  const processSubmission = () => {
    // If user did not enable Heard to mail 1099-NEC for user, double check
    if (!formik.values.permissionToMailForm && !validatedNoMailingNeeded) {
      setValidateNoMailingOpen(true)
    } else {
      submitForm()
    }
  }

  const actionButton = () => {
    if (isEditState) {
      const name = `${formik.values.firstName} ${formik.values.lastName}`
      return (
        <>
          <Button
            disabled={!formik.isValid || processingRequests}
            fullWidth
            onClick={submitForm}
          >
            {processingRequests ? (
              <Loader active inline="centered" />
            ) : (
              'Submit 1099-NEC Edit'
            )}
          </Button>
          {submitEditModalOpen && (
            <Edit1099ModalConfirmation
              open={submitEditModalOpen}
              onClose={() => setSubmitEditModalOpen(false)}
              submitForm={submitForm}
              isEditState
              taxYear={taxYear}
              contractorName={name}
            />
          )}
        </>
      )
    } else if (isCorrectionState) {
      const name = `${formik.values.firstName} ${formik.values.lastName}`
      return (
        <>
          <Button
            disabled={!formik.isValid || processingRequests}
            fullWidth
            onClick={() => setSubmitCorrectionModalOpen(true)}
          >
            {processingRequests ? (
              <Loader active inline="centered" />
            ) : (
              'Submit 1099-NEC Correction'
            )}
          </Button>
          {submitCorrectionModalOpen && (
            <Edit1099ModalConfirmation
              open={submitCorrectionModalOpen}
              onClose={() => setSubmitCorrectionModalOpen(false)}
              submitForm={submitForm}
              isCorrectionState
              taxYear={taxYear}
              contractorName={name}
            />
          )}
        </>
      )
    } else {
      return (
        <>
          <Button
            disabled={!formik.isValid || processingRequests}
            fullWidth
            onClick={processSubmission}
          >
            {processingRequests ? (
              <Loader active inline="centered" />
            ) : (
              'Confirm'
            )}
          </Button>
          {validateNoMailingOpen && (
            <NoMailModalConfirmation
              onClose={() => setValidateNoMailingOpen(false)}
              validateNoMailingOpen
              setValidatedNoMailingNeeded={() =>
                setValidatedNoMailingNeeded(true)
              }
              setMailingToTrue={() => setMailingToTrue()}
            />
          )}
        </>
      )
    }
  }

  const tinIdFormElement = () => {
    const tinIsPending =
      contractor.tinFingerprint &&
      contractor.tinVerificationStatus === TIN_VERIFICATION_STATUS.pending
    const isDisabled = verifiedTin || tinIsPending ? true : false
    return (
      <GridRowColumn width={16}>
        <FormikInput
          label="Tax Identification Number"
          description={
            <>
              If this contractor has a Business Name, enter their EIN.
              Otherwise, enter their SSN. Enter the number without any hyphens.
            </>
          }
          type={ssnType}
          disabled={isDisabled}
          error={tinError}
          placeholder="xxxxxxxxx"
          onRightIconClick={() =>
            setSsnType((type) => (type === 'password' ? 'text' : 'password'))
          }
          rightIcon={ssnType === 'password' ? 'eye' : 'eye slash'}
          name={fieldNames.taxIdNumber}
          fullWidth
          required
        />
        {tinIsPending && (
          <Text as="bodySm" color="orange">
            This contractor TIN verification is pending. You will receive an
            email once the TIN has been verified or if there is any issue with
            verification.
          </Text>
        )}
        {verifiedTin && (
          <Text as="bodyXs" color="green">
            We have verified this contractor&apos;s TIN information.
          </Text>
        )}
        {tinError && (
          <Text as="bodyXs" color="red">
            Please correct this contractor&apos;s TIN information.
          </Text>
        )}
      </GridRowColumn>
    )
  }
  return (
    <FormikProvider value={formik}>
      <GridRowColumn width={12}>
        <Card
          backgroundColor={
            isEditState || isCorrectionState ? 'natural' : 'stone40'
          }
        >
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <FormikInput
                  label="First Name"
                  name={fieldNames.firstName}
                  fullWidth
                  required
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <FormikInput
                  label="Last Name"
                  name={fieldNames.lastName}
                  fullWidth
                  required
                />
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn>
              <FormikInput
                label="Business Name"
                description={'If different from above.'}
                name={fieldNames.businessName}
                fullWidth
              />
            </GridRowColumn>
            {tinIdFormElement()}
            <Grid.Row>
              <Grid.Column width={16}>
                <FormikLocationSearchInput
                  label="Street Address"
                  placeholder="Street Address"
                  required
                  description={
                    'Please double-check that this matches the address on their W-9.'
                  }
                  fullWidth
                  name={fieldNames.address}
                  singleLineAddressOnly
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={6}>
                <FormikInput
                  label="City"
                  fullWidth
                  name={fieldNames.city}
                  required
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <FormikDropdown
                  label="State"
                  name={fieldNames.state}
                  options={STATES}
                  fullWidth
                  required
                  search
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <FormikInput
                  label="Zipcode"
                  componentType="number"
                  name={fieldNames.postalCode}
                  fullWidth
                  required
                  {...identificationNumberProps}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <FormikDropdown
                  label="Country"
                  name={fieldNames.country}
                  options={[{ text: 'United States', value: 'US' }]}
                  fullWidth
                  required
                />
              </Grid.Column>
            </Grid.Row>
            {needsPayerStateId && (
              <GridRowColumn width={5}>
                <FormikInput
                  label={<>Payer State ID{payerStateIdPopup}</>}
                  componentType="number"
                  name={fieldNames.payerStateId}
                  fullWidth
                  required
                  {...identificationNumberProps}
                />
              </GridRowColumn>
            )}
            <Grid.Row>
              <Grid.Column width={8}>
                <FormikInput
                  label={
                    <>
                      Amount Paid to Recipient in {taxYear}
                      {compensationPopup}
                    </>
                  }
                  componentType="currency"
                  name={fieldNames.compensation}
                  fullWidth
                  required
                />
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column width={16}>
                <FormikInput
                  label="Recipient Email"
                  description={
                    'Enter the email of the recipient. This enables us to notify the recipient of 1099-NEC Filing Statuses'
                  }
                  fullWidth
                  name={fieldNames.email}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                width={16}
                style={{
                  backgroundColor: Colors.lightOrange,
                  padding: '16px 24px',
                }}
              >
                <Text as="bodyMd" style={{ fontWeight: 500 }}>
                  Mail Physical 1099-NEC to Contractor {compensationPopup}
                </Text>
                <Text as="bodySm">
                  Select the checkbox below to enable Heard to mail the physical
                  1099-NEC to this contractor. We strongly recommend doing this.
                </Text>
                <br />
                <FormikCheckbox
                  label="I authorize Heard to physically mail the filed 1099-NEC form to this contractor."
                  name={fieldNames.permissionToMailForm}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card>
      </GridRowColumn>
      <Divider />
      <Ten99FilingErrors
        fetchKeys={[
          ABOUND_CONTRACTOR_UPDATE_KEY,
          ABOUND_TAX_FILINGS_CREATE_KEY,
          ABOUND_TAX_FILINGS_UPDATE_KEY,
        ]}
      />
      <Grid.Row>
        <Grid.Column width={2}>
          <Button variant="secondary" fullWidth onClick={goToPrevStep}>
            Back
          </Button>
        </Grid.Column>
        <Grid.Column width={10} />
        <Grid.Column width={4}>{actionButton()}</Grid.Column>
      </Grid.Row>
    </FormikProvider>
  )
}
const ContractorDetailAnd1099Panel = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { contractorUUID } = useParams<{ contractorUUID: string }>()
  const isEditState = location.pathname.endsWith('/edit')
  const isCorrectionState = location.pathname.endsWith('/correction')

  const taxYear = useReselector(selectCurrentAnnualTaxYear)

  const contractor = useReselector(
    selectAboundContractorsByUUID,
    contractorUUID
  )
  const ten99 = useReselector(
    getAboundTaxDocForPayerByContractorId,
    contractor?.id
  )

  useEffect(() => {
    dispatch(fetchAboundPayer())
    dispatch(fetchAboundContractors())
    dispatch(fetchAboundTaxDocumentsForPayer())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
  }, [contractor?.id, contractorUUID, dispatch])

  const fetchingPayer = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ABOUND_PAYER_KEY
  )
  const fetchingAnnualTaxDetails = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ALL_ANNUAL_TAX_DETAILS_KEY
  )

  const contractorFromPast = useReselector(
    selectContractorIsFromPreviousTaxYears,
    contractor?.uuid
  )

  const content = useMemo(() => {
    const tinError =
      contractor?.tinVerificationStatus === TIN_VERIFICATION_STATUS.mismatch
    if (contractor) {
      const contractorDetailTitle = () => {
        if (isEditState) {
          return `Editing ${taxYear} Form 1099-NEC for ${contractor?.firstName} ${contractor?.lastName}`
        } else if (isCorrectionState) {
          return `Correcting ${taxYear} Form 1099-NEC for ${contractor?.firstName} ${contractor?.lastName}`
        } else {
          return `1099-NEC: ${contractor?.firstName} ${contractor?.lastName}`
        }
      }

      const contractorSubtitle = () => {
        if (isEditState) {
          return 'Please edit the information below for this contractor, to submit an edit to their 1099-NEC filing.'
        } else if (isCorrectionState) {
          return 'Please update the information below for this contractor, to submit a correction to their 1099-NEC filing. This would void the previous filing and resubmit it to the IRS.'
        } else if (contractorFromPast) {
          return 'Please update the information below for this contractor. This should match the information in their W-9. For security purposes, we are unable to store the TIN. You will need to re-enter it below.'
        } else {
          return 'Please enter the information below for this contractor. This should match the information in their W-9.'
        }
      }
      return (
        <Grid centered>
          {/* <Ten99FilingErrors
            fetchKeys={[FETCH_ABOUND_CONTRACTOR_DETAIL_KEY(contractor.id)]}
          /> */}
          <GridRowColumn width={12}>
            <div style={{ textAlign: 'center', marginBottom: -12 }}>
              <Icon
                icon={regular('circle-user')}
                color={'green'}
                style={{ height: 64 }}
              />
            </div>
          </GridRowColumn>
          <GridRowColumn width={12}>
            <Text textAlign="center" as="h1">
              {contractorDetailTitle()}
            </Text>
            <Text textAlign="center" style={{ marginTop: 32 }}>
              {contractorSubtitle()}
            </Text>
          </GridRowColumn>
          {tinError && (
            <GridRowColumn width={12}>
              <Alert type="error">
                The provided Tax Identification Number (TIN) does not match IRS
                records. Please verify the contractor&apos;s name and/or TIN
                information and try again.
              </Alert>
            </GridRowColumn>
          )}
          <ContractorDetailForm
            ten99={ten99}
            isEditState={isEditState}
            isCorrectionState={isCorrectionState}
            tinError={tinError}
            goToPrevStep={() =>
              navigate('/taxes/annual/file_1099_nec/contractors')
            }
            contractor={contractor}
          />
        </Grid>
      )
    }
    return null
  }, [
    contractor,
    contractorFromPast,
    isCorrectionState,
    isEditState,
    navigate,
    taxYear,
    ten99,
  ])

  const enable1099nec = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enable1099nec,
    false
  )
  if (!enable1099nec) {
    navigate('/taxes/annual')
  }

  return (
    <File1099sPage
      isLoading={fetchingPayer || fetchingAnnualTaxDetails}
      contentHeader={<Text as="h3">File 1099-NECs</Text>}
      content={content}
      currentStep={1}
    />
  )
}

export default ContractorDetailAnd1099Panel
