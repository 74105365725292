import { Card, Text } from '../../../../components/BaseComponents'
import './styles.scss'

export interface BookkeeperNoteProps {
  note?: string
}
const BookkeeperNote = ({ note }: BookkeeperNoteProps) => {
  if (!note) return null

  return (
    <Card
      backgroundColor="lightOrange"
      className="eoy-final-review__bookkeeper-note"
    >
      <Text as="h3">Note from Bookkeeper</Text>
      <Text
        as="bodyMd"
        color="orange"
        className="eoy-final-review__bookkeeper-note__text"
      >
        {note}
      </Text>
    </Card>
  )
}

export default BookkeeperNote
