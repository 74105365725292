import { useCallback, useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import {
  Table,
  Loader,
  Button,
  Checkbox,
  Icon,
  Popup,
  Dimmer,
} from 'semantic-ui-react'
import CurrencyFormatLabel from '../shared/CurrencyFormatLabel'
import Truncate from 'react-truncate'

import BulkTransactionActions from './shared/BulkTransactionActions'
import ConfirmTransactionButton from './shared/ConfirmTransactionButton'
import NeedsClarificationButton from './shared/NeedsClarificationButton'
import TransactionCategoryDropdown from './shared/TransactionCategoryDropdown'
import DeleteTransactionButton from './shared/DeleteTransactionButton'
import TransactionTypeButton from './shared/TransactionTypeButton'
import TransactionHeaderCheckbox from './shared/TransactionHeaderCheckbox'
import TransactionFilters from './TransactionFilters'
import {
  REQUEST_ADMIN_USER_DOCUMENTS_KEY,
  fetchAdminUsersDocuments,
} from '../../actions/admin/userDocumentsActions'
import {
  FETCH_FILTERED_TRANSACTIONS_KEY,
  fetchFilteredTransactions,
} from '../../actions/adminActions'
import {
  FETCH_TRANSACTION_CATEGORIES_KEY,
  fetchTransactionCategoriesIfNeeded,
} from '../../features/Reports/reports.slice'
import {
  getTransactionSelector,
  getUserFinancialAccountById,
} from '../../selectors/financeSelectors'
import TransactionTableExpandedRow from './UserTransactionsPanels/TransactionTableExpandedRow'
import ManualTransactionActions from './shared/ManualTransactionActions'
import { SplitTransactionIcon } from './shared/SplitTransaction/SplitTransactionModule'
import TransactionCategoryLabel from '../shared/TransactionCategoryLabel'
import './TransactionTable.scss'
import {
  selectIsNotesTab,
  selectIsTableExpandable,
  selectTransactionFilterQuery,
  selectTransactionFilters,
  TRANSACTIONS_SORT_BY,
  updateTransactionFilters,
} from '../../reducers/finances/transactionFiltersReducer'
import {
  getSelectedByTransactionId,
  shiftSelect,
  updateSelectedTransaction,
} from '../../reducers/admin/selectedTransactionsReducer'
import { Transaction } from '../../reducers/admin/allTransactions.slice'
import { centsToDollars } from '../../utils/currencyHelpers'

import TransactionChangeLogsPanel, {
  TransactionChangeLogToggleButton,
} from '../../features/Admin/TransactionChangeLogs/TransactionChangeLogsPanel'
import {
  formatTransactionAccountName,
  isTransactionLocked,
} from '../../features/Transactions/helpers'
import { getLockedTransactionDescription } from '../../features/Transactions/copyConstants'
import AdminNotesModal from '../../features/Admin/Transactions/AdminNotesModal'
import {
  REQUEST_USER_FINANCIAL_ACCOUNTS_KEY,
  fetchUserFinancialAccounts,
} from '../../actions/admin/adminFinancialAccountActions'
import TransactionPagination from '../Finances/TransactionsPanel/TransactionPagination'
import TransactionJournalEntriesPanel from '../../features/JournalEntries/components/TransactionJournalEntriesPanel'
import { useReselector } from '../../utils/sharedHooks'
import {
  selectAdminQueriedTransactionIds,
  selectAdminQueriedTransactionsCount,
} from '../../features/Transactions/transactions.selectors'
import { selectDocumentsByUserId } from '../../features/UserDocuments/userDocuments.selector'
import { DATE_FORMATS } from '../../utils/dateHelpers'
import AdminExportTransactionCsv from '../AdminExportTransactionCsv'
import { Colors, Fonts } from '../../styles/theme'
import { selectIsFetchingForKeys } from '../../reducers/fetch'
import { selectCurrentAnnualTaxYear } from '../../features/Admin/AnnualTaxDetails/annualTaxDetails.selector'
import MarkedDuplicateTransactionButton from './shared/MarkedDuplicateTransactionButton'
import { useAppDispatch } from '../../utils/typeHelpers'
import { adminGetSubmission } from '../../features/Bookkeeping/end-of-year-review/actions'
import { DateTime } from 'luxon'

const TransactionAttachment = ({
  transaction,
}: {
  transaction: Transaction
}) => {
  const documents = useReselector(selectDocumentsByUserId, transaction.userId)

  return transaction.receipts?.map((receipt) => {
    const doc = documents?.find((document) => document.id === receipt.id)
    if (!doc?.signedUrl) {
      return null
    }
    return (
      <p key={receipt.id}>
        <a href={doc.signedUrl} target="_blank" rel="noopener noreferrer">
          <Popup
            content={`File Name: ${doc.fileDescription}`}
            trigger={<Truncate>{doc.fileDescription}</Truncate>}
          />
        </a>
      </p>
    )
  })
}

const JournalTableToggleButton = ({ onClick }: { onClick: () => void }) => (
  <Popup
    hoverable
    style={{ textAlign: 'center' }}
    position="top right"
    content="View journal table"
    trigger={
      <Button basic icon onClick={onClick}>
        <Icon name="list alternate outline" />
      </Button>
    }
  />
)

export const TransactionTableRow = ({
  transactionId,
}: {
  transactionId: number
}) => {
  const dispatch = useAppDispatch()
  const selected = useReselector(getSelectedByTransactionId, transactionId)
  const transaction = useReselector(getTransactionSelector, transactionId)
  const userId = transaction?.userId

  const financialAccount = useReselector(
    getUserFinancialAccountById,
    userId,
    transaction?.financialAccountId
  )

  // Separated out from selected for more responsive UI
  const [checked, setChecked] = useState(selected || false)
  const [splitExpanded, setSplitExpanded] = useState(false)
  const [changeLogExpanded, setChangeLogExpanded] = useState(false)
  const [journalTableExpanded, setJournalTableExpanded] = useState(false)
  const [adminNotesModalOpen, setAdminNotesModalOpen] = useState(false)

  const handleCheckboxSelect = useCallback(
    (shiftKey: boolean) => {
      const newCheckedValue = !checked

      setChecked(newCheckedValue)
      if (shiftKey) {
        dispatch(shiftSelect(transactionId))
      }
      dispatch(
        updateSelectedTransaction({
          transactionId,
          value: newCheckedValue,
        })
      )
    },
    [checked, dispatch, transactionId]
  )

  useEffect(() => {
    setChecked(selected)
  }, [selected])

  if (!transaction) {
    return null
  }

  const rowNegative = !transaction.type
  const rowWarning = transaction.type && !transaction.confirmedAt
  const rowPositive = transaction.confirmedAt !== null

  const locked = isTransactionLocked({
    date: transaction.date,
    userLockedYear: transaction.user?.booksLockedForUserYear,
    adminLockedYear: transaction.user?.booksLockedForAdminYear,
    isAdmin: true,
  })

  return (
    <>
      <Table.Row
        negative={rowNegative}
        warning={Boolean(rowWarning)}
        positive={rowPositive}
        disabled={locked}
      >
        <Table.Cell>
          <div className="flexbox">
            {locked && (
              <Popup
                content={
                  getLockedTransactionDescription()
                    .adminTransactionRowLockIconTooltip
                }
                trigger={
                  <Icon
                    name="lock"
                    size="small"
                    style={{ pointerEvents: 'auto', color: 'rgba(0,0,0,1)' }}
                  />
                }
              />
            )}
            <Checkbox
              checked={checked}
              disabled={Boolean(transaction.confirmedAt)}
              onClick={(e) =>
                // for some reason, being disabled doesn't mean the onClick is not triggered :shrug:
                !transaction.confirmedAt && handleCheckboxSelect(e.shiftKey)
              }
            />
          </div>
        </Table.Cell>
        <Table.Cell>
          {transaction.splitTransactions &&
            transaction.splitTransactions?.length > 0 && (
              <Popup
                hoverable
                style={{ textAlign: 'center' }}
                position="top left"
                content={
                  !splitExpanded
                    ? 'You can view this split transaction by clicking here'
                    : 'Hide the split transactions'
                }
                trigger={
                  <Button
                    color="teal"
                    className={
                      splitExpanded ? 'active custom-chevron' : 'custom-chevron'
                    }
                    icon
                    onClick={() => setSplitExpanded(!splitExpanded)}
                  >
                    <Icon
                      style={{ mouseEvents: 'none' }}
                      name={splitExpanded ? 'chevron up' : 'chevron down'}
                    />
                  </Button>
                }
              />
            )}
          {transaction.id}
        </Table.Cell>
        <Table.Cell>
          {transaction.user
            ? `${transaction.user.firstName} ${transaction.user.lastName}`
            : transaction.userId}
        </Table.Cell>
        <Table.Cell>
          <Moment format={DATE_FORMATS.DISPLAY_SHORT} utc>
            {transaction.date}
          </Moment>
        </Table.Cell>
        <Table.Cell>
          {transaction.accountTransactions && (
            <span>
              {formatTransactionAccountName(transaction, financialAccount)}
            </span>
          )}
          {!transaction.accountTransactions && <span>N/A</span>}
        </Table.Cell>
        <Table.Cell textAlign="right">
          <CurrencyFormatLabel
            value={centsToDollars(transaction.amountInCents)}
          />
        </Table.Cell>
        <Table.Cell>
          {transaction.manuallyCreated && (
            <Icon
              name="upload"
              style={{ pointerEvents: 'auto', color: 'rgba(0,0,0,1)' }}
            />
          )}
          <span>{transaction.description}</span>
          <div className="adminNotes">
            {transaction.adminNotes && (
              <>
                <b>
                  <u>Admin Note:</u>
                </b>

                <Button
                  basic
                  className="link"
                  onClick={() => setAdminNotesModalOpen(true)}
                >
                  {transaction.adminNotes.length > 10 ? (
                    <Popup
                      hoverable
                      position="bottom left"
                      content={transaction.adminNotes}
                      trigger={
                        <u>{transaction.adminNotes.substring(10, 0)}...</u>
                      }
                    />
                  ) : (
                    <u>{transaction.adminNotes}</u>
                  )}
                </Button>
              </>
            )}
            {!transaction.adminNotes && (
              <Button
                primary
                className="link adminNotes"
                onClick={() => setAdminNotesModalOpen(true)}
              >
                <u>Add admin note</u>
              </Button>
            )}
          </div>
        </Table.Cell>

        <Table.Cell>
          <p>{transaction.notes}</p>
          {transaction.notesLastUpdatedAt && (
            <p style={{ color: Colors.mediumGray, ...Fonts.bodyXs }}>
              <i>
                <Moment format={DATE_FORMATS.DISPLAY_SHORT} utc>
                  {transaction.notesLastUpdatedAt}
                </Moment>
              </i>
            </p>
          )}
        </Table.Cell>
        <Table.Cell>{transaction.nickname}</Table.Cell>
        <Table.Cell>
          <TransactionTypeButton transaction={transaction} />
        </Table.Cell>
        <Table.Cell>
          <TransactionCategoryDropdown transaction={transaction} />
        </Table.Cell>
        <Table.Cell>
          <TransactionAttachment transaction={transaction} />
          {/*To prevent the fetching of many files, we disable for All Transactions table*/}
          {!userId && <span>docs only viewed in user view</span>}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {transaction.manuallyDedupedAt && (
            <MarkedDuplicateTransactionButton transaction={transaction} />
          )}
          <div className="actionsCellContainer">
            {process.env.VITE_HIDE_TRXN_CONFIRM_BUTTON !== 'true' ? (
              <ConfirmTransactionButton transaction={transaction} />
            ) : null}
            <NeedsClarificationButton transaction={transaction} />
            <SplitTransactionIcon transaction={transaction} />
            <ManualTransactionActions transaction={transaction} />
            <TransactionChangeLogToggleButton
              onClick={() => setChangeLogExpanded((prev) => !prev)}
            />
            <JournalTableToggleButton
              onClick={() => setJournalTableExpanded((prev) => !prev)}
            />
          </div>
          <DeleteTransactionButton transaction={transaction} />
        </Table.Cell>
        <AdminNotesModal
          modalOpen={adminNotesModalOpen}
          setModalOpen={setAdminNotesModalOpen}
          transaction={transaction}
        />
      </Table.Row>
      {splitExpanded && renderSplitExpanded(transaction)}
      {changeLogExpanded && (
        <TransactionChangeLogsPanel transactionId={Number(transaction.id)} />
      )}

      {/* Journal entries for a non-split transaction */}
      {journalTableExpanded && !transaction.splitAt && (
        <Table.Row>
          <Table.Cell colSpan="16">
            <TransactionJournalEntriesPanel transaction={transaction} />
          </Table.Cell>
        </Table.Row>
      )}

      {/* Journal entries for a split transaction */}
      {journalTableExpanded &&
        transaction.splitAt &&
        transaction.splitTransactions?.map((t) => (
          <Table.Row key={`journal-table-${t.id}`}>
            <Table.Cell colSpan="16">
              <TransactionJournalEntriesPanel transaction={t} />
            </Table.Cell>
          </Table.Row>
        ))}
    </>
  )
}

// Originally moved into /shared directory, but html col structure is not identical across all files
// making it difficult to reuse as currently implemented
const renderSplitExpanded = (transaction: Transaction) =>
  transaction.splitTransactions?.map((t, index) => (
    <Table.Row
      key={`split-transaction-${t.id}`}
      className="expandable-transaction-row"
    >
      <Table.Cell />
      <Table.Cell />
      <Table.Cell />
      <Table.Cell />
      <Table.Cell />
      <Table.Cell />
      <Table.Cell>
        <span
          className={
            transaction.splitTransactions &&
            index === transaction.splitTransactions.length - 1
              ? 'half-height'
              : ''
          }
        />
        {t.description}
      </Table.Cell>
      <Table.Cell textAlign="right">
        <CurrencyFormatLabel
          value={t ? centsToDollars(t.amountInCents) : null}
        />
      </Table.Cell>
      <Table.Cell>{t.type}</Table.Cell>
      <Table.Cell>
        <TransactionCategoryLabel transaction={t} />
      </Table.Cell>
      <Table.Cell>{t.notes}</Table.Cell>
      <Table.Cell />
    </Table.Row>
  ))

const TransactionTable = ({ userId }: { userId?: number }) => {
  const dispatch = useAppDispatch()

  let fetchKeys = [
    FETCH_TRANSACTION_CATEGORIES_KEY,
    FETCH_FILTERED_TRANSACTIONS_KEY,
  ]
  if (userId) {
    fetchKeys = fetchKeys.concat([
      REQUEST_USER_FINANCIAL_ACCOUNTS_KEY(userId),
      REQUEST_ADMIN_USER_DOCUMENTS_KEY(userId),
    ])
  }
  const loading = useReselector(selectIsFetchingForKeys, fetchKeys)

  const queriedTransactionIds = useReselector(selectAdminQueriedTransactionIds)
  const queriedTransactionsCount = useReselector(
    selectAdminQueriedTransactionsCount
  )
  const isTableExpandable = useReselector(selectIsTableExpandable)
  const transactionFilters = useReselector(selectTransactionFilters)
  const query = useReselector(selectTransactionFilterQuery)
  const isNotesTab = useReselector(selectIsNotesTab)
  // This is used to fetch date that the tax checklist launches
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)

  useEffect(() => {
    if (!userId) return

    adminGetSubmission(
      userId,
      currentTaxYear
    )(dispatch).then((submission) => {
      return dispatch(
        updateTransactionFilters({
          currentTaxYear,
          lastReviewDate:
            submission?.lastBkSubmittedAt ??
            submission?.createdAt ??
            DateTime.utc().minus({ days: 7 }).toJSDate().toISOString(),
        })
      )
    })
  }, [dispatch, userId, currentTaxYear])

  useEffect(() => {
    const fetch = async () => {
      await Promise.all([
        userId && dispatch(fetchUserFinancialAccounts(userId)),
        userId && dispatch(fetchAdminUsersDocuments(userId)),
        dispatch(fetchTransactionCategoriesIfNeeded()),
      ])
    }

    fetch()
  }, [dispatch, userId])

  useEffect(() => {
    if (query) {
      dispatch(fetchFilteredTransactions(query))
    }
  }, [dispatch, query])

  const sortColumn = useCallback(
    (sortColumnName: string) => {
      const { sortVal, sortAsc } = transactionFilters
      dispatch(
        updateTransactionFilters({
          sortVal: sortColumnName,
          sortAsc: sortColumnName === sortVal ? !sortAsc : false,
        })
      )
    },
    [dispatch, transactionFilters]
  )

  const tableRows = useMemo(() => {
    if (!queriedTransactionsCount) {
      return (
        <Table.Row>
          <Table.Cell colSpan={11}>No transactions found</Table.Cell>
        </Table.Row>
      )
    }
    return queriedTransactionIds.map((key) =>
      isTableExpandable ? (
        <TransactionTableExpandedRow key={key} transactionId={key} />
      ) : (
        <TransactionTableRow key={key} transactionId={key} />
      )
    )
  }, [isTableExpandable, queriedTransactionIds, queriedTransactionsCount])

  const getSorted = useCallback(
    (name: string) => {
      const { sortVal, sortAsc } = transactionFilters
      return name === sortVal
        ? sortAsc
          ? 'ascending'
          : 'descending'
        : undefined
    },
    [transactionFilters]
  )

  return (
    <div className="transactionTable">
      {userId && <AdminExportTransactionCsv />}

      <TransactionFilters userId={userId} />

      {isNotesTab && (
        <h3>
          Notes left after confirmation{' '}
          {queriedTransactionsCount
            ? queriedTransactionsCount > 0
              ? `(${queriedTransactionsCount})`
              : ''
            : ''}
        </h3>
      )}

      <br />

      <BulkTransactionActions />
      {loading && (
        <Dimmer active inverted>
          <Loader active inline="centered">
            Loading Transactions
          </Loader>
        </Dimmer>
      )}

      {!loading && (
        <>
          <Table sortable striped compact="very" size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <TransactionHeaderCheckbox />
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSorted(TRANSACTIONS_SORT_BY.ID)}
                  onClick={() => sortColumn(TRANSACTIONS_SORT_BY.ID)}
                >
                  Id
                </Table.HeaderCell>
                <Table.HeaderCell className="noSort">User</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSorted(TRANSACTIONS_SORT_BY.DATE)}
                  onClick={() => sortColumn(TRANSACTIONS_SORT_BY.DATE)}
                >
                  Date
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSorted(TRANSACTIONS_SORT_BY.ACCOUNT)}
                  onClick={() => sortColumn(TRANSACTIONS_SORT_BY.ACCOUNT)}
                >
                  Account Info
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSorted(TRANSACTIONS_SORT_BY.AMOUNT)}
                  onClick={() => sortColumn(TRANSACTIONS_SORT_BY.AMOUNT)}
                >
                  Amount
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSorted(TRANSACTIONS_SORT_BY.DESCRIPTION)}
                  onClick={() => sortColumn(TRANSACTIONS_SORT_BY.DESCRIPTION)}
                >
                  Description
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSorted(TRANSACTIONS_SORT_BY.NOTES)}
                  onClick={() => sortColumn(TRANSACTIONS_SORT_BY.NOTES)}
                >
                  Notes
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSorted(TRANSACTIONS_SORT_BY.NICKNAME)}
                  onClick={() => sortColumn(TRANSACTIONS_SORT_BY.NICKNAME)}
                >
                  Nickname
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSorted(TRANSACTIONS_SORT_BY.TYPE)}
                  onClick={() => sortColumn(TRANSACTIONS_SORT_BY.TYPE)}
                >
                  Expense Type
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={4}
                  sorted={getSorted(TRANSACTIONS_SORT_BY.CATEGORY)}
                  onClick={() => sortColumn(TRANSACTIONS_SORT_BY.CATEGORY)}
                >
                  Category
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>Files</Table.HeaderCell>
                <Table.HeaderCell className="noSort">Actions</Table.HeaderCell>
                {isTableExpandable && <Table.HeaderCell className="noSort" />}
              </Table.Row>
            </Table.Header>
            <Table.Body>{tableRows}</Table.Body>
          </Table>

          <TransactionPagination
            transactionsCount={queriedTransactionsCount || 0}
          />
        </>
      )}

      <br />
      <br />
      <br />
    </div>
  )
}

export default TransactionTable
