import { ReactNode } from 'react'

import { Button, Table } from '../../../../components/BaseComponents'
import {
  AnnualTaxDetail,
  AnnualTaxDetailStatuses,
} from '../annualTaxDetails.slice'
import {
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../../utils/dateHelpers'
import { AnnualTaxDateType } from '../annualTaxDateTypes.slice'

interface Props {
  details: AnnualTaxDetail
  dateTypes?: AnnualTaxDateType[]
  onEditClick: () => void
}

const StyledCell = ({
  children,
  status,
}: {
  children: ReactNode
  status: AnnualTaxDetailStatuses
}) => (
  <Table.Cell positive={status === AnnualTaxDetailStatuses.current}>
    {children}
  </Table.Cell>
)

const TimeCell = ({
  status,
  date,
}: {
  status: AnnualTaxDetailStatuses
  date?: string
}) => (
  <StyledCell status={status}>
    {formatISOFromUTC(date, DATE_FORMATS_LUXON.DISPLAY_ABBR)}
  </StyledCell>
)

const AnnualTaxDetailsRow = ({
  details,
  dateTypes = [],
  onEditClick,
}: Props) => (
  <Table.Row>
    <StyledCell status={details.status}>
      {details.status !== AnnualTaxDetailStatuses.past && (
        <Button icon="pencil" onClick={onEditClick} />
      )}
    </StyledCell>
    <StyledCell status={details.status}>{details.taxYear}</StyledCell>
    <StyledCell status={details.status}>{details.status}</StyledCell>
    {dateTypes.map((dateType) => (
      <TimeCell
        key={dateType.id}
        status={details.status}
        date={details[dateType.id]}
      />
    ))}
    <TimeCell status={details.status} date={details.updatedAt} />
  </Table.Row>
)

export default AnnualTaxDetailsRow
