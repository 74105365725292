import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FinancialAccount } from '../finances/financialAccountsReducer'
import {
  receiveSingleReconciliation,
  receiveReconciliations,
  FinancialAccountReconciliationWithTransactions,
} from './allFinancialAccountReconciliationsReducer'

export interface PlaidItem {
  id: number
  userId: number
  itemId: string
  needsReconnection: boolean
  disconnectedOn?: string
  connectedOn: string
  errorObject?: {
    display_message: string | null
    documentation_url: string
    error_code: string
    error_message: string
    error_type: string
    request_id: string
    suggested_action: null
  }
  institutionName: string
  institutionId: string
  createdAt: string
  updatedAt: string
}

// This is duplicated redux with allFinancialAccountReconciliations and should be combined
export interface Reconciliation {
  id: number
  endingBalanceDate: string
  accountId: number
  endingBalanceInCents: number
  startingBalanceDate: string
  startingBalanceInCents: number
  status: 'complete' | 'incomplete' | 'blocked'
  createdAt: string
  updatedAt: string
  type: 'manual' | 'auto'
  endingFinancialAccountBalanceId: number | undefined
  bookkeepingReportId: number | undefined
}

export interface ManualAccount {
  id: number
  name: string
  financialProfile: number
  financialAccountId: number
  createdAt: string
  updatedAt: string
  inactive: boolean
}

// Admin accounts comes back with more information
export interface FinancialAccountWithAdminInfo
  extends Omit<FinancialAccount, 'accounts'> {
  reconciliations: Reconciliation[]
  accounts: PlaidItem | null
  manualAccount: ManualAccount | null
}

export interface AdminFinancialAccountState {
  byUserId: {
    [key: number]: { [key: number]: FinancialAccountWithAdminInfo | undefined }
  }
}

const initialState: AdminFinancialAccountState = {
  byUserId: {},
}

const updateReconciliationForAccount = (
  state: AdminFinancialAccountState,
  reconciliation: FinancialAccountReconciliationWithTransactions
) => {
  // This is pretty messy but it updates the reconciliations for a particular financial account.
  // Since we don't have the user id we have to go through all accounts to check
  for (const value of Object.values(state.byUserId)) {
    const financialAccount = value?.[reconciliation.accountId]
    if (financialAccount) {
      const newReconciliations = financialAccount.reconciliations
      const index = newReconciliations.findIndex(
        (r) => r.id === reconciliation.id
      )

      if (index > -1) {
        newReconciliations[index] = reconciliation
      } else {
        newReconciliations.unshift(reconciliation)
      }

      financialAccount.reconciliations = newReconciliations
    }
  }
}

const financialProfileSlice = createSlice({
  name: 'financialAccounts',
  initialState,
  reducers: {
    receiveUserFinancialAccounts: (
      state,
      action: PayloadAction<{
        accounts: {
          [p: string]: FinancialAccountWithAdminInfo
        }
        userId: number
      }>
    ) => {
      state.byUserId[action.payload.userId] = action.payload.accounts
    },
    receiveUserFinancialAccount: (
      state,
      action: PayloadAction<{
        userId: number
        account: FinancialAccountWithAdminInfo
      }>
    ) => {
      state.byUserId[action.payload.userId][action.payload.account.id] =
        action.payload.account
    },
    receiveAdminDeleteUserFinancialAccount: (
      state,
      action: PayloadAction<{
        userId: number
        deletedId: number
      }>
    ) => {
      delete state.byUserId[action.payload.userId][action.payload.deletedId]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(receiveSingleReconciliation, (state, action) =>
      updateReconciliationForAccount(state, action.payload)
    )

    builder.addCase(receiveReconciliations, (state, action) => {
      for (const reconciliation of Object.values(action.payload)) {
        updateReconciliationForAccount(state, reconciliation)
      }
    })
  },
})

export default financialProfileSlice.reducer

export const {
  receiveUserFinancialAccounts,
  receiveUserFinancialAccount,
  receiveAdminDeleteUserFinancialAccount,
} = financialProfileSlice.actions
