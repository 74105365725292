import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { UserDocument } from '../../../../UserDocuments/userDocuments.slice'
import Input from '../../../../../components/BaseComponents/Input'
import {
  centsToDollars,
  dollarsToCents,
} from '../../../../../utils/currencyHelpers'
import { useDebounce } from '../../../../../utils/sharedHooks'
import {
  EndOfYearReviewStepStatus,
  Document,
  EndOfYearAdminReviewStep,
  PreviousYearReturnStep,
} from '../../types'
import { replaceDocument } from '../helpers'
import { AdminYearEndReviewContext } from '../context'
import { useAdminDocuments } from '../hooks'
import BookkeepingReviewSection from '../bookkeeping-review-section'
import { DocumentRows, DocumentRow } from '../document-row'
import OtherDocuments from '../other-documents'
import StepNotRelevant from '../step-not-relevant'
import './styles.scss'

const { pending, complete } = EndOfYearReviewStepStatus
const STEP_TYPE = EndOfYearAdminReviewStep.docsPreviousTaxReturn

export type PreviousTaxReturnSectionProps = {
  step: PreviousYearReturnStep | null
  userDocuments: UserDocument[]
}
const PreviousTaxReturnSection = ({
  step,
  userDocuments,
}: PreviousTaxReturnSectionProps) => {
  const {
    taxYear,
    updateStep,
    priorYearRetainedEarnings,
    priorYearRetainedEarningsOnChange,
  } = useContext(AdminYearEndReviewContext)

  const [localPriorYearRE, setLocalPriorYearRE] = useState<string>('')
  const debouncedPriorYearRE = useDebounce(localPriorYearRE, 600)

  const {
    getFileIfExists,
    sortedDocuments,
    updateStepUsing,
    hasDocuments,
    updatedByUser,
  } = useAdminDocuments<Document>({
    step: STEP_TYPE,
    stepContextDocuments: step?.context?.documents,
    userDocuments,
  })

  const onConfirmClick = useCallback(() => {
    const status = step?.status === complete ? pending : complete
    const documents =
      status === complete
        ? step?.context?.documents?.map((document) => ({
            ...document,
            requestReUpload: false,
            adminNote: '',
          }))
        : undefined
    updateStep({
      status,
      step: STEP_TYPE,
      context: documents ? { ...step?.context, documents } : undefined,
    })
  }, [step, updateStep])

  const previousYear = useMemo(() => parseInt(taxYear) - 1, [taxYear])

  useEffect(() => {
    setLocalPriorYearRE((prev) => {
      if (priorYearRetainedEarnings) {
        const value = centsToDollars(priorYearRetainedEarnings).toString()
        return prev === value ? prev : value
      }
      return ''
    })
  }, [priorYearRetainedEarnings])

  useEffect(() => {
    if (debouncedPriorYearRE) {
      priorYearRetainedEarningsOnChange(dollarsToCents(debouncedPriorYearRE))
    }
  }, [debouncedPriorYearRE, priorYearRetainedEarningsOnChange])

  return (
    <BookkeepingReviewSection
      sectionTitle={`${previousYear} Business Tax Return`}
      className="eoy-admin__previous-year-return"
      status={step?.status ?? EndOfYearReviewStepStatus.pending}
      updatedByUser={updatedByUser}
      confirmationTitle="Adjustments completed"
      confirmationDescription=""
      minHeight={70}
      testId="prior-year-tax-return-documents"
      onConfirmClick={step ? onConfirmClick : undefined}
    >
      {step ? (
        <>
          <DocumentRows>
            {sortedDocuments.map((doc) => (
              <DocumentRow
                key={`tax_return_${doc.documentId}`}
                description={`${previousYear} 1120-S`}
                document={doc}
                file={getFileIfExists(doc)}
                testId={`tax_return_${doc.documentId}`}
                onRequestReUpload={(updatedDoc) => {
                  updateStepUsing({
                    ...step.context,
                    documents: replaceDocument(
                      updatedDoc,
                      step.context.documents
                    ),
                  })
                }}
              />
            ))}
          </DocumentRows>

          <OtherDocuments
            userDocuments={userDocuments.filter((ud) =>
              step.context?.otherDocumentIds?.some((id) => id === ud.id)
            )}
            showHeader
          />

          {!hasDocuments && (
            <StepNotRelevant text="User did not upload any documents." />
          )}

          <Input
            className="eoy-admin__previous-year-return__retained_earnings"
            label={`${previousYear} RE Value`}
            componentType="currency"
            value={localPriorYearRE}
            onChange={setLocalPriorYearRE}
            placeholder="Enter value"
          />
        </>
      ) : (
        <StepNotRelevant />
      )}
    </BookkeepingReviewSection>
  )
}

export default PreviousTaxReturnSection
