import { useEffect, useState } from 'react'
import { Loader } from 'semantic-ui-react'

import { fetchAnnualTaxFormTypesIfNeeded } from '../annualTaxFormType.slice'
import { fetchAnnualTaxFilingStatusTypesIfNeeded } from '../annualTaxFilingStatusType.slice'
import { fetchAnnualTaxFilingsIfNeeded } from '../annualTaxFilings.slice'
import './AnnualTaxOverview.scss'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { fetchUserDocuments } from '../../../UserDocuments/userDocuments.slice'
import {
  fetchSubscriptions,
  selectIsBasicPlan,
} from '../../../../reducers/subscription.slice'
import { useReselector } from '../../../../utils/sharedHooks'
import UpgradeToPremiumModal from '../../../../components/shared/UpgradeToPremium/UpgradeToPremiumModal'
import { fetchAnnualTaxFilingFormsIfNeeded } from '../annualTaxFilingForms.slice'
import { fetchUserDocumentCategoriesIfNeeded } from '../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { fetchTaxUserDocumentsIfNeeded } from '../taxUserDocuments.slice'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { fetchAllEoyReviewStepsIfNeeded } from '../TaxChecklist/Shared/ReviewStepsandProgresses/allEoyReviewSteps.slice'
import { fetchUserEoyReviewProgress } from '../TaxChecklist/Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import {
  getAnnualTaxFilingForYearSelector,
  selectLaterJoinerOptedOutDuringSignup,
} from '../annualTaxFilings.selector'
import AnnualTaxTskEntryPoint from './AnnualTaxTskEntryPoint'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../OpenFeature'
import YearEndHome from './YearEndHome'
import { isFreeTrialPromoCode } from '../../../../selectors/user.selectors'
import FreeTrialAnnualTaxPreview from './FreeTrialAnnualTaxPreview'
import LateJoinerOptedOutPage from './LateJoinerOptedOutPage'
import { selectIsAfter1099NECSubmitToAboundDueDate } from '../aboundAnnualTaxFilings.selector'
import { UpdatesDisabledPage } from '../../../Transactions/UpdatesDisabledPage'

const AnnualTaxesPanel = () => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const [fetching, setFetching] = useState(true)

  useEffect(() => {
    async function batchFetch() {
      await Promise.all([
        dispatch(fetchAnnualTaxFormTypesIfNeeded()),
        dispatch(fetchUserDocuments()),
        dispatch(fetchTaxUserDocumentsIfNeeded()),
        dispatch(fetchAnnualTaxFilingStatusTypesIfNeeded()),
        dispatch(fetchAllAnnualTaxDetailsIfNeeded()),
        dispatch(fetchSubscriptions()),
        dispatch(fetchUserDocumentCategoriesIfNeeded()),
        dispatch(fetchAllEoyReviewStepsIfNeeded()),
        // Force refetch of these because form needs and submissions may have happened in other flows
        dispatch(fetchAnnualTaxFilingFormsIfNeeded(true)),
        dispatch(fetchAnnualTaxFilingsIfNeeded(true)),
      ])
      setFetching(false)
    }
    batchFetch()
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchUserEoyReviewProgress(taxYear))
  }, [dispatch, taxYear])

  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)
  const userHasBasicPlan = useReselector(selectIsBasicPlan)
  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    taxYear
  )
  const lateJoinerOptedOut = useReselector(
    selectLaterJoinerOptedOutDuringSignup
  )
  const isAfter1099NECDueDate = useReselector(
    selectIsAfter1099NECSubmitToAboundDueDate
  )
  const releaseYearEndHome = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.releaseYearEndHome,
    false
  )
  const shouldDisableTransactionUpdates = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.disableTransactionUpdates,
    false
  )
  const tskSubmitted = annualTaxFiling?.pretaxSurveySubmittedAt

  if (shouldDisableTransactionUpdates) {
    return <UpdatesDisabledPage />
  }

  if (fetching) {
    return <Loader active />
  }

  /*
    If user is in free trial, we want to show a preview of the Annual Tax Experience
  */
  if (hasFreeTrialPromoCode) {
    return <FreeTrialAnnualTaxPreview />
  }

  if (userHasBasicPlan) {
    return <UpgradeToPremiumModal feature="annual-taxes" />
  }
  if (releaseYearEndHome && tskSubmitted) {
    return <YearEndHome />
  }
  if (isAfter1099NECDueDate && lateJoinerOptedOut) {
    return <LateJoinerOptedOutPage />
  }
  return <AnnualTaxTskEntryPoint annualTaxFiling={annualTaxFiling} />
}

export default AnnualTaxesPanel
