import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

import { fetchWrapper } from '../../../../../../reducers/fetch'
import { keyBy } from 'lodash'

interface EoyStepResponses {
  hasBusinessIncomeToAdd?: boolean | null
  hasBusinessExpenseToAdd?: boolean | null
  transactionIds?: number[] | null
  skippedDocumentCategories?: string[]
  // Currently used for document categories
  needsHelp?: string[]
  madePayment?: boolean
  comment?: string
}

export interface UserEoyReviewProgress {
  id: number
  annualTaxFilingId: number
  endOfYearReviewStepId: number
  responses: EoyStepResponses[]
  completedAt: string | null
  createdAt: string
  lastViewedAt: string
  updatedAt: string
}

interface UpdateUserEoyReviewProgressPayload {
  completedAt?: Date | null
  responses?: EoyStepResponses[]
}

export interface UserEoyReviewProgressState {
  [key: number]: UserEoyReviewProgress
}

export enum StepStatus {
  notStarted = 'notStarted',
  inProgress = 'inProgress',
  completed = 'completed',
}

const userEoyReviewProgressSlice = createSlice({
  name: 'userEoyReviewProgress',
  initialState: {} as UserEoyReviewProgressState,
  reducers: {
    setSteps: (
      state,
      action: PayloadAction<{
        [key: number]: UserEoyReviewProgress
      }>
    ) => ({ ...state, ...action.payload }),
    createOrUpdateStep: (
      state,
      action: PayloadAction<UserEoyReviewProgress>
    ) => {
      state[action.payload.id] = action.payload
    },
    upsertSteps: (state, action: PayloadAction<UserEoyReviewProgress[]>) => {
      action.payload.forEach((step) => {
        state[step.id] = step
      })
    },
    deleteStep: (state, action: PayloadAction<number>) => {
      delete state[action.payload]
    },
  },
})

const { setSteps, createOrUpdateStep, upsertSteps, deleteStep } =
  userEoyReviewProgressSlice.actions
export default userEoyReviewProgressSlice.reducer

export const FETCH_USER_EOY_REVIEW_PROGRESS_KEY =
  'FETCH_USER_EOY_REVIEW_PROGRESS_KEY'
export const fetchUserEoyReviewProgress = (year: string, userId?: number) =>
  fetchWrapper({
    fetchKey: FETCH_USER_EOY_REVIEW_PROGRESS_KEY,
    defaultErrorMessage: 'Error fetching user end of year review steps',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<UserEoyReviewProgress[]>(
        `/finances/api/v1/user_end_of_year_review_progress/${year}`,
        { params: { userId } }
      )

      dispatch(setSteps(keyBy(json.data, 'id')))

      return json.data
    },
  })

export const fetchUserEoyReviewForUser = (taxYear: string, userId: number) =>
  fetchWrapper({
    fetchFunction: async () => {
      const json = await axios.get<UserEoyReviewProgress[]>(
        '/finances/api/v1/admin/user_end_of_year_review_progress',
        { params: { userId, taxYear } }
      )

      return json.data
    },
  })

export const CREATE_USER_EOY_REVIEW_PROGRESS_KEY =
  'CREATE_USER_EOY_REVIEW_PROGRESS_KEY'
export const createUserEoyReviewProgress = (data: {
  annualTaxFilingId?: number
  endOfYearReviewStepId: number
  completedAt?: Date
}) =>
  fetchWrapper({
    fetchKey: CREATE_USER_EOY_REVIEW_PROGRESS_KEY,
    defaultErrorMessage: 'Error creating user end of year review step',
    defaultValue: false,
    fetchFunction: async (dispatch) => {
      const json = await axios.post<UserEoyReviewProgress>(
        '/finances/api/v1/user_end_of_year_review_progress',
        data
      )
      dispatch(createOrUpdateStep(json.data))
      return json.data
    },
  })

export const DELETE_USER_EOY_REVIEW_PROGRESS_KEY =
  'DELETE_USER_EOY_REVIEW_PROGRESS_KEY'
export const deleteUserEoyReviewProgress = (id: number) =>
  fetchWrapper({
    fetchKey: DELETE_USER_EOY_REVIEW_PROGRESS_KEY,
    defaultErrorMessage: 'Error deleting user end of year review step',
    defaultValue: false,
    fetchFunction: async (dispatch) => {
      const json = await axios.delete<{ id: number }>(
        `/finances/api/v1/user_end_of_year_review_progress/${id}`
      )
      dispatch(deleteStep(json.data.id))
      return json.data
    },
  })

export const UPDATE_USER_EOY_REVIEW_PROGRESS_KEY =
  'UPDATE_USER_EOY_REVIEW_PROGRESS_KEY'

export const updateUserEoyReviewProgress = (
  id: number,
  data: UpdateUserEoyReviewProgressPayload
) =>
  fetchWrapper({
    fetchKey: UPDATE_USER_EOY_REVIEW_PROGRESS_KEY,
    defaultErrorMessage: 'Error updating user end of year review step',
    fetchFunction: async (dispatch) => {
      const json = await axios.put<UserEoyReviewProgress>(
        `/finances/api/v1/user_end_of_year_review_progress/${id}`,
        data
      )
      dispatch(createOrUpdateStep(json.data))
      return json.data
    },
  })

export interface UserEoyReviewProgressUpsertPayload
  extends Omit<UserEoyReviewProgress, 'completedAt'> {
  completedAt?: Date | null
}

export const UPSERT_USER_EOY_REVIEW_PROGRESS_KEY =
  'UPSERT_USER_EOY_REVIEW_PROGRESS_KEY'
export const upsertUserEoyReviewProgress = (
  data: Partial<UserEoyReviewProgressUpsertPayload>[]
) => {
  return fetchWrapper({
    fetchKey: UPSERT_USER_EOY_REVIEW_PROGRESS_KEY,
    defaultErrorMessage: 'Error updating progress',
    fetchFunction: async (dispatch) => {
      if (data.length === 0) return []
      const json = await axios.post<UserEoyReviewProgress[]>(
        '/finances/api/v1/user_end_of_year_review_progress/upsertMany',
        data
      )
      dispatch(upsertSteps(json.data))
      return json.data
    },
  })
}
