import { Grid, Image } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import {
  DEDUCTIONS_AND_CREDITS_SCREENS,
  DeductionsAndCreditsStepProps,
} from '.'
import {
  TaxListOptionId,
  TaxListQuestionId,
  useTaxListQuestionRes,
} from '../../service'
import {
  Button,
  Card,
  FormikRadioToggleButton,
  GridRowColumn,
  Link,
  Text,
  makeReqStringSchema,
  getFieldNames,
  GridColumnSpacer,
  FormikScrollOnError,
} from '../../../../../../components/BaseComponents'
import { useReselector } from '../../../../../../utils/sharedHooks'
import {
  selectTaxListQuestionResponsesByFormId,
  selectTaxListQuestionResponsesByQuestionIds,
} from '../../taxChecklist.selectors'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { CalculationAlert } from '../../Shared/SpecialtyAlerts'
import { formatCurrencyFromCents } from '../../../../../../utils/currencyHelpers'
import { getSimplifiedDeduction } from './HomeOfficeSimplifiedPanel'
import SkipQuestion, {
  skipQuestionId,
  useSkipQuestion,
} from '../../Shared/SkipQuestion'
import { tqBigSpace } from '../../helpers'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { select1040FormForYear } from '../../../annualTaxFilingForms.selector'

export const homeOfficeQuestionIds = [
  TaxListQuestionId.home_office_sq_ft_for_business,
  TaxListQuestionId.home_office_sq_ft_total,
  TaxListQuestionId.home_office_total_standard_amount_in_cents,
  TaxListQuestionId.home_office_indirect_insurance_amount_in_cents,
  TaxListQuestionId.home_office_indirect_rent_amount_in_cents,
  TaxListQuestionId.home_office_indirect_maintenance_amount_in_cents,
  TaxListQuestionId.home_office_indirect_electricity_amount_in_cents,
  TaxListQuestionId.home_office_indirect_water_sewage_amount_in_cents,
  TaxListQuestionId.home_office_indirect_internet_amount_in_cents,
  TaxListQuestionId.home_office_indirect_other_expense_amount_in_cents,
  TaxListQuestionId.home_office_direct_expenses,
  TaxListQuestionId.home_office_direct_insurance_amount_in_cents,
  TaxListQuestionId.home_office_direct_rent_amount_in_cents,
  TaxListQuestionId.home_office_direct_maintenance_amount_in_cents,
  TaxListQuestionId.home_office_direct_electricity_amount_in_cents,
  TaxListQuestionId.home_office_direct_water_sewage_amount_in_cents,
  TaxListQuestionId.home_office_direct_internet_amount_in_cents,
  TaxListQuestionId.home_office_direct_other_expense_amount_in_cents,
  TaxListQuestionId.home_office_deduction_method,
]

export const indirectHomeOfficeStandardQuestionIds = [
  TaxListQuestionId.home_office_indirect_insurance_amount_in_cents,
  TaxListQuestionId.home_office_indirect_rent_amount_in_cents,
  TaxListQuestionId.home_office_indirect_maintenance_amount_in_cents,
  TaxListQuestionId.home_office_indirect_electricity_amount_in_cents,
  TaxListQuestionId.home_office_indirect_water_sewage_amount_in_cents,
  TaxListQuestionId.home_office_indirect_internet_amount_in_cents,
]

// These questions are only required if they indicate they have direct office expenses
const conditionalHomeOfficeStandardQuestionIds = [
  TaxListQuestionId.home_office_direct_insurance_amount_in_cents,
  TaxListQuestionId.home_office_direct_rent_amount_in_cents,
  TaxListQuestionId.home_office_direct_maintenance_amount_in_cents,
  TaxListQuestionId.home_office_direct_electricity_amount_in_cents,
  TaxListQuestionId.home_office_direct_water_sewage_amount_in_cents,
  TaxListQuestionId.home_office_direct_internet_amount_in_cents,
  TaxListQuestionId.home_office_direct_other_expense_amount_in_cents,
]

export const directHomeOfficeStandardQuestionIds = [
  TaxListQuestionId.home_office_direct_expenses,
  ...conditionalHomeOfficeStandardQuestionIds,
]

const requiredHomeOfficeStandardQuestionIds = [
  TaxListQuestionId.home_office_sq_ft_for_business,
  TaxListQuestionId.home_office_sq_ft_total,
  TaxListQuestionId.home_office_total_standard_amount_in_cents,
  ...indirectHomeOfficeStandardQuestionIds,
  //they will need to answer whether they had direct expenses, even if they didn't have any
  TaxListQuestionId.home_office_direct_expenses,
]

export const homeOfficeSimplifiedQuestionIds = [
  TaxListQuestionId.home_office_sq_ft_for_business,
]

export const homeOfficeStandardQuestionIds = [
  ...requiredHomeOfficeStandardQuestionIds,
  ...conditionalHomeOfficeStandardQuestionIds,
]

const HomeOfficeCalculatorResult = ({
  goToNextStep,
  amount,
}: {
  goToNextStep: () => void
  amount: string
}) => (
  <div style={{ marginTop: 24, marginBottom: 24, width: '100%' }}>
    <CalculationAlert
      contentStyle={{ flexGrow: 1 }}
      innerTextStyle={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
    >
      <span style={{ flexShrink: 0 }}>{amount}</span>

      <Button
        variant="link"
        fullWidth
        style={{ flexGrow: 1, justifyContent: 'right' }}
        onClick={goToNextStep}
      >
        Edit
      </Button>
    </CalculationAlert>
  </div>
)

const HomeOfficePanel = ({
  goBack,
  goToNextStep,
  previousScreen,
}: DeductionsAndCreditsStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const form1040 = useReselector(select1040FormForYear, taxYear)
  const formId = form1040?.id

  const simplifiedResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    homeOfficeSimplifiedQuestionIds,
    formId
  )

  const simplifiedComplete =
    simplifiedResponses.length === homeOfficeSimplifiedQuestionIds.length

  const standardResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    homeOfficeStandardQuestionIds,
    formId
  )

  const hadDirectExpenses = standardResponses.find(
    (r) => r.questionId === TaxListQuestionId.home_office_direct_expenses
  )?.value

  const standardComplete = hadDirectExpenses
    ? standardResponses.length === homeOfficeStandardQuestionIds.length
    : standardResponses.length === requiredHomeOfficeStandardQuestionIds.length

  const homeOfficeDeductionQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.home_office_deduction_method,
    formId,
  })

  const responseSquareFootageBusiness = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_sq_ft_for_business,
    formId
  )

  //Sq ft for business is shared between simplified and standard, so the existence of
  //an answer for this question will imply they have started the standard method
  const responseSquareFootageTotal = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_sq_ft_total,
    formId
  )

  const responseTotalAmount = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_total_standard_amount_in_cents,
    formId
  )

  const hadBusinessMileageResponse = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.had_business_mileage,
    formId
  )

  const ownedFarmResponse = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.owned_farm,
    formId
  )

  const hadCollegeStudentsResponse = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.had_college_students,
    formId
  )

  const paidDependentExpensesResponse = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.paid_dependent_expenses,
    formId
  )

  const charitableContributionsResponse = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.charitable_contribution,
    formId
  )

  const skippedQuestions = useSkipQuestion([
    TaxListQuestionId.home_office_deduction_method,
  ])

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.home_office_deduction_method]:
        homeOfficeDeductionQR.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      const responseData = [homeOfficeDeductionQR.getResponseData(values)]

      //we need to derive the next screen because the simple and standard home office deduction screens are in the stack
      let nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.review

      if (hadBusinessMileageResponse?.[0]?.value) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.businessMileage
      } else if (ownedFarmResponse?.[0]?.value) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.farm
      } else if (hadCollegeStudentsResponse?.[0]?.value) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.education
      } else if (paidDependentExpensesResponse?.[0]?.value) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.dependentCare
      } else if (charitableContributionsResponse?.[0]?.value) {
        nextScreen = DEDUCTIONS_AND_CREDITS_SCREENS.charitableContributions
      }
      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.homeOffice],
      })
    },
  })

  const { isSubmitting, submitForm } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <FormikScrollOnError />
      <Grid>
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Home Office
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg">
            A home office must be <b>exclusively</b> used for business, meaning
            that the space is not used for any other personal use.
          </Text>
          <br />
          <Text as="bodyLg">
            You can go through both methods if you&apos;d like to see which
            works better for you, and then select the method when you are
            finished. Check out our{' '}
            <Link
              href="https://support.joinheard.com/hc/en-us/articles/10270233571607-Home-Office-Deductions-FAQs"
              newPage
            >
              FAQs
            </Link>{' '}
            if you need help.
          </Text>
        </GridRowColumn>
        <Grid.Row>
          <Grid.Column width={4} only="computer" />
          <Grid.Column computer={4} mobile={16} table={16} stretched>
            <Card
              backgroundColor={simplifiedComplete ? 'stone40' : 'natural'}
              style={{ display: 'flex' }}
            >
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/house.svg"
                style={{
                  margin: 'auto',
                  width: 120,
                  height: 100,
                  backgroundColor: 'transparent',
                }}
              />
              <Text
                as="h2"
                textAlign="center"
                style={{ marginTop: 24, marginBottom: 16 }}
              >
                Simplified Method
              </Text>
              {!simplifiedComplete && (
                <Text>
                  You&apos;ll only share the total amount of square feet used as
                  part of the home office. This suits people who:
                  <ul>
                    <li>have a small home office</li>
                    <li>prefer a simpler method</li>
                    <li>
                      in the event of an audit, would simply need to prove their
                      office’s square footage.
                    </li>
                  </ul>
                </Text>
              )}
              {simplifiedComplete && <Text>Your deduction amount:</Text>}
              <div style={{ flex: 1, minHeight: 20 }} />
              {simplifiedComplete &&
                responseSquareFootageBusiness.length &&
                typeof responseSquareFootageBusiness[0].value === 'number' && (
                  <HomeOfficeCalculatorResult
                    goToNextStep={() =>
                      goToNextStep(
                        null,
                        DEDUCTIONS_AND_CREDITS_SCREENS.homeOfficeSimplified
                      )
                    }
                    amount={getSimplifiedDeduction(
                      responseSquareFootageBusiness[0].value
                    )}
                  />
                )}
              {!simplifiedComplete && (
                <Button
                  fullWidth
                  variant="secondary"
                  onClick={() =>
                    goToNextStep(
                      null,
                      DEDUCTIONS_AND_CREDITS_SCREENS.homeOfficeSimplified
                    )
                  }
                >
                  Start
                </Button>
              )}
              {simplifiedComplete && (
                <FormikRadioToggleButton
                  name={
                    fieldNames[TaxListQuestionId.home_office_deduction_method]
                  }
                  fullWidth
                  value={TaxListOptionId.home_office_method_simplified}
                  schema={makeReqStringSchema({})}
                >
                  Use Simplified
                </FormikRadioToggleButton>
              )}
            </Card>
          </Grid.Column>
          <GridColumnSpacer tablet mobile />
          <Grid.Column computer={4} mobile={16} table={16} stretched>
            <Card
              backgroundColor={standardComplete ? 'stone40' : 'natural'}
              style={{ display: 'flex' }}
            >
              <Image
                src="https://heard-images.s3.amazonaws.com/assets/big-house.svg"
                style={{
                  margin: 'auto',
                  width: 120,
                  height: 100,
                  backgroundColor: 'transparent',
                }}
              />
              <br />
              <Text
                as="h2"
                textAlign="center"
                style={{ marginTop: 24, marginBottom: 16 }}
              >
                Standard Method
              </Text>
              <br />
              {!standardComplete && (
                <Text>
                  You&apos;ll share home expenses and calculate the percentage
                  used for the home office. This suits people who:
                  <ul>
                    <li>
                      have a large home office and high cost utilities and home
                      expenses{' '}
                    </li>
                    <li>
                      in the event of an audit, would be able to provide all of
                      last year’s home-related bills and receipts
                    </li>
                  </ul>
                </Text>
              )}
              {standardComplete && <Text>Your deduction amount:</Text>}
              <div style={{ flex: 1, minHeight: 20 }} />
              {standardComplete &&
                responseTotalAmount.length &&
                typeof responseTotalAmount[0].value === 'number' && (
                  <HomeOfficeCalculatorResult
                    goToNextStep={() =>
                      goToNextStep(
                        null,
                        DEDUCTIONS_AND_CREDITS_SCREENS.homeOfficeStandardStep1
                      )
                    }
                    amount={formatCurrencyFromCents(
                      responseTotalAmount[0].value,
                      {
                        hideDecimalsIfZero: true,
                      }
                    )}
                  />
                )}
              {!standardComplete && (
                <Button
                  fullWidth
                  variant="secondary"
                  onClick={() =>
                    goToNextStep(
                      null,
                      DEDUCTIONS_AND_CREDITS_SCREENS.homeOfficeStandardStep1
                    )
                  }
                >
                  {responseSquareFootageTotal.length ? 'Continue' : 'Start'}
                </Button>
              )}
              {standardComplete && (
                <FormikRadioToggleButton
                  name={
                    fieldNames[TaxListQuestionId.home_office_deduction_method]
                  }
                  fullWidth
                  value={TaxListOptionId.home_office_method_standard}
                  schema={makeReqStringSchema({})}
                >
                  Use Standard
                </FormikRadioToggleButton>
              )}
            </Card>
          </Grid.Column>
        </Grid.Row>
        <GridRowColumn {...tqBigSpace}>
          <SkipQuestion
            questionId={TaxListQuestionId.home_office_deduction_method}
            overrideCopy="Unsure? Follow-up with tax preparer to choose the best method."
          />
        </GridRowColumn>
        <FormFlowFooter
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          continueDisabled={isSubmitting}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default HomeOfficePanel
