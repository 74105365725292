import { DateTime } from 'luxon'
import {
  YearEndModuleStatusOptions,
  YearEndModuleType,
} from '../yearEndModuleStatus.slice'
import YearEndModuleBaseCard, {
  defaultDeadlineApproaching,
  YearEndModuleStatusDynamicDataParams,
} from './YearEndModuleStatusBaseCard'
import { Link, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import {
  getModuleNameCopy,
  useModuleActionButtonBehavior,
} from '../yearEndModuleStatus.helpers'
import { DATE_FORMATS_LUXON, isoToLocalDate } from '../../../utils/dateHelpers'

const getDescription = (
  status: YearEndModuleStatusOptions,
  dueDate: DateTime | null,
  taxYear: string,
  opts: { hasSubmitted1099NEC: boolean }
) => {
  const baseDescription =
    'If you’ve paid one or more contractors $600 or more throughout 2024, you’ll need to complete this survey so we can file 1099-NEC’s on your behalf.'
  switch (status) {
    case YearEndModuleStatusOptions.upNext:
    case YearEndModuleStatusOptions.inProgress: {
      return baseDescription
    }
    case YearEndModuleStatusOptions.missed: {
      const dueDateText = dueDate?.isValid
        ? dueDate.toFormat(DATE_FORMATS_LUXON.MONTH_DAY)
        : ''
      const deadlinePassedCopy = opts.hasSubmitted1099NEC ? (
        <>
          {dueDateText} deadline to resolve issues related to your 1099-NEC
          filing
        </>
      ) : (
        <>{dueDateText} deadline to submit the 1099-NEC survey</>
      )
      return (
        <>
          Since you missed the {deadlinePassedCopy}, Heard can no longer file
          1099-NEC’s on your behalf.
          <br />
          <br />
          We recommend using{' '}
          <Link
            href="https://www.track1099.com/"
            newPage
            size="small"
            style={{
              textDecoration: 'underline',
              color: Colors.charcoal,
              textDecorationColor: Colors.charcoal,
            }}
          >
            www.track1099.com
          </Link>{' '}
          to file your 1099-NECs for any contractors you paid $600 or more to in{' '}
          {taxYear}.
        </>
      )
    }
    case YearEndModuleStatusOptions.waitingOnHeard: {
      return 'We’re filing your 1099-NEC(s). This may take up to 5-7 business days.'
    }
    case YearEndModuleStatusOptions.actionRequired: {
      return (
        <>
          There was an error with your 1099-NEC filing(s). Please review the{' '}
          <b>1099-NEC Filings</b> section in the right sidebar for next steps.
        </>
      )
    }
    case YearEndModuleStatusOptions.complete: {
      return 'Your 1099-NEC(s) have been filed.'
    }
    default:
      return null
  }
}

const File1099NECCard = ({
  status,
  dueDate,
  taxYear,
  hasSubmitted1099NEC,
}: YearEndModuleStatusDynamicDataParams & {
  hasSubmitted1099NEC?: boolean | null
}) => {
  const dueDateObj = dueDate ? isoToLocalDate(dueDate) : null
  const description = getDescription(status, dueDateObj, taxYear, {
    hasSubmitted1099NEC: Boolean(hasSubmitted1099NEC),
  })
  const { onActionClick } = useModuleActionButtonBehavior(
    YearEndModuleType.file1099Nec,
    status
  )
  return (
    <YearEndModuleBaseCard
      key={YearEndModuleType.file1099Nec}
      status={status}
      dueDate={dueDate}
      taxYear={taxYear}
      header={getModuleNameCopy(YearEndModuleType.file1099Nec)}
      imageUrl="https://heard-images.s3.amazonaws.com/assets/form-fill.svg"
      description={<Text as="bodySm">{description}</Text>}
      timer={{
        unit: 'minutes',
        value: 25,
      }}
      deadlineApproaching={{
        ...defaultDeadlineApproaching,
        alertBody: (
          <Text as="bodySm">
            Not filing 1099-NEC’s could incur penalties from the IRS.{' '}
            <Link
              href="https://support.joinheard.com/hc/en-us/articles/28063466359191-How-to-avoid-penalties-related-to-1099-NEC"
              newPage
              size="small"
              style={{
                textDecoration: 'underline',
                color: Colors.charcoal,
                textDecorationColor: Colors.charcoal,
              }}
            >
              More info
            </Link>
          </Text>
        ),
      }}
      button={{
        showOn: [
          YearEndModuleStatusOptions.upNext,
          YearEndModuleStatusOptions.inProgress,
        ],
        onActionClick,
      }}
    />
  )
}

export default File1099NECCard
