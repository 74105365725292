import axios from 'axios'
import moment from 'moment'

import { UserWithAdminInfo } from '../../../reducers/admin/allUsersReducer'
import { User, UserRole } from '../../../reducers/auth/userReducer'
import { BookkeepingReport } from '../../../reducers/finances/bookkeepingReportsReducer'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { BookkeepingReportStatistics } from './helpers'
import { fetchWrapper } from '../../../reducers/fetch'

export const listUserRoles = async () => {
  try {
    const response = await axios.get<UserRole[]>(
      '/finances/api/v1/admin/user_roles'
    )
    return response.data
  } catch (_) {
    return false
  }
}

export const listAllAdmins = async () => {
  try {
    const response = await axios.get<User[]>('/finances/api/v1/admin/all')
    return response.data
  } catch (_) {
    return []
  }
}

export const listBookkeepersUsers = async (
  page: number,
  bookkeeper: number | string,
  userId?: number
) => {
  try {
    const response = await axios.get<{
      rows: UserWithAdminInfo[]
      count: number
    }>('/finances/api/v1/admin/users/crm', {
      params: {
        userId,
        bookkeeperId: bookkeeper !== 'All Books' ? bookkeeper : undefined,
        page,
      },
    })
    return response.data
  } catch (_) {
    return false
  }
}

export const getBookkeeperStatistics = (
  year: number | string,
  bookkeeper?: number | string
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error fetching all uncategorized transactions',
    fetchFunction: () =>
      axios
        .get<BookkeepingReportStatistics>(
          '/finances/api/v1/admin/bookkeeping_reports/stats',
          {
            params: {
              year,
              bookkeeperId: bookkeeper !== 'All Books' ? bookkeeper : undefined,
            },
          }
        )
        .then((json) => {
          return json.data
        }),
  })

export const getTableData = (users: UserWithAdminInfo[], year: string) => {
  return users.map((user) => {
    const reportsForYear = user?.financialProfile?.reports
      ? user.financialProfile.reports
          .filter((report: BookkeepingReport) => {
            const reportYear = report?.date?.split('-')[0]
            return reportYear === year
          })
          .map((report: BookkeepingReport) => {
            return {
              id: report.id,
              status: report?.statuses[0]?.status,
              month: report?.date?.split('-')[1],
            }
          })
      : []

    const annualTaxFiling = user?.annualTaxFilings?.find(
      (atf) => atf.year === year
    )

    const eoyBookkeepingFollowupStatus =
      user?.eoyBookkeepingFollowupStatuses?.find(
        (yeModuleStatus) => yeModuleStatus.year === year
      )?.status

    return {
      user,
      onboardingDate: user?.onboardingMeetingAt
        ? moment(user?.onboardingMeetingAt).format(DATE_FORMATS.DISPLAY_SHORT)
        : 'N/A',
      hasAnnualTaxExtension: Boolean(annualTaxFiling?.extensionRequestedAt),
      eoyBookkeepingFollowupStatus,
      reports: {
        jan: reportsForYear.find((report) =>
          report?.month === '01' ? report : undefined
        ),
        feb: reportsForYear.find((report) =>
          report?.month === '02' ? report : undefined
        ),
        mar: reportsForYear.find((report) =>
          report?.month === '03' ? report : undefined
        ),
        apr: reportsForYear.find((report) =>
          report?.month === '04' ? report : undefined
        ),
        may: reportsForYear.find((report) =>
          report?.month === '05' ? report : undefined
        ),
        jun: reportsForYear.find((report) =>
          report?.month === '06' ? report : undefined
        ),
        jul: reportsForYear.find((report) =>
          report?.month === '07' ? report : undefined
        ),
        aug: reportsForYear.find((report) =>
          report?.month === '08' ? report : undefined
        ),
        sep: reportsForYear.find((report) =>
          report?.month === '09' ? report : undefined
        ),
        oct: reportsForYear.find((report) =>
          report?.month === '10' ? report : undefined
        ),
        nov: reportsForYear.find((report) =>
          report?.month === '11' ? report : undefined
        ),
        dec: reportsForYear.find((report) =>
          report?.month === '12' ? report : undefined
        ),
      },
    }
  })
}
