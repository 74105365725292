import { useMemo, useState } from 'react'

import { Checkbox, Popup } from '../../../../../components/BaseComponents'
import FileUploadModal from '../../../../../components/FileUpload/FileUploadModal'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectUserDocumentCategoryByInternalName } from '../../../../Admin/UserDocumentCategories/userDocumentCategories.selectors'
import { UploadDocumentType } from '../../../../../constants/businessConstants'
import { selectUserTaxDocumentsForCategory } from '../../../../UserDocuments/userDocuments.selector'
import type { Colors } from '../../../../../styles/theme'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { selectIsTwoFormFiler } from '../../annualTaxFilingForms.selector'
import {
  selectIsMarriedFilingJointlyOrQualifyingWidow,
  selectOtherTaxDocuments,
} from '../taxChecklist.selectors'
import { TaxListQuestionId } from '../service'
import { UserDocumentCategoryIdentifier } from '../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { DocumentUploadCard } from '../../../../../components/FileUpload/DocumentUploadCard'
import { useCheckedDocumentCategories } from './index'
import { selectIsCurrentUserScorp } from '../../../../../selectors/user.selectors'

const UploadCard = ({
  docCategoryIdentifier,
  isOtherCat,
  styleOverrides = {},
  isRequired,
  readOnly,
}: {
  docCategoryIdentifier: string
  isOtherCat?: boolean
  styleOverrides?: {
    skipDescription?: boolean
    cardBackgroundColor?: keyof typeof Colors
  }
  isRequired?: boolean
  readOnly?: boolean
}) => {
  const [modelOpen, setModalOpen] = useState(false)
  const { skipDescription, cardBackgroundColor } = styleOverrides
  const isScorp = useReselector(selectIsCurrentUserScorp)
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const previousTaxYear = (Number(currentTaxYear) - 1).toString()
  const { skippedCategories, updateSkipped, helpCategories, updateHelped } =
    useCheckedDocumentCategories()

  const skipped = skippedCategories?.includes(docCategoryIdentifier)
  const needsAssistance = helpCategories?.includes(docCategoryIdentifier)

  const isPreviousYearTaxReturn = /final_return/i.test(
    docCategoryIdentifier.toLowerCase()
  )
  const isTwoFormFiler = useReselector(selectIsTwoFormFiler, currentTaxYear)
  const isMfjOrWidow = useReselector(
    selectIsMarriedFilingJointlyOrQualifyingWidow,
    TaxListQuestionId.filing_status,
    currentTaxYear
  )

  const taxDocCat = useReselector(
    selectUserDocumentCategoryByInternalName,
    docCategoryIdentifier
  )

  // include all final_return categories (generic, 1120s, 1040)
  const taxDocumentsForCategory = useReselector(
    selectUserTaxDocumentsForCategory,
    docCategoryIdentifier,
    isPreviousYearTaxReturn ? previousTaxYear : currentTaxYear
  )
  const otherTaxDocuments = useReselector(
    selectOtherTaxDocuments,
    currentTaxYear
  )

  const taxDocuments = useMemo(() => {
    if (isOtherCat) {
      return otherTaxDocuments
    }
    return taxDocumentsForCategory
  }, [isOtherCat, otherTaxDocuments, taxDocumentsForCategory])

  // Since the descriptions are dynamic now, we should probably refactor these for next year
  const docDescription = useMemo(() => {
    if (isOtherCat) {
      return 'If there are any other documents you’d like us to review, upload them here. If our tax team requires anything additional from you, we’ll reach out directly.'
    }

    if (
      taxDocCat?.internalName ===
      UserDocumentCategoryIdentifier.finalReturnForm1040
    ) {
      return `Upload your personal tax return (Form 1040) and your State Tax Return (if applicable and not already part of your 1040 document), from the previous tax year (${previousTaxYear}).)`
    } else if (
      taxDocCat?.internalName ===
      UserDocumentCategoryIdentifier.finalReturnForm1120s
    ) {
      return `Upload your business tax return (Form 1120s) and your State Tax Return (if applicable and not already part of your 1040 document), from the previous tax year (${previousTaxYear}).)`
    }

    if (isScorp && !isTwoFormFiler) {
      if (
        taxDocCat?.internalName === UserDocumentCategoryIdentifier.form1099nec
      ) {
        return 'A 1099-NEC form is provided to your business for any independent contractor work your S-corp business provided. Do not include any 1099-NECs you received for contractor work outside of your S-corp business.'
      }
      if (
        taxDocCat?.internalName === UserDocumentCategoryIdentifier.form1099int
      ) {
        return 'A 1099-INT form is sent to your business when one of your business cash accounts earn interest. Do not include any 1099-INTs you received for interest earned on cash accounts not tied to your S-corp business.'
      }
      if (
        taxDocCat?.internalName === UserDocumentCategoryIdentifier.form1099div
      ) {
        return "A 1099-DIV form is sent to your business whenever your business received a dividend from an investment held under your business' EIN. Do not include any 1099-DIVs you received for investments made outside of your S-corp business."
      }
    }

    if (
      isTwoFormFiler &&
      taxDocCat?.internalName === UserDocumentCategoryIdentifier.scheduleK1
    ) {
      return `${taxDocCat.description} You'll need your Schedule K-1 to file with your personal tax return, which becomes available after your business tax return has been filed. If your tax preparer didn't upload the K-1 form on its own, you can still find it within your business tax return.`
    }
    let description = taxDocCat?.description
    if (
      isMfjOrWidow &&
      taxDocCat &&
      [
        UserDocumentCategoryIdentifier.form1099nec,
        UserDocumentCategoryIdentifier.form1099misc,
        UserDocumentCategoryIdentifier.form1098,
        UserDocumentCategoryIdentifier.form1099b,
        UserDocumentCategoryIdentifier.form1099div,
        UserDocumentCategoryIdentifier.form1099int,
        UserDocumentCategoryIdentifier.formW2g,
        UserDocumentCategoryIdentifier.form1099sa,
        UserDocumentCategoryIdentifier.form1095a,
        UserDocumentCategoryIdentifier.form1095b,
        UserDocumentCategoryIdentifier.form1095c,
      ].includes(taxDocCat.internalName as UserDocumentCategoryIdentifier)
    ) {
      description = `${description} Please also upload any of these documents that apply to your spouse.`
    }
    return description
  }, [
    isOtherCat,
    taxDocCat,
    isScorp,
    isTwoFormFiler,
    isMfjOrWidow,
    previousTaxYear,
  ])

  const name = useMemo(() => {
    if (isOtherCat) {
      return 'Other documents'
    } else if (
      taxDocCat?.internalName ===
      UserDocumentCategoryIdentifier.finalReturnForm1040
    ) {
      return 'Prior Year Personal Tax Return (Form 1040)'
    } else if (
      taxDocCat?.internalName ===
      UserDocumentCategoryIdentifier.finalReturnForm1120s
    ) {
      return 'Prior Year Business Tax Return (Form 1120S)'
    }
    return taxDocCat?.title || ''
  }, [isOtherCat, taxDocCat?.internalName, taxDocCat?.title])

  const href = useMemo(() => {
    if (
      taxDocCat?.internalName ===
      UserDocumentCategoryIdentifier.finalReturnForm1040
    ) {
      return 'https://www.irs.gov/instructions/i1040gi'
    } else if (
      taxDocCat?.internalName ===
      UserDocumentCategoryIdentifier.finalReturnForm1120s
    ) {
      return 'https://www.irs.gov/instructions/i1120s'
    }

    return undefined
  }, [taxDocCat?.internalName])

  const tooltip = useMemo(() => {
    if (
      isTwoFormFiler &&
      taxDocCat?.internalName === UserDocumentCategoryIdentifier.form1099k
    ) {
      return 'If you already uploaded a 1099-K with your 1120-S return, you will only need to upload another one if you used a payment processor under your own SSN, instead of the EIN of your S-Corp.'
    }
    return null
  }, [isTwoFormFiler, taxDocCat?.internalName])

  const uploaded = taxDocuments.length > 0

  const status = useMemo(() => {
    if (isOtherCat) {
      return 'none'
    }
    if (uploaded) {
      return 'success'
    }
    return skipped ? 'disabled' : 'none'
  }, [isOtherCat, skipped, uploaded])

  const rightContent = useMemo(() => {
    if (readOnly) {
      return null
    }
    return (
      <>
        {!taxDocuments.length && !isRequired && (
          <Checkbox
            label="Unsure, or don’t have"
            onChange={() => updateSkipped?.(docCategoryIdentifier, !skipped)}
            checked={skipped}
            variant="default"
          />
        )}
        <Checkbox
          label="Need follow-up"
          onChange={() =>
            updateHelped?.(docCategoryIdentifier, !needsAssistance)
          }
          checked={needsAssistance}
          variant="default"
        />
      </>
    )
  }, [
    docCategoryIdentifier,
    isRequired,
    needsAssistance,
    readOnly,
    skipped,
    taxDocuments.length,
    updateHelped,
    updateSkipped,
  ])

  return (
    <>
      <DocumentUploadCard
        name={name}
        description={
          !skipDescription ? (
            <>
              {docDescription}
              {tooltip && <Popup content={tooltip} />}
            </>
          ) : null
        }
        documents={taxDocuments}
        href={href}
        handleUploadBtn={() => setModalOpen(!modelOpen)}
        status={status}
        cardBackgroundColor={cardBackgroundColor}
        readOnly={readOnly}
        rightContent={rightContent}
      />
      <FileUploadModal
        open={modelOpen}
        close={() => setModalOpen(false)}
        userFacing
        documentType={taxDocCat?.type || UploadDocumentType.TAX}
        categoryId={taxDocCat?.id}
        // Remove checked skipped status once upload is complete
        setUploadedFile={() => updateSkipped?.(docCategoryIdentifier, false)}
        year={isPreviousYearTaxReturn ? previousTaxYear : currentTaxYear}
        taxYearDisabled={isPreviousYearTaxReturn}
      />
    </>
  )
}

export default UploadCard
