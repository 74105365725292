import { useCallback, useMemo, useState } from 'react'
import { DEDUCTIONS_AND_CREDITS_SCREENS } from '.'
import {
  UPSERT_CHILDCARE_PROVIDERS_KEY,
  ChildcareProvider,
  DELETE_CHILDCARE_PROVIDERS_KEY,
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  upsertChildcareProviders,
} from '../../taxChecklistQuestion.actions'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import { useParams } from 'react-router-dom'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { selectIsFetchingForKeys } from '../../../../../../reducers/fetch'
import { TaxListQuestionId } from '../../service'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponseByFormIdAndChildcareProviderId,
} from '../../taxChecklist.selectors'
import { FormikProvider, useFormik } from 'formik'
import { logSentryError } from '../../../../../../utils/sentryHelpers'
import RemoveChildcareProviderModal from './RemoveChildcareProviderModal'
import {
  Button,
  Card,
  FormikInput,
  FormikLocationSearchInput,
  GridRowColumn,
  Icon,
  Popup,
  Text,
  getFieldName,
  makeEinSchema,
  makeNumberSchema,
  makeReqStringSchema,
  FormikScrollOnError,
} from '../../../../../../components/BaseComponents'
import { Divider, Grid } from 'semantic-ui-react'
import { Colors } from '../../../../../../styles/theme'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  centsToDollars,
  dollarsToCents,
  formatCurrencyFromCents,
} from '../../../../../../utils/currencyHelpers'
import { UPDATE_USER_EOY_REVIEW_PROGRESS_KEY } from '../../Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import ReadOnlyForm from '../../Shared/ReadOnlyForm'
import { StepUpdates } from '../../Shared/ReviewStepsandProgresses/useProgressSteps'
import { useAppDispatch } from '../../../../../../utils/typeHelpers'

interface ChildcareProviderFormProps {
  childcareProvider: Partial<ChildcareProvider>
  index: number
  removeChildcareProvider: ({
    index,
    childcareProviderId,
  }: {
    index: number
    childcareProviderId?: number
  }) => void
  goToNextStep: (
    data: Partial<TaxChecklistResponse>[] | null,
    newScreen: DEDUCTIONS_AND_CREDITS_SCREENS | null,
    stepUpdates?: StepUpdates,
    getNextScreen?: () => DEDUCTIONS_AND_CREDITS_SCREENS
  ) => Promise<boolean>
  loadChildcareProviders?: () => void
}

export const ChildcareProviderForm = ({
  childcareProvider,
  index,
  goToNextStep,
  removeChildcareProvider,
  loadChildcareProviders,
}: ChildcareProviderFormProps) => {
  const dispatch = useAppDispatch()

  const [modalOpen, setModalOpen] = useState(false)
  const { formId } = useParams()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    UPSERT_CHILDCARE_PROVIDERS_KEY,
    DELETE_CHILDCARE_PROVIDERS_KEY,
    UPDATE_USER_EOY_REVIEW_PROGRESS_KEY,
  ])

  const questionProviderName = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.childcare_provider_name,
    taxYear
  )

  const responseProviderName = useReselector(
    selectTaxListQuestionResponseByFormIdAndChildcareProviderId,
    TaxListQuestionId.childcare_provider_name,
    Number(formId),
    childcareProvider.id
  )

  const questionProviderAddress = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.childcare_provider_address,
    taxYear
  )

  const responseProviderAddress = useReselector(
    selectTaxListQuestionResponseByFormIdAndChildcareProviderId,
    TaxListQuestionId.childcare_provider_address,
    Number(formId),
    childcareProvider.id
  )

  const questionProviderEin = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.childcare_provider_ein,
    taxYear
  )

  const responseProviderEin = useReselector(
    selectTaxListQuestionResponseByFormIdAndChildcareProviderId,
    TaxListQuestionId.childcare_provider_ein,
    Number(formId),
    childcareProvider.id
  )

  const questionTotalAmount = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.childcare_provider_expenses_amount_in_cents,
    taxYear
  )

  const responseTotalAmount = useReselector(
    selectTaxListQuestionResponseByFormIdAndChildcareProviderId,
    TaxListQuestionId.childcare_provider_expenses_amount_in_cents,
    Number(formId),
    childcareProvider.id
  )

  const formik = useFormik({
    initialValues: {
      providerName: responseProviderName?.value ?? childcareProvider.name,
      providerAddress:
        responseProviderAddress?.value ?? childcareProvider.address,
      providerEin:
        typeof responseProviderEin?.value === 'string'
          ? responseProviderEin.value
          : (childcareProvider.ein ?? ''),
      confirmProviderEin:
        typeof responseProviderEin?.value === 'string'
          ? responseProviderEin.value
          : (childcareProvider.ein ?? ''),
      totalAmount:
        typeof responseTotalAmount?.value === 'number'
          ? centsToDollars(responseTotalAmount?.value)
          : undefined,
    },
    isInitialValid: Boolean(
      (responseProviderName?.value ?? childcareProvider.name) &&
        (responseProviderAddress?.value ?? childcareProvider.address) &&
        (responseProviderEin?.value ?? childcareProvider.ein) &&
        responseTotalAmount?.value
    ),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const responseData: Partial<TaxChecklistResponse>[] = [
          {
            id: responseProviderName?.id,
            value: values.providerName,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.childcare_provider_name,
            childcareProviderId: childcareProvider.id,
          },
          {
            id: responseProviderAddress?.id,
            value: values.providerAddress,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.childcare_provider_address,
            childcareProviderId: childcareProvider.id,
          },
          {
            id: responseProviderEin?.id,
            value: values.providerEin,
            annualTaxFilingFormId: Number(formId),
            questionId: TaxListQuestionId.childcare_provider_ein,
            childcareProviderId: childcareProvider.id,
          },
          {
            id: responseTotalAmount?.id,
            value: dollarsToCents(values.totalAmount),
            annualTaxFilingFormId: Number(formId),
            questionId:
              TaxListQuestionId.childcare_provider_expenses_amount_in_cents,
            childcareProviderId: childcareProvider.id,
          },
        ]
        const childcareProviderUpdates: Partial<ChildcareProvider> = {
          id: childcareProvider.id,
          name:
            typeof values.providerName === 'string'
              ? values.providerName
              : undefined,
          address:
            typeof values.providerAddress === 'string'
              ? values.providerAddress
              : undefined,
          ein:
            typeof values.providerEin === 'string'
              ? values.providerEin
              : undefined,
        }
        let updateSuccess = true
        let childcareProviderId: number | null = null
        if (Object.keys(childcareProviderUpdates).length > 0) {
          const upsertResponse = await upsertChildcareProviders([
            childcareProviderUpdates,
          ])(dispatch)
          updateSuccess = Boolean(upsertResponse && upsertResponse.length > 0)
          childcareProviderId = upsertResponse?.[0]?.id ?? null
        }
        if (updateSuccess && responseData.length > 0 && childcareProviderId) {
          await goToNextStep(
            responseData.map((rd) => ({ ...rd, childcareProviderId })),
            DEDUCTIONS_AND_CREDITS_SCREENS.skipNavigation
          )
          // skipcq: JS-0357
          setEditing(false)
          loadChildcareProviders?.()
        }
      } catch (err) {
        logSentryError(err)
      }
    },
  })

  const { values, isValid, submitForm, resetForm } = formik
  const [editing, setEditing] = useState(!isValid)

  const readOnly = useMemo(() => {
    return [
      { ...questionProviderName, responseText: values.providerName },
      { ...questionProviderAddress, responseText: values.providerAddress },
      { ...questionProviderEin, responseText: values.providerEin },
      {
        ...questionTotalAmount,
        responseText: formatCurrencyFromCents(
          dollarsToCents(values.totalAmount)
        ),
      },
    ]
  }, [
    questionProviderName,
    questionProviderAddress,
    questionProviderEin,
    questionTotalAmount,
    values,
  ])

  const onCancel = useCallback(() => {
    if (!childcareProvider.id) {
      removeChildcareProvider({ index })
    }
    resetForm()
    setEditing(false)
  }, [childcareProvider.id, index, removeChildcareProvider, resetForm])

  return (
    <FormikProvider value={formik}>
      <FormikScrollOnError />
      <RemoveChildcareProviderModal
        open={modalOpen}
        close={() => setModalOpen(false)}
        removeChildcareProvider={() =>
          removeChildcareProvider({
            index,
            childcareProviderId: childcareProvider.id,
          })
        }
      />
      {/* READ ONLY FORM */}
      {!editing && (
        <ReadOnlyForm
          title={`Dependent Care Provider ${index + 1}`}
          setEditing={() => setEditing(true)}
          readOnlyData={readOnly}
        />
      )}
      {/* EDITABLE FORM */}
      {editing && (
        <Card type="subsection" backgroundColor="stone40">
          <Grid>
            <GridRowColumn
              columnStyle={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text as="h2" color="forest">
                Dependent Care Provider {index + 1}
              </Text>
              {childcareProvider.id && (
                <Button
                  style={{ borderColor: Colors.red }}
                  variant="secondary"
                  onClick={() => setModalOpen(true)}
                >
                  <Icon size="1x" color="red" icon={regular('trash-can')} />
                </Button>
              )}
            </GridRowColumn>
            <GridRowColumn short>
              <Divider />
            </GridRowColumn>
            <GridRowColumn short>
              <FormikInput
                label={questionProviderName?.question?.text}
                name={getFieldName<typeof values>('providerName')}
                schema={makeReqStringSchema({
                  field: "provider's full name",
                })}
                placeholder="Enter name of provider"
                fullWidth
              />
            </GridRowColumn>
            <GridRowColumn short>
              <FormikLocationSearchInput
                label={questionProviderAddress?.question?.text}
                name={getFieldName<typeof values>('providerAddress')}
                schema={makeReqStringSchema({
                  field: "provider's address",
                })}
                placeholder="Street Address, City, State ZIP code"
                fullWidth
              />
            </GridRowColumn>
            <Grid.Row>
              <Grid.Column width={8}>
                <FormikInput
                  label={questionProviderEin?.question?.text}
                  name={getFieldName<typeof values>('providerEin')}
                  fullWidth
                  placeholder="12-3456789"
                  format="##-#######"
                  schema={makeEinSchema({
                    field: "provider's ein",
                  })}
                  componentType="pattern"
                  afterLabel={
                    <Popup
                      content={
                        <>
                          <Text as="h3">
                            How to find Provider&apos;s EIN (Employee
                            Identification Number)
                          </Text>
                          <br />
                          <Text as="bodySm">
                            <ul>
                              <li>
                                Certified providers will have a formal letter
                                with their tax information. Check to see if they
                                have one.
                              </li>
                              <li>Request the EIN from them with a W-10</li>
                            </ul>
                          </Text>
                        </>
                      }
                    />
                  }
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <FormikInput
                  label={`Confirm ${questionProviderEin?.question?.text}`}
                  name={getFieldName<typeof values>('confirmProviderEin')}
                  fullWidth
                  placeholder="12-3456789"
                  format="##-#######"
                  schema={makeEinSchema({
                    field: "provider's ein",
                  }).oneOf([values.providerEin], 'EIN does not match')}
                  componentType="pattern"
                />
              </Grid.Column>
            </Grid.Row>
            <GridRowColumn width={8}>
              <FormikInput
                name={getFieldName<typeof values>('totalAmount')}
                label={questionTotalAmount?.question?.text}
                placeholder="$0.00"
                schema={makeNumberSchema({
                  field: 'total amount',
                  allowedDecimals: 2,
                })}
                fullWidth
                componentType="currency"
              />
            </GridRowColumn>
            <GridRowColumn>
              <Divider />
            </GridRowColumn>
            <GridRowColumn
              short
              columnStyle={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 20,
              }}
            >
              <Button
                onClick={onCancel}
                style={{ border: 0 }}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                loading={isUpdating}
                variant="secondary"
                onClick={submitForm}
                disabled={!isValid || isUpdating}
              >
                Save
              </Button>
            </GridRowColumn>
          </Grid>
        </Card>
      )}
    </FormikProvider>
  )
}
