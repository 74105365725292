import {
  YearEndModuleStatusOptions,
  YearEndModuleType,
  FormFilingJobSubstep,
  FormFilingJobSubstepId,
  YearEndModuleSubstepId,
} from '../yearEndModuleStatus.slice'
import YearEndModuleBaseCard, {
  defaultDeadlineApproaching,
  getDeadlineApproachingDaysUntilDueDate,
  UpcomingStatusTextWrapper,
  YearEndModuleStatusDynamicDataParams,
} from './YearEndModuleStatusBaseCard'
import { Link, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import {
  getModuleNameCopy,
  useModuleActionButtonBehavior,
  getModuleComplementaryText,
} from '../yearEndModuleStatus.helpers'

const getDescription = (
  status: YearEndModuleStatusOptions,
  filingJobSubstepId?: YearEndModuleSubstepId | FormFilingJobSubstepId | null
) => {
  switch (status) {
    case YearEndModuleStatusOptions.upcoming: {
      return (
        <UpcomingStatusTextWrapper moduleType={YearEndModuleType.file1120S}>
          We’ll connect you with a tax preparer from our partner TaxFyle.
          They’ll work with you to finalize and submit your return.
          <div style={{ paddingTop: 16 }} />
          Complete your other tasks to reserve a live consultation with your tax
          preparer.
          <br />
        </UpcomingStatusTextWrapper>
      )
    }
    case YearEndModuleStatusOptions.waitingOnHeard: {
      switch (filingJobSubstepId) {
        case FormFilingJobSubstep.notClaimed:
          return 'We’re matching you to a tax preparer who will handle your business tax return. Your bookkeeping and tax information will be sent to them.'
        case FormFilingJobSubstep.inProgress:
          return 'Your tax preparer is currently working on your business tax return. They’ll reach out if they have any questions.'
        case FormFilingJobSubstep.draftApproved:
          return 'Preparing tax filing for your signature.'
        case FormFilingJobSubstep.signedAndApproved:
          return 'We’re filing your Form 1120-S with the IRS.'
        default:
          return 'Your tax preparer is working on your return.'
      }
    }
    case YearEndModuleStatusOptions.actionRequired: {
      switch (filingJobSubstepId) {
        case FormFilingJobSubstep.startedUserCTA:
          return 'Your return has been started.'
        case FormFilingJobSubstep.inProgressNeedsUserAction:
          return 'Your tax preparer has questions about your information.'
        case FormFilingJobSubstep.draftReadyForReview:
          return 'Your tax return draft is ready for review.'
        case FormFilingJobSubstep.signatureRequested:
          return 'Sign and submit your tax return.'
        case FormFilingJobSubstep.rejectedByIRS:
          return (
            <>
              Unfortunately, your tax return was <b>rejected</b> by the IRS.
              Please work with your tax preparer to resolve any issues with your
              return.
            </>
          )
        default:
          return 'Your tax return is being processed.'
      }
    }
    case YearEndModuleStatusOptions.complete: {
      return 'Your tax return has been filed.'
    }
    case YearEndModuleStatusOptions.cancelled: {
      return 'Your tax filing has been cancelled. No further action needed.'
    }
    default:
      return null
  }
}

const getAlertBody = (dueDate: string | null) => {
  const daysUntilDueDate = getDeadlineApproachingDaysUntilDueDate(dueDate)
  if (daysUntilDueDate === null) {
    return null
  }
  const link = (
    <Link
      href="https://support.joinheard.com/hc/en-us/articles/16958669406743-Why-did-I-receive-an-IRS-underpayment-penalty"
      newPage
      size="small"
      style={{
        textDecoration: 'underline',
        color: Colors.charcoal,
        textDecorationColor: Colors.charcoal,
      }}
    >
      More info
    </Link>
  )
  if (daysUntilDueDate <= 0) {
    return (
      <Text as="bodySm">
        After that, you may be at risk of penalties and interest. {link}
      </Text>
    )
  }
  return (
    <Text as="bodySm">
      This tax return is now overdue. You may be at risk of penalties and
      interest. Please file it ASAP to avoid additional fines. {link}
    </Text>
  )
}

const File1120SCard = ({
  status,
  dueDate,
  taxYear,
  substepIdentifier,
}: YearEndModuleStatusDynamicDataParams & {
  filingJobSubstepId?: FormFilingJobSubstepId
}) => {
  const description = getDescription(status, substepIdentifier)
  const showButtonOn =
    substepIdentifier !== FormFilingJobSubstep.notClaimed
      ? [
          YearEndModuleStatusOptions.upcoming,
          YearEndModuleStatusOptions.actionRequired,
          YearEndModuleStatusOptions.waitingOnHeard,
          YearEndModuleStatusOptions.cancelled,
        ]
      : []

  const { onActionClick } = useModuleActionButtonBehavior(
    YearEndModuleType.file1120S,
    status
  )

  return (
    <YearEndModuleBaseCard
      key={YearEndModuleType.file1120S}
      status={status}
      dueDate={dueDate}
      substepIdentifier={substepIdentifier}
      taxYear={taxYear}
      header={getModuleNameCopy(YearEndModuleType.file1120S)}
      imageUrl="https://heard-images.s3.amazonaws.com/assets/1120s-form.svg"
      description={<Text as="bodySm">{description}</Text>}
      timer={{
        unit: 'days',
        value: '7-10',
        complementaryText: getModuleComplementaryText(
          YearEndModuleType.file1120S
        ),
      }}
      deadlineApproaching={{
        ...defaultDeadlineApproaching,
        alertBody: getAlertBody(dueDate),
      }}
      button={{
        showOn: showButtonOn,
        onActionClick,
      }}
    />
  )
}

export default File1120SCard
