import { useCallback, useContext } from 'react'
import { Link } from '../../../../../components/BaseComponents'
import {
  EndOfYearAdminReviewStep,
  EndOfYearReviewStepStatus,
  OutstandingTransactionsStep,
} from '../../types'
import BookkeepingReviewSection from '../bookkeeping-review-section'
import SimpleRow from '../simple-row'
import { TEST_IDS } from '../helpers'
import { AdminYearEndReviewContext } from '../context'
import UpdatedRow from '../updated-row'
import './styles.scss'

const { pending, userActionRequired, complete } = EndOfYearReviewStepStatus
const STEP_TYPE = EndOfYearAdminReviewStep.transactions

export type OutstandingTransactionsSectionProps = {
  step: OutstandingTransactionsStep | null
  userId: number
}
const OutstandingTransactionsSection = ({
  step,
  userId,
}: OutstandingTransactionsSectionProps) => {
  const { updateStep, openAdminNoteModal } = useContext(
    AdminYearEndReviewContext
  )

  const onConfirmClick = useCallback(() => {
    const status = step?.status === complete ? pending : complete
    updateStep({
      step: step?.step ?? STEP_TYPE,
      status,
    })
  }, [step, updateStep])

  const onNeedsClarificationCallback = useCallback(
    async (adminNote: string) => {
      const status =
        step?.status === userActionRequired ? pending : userActionRequired
      await updateStep({
        status,
        step: step?.step ?? STEP_TYPE,
        context: { ...step?.context, adminNote },
      })
    },
    [step, updateStep]
  )

  const resetToPending = useCallback(async () => {
    await updateStep({
      step: step?.step ?? STEP_TYPE,
      status: pending,
      context: { ...step?.context, adminNote: undefined },
    })
  }, [step, updateStep])

  return (
    <BookkeepingReviewSection
      sectionTitle="Review outstanding transactions"
      className="eoy-admin__outstanding-transactions"
      status={step?.status ?? pending}
      updatedByUser={step?.context?.updatedByUser}
      confirmationTitle="Confirmed"
      confirmationDescription={
        step?.status === userActionRequired ? (
          <strong>Requesting follow-up on transactions.</strong>
        ) : null
      }
      minHeight={130}
      testId="outstanding-transactions"
      onConfirmClick={onConfirmClick}
    >
      {step?.context?.updatedByUser && (
        <UpdatedRow
          description="User has provided new categorizations or notes"
          testId={TEST_IDS.transactionsUpdatedRow}
        />
      )}

      <SimpleRow
        description="Check if the user has outstanding transactions"
        checkboxLabel="Needs clarification"
        checked={step?.status === userActionRequired}
        testId={TEST_IDS.checkIfOutstandingTransactions}
        onCheckboxClick={() =>
          step?.status === userActionRequired
            ? resetToPending()
            : openAdminNoteModal(
                step?.context?.adminNote ?? null,
                onNeedsClarificationCallback
              )
        }
      />

      <Link
        className="eoy-admin__outstanding-transactions__view-transactions"
        to={`/admin/finances/transactions/${userId}`}
        size="small"
      >
        View transactions &rarr;
      </Link>
    </BookkeepingReviewSection>
  )
}

export default OutstandingTransactionsSection
