export const ABOUND_STATUS = Object.freeze({
  created: 'created',
  verifying: 'verifying',
  pending: 'pending',
  error: 'error',
  done: 'done',

  // v4 below
  not_started: 'not_started',
  filed: 'filed',
  accepted: 'accepted',
  rejected: 'rejected',
  voided: 'voided',
  corrected: 'corrected',
  deleted: 'deleted',
})

export const ABOUND_ERROR_TYPES = Object.freeze({
  ssnVerificationError: 'ssnVerificationError',
  userError: 'userError',
  payerError: 'payerError',
  documentError: 'documentError',
})

export const zeroToTenIntToWord = Object.freeze({
  0: 'zero',
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
})

export const TIN_VERIFICATION_STATUS = Object.freeze({
  unverified: 'unverified',
  verified: 'verified',
  mismatch: 'mismatch',
  pending: 'pending',
  error: 'error',
  lockedOut: 'lockedOut',
  match: 'match',
})

export const TEN_99_STEPS = [
  'Confirm Business Details',
  'Add or Update Contractor Details',
  'Review',
]
