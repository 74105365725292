import { Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import { DeductionsAndCreditsStepProps } from '.'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TaxListQuestionId, useTaxListQuestionRes } from '../../service'
import {
  FormikInput,
  GridRowColumn,
  Text,
  makeNumberSchema,
  getFieldNames,
  FormikScrollOnError,
} from '../../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import UploadDocumentSubSection from '../../Documents/UploadDocumentSubSection'
import { UserDocumentCategoryIdentifier } from '../../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import SkipQuestion, {
  createSkipSchema,
  skipQuestionId,
  useSkipQuestion,
} from '../../Shared/SkipQuestion'
import { tqBigSpace, tqSmallSpace } from '../../helpers'
import { select1040FormForYear } from '../../../annualTaxFilingForms.selector'

export const businessMileageQuestionIds = [TaxListQuestionId.business_mileage]
const HadBusinessMileagePanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: DeductionsAndCreditsStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const form1040 = useReselector(select1040FormForYear, taxYear)
  const formId = form1040?.id

  const businessMileageQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.business_mileage,
    formId,
  })

  const skippedQuestions = useSkipQuestion(businessMileageQuestionIds)

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.business_mileage]: businessMileageQR.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData = [businessMileageQR.getResponseData(values)]

      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.businessMileage],
      })
    },
  })

  const { submitForm, isSubmitting, values } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <FormikScrollOnError />
      <Grid>
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Business Mileage
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg" textAlign="left">
            You may claim a deduction on business miles traveled this year. If
            you use a personal car for business, only miles traveled while on
            business can be deducted.
            <br />
            <br />
            As a reminder, daily commutes do not qualify for mileage deductions.
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace}>
          <FormikInput
            name={fieldNames[TaxListQuestionId.business_mileage]}
            label={businessMileageQR.label}
            schema={createSkipSchema(
              values,
              TaxListQuestionId.business_mileage,
              makeNumberSchema({
                field: 'business mileage',
              })
            )}
            description="Enter an estimate"
            fullWidth
            placeholder={`Total estimated miles in ${taxYear}`}
          />
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace} short>
          <SkipQuestion questionId={TaxListQuestionId.business_mileage} />
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace} centerContent>
          <Divider />
        </GridRowColumn>
        <UploadDocumentSubSection
          categories={
            values[TaxListQuestionId.business_mileage]
              ? [
                  UserDocumentCategoryIdentifier.carRegistration,
                  UserDocumentCategoryIdentifier.mileageTracker,
                ]
              : []
          }
        />
        <Grid.Row />
        <FormFlowFooter
          continueDisabled={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default HadBusinessMileagePanel
