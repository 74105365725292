import { useEffect, useMemo, useState } from 'react'
import { Divider } from 'semantic-ui-react'

import {
  Table,
  Dropdown,
  Pagination,
  Loader,
} from '../../../../components/BaseComponents'
import AnnualTaxFilingTableData from './AnnualTaxFilingTableData'
import {
  AboundTaxDocument,
  AboundTaxDocumentAboundStatus,
  AboundTaxDocumentInternalStatus,
  fetchAdminAboundAnnualTaxFilings,
} from '../../../Taxes/AnnualTaxes/aboundAnnualTaxFilings.slice'
import { fetchAllUsersIfNeeded } from '../../../../actions/admin/adminAllUsersActions'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../AnnualTaxDetails/annualTaxDetails.slice'
import { useReselector } from '../../../../utils/sharedHooks'
import { getAllUsersById } from '../../../../selectors/user.selectors'
import { valsToDropdownOptions } from '../../../../components/BaseComponents/Dropdown'
import PageHeader from '../../../../components/shared/PageHeader'
import { useAppDispatch } from '../../../../utils/typeHelpers'
const LIMIT = 10

const AboundTaxFilingsTable = () => {
  const dispatch = useAppDispatch()
  const allUsers = useReselector(getAllUsersById)
  const [fetching, setFetching] = useState(true)
  const [page, setPage] = useState(1)
  const [aboundTaxDocs, setAboundTaxDocs] = useState<AboundTaxDocument[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [searchedUserId, setSearchedUserId] = useState<number>()
  const [filteredAboundStatus, setFilteredAboundStatus] =
    useState<AboundTaxDocumentAboundStatus>()
  const [filteredInternalStatus, setFilteredInternalStatus] =
    useState<AboundTaxDocumentInternalStatus>()
  const [filteredYear, setFilteredYear] = useState(2024)

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true)
      await dispatch(fetchAllUsersIfNeeded())
      await dispatch(fetchAllAnnualTaxDetailsIfNeeded())
      const response = await fetchAdminAboundAnnualTaxFilings({
        page,
        limit: LIMIT,
        userId: searchedUserId,
        aboundStatus: filteredAboundStatus,
        internalStatus: filteredInternalStatus,
        calendarYear: filteredYear,
      })(dispatch)
      if (response) {
        const { count, documents } = response
        setTotalCount(count)
        setAboundTaxDocs(documents)
      }
      setFetching(false)
    }

    fetchData()
  }, [
    page,
    searchedUserId,
    dispatch,
    filteredAboundStatus,
    filteredInternalStatus,
    filteredYear,
  ])

  useEffect(() => {
    setPage(1)
  }, [
    searchedUserId,
    filteredAboundStatus,
    filteredInternalStatus,
    filteredYear,
  ])

  const pagesNeeded = Math.ceil(totalCount / LIMIT)
  const userOptions = useMemo(
    () =>
      Object.values(allUsers).map((user) => ({
        key: user.id,
        text: `${user.firstName} ${user.lastName}`,
        value: user.id,
      })),
    [allUsers]
  )

  return (
    <>
      <PageHeader header="Abound 1099-NEC Filing Status" />
      <br />
      <div className="flexbox">
        <Dropdown
          search
          clearable
          label="Filter by User"
          placeholder="Search for User"
          value={searchedUserId}
          options={userOptions}
          onChange={setSearchedUserId}
        />
        <Dropdown
          clearable
          label="Filter by Abound Status"
          placeholder="Choose Filter"
          value={filteredAboundStatus}
          options={valsToDropdownOptions(
            Object.values(AboundTaxDocumentAboundStatus)
          )}
          onChange={setFilteredAboundStatus}
        />
        <Dropdown
          clearable
          label="Filter by Internal Status"
          placeholder="Choose Filter"
          value={filteredInternalStatus}
          options={valsToDropdownOptions(
            Object.values(AboundTaxDocumentInternalStatus)
          )}
          onChange={setFilteredInternalStatus}
        />
        <Dropdown
          label="Filter by Year"
          placeholder="Choose Filter"
          value={filteredYear}
          options={valsToDropdownOptions([2022, 2023, 2024])}
          onChange={setFilteredYear}
        />
      </div>
      <Divider />
      {/*div container to display loader correctly*/}
      <div style={{ position: 'relative' }}>
        <Table striped celled compact="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>User + Contractor</Table.HeaderCell>
              <Table.HeaderCell>Last Updated</Table.HeaderCell>
              <Table.HeaderCell>Tin Verification Status</Table.HeaderCell>
              <Table.HeaderCell>Abound Status</Table.HeaderCell>
              <Table.HeaderCell>Internal Status</Table.HeaderCell>
              <Table.HeaderCell>Mailing Status</Table.HeaderCell>
              <Table.HeaderCell>Abound Requests</Table.HeaderCell>
              <Table.HeaderCell>Filed At</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {fetching && (
              <Table.Row>
                <Table.Cell colSpan="7">
                  <Loader loading />
                </Table.Cell>
              </Table.Row>
            )}
            {aboundTaxDocs.map((doc) => (
              <AnnualTaxFilingTableData key={doc.id} document={doc} />
            ))}
          </Table.Body>
        </Table>
      </div>
      {totalCount > LIMIT && (
        <Pagination
          currentPage={page}
          onPageClick={setPage}
          pages={pagesNeeded}
        />
      )}
    </>
  )
}

export default AboundTaxFilingsTable
