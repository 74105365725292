import { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Modal,
  Text,
  TextArea,
} from '../../../../../components/BaseComponents'
import {
  AdminNoteState,
  ConfirmModalState,
  DocumentReUploadState,
  StatementReUploadState,
} from '../../types'
import './styles.scss'
import { TEST_IDS } from '../helpers'

type BaseModalProps = {
  headerContent: React.ReactNode
  descriptionContent: React.ReactNode
  noteValue: string | null
  open: boolean
  loading: boolean
  onCancel: () => void
  onSave: (newNote: string) => void
}
const BaseModal = ({
  headerContent,
  descriptionContent,
  noteValue,
  loading,
  open,
  onCancel,
  onSave,
}: BaseModalProps) => {
  const [note, setNote] = useState<string | null>(noteValue)

  const onCancelClick = () => {
    setNote(null)
    onCancel()
  }

  useEffect(() => setNote(noteValue), [noteValue])

  return (
    <Modal size="tiny" open={open} onClose={onCancelClick} closeIcon>
      <Modal.Header content={headerContent} />
      <Modal.Content>
        <Text
          className="eoy-admin__base-modal__description"
          testId={TEST_IDS.baseModalDescription}
        >
          {descriptionContent}
        </Text>
        <TextArea
          placeholder="Enter a note"
          value={note ?? ''}
          onChange={(value: string) => setNote(value)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button
          onClick={() => onSave(note ?? '')}
          disabled={!note}
          loading={loading}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export type AdminNoteModalProps = {
  state: AdminNoteState
  setState: React.Dispatch<React.SetStateAction<AdminNoteState>>
}
export const AdminNoteModal = ({ state, setState }: AdminNoteModalProps) => {
  const onCancel = useCallback(() => {
    setState({
      note: null,
      open: false,
      loading: false,
      onSave: null,
    })
  }, [setState])

  const onSave = useCallback(
    async (note: string) => {
      if (state?.onSave) {
        setState((prev) => ({ ...prev, loading: true }))
        await state.onSave(note)
      }
      setState({ open: false, loading: false, note: null, onSave: null })
    },
    [state, setState]
  )

  return (
    <BaseModal
      headerContent="Add note for user"
      descriptionContent="Add a note explaining any changes, context, or requests for clarification."
      noteValue={state.note}
      open={state.open}
      loading={state.loading}
      onCancel={onCancel}
      onSave={onSave}
    />
  )
}

export type StatementReUploadModalProps = {
  state: StatementReUploadState
  setState: React.Dispatch<React.SetStateAction<StatementReUploadState>>
}
export const StatementReUploadModal = ({
  state,
  setState,
}: StatementReUploadModalProps) => {
  const onCancel = useCallback(() => {
    setState({
      note: null,
      open: false,
      loading: false,
      onSave: null,
    })
  }, [setState])

  const onSave = useCallback(
    async (note: string) => {
      if (state?.onSave) {
        setState((prev) => ({ ...prev, loading: true }))
        await state.onSave(note)
      }
      setState({ open: false, loading: false, note: null, onSave: null })
    },
    [state, setState]
  )

  return (
    <BaseModal
      headerContent="Request bank statement reupload"
      descriptionContent={
        <>
          <strong>Required:</strong> Please add a note explaining why the
          uploaded statement(s) weren&apos;t correct.
        </>
      }
      noteValue={state.note}
      open={state.open}
      loading={state.loading}
      onCancel={onCancel}
      onSave={onSave}
    />
  )
}

export type DocumentReUploadModalProps = {
  state: DocumentReUploadState
  setState: React.Dispatch<React.SetStateAction<DocumentReUploadState>>
}
export const DocumentReUploadModal = ({
  state,
  setState,
}: DocumentReUploadModalProps) => {
  const onCancel = useCallback(() => {
    setState({
      note: null,
      open: false,
      loading: false,
      onSave: null,
    })
  }, [setState])

  const onSave = useCallback(
    async (note: string) => {
      if (state?.onSave) {
        setState((prev) => ({ ...prev, loading: true }))
        await state.onSave(note)
      }
      setState({ open: false, loading: false, note: null, onSave: null })
    },
    [state, setState]
  )

  return (
    <BaseModal
      headerContent="Request document reupload"
      descriptionContent={
        <>
          <strong>Required:</strong> Please add a note explaining why the
          uploaded document wasn&apos;t correct and what the user should upload
          instead.
        </>
      }
      noteValue={state.note}
      open={state.open}
      loading={state.loading}
      onCancel={onCancel}
      onSave={onSave}
    />
  )
}

export type ConfirmHandoffModalProps = {
  state: ConfirmModalState
  setState: React.Dispatch<React.SetStateAction<ConfirmModalState>>
  submitModule: () => Promise<void>
}
export const ConfirmHandoffModal = ({
  state,
  setState,
  submitModule,
}: ConfirmHandoffModalProps) => {
  const onCancel = useCallback(
    () => setState({ open: false, loading: false }),
    [setState]
  )

  const onSave = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }))
    await submitModule()
    setState({ open: false, loading: false })
  }, [setState, submitModule])

  return (
    <Modal size="tiny" open={state.open} onClose={onCancel} closeIcon>
      <Modal.Header content="Confirm Handoff" />
      <Modal.Content>
        <Text
          className="eoy-admin__confirm-modal__description"
          testId={TEST_IDS.confirmModalDescription}
        >
          Once books are handed off to the user for follow-up,{' '}
          <span>no further changes can be made</span> unless updates are
          requested.
        </Text>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onSave} loading={state.loading}>
          Confirm Handoff
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
