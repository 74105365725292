import { useCallback, useState } from 'react'
import { Moment } from 'moment'
import { Text, IconButton, Button } from '../../../BaseComponents'
import { Icon } from 'semantic-ui-react'
import FileUploadModal from '../../../FileUpload/FileUploadModal'
import { DeleteStatement } from '../DeleteStatementModal'
import { UploadDocumentType } from '../../../../constants/businessConstants'
import { UserDocument } from '../../../../features/UserDocuments/userDocuments.slice'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import styles from './styles.module.scss'

export const MissingStatementCard = ({
  onUploadOrDelete,
}: {
  onUploadOrDelete: (id: number, isDelete: boolean) => void
}) => {
  const [uploadedStatements, setUploadedStatements] = useState<UserDocument[]>(
    []
  )
  const [openUpload, setOpenUpload] = useState(false)
  const [deleteDocumentModalOpen, setDeleteDocumentModalOpen] = useState(false)
  const [documentToDelete, setDocumentToDelete] = useState<UserDocument>()

  const setUploadedFile = useCallback(
    ({
      doc,
    }: {
      statementMonth?: Moment
      fileName?: string
      id?: number
      doc?: UserDocument
    }) => {
      if (doc?.id) {
        setUploadedStatements((prev) => [...prev, doc])
        onUploadOrDelete(doc.id, false)
      }
    },
    [onUploadOrDelete]
  )

  return (
    <div className={styles.bodyCard}>
      <div className={styles.topRow}>
        <div className={styles.isChecked}>
          <Button
            icon
            labelPosition="left"
            onClick={() => setOpenUpload(true)}
            style={{ width: '147px', fontSize: '14px' }}
            variant="primary"
          >
            Upload
            <Icon
              name="upload"
              style={{
                background: 'none',
                border: 'none',
                boxShadow: 'none',
                fontSize: '16px',
              }}
            />
          </Button>
        </div>
      </div>
      <div className={styles.fileListCard}>
        {Boolean(uploadedStatements.length) && (
          <>
            {uploadedStatements.map((file) => (
              <Text key={file.id}>
                {file.fileDescription}
                <IconButton
                  onClick={() => {
                    setDeleteDocumentModalOpen(true)
                    setDocumentToDelete(file)
                    onUploadOrDelete(file.id, true)
                  }}
                  icon={regular('trash-can')}
                  style={{ marginLeft: 6 }}
                />
              </Text>
            ))}
          </>
        )}
      </div>
      <FileUploadModal
        documentType={UploadDocumentType.STATEMENT}
        open={openUpload}
        close={() => setOpenUpload(false)}
        userFacing
        setUploadedFile={setUploadedFile}
      />
      <DeleteStatement
        document={documentToDelete}
        open={deleteDocumentModalOpen}
        close={() => setDeleteDocumentModalOpen(false)}
        updateDocumentStatements={() =>
          setUploadedStatements(
            uploadedStatements.filter((doc) => doc.id !== documentToDelete?.id)
          )
        }
      />
    </div>
  )
}
