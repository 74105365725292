import { useParams } from 'react-router-dom'
import { Divider, Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import {
  FormikInput,
  FormikScrollOnError,
  getFieldNames,
  GridRowColumn,
  makeNumberSchema,
  Popup,
  Text,
} from '../../../../../../components/BaseComponents'
import { TaxListQuestionId, useTaxListQuestionRes } from '../../service'
import { IncomeAndLiabilitiesStepProps } from '.'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import UploadDocumentSubSection from '../../Documents/UploadDocumentSubSection'
import { UserDocumentCategoryIdentifier } from '../../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import SkipQuestion, {
  createSkipSchema,
  skipQuestionId,
  useSkipQuestion,
} from '../../Shared/SkipQuestion'
import { TQYesNoResponse } from '../../Shared/FormHelpers'
import { tqBigSpace, tqSmallSpace } from '../../helpers'

export const lessCommonScenarioFollowUpQuestionIds = [
  TaxListQuestionId.crypto_made_txns,
  TaxListQuestionId.crypto_sold,
  TaxListQuestionId.crypto_statement,
  TaxListQuestionId.number_of_crypto_statements,
  TaxListQuestionId.k1_income,
  TaxListQuestionId.number_of_k1s_sprop,
  TaxListQuestionId.foreign_income_or_taxes,
  TaxListQuestionId.disability_income,
  TaxListQuestionId.gave_large_gifts,
]
const LessCommonScenariosPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: IncomeAndLiabilitiesStepProps) => {
  const { formId: formIdParam } = useParams()
  const formId = Number(formIdParam)

  const cryptoTxnsQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.crypto_made_txns,
    formId,
  })

  const cryptoSoldQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.crypto_sold,
    formId,
  })

  const cryptoStatementQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.crypto_statement,
    formId,
  })

  const numberOfCryptoStatementsQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.number_of_crypto_statements,
    formId,
  })

  const incomeFromK1QR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.k1_income,
    formId,
  })

  const numberOfK1s = useTaxListQuestionRes({
    questionId: TaxListQuestionId.number_of_k1s_sprop,
    formId,
  })

  const foreignIncomeQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.foreign_income_or_taxes,
    formId,
  })

  const disabilityIncomeQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.disability_income,
    formId,
  })

  const gaveLargeGiftsQR = useTaxListQuestionRes({
    questionId: TaxListQuestionId.gave_large_gifts,
    formId,
  })

  const skippedQuestions = useSkipQuestion(
    lessCommonScenarioFollowUpQuestionIds
  )

  const formik = useFormik({
    initialValues: {
      [TaxListQuestionId.crypto_made_txns]: cryptoTxnsQR.initialResponse,
      [TaxListQuestionId.crypto_sold]: cryptoSoldQR.initialResponse,
      [TaxListQuestionId.crypto_statement]: cryptoStatementQR.initialResponse,
      [TaxListQuestionId.number_of_crypto_statements]:
        numberOfCryptoStatementsQR.initialResponse,
      [TaxListQuestionId.k1_income]: incomeFromK1QR.initialResponse,
      [TaxListQuestionId.number_of_k1s_sprop]: numberOfK1s.initialResponse,
      [TaxListQuestionId.foreign_income_or_taxes]:
        foreignIncomeQR.initialResponse,
      [TaxListQuestionId.disability_income]: disabilityIncomeQR.initialResponse,
      [TaxListQuestionId.gave_large_gifts]: gaveLargeGiftsQR.initialResponse,
      [skipQuestionId]: skippedQuestions.initialValue,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (values[TaxListQuestionId.crypto_statement] !== true) {
        values[TaxListQuestionId.number_of_crypto_statements] = 0
      }
      if (values[TaxListQuestionId.number_of_k1s_sprop] !== true) {
        values[TaxListQuestionId.number_of_k1s_sprop] = 0
      }
      const responseData = [
        cryptoTxnsQR.getResponseData(values),
        cryptoSoldQR.getResponseData(values),
        cryptoStatementQR.getResponseData(values),
        numberOfCryptoStatementsQR.getResponseData(values),
        incomeFromK1QR.getResponseData(values),
        numberOfK1s.getResponseData(values),
        foreignIncomeQR.getResponseData(values),
        disabilityIncomeQR.getResponseData(values),
        gaveLargeGiftsQR.getResponseData(values),
      ]

      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.lessCommonScenarios1040],
      })
    },
  })

  const { values, submitForm, isSubmitting } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <FormikScrollOnError />
      <Grid>
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Less Common Scenarios
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg" textAlign="center">
            We have a few more questions based upon your selections from the
            previous screen.
          </Text>
        </GridRowColumn>

        <TQYesNoResponse
          questionResponse={cryptoTxnsQR}
          skippable
          description="(e.g. cryptocurrency)"
          afterLabel={
            <Popup
              content={
                <>
                  <Text as="h3">Cryptocurrency</Text>
                  <br />
                  <Text>
                    Not every crypto company will issue you a 1099-B. If you
                    haven’t received one in time to complete the questionnaire,
                    you’ll export your cryptocurrency transaction history
                    instead. Each company should have a way for you to download
                    your transactions as a CSV file.
                  </Text>
                </>
              }
            />
          }
        />

        {values[TaxListQuestionId.crypto_made_txns] && (
          <>
            <TQYesNoResponse
              questionResponse={cryptoSoldQR}
              skippable
              description="(e.g. cryptocurrency)"
            />
            <TQYesNoResponse questionResponse={cryptoStatementQR} skippable />
          </>
        )}

        {values[TaxListQuestionId.crypto_statement] && (
          <>
            <GridRowColumn {...tqSmallSpace}>
              <FormikInput
                name={fieldNames[TaxListQuestionId.number_of_crypto_statements]}
                label={numberOfCryptoStatementsQR.label}
                componentType="number"
                prefix=""
                schema={createSkipSchema(
                  values,
                  TaxListQuestionId.number_of_crypto_statements,
                  makeNumberSchema({ allowedDecimals: 0 })
                )}
                placeholder="Enter number"
                decimalScale={0}
                fullWidth
              />
            </GridRowColumn>
            <GridRowColumn {...tqSmallSpace} short>
              <SkipQuestion
                questionId={TaxListQuestionId.number_of_crypto_statements}
              />
            </GridRowColumn>
          </>
        )}

        <TQYesNoResponse
          questionResponse={incomeFromK1QR}
          skippable
          description="(e.g. Partnerships, S corps, or Trusts)"
          afterLabel={
            <Popup
              content={
                <>
                  <Text as="h3">Received income from K-1s</Text>
                  <br />
                  <Text>
                    This applies to you if you have a partnership or a
                    corporation outside of Heard, or if you’re a shareholder in
                    any business outside of your therapy practice.
                  </Text>
                </>
              }
            />
          }
        />

        {values[TaxListQuestionId.k1_income] && (
          <>
            <GridRowColumn {...tqSmallSpace}>
              <FormikInput
                name={fieldNames[TaxListQuestionId.number_of_k1s_sprop]}
                label={numberOfK1s.label}
                componentType="number"
                prefix=""
                schema={createSkipSchema(
                  values,
                  TaxListQuestionId.number_of_k1s_sprop,
                  makeNumberSchema({ allowedDecimals: 0 })
                )}
                placeholder="Enter number"
                decimalScale={0}
                fullWidth
              />
            </GridRowColumn>
            <GridRowColumn {...tqSmallSpace} short>
              <SkipQuestion
                questionId={TaxListQuestionId.number_of_k1s_sprop}
              />
            </GridRowColumn>
          </>
        )}

        <TQYesNoResponse
          questionResponse={foreignIncomeQR}
          skippable
          requiresFollowup
        />

        <TQYesNoResponse questionResponse={disabilityIncomeQR} skippable />

        <TQYesNoResponse
          questionResponse={gaveLargeGiftsQR}
          skippable
          requiresFollowup
        />

        <GridRowColumn short {...tqSmallSpace}>
          <Divider />
        </GridRowColumn>

        <UploadDocumentSubSection
          categories={[
            values[TaxListQuestionId.crypto_statement]
              ? UserDocumentCategoryIdentifier.form1099b
              : undefined,
            values[TaxListQuestionId.crypto_statement] === false
              ? UserDocumentCategoryIdentifier.cryptoTransactions
              : undefined,
          ]}
        />

        <Grid.Row />
        <FormFlowFooter
          continueDisabled={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default LessCommonScenariosPanel
