import { findIndex, sortBy } from 'lodash'
import {
  Document,
  EndOfYearAdminReviewStep,
  EndOfYearReviewStepStatus,
  EndOfYearReviewSubmissionStep,
  IFixedAssetDepreciationStepContext,
  KeyedFetchError,
  Receipt,
} from '../types'

/**
 * Only set errors if they have changed
 *
 * @param prevErrors previous errors
 * @param newErrors new errors
 * @returns newErrors if changes, else prevErrors
 */
export const prevOrNewErrors = (
  prevErrors: KeyedFetchError[],
  newErrors: KeyedFetchError[]
) => {
  if (prevErrors.length === newErrors.length) {
    if (
      newErrors.every((newError) =>
        prevErrors.some(
          (prevError) =>
            prevError.key === newError.key &&
            prevError.message === newError.message
        )
      )
    ) {
      // No changes detected
      return prevErrors
    }
  }
  return newErrors
}

export const NOT_RELEVANT_REASONS = [
  { text: 'I haven’t used this vendor in 2024', value: 'notUsed' },
  {
    text: 'I don’t have access to this vendor anymore',
    value: 'noAccess',
  },
]

export const sortDocuments = <T extends Document | Receipt>(
  documents?: T[]
): T[] => {
  if (documents && documents.length > 0 && 'categoryCode' in documents[0]) {
    return sortBy(documents, ['provider', 'categoryCode', 'name'])
  }
  return documents ?? []
}

export const replaceDocument = (
  document: Document,
  documents?: Document[]
): Document[] => {
  const copy = [...(documents ?? [])]
  const index = findIndex(copy, (doc) =>
    doc.documentId
      ? doc.documentId === document.documentId
      : doc.provider === document.provider &&
        doc.categoryCode === document.categoryCode &&
        doc.name === document.name
  )

  if (index === -1) {
    return [...copy, document]
  }

  copy.splice(index, 1, document)
  return copy
}

// Some useful predicates
export const stepIsNot =
  (steps: EndOfYearAdminReviewStep[]) =>
  (step: EndOfYearReviewSubmissionStep) =>
    !steps.includes(step.step)

export const stepStatusIs =
  (status: EndOfYearReviewStepStatus) =>
  (step: EndOfYearReviewSubmissionStep) =>
    step.status === status

export const TEST_IDS = {
  error: (key: string) => `eoy-admin__error__${key}`,
  group: (root?: string) => (root ? `${root}__group` : undefined),
  submissionViewHeader: 'submission-view-header',
  submissionFinalReviewGroup: 'submission-final-review-group',
  submissionBookkeepingGroup: 'submission-bookkeeping-group',
  submissionDocumentsGroup: 'submission-documents-group',
  bkReviewSectionTitle: (root?: string) =>
    root ? `${root}__title-col` : undefined,
  bkReviewConfirmDescription: (root?: string) =>
    root ? `${root}__confirm-description` : undefined,
  cannotReconnectTitle: 'cannot-reconnect-title',
  cannotReconnectAccount: (id: number) => `cannot-reconnect-account_${id}`,
  allAccountsConnected: 'all-accounts-connected',
  checkIfOutstandingTransactions: 'check-if-outstanding-transactions',
  checkIfTxNeedClarification: 'check-if-tx-need-clarification',
  checkIfMissingBankStatements: 'check-if-missing-bank-statements',
  checkIfNextYearStatementsNeeded: 'check-if-next-year-statements-needed',
  baseModalDescription: 'eoy-admin_base-modal_description',
  confirmModalDescription: 'eoy-admin_confirm-handoff-modal_description',
  documentRowTitle: (root?: string) =>
    root ? `${root}__document-row-title` : undefined,
  documentRowNotRelevantReason: (root?: string) =>
    root ? `${root}__document-row-nr-reason` : undefined,
  documentRowNotRelevantContext: (root?: string) =>
    root ? `${root}__document-row-nr-context` : undefined,
  documentRowNote: (root?: string) =>
    root ? `${root}__document-row-note` : undefined,
  stepNoRelevant: 'admin-eoy_step-not-relevant',
  transactionAccordionDate: 'admin-eoy_tx-accordion_date',
  transactionAccordionDescription: 'admin-eoy_tx-accordion_description',
  transactionAccordionCategory: 'admin-eoy_tx-accordion_category',
  transactionAccordionAmount: 'admin-eoy_tx-accordion_amount',
  missingStatementsUpdatedRow: 'missing-statements-updated-row',
  transactionsUpdatedRow: 'transactions-updated-row',
  finalReviewUpdatedRow: 'final-review-updated-row',
}

/**
 * This assets over $2,500 step context schema changed after launch
 * to allow a BK to request reupload and add a note to a receipt.
 * The following code merges receiptIds into receipts so that we are
 * backwards compatible when Unify 1 launches. We have a backfill task
 * we will perform after Unify 1 launches to fix the data and remove
 * the back-compat code.
 *
 * https://linear.app/heard/issue/HEA-5264/backfill-asset-depreciation-step-context
 *
 */
export const migrateAssetStepContext = (
  context: IFixedAssetDepreciationStepContext | undefined
) => {
  if (!context) {
    return { receipts: [], otherDocumentIds: [] }
  }

  const receiptIds = context.receiptIds ?? []
  const receipts = context.receipts ?? []

  if (receiptIds.length === 0) {
    return context
  }

  const missingReceiptIds = receiptIds.filter(
    (id) => !receipts.some((r) => r.documentId === id)
  )
  const migratedReceipts = [
    ...missingReceiptIds.map((id) => ({ documentId: id }) as Receipt),
    ...receipts,
  ]

  return {
    ...context,
    receipts: migratedReceipts,
    receiptIds: undefined,
  }
}
