import { UserDocument } from '../../../../UserDocuments/userDocuments.slice'
import { Link, Text } from '../../../../../components/BaseComponents'
import './styles.scss'

export type OtherDocumentsProps = {
  showHeader: boolean
  userDocuments: UserDocument[]
}
const OtherDocuments = ({ showHeader, userDocuments }: OtherDocumentsProps) => {
  if (userDocuments.length === 0) {
    return null
  }
  return (
    <div className="eoy-admin__other-documents">
      {showHeader && (
        <div className="eoy-admin__other-documents__header">
          <Text as="eyebrow" color="darkGray">
            Other Documents
          </Text>
        </div>
      )}
      {userDocuments.map((document) => (
        <div className="eoy-admin__other-documents__document" key={document.id}>
          <Link href={document.signedUrl ?? ''}>
            {document.fileDescription}
          </Link>
        </div>
      ))}
    </div>
  )
}

export default OtherDocuments
