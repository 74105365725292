import { Divider, Grid } from 'semantic-ui-react'
import {
  Alert,
  GridRowColumn,
  Loader,
  Modal,
  Text,
} from '../../../components/BaseComponents'
import { DATE_FORMATS_LUXON } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import {
  fetchTaxfyleJobHistory,
  getAdminTaxfyleJobHistoryKey,
  isTaxfyleJobHistoryMember,
  TaxfyleJobHistory,
  TaxfyleJobHistoryChangedField,
} from './adminTaxfyleJobHistory.slice'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { getFetchError, selectIsFetchingForKeys } from '../../../reducers/fetch'

const getRoleDescription = (role: 'CHAMPION' | 'SUPPORTING') => {
  switch (role) {
    case 'CHAMPION':
      return 'the primary provider'
    case 'SUPPORTING':
      return 'a supporting provider'
    default:
      return role satisfies never
  }
}

const getActivityDescription = (activity: TaxfyleJobHistory) => {
  switch (activity.changedField) {
    case TaxfyleJobHistoryChangedField.jobStatus:
      return `Job status${activity.formType ? ` for ${activity.formType}` : ''} changed from ${activity.oldValue ?? 'none'} to ${activity.newValue ?? 'none'}`
    case TaxfyleJobHistoryChangedField.jobStep:
      return `Job step${activity.formType ? ` for ${activity.formType}` : ''} changed from ${activity.oldValue ?? 'none'} to ${activity.newValue ?? 'none'}`
    case TaxfyleJobHistoryChangedField.services:
      return `${activity.newValue} service added to job`
    case TaxfyleJobHistoryChangedField.members: {
      const oldMember = isTaxfyleJobHistoryMember(activity.oldValue)
        ? activity.oldValue
        : null
      const newMember = isTaxfyleJobHistoryMember(activity.newValue)
        ? activity.newValue
        : null
      if (oldMember && newMember && oldMember.role !== newMember.role) {
        return `${(oldMember ?? newMember)?.display_name}'s role changed from ${getRoleDescription(oldMember?.role)} to ${getRoleDescription(newMember?.role)}`
      } else if (oldMember && !newMember) {
        return `${oldMember.display_name} removed from job as ${getRoleDescription(oldMember.role)}`
      } else if (newMember && !oldMember) {
        return `${newMember.display_name} added to job as ${getRoleDescription(newMember.role)}`
      }
      return `Provider changed from ${oldMember ?? 'none'} to ${newMember ?? 'none'}`
    }
    case TaxfyleJobHistoryChangedField.transferredProviderReason:
      return `The provider on the job was transferred due to ${activity.newValue}`
    default:
      return activity.changedField satisfies never
  }
}

const HistoryRecord = ({
  date,
  description,
}: {
  date: string
  description: string
}) => (
  <Grid.Row short>
    <Grid.Column width={5}>
      <Text>
        {DateTime.fromISO(date)?.toFormat(DATE_FORMATS_LUXON.DISPLAY_FULL)}
      </Text>
    </Grid.Column>
    <Grid.Column width={11}>
      <Text>{description}</Text>
    </Grid.Column>
  </Grid.Row>
)

export const TaxfyleHistoryContent = ({
  taxfyleJobId,
  createdAt,
  open,
}: {
  taxfyleJobId: string
  createdAt: string
  open: boolean
}) => {
  const [history, setHistory] = useState<TaxfyleJobHistory[]>()
  const dispatch = useAppDispatch()
  const isLoading = useReselector(selectIsFetchingForKeys, [
    getAdminTaxfyleJobHistoryKey(taxfyleJobId),
  ])
  const error = useReselector(
    getFetchError,
    getAdminTaxfyleJobHistoryKey(taxfyleJobId)
  )

  useEffect(() => {
    const loadData = async () => {
      if (open) {
        const res = await fetchTaxfyleJobHistory(taxfyleJobId)(dispatch)
        if (res) {
          setHistory(res)
        }
      }
    }

    loadData()
  }, [dispatch, taxfyleJobId, open])

  return (
    <>
      <Loader loading={isLoading} />
      <Grid>
        {error?.message && (
          <GridRowColumn short>
            <Alert type="error">{error.message}</Alert>
          </GridRowColumn>
        )}
        {history?.map((activity) => (
          <>
            <Divider />
            <HistoryRecord
              key={activity.id}
              date={activity.createdAt}
              description={getActivityDescription(activity)}
            />
          </>
        ))}
        <Divider />
        <HistoryRecord date={createdAt} description="Job created" />
        <Divider />
      </Grid>
    </>
  )
}

const TaxfyleJobHistoryModal = ({
  taxfyleJobId,
  createdAt,
  open,
  onClose,
}: {
  taxfyleJobId: string
  createdAt: string
  open: boolean
  onClose: () => void
}) => (
  <Modal open={open} onClose={onClose} closeIcon>
    <Modal.Header>
      <Text as="h2">Activity for {taxfyleJobId}</Text>
    </Modal.Header>
    <Modal.Content>
      <TaxfyleHistoryContent
        taxfyleJobId={taxfyleJobId}
        createdAt={createdAt}
        open={open}
      />
    </Modal.Content>
  </Modal>
)

export default TaxfyleJobHistoryModal
