import { Grid } from 'semantic-ui-react'
import { Text } from '../../../../../components/BaseComponents'
import { TEST_IDS } from '../helpers'
import './styles.scss'

export type EndOfYearReviewGroupProps = {
  title: string
  dimmed: boolean
  testId?: string
  prefix?: React.ReactElement
  children?: React.ReactNode
}

const EndOfYearReviewGroup = ({
  title,
  dimmed,
  testId,
  prefix,
  children,
}: EndOfYearReviewGroupProps) => {
  return (
    <div className="eoy-admin__group">
      <Text as="h2" testId={TEST_IDS.group(testId)}>
        {title}
      </Text>
      {prefix}

      <div className={dimmed ? 'eoy-admin__group__dimmed' : ''}>
        <div className="eoy-admin__group__overlay" />
        <Grid celled className="eoy-admin__group__children">
          {children}
        </Grid>
      </div>
    </div>
  )
}

export default EndOfYearReviewGroup
