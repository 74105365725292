import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchWrapper } from '../../../reducers/fetch'
import { AnnualTaxFiling } from '../../Taxes/AnnualTaxes/annualTaxFilings.slice'
import {
  AnnualTaxFilingForm,
  AnnualTaxFilingFormQuestionnaireStatus,
  TaxfyleJob,
  TaxfyleJobStatus,
  TaxfyleJobStep,
} from '../../Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import { UserEoyReviewProgress } from '../../Taxes/AnnualTaxes/TaxChecklist/Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { AnnualTaxFormType } from '../../Taxes/AnnualTaxes/annualTaxFormType.slice'
import { TaxFormType } from '../../Taxes/AnnualTaxes/Questionnaires/constants'

export interface AnnualTaxFilingFormWithDocument
  extends Omit<AnnualTaxFilingForm, 'documents'> {
  formType: Pick<AnnualTaxFormType, 'name' | 'displayName'>
  taxfyleJob: TaxfyleJob | null
}

export interface AnnualTaxFilingNonNormalized
  extends Omit<AnnualTaxFiling, 'annualTaxFilingForms'> {
  annualTaxFilingForms: AnnualTaxFilingFormWithDocument[]
}

export interface AdminAnnualTaxFiling
  extends Omit<AnnualTaxFiling, 'annualTaxFilingForms'> {
  exemptedFromExtensionAt: string | null
  exemptedFromExtensionReason: string | null
  annualTaxFilingForms: AnnualTaxFilingFormWithDocument[]
  annualTaxFilings: TaxFilingUser | null
  userEndOfYearReviewProgresses: UserEoyReviewProgress[]
}

export interface AdminAnnualTaxFilingState {
  [key: string]: AdminAnnualTaxFiling
}

export interface TaxFilingUser {
  id: number
  uuid: string
  firstName: string
  lastName: string
  email: string
  createdAt: string
  annualTaxFilings: AnnualTaxFilingNonNormalized[]
  bookkeeperId: number
  bookkeeper: {
    firstName: string
    lastName: string
  }
  ownerId: number
  owner: {
    firstName: string
    lastName: string
  }
  taxPreparerId: number
  taxPreparer: {
    firstName: string
    lastName: string
  }
  taxCoordinatorId: number
  taxCoordinator: {
    firstName: string
    lastName: string
  }
}

const adminAnnualTaxFilingsSlice = createSlice({
  name: 'allAnnualTaxFilings',
  initialState: {} as AdminAnnualTaxFilingState,
  reducers: {
    getAllAnnualTaxFilings: (
      state,
      action: PayloadAction<{ [key: string]: AdminAnnualTaxFiling }>
    ) => ({ ...state, ...action.payload }),
    updateSingleAnnualTaxFiling: (
      state,
      action: PayloadAction<AdminAnnualTaxFiling>
    ) => {
      state[action.payload.id] = action.payload
    },
  },
})

export default adminAnnualTaxFilingsSlice.reducer

// Actions
export const { getAllAnnualTaxFilings, updateSingleAnnualTaxFiling } =
  adminAnnualTaxFilingsSlice.actions

/* This fetches a list of users and their annual tax filings. */

export const FETCH_ADMIN_ANNUAL_TAX_FILINGS_KEY =
  'FETCH_ADMIN_ANNUAL_TAX_FILINGS_KEY'
export const fetchAdminAnnualTaxFilings = ({
  userIds,
  ...rest
}: {
  page: number
  limit: number
  year?: string
  userIds?: number | number[]
  taxfyleJobStatus?: TaxfyleJobStatus
  taxfyleJobStep?: TaxfyleJobStep
  taxChecklistStatus?: AnnualTaxFilingFormQuestionnaireStatus
  jobTimeInStatus?: {
    max?: string
    min?: string
  }
  formNeeds?: TaxFormType | 'unanswered' | 'noServices'
  bookkeeperId?: number
  extensionRequestStatus?: AnnualTaxFilingFormQuestionnaireStatus
  extensionTaxfyleJobStatus?: TaxfyleJobStatus
  exempted?: boolean
}) =>
  fetchWrapper({
    fetchKey: FETCH_ADMIN_ANNUAL_TAX_FILINGS_KEY,
    defaultErrorMessage: 'Error fetching all user tax filings',
    fetchFunction: async (dispatch) => {
      const json = await axios.get<{
        filings: AdminAnnualTaxFiling[]
        count: number
        page: number
      }>('/finances/api/v1/admin/annual_tax_filings', {
        params: {
          ...rest,
          userIds: Array.isArray(userIds)
            ? userIds.join(',')
            : userIds?.toString(),
        },
      })
      dispatch(getAllAnnualTaxFilings(keyBy(json.data.filings, 'id')))
      return json.data
    },
  })

export const ADMIN_UPDATE_ANNUAL_TAX_FILING_KEY =
  'ADMIN_UPDATE_ANNUAL_TAX_FILING_KEY'
/* Updates an Annual Tax Filing */
export const updateAdminAnnualTaxFiling = (
  annualTaxFilingId: number,
  data: Partial<AdminAnnualTaxFiling>
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error updating annual tax filing',
    fetchKey: ADMIN_UPDATE_ANNUAL_TAX_FILING_KEY,
    fetchFunction: async (dispatch) => {
      const json = await axios.put<AdminAnnualTaxFiling>(
        `/finances/api/v1/admin/annual_tax_filings/${annualTaxFilingId}`,
        data
      )

      dispatch(updateSingleAnnualTaxFiling(json.data))

      return json.data
    },
  })

export const adminUpdateAnnualTaxFilingStatus = (
  taxFilingId: number,
  data: {
    annualTaxFilingStatusTypeId: number
  }
) =>
  fetchWrapper({
    defaultErrorMessage: 'Error updating annual tax filing status',
    fetchFunction: async () => {
      // Note api doesn't return extra admin information here so don't save off
      const json = await axios.post<AnnualTaxFilingNonNormalized>(
        `/finances/api/v1/admin/annual_tax_filings/${taxFilingId}/annual_tax_filing_statuses`,
        data
      )
      return json.data
    },
  })

/* This fetches a list of users and their annual tax filings. We do not save this to the Redux store. */
export const fetchAdminAnnualTaxFilingById = (id: string | undefined) =>
  fetchWrapper({
    defaultErrorMessage: 'Error fetching all user tax filings',
    fetchFunction: async () => {
      // Note api doesn't return extra admin information here so don't save off
      const json = await axios.get<{
        filing: AnnualTaxFilingNonNormalized
      }>(`/finances/api/v1/admin/annual_tax_filings/${id}`)

      return json.data
    },
  })
