import { Fragment, useCallback, useEffect, useState, useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { UploadDocumentType } from '../../../../constants/businessConstants'
import {
  PrefixedUserDocumentCategoryIdentifier,
  UserDocumentCategoryIdentifier,
} from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import {
  getMatchingMerchants,
  IMerchantDocument,
  IMerchantFees,
} from '../processing-fees-documents/documents-map'
import {
  Accordion,
  Text,
  GridRowColumn,
} from '../../../../components/BaseComponents'
import { DocumentUploadCard } from '../../../../components/FileUpload/DocumentUploadCard'
import { StepHeaders } from '../../../../components/StepHeader'
import FileUploadModal, {
  UploadedFile,
} from '../../../../components/FileUpload/FileUploadModal'
import {
  IDocumentsStepContext,
  IFixedAssetDepreciationStepContext,
  OtherDocumentsStepContext,
  Document,
} from '../types'
import { NotRelevantDocuments } from '../not-relevant-documents'
import {
  userGetBookkeepingStep,
  userUpdateBookkeepingStepContext,
} from '../actions'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useDocuments } from '../hooks'
import { UserDocument } from '../../../UserDocuments/userDocuments.slice'
import { migrateAssetStepContext } from '../admin/helpers'
import { Transaction } from '../../../../reducers/admin/allTransactions.slice'
import './styles.scss'

interface IProvider {
  id: number
  name: string
  createdAt: string
  updatedAt: string
}

export interface IProviders {
  [id: number]: IProvider
}

export interface IPayrollNotrelevant {
  [key: string]: {
    w3?: boolean
    journal?: boolean
  }
}

interface PayrollDocumentsStepContext extends IDocumentsStepContext {
  payrollProviders?: string[]
}

interface ITransactionCategory {
  id: number
  identifier: string
  name: string
}

interface IReceipt {
  id: number
  fileDescription: string
  signedUrl: string
}

export interface IReceiptTransaction {
  id: number
  amountInCents: number
  date: string
  description: string
  transactionCategory: ITransactionCategory
  receipts: IReceipt[]
}

export interface IPayrollDocumentsProps {
  providers: IProviders
  stepContext: PayrollDocumentsStepContext
  setStepContext?: (context: PayrollDocumentsStepContext) => void
  taxYear: string
  processingFees?: IMerchantFees[]
  assetsOver2500?: Transaction[]
  isMultiYearSCorp: boolean
  documents: UserDocument[]
  setIsStepComplete: (value: boolean) => void
  isFollowUp?: boolean
}

export const ReviewDocuments = ({
  documents,
  providers,
  stepContext,
  setIsStepComplete,
  taxYear,
  processingFees = [],
  assetsOver2500 = [],
  isMultiYearSCorp,
  isFollowUp = false,
}: IPayrollDocumentsProps) => {
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false)
  const [uniqueDocumentMerchants, setUniqueDocumentMerchants] = useState<
    IMerchantDocument[]
  >([])
  const { assetsGreater2500Receipts } = PrefixedUserDocumentCategoryIdentifier
  const [fileUpload, setFileUpload] = useState<{
    stepContextKey: string
    type?: UploadDocumentType
    provider?: number | null
    userDocumentInternalName?: string
    merchantName?: string
    documentCategoryName?: string
    documentName?: string
    documentId?: number
  } | null>({
    stepContextKey: '',
    provider: null,
    userDocumentInternalName: '',
  })
  const { OTHER } = UploadDocumentType

  const transactionsWithoutReceipts = useMemo(() => {
    return assetsOver2500.reduce<Transaction[]>((withReceipts, transaction) => {
      if (
        (transaction?.receipts && transaction?.receipts.length === 0) ||
        !transaction?.receipts
      ) {
        withReceipts.push(transaction)
      }

      return withReceipts
    }, [])
  }, [assetsOver2500])

  const contractorPaymentsReport = useMemo(
    () =>
      PrefixedUserDocumentCategoryIdentifier.contractorPaymentsReport(taxYear),
    [taxYear]
  )

  const [payrollProviders, setPayrollProviders] = useState<string[]>([])
  const [payrollStepContext, setPayrollStepContext] = useState<
    PayrollDocumentsStepContext | undefined
  >()
  const [contractorStepContext, setContractorStepContext] = useState<
    PayrollDocumentsStepContext | undefined
  >()
  const [feesStepContext, setFeesStepContext] = useState<
    IDocumentsStepContext | undefined
  >()
  const [assetStepContext, setAssetStepContext] = useState<
    IFixedAssetDepreciationStepContext | undefined
  >()
  const [taxStepContext, setTaxStepContext] = useState<
    IDocumentsStepContext | undefined
  >()
  const [otherDocsContext, setotherDocsContext] = useState<
    OtherDocumentsStepContext | undefined
  >()
  const [contractorProviders, setContractorProviders] = useState<string[]>([])
  const year = useReselector(selectCurrentAnnualTaxYear)
  const formW3 = UserDocumentCategoryIdentifier.formW3
  const dispatch = useAppDispatch()

  const payrollJournal =
    PrefixedUserDocumentCategoryIdentifier.payrollJournal(year)

  const {
    filterDocuments: filterFeesDocuments,
    getStatus: getFeesStatus,
    getDocument: getFeesDocs,
  } = useDocuments({
    stepContextDocuments: feesStepContext?.documents,
    userDocuments: documents,
  })

  const {
    filterDocuments: filterPayrollDocuments,
    getStatus: getPayrollStatus,
    getDocument: getPayrollDocs,
  } = useDocuments({
    stepContextDocuments: payrollStepContext?.documents,
    userDocuments: documents,
  })

  const {
    filterDocuments: filterContratorDocuments,
    getStatus: getContractorStatus,
    getDocument: getContractorDocs,
  } = useDocuments({
    stepContextDocuments: contractorStepContext?.documents,
    userDocuments: documents,
  })

  const {
    filterDocuments: filterTaxDocuments,
    getStatus: getPreviousTaxReturnStatus,
    getDocument: getTaxDocs,
  } = useDocuments({
    stepContextDocuments: taxStepContext?.documents,
    userDocuments: documents,
  })

  /*const onPayrollProviderChange = useCallback(
    (val: string[]) => {
      const filteredDocuments = stepContext?.documents?.filter(
        (doc) => doc.notRelevant && !val.includes(doc.provider)
      )
      setStepContext({
        ...stepContext,
        documents: filteredDocuments,
        payrollProviders: val,
      })
    },
    [stepContext, setStepContext]
  )*/
  const receipts = useMemo(() => {
    const uploadedReceipts = assetStepContext?.receipts ?? []
    return documents.filter((doc) =>
      uploadedReceipts.some((r) => r.documentId === doc.id)
    )
  }, [documents, assetStepContext?.receipts])
  const checkIfAssetReUpload = useCallback(
    (receiptId: number) => {
      const uploadedReceipts = (assetStepContext?.receipts ?? []).filter(
        (r) => r.documentId === receiptId
      )
      const returnDocument: Document = {
        categoryCode: UserDocumentCategoryIdentifier.finalReturnForm1120s,
        requestReUpload: true,
      }
      const returnReceiptDocument: Document = {
        categoryCode: assetsGreater2500Receipts(taxYear),
        requestReUpload: uploadedReceipts[0]?.requestReUpload,
        updatedByUser: uploadedReceipts[0]?.updatedByUser,
        adminNote: uploadedReceipts[0]?.adminNote,
      }
      return returnReceiptDocument ?? returnDocument
    },
    [assetStepContext?.receipts, assetsGreater2500Receipts, taxYear]
  )
  const previousTaxReturnStatus = useMemo(() => {
    return getPreviousTaxReturnStatus(
      UserDocumentCategoryIdentifier.finalReturnForm1120s,
      ''
    )
  }, [getPreviousTaxReturnStatus])

  useEffect(() => {
    const payrollProvidersCanContinue = payrollProviders.every((provider) => {
      return (
        getPayrollStatus(formW3, provider, (categoryInternalName, provider) => {
          const filterDocs = payrollStepContext?.documents?.find((doc) => {
            return (
              doc.categoryCode === categoryInternalName &&
              doc.provider === provider
            )
          })
          return filterDocs?.notRelevant !== undefined
        }) === 'success' &&
        getPayrollStatus(
          payrollJournal,
          provider,
          (categoryInternalName, provider) => {
            const filterDocs = payrollStepContext?.documents?.find((doc) => {
              return (
                doc.categoryCode === categoryInternalName &&
                doc.provider === provider
              )
            })
            return filterDocs?.notRelevant !== undefined
          }
        ) === 'success'
      )
    })
    const contractorPaymentsCanContinue = contractorProviders.every(
      (provider) => {
        return (
          getContractorStatus(
            contractorPaymentsReport,
            provider,
            (categoryInternalName, provider) => {
              const filterDocs = contractorStepContext?.documents?.find(
                (doc) => {
                  return (
                    doc.categoryCode === categoryInternalName &&
                    doc.provider === provider
                  )
                }
              )
              return filterDocs?.notRelevant !== undefined
            }
          ) === 'success'
        )
      }
    )
    const processingFeesCanContinue = uniqueDocumentMerchants.every(
      ({ merchantName, documentCategoryName, documentName }) => {
        return (
          getFeesStatus(
            documentCategoryName,
            merchantName,
            (categoryInternalName, provider, documentName) => {
              const filterDocs = feesStepContext?.documents?.find((doc) => {
                return (
                  doc.categoryCode === categoryInternalName &&
                  doc.provider === provider &&
                  doc.name === documentName
                )
              })
              return filterDocs?.notRelevant !== undefined
            },
            documentName
          ) === 'success'
        )
      }
    )

    const assetsOver2500CanContinue =
      receipts.length === transactionsWithoutReceipts.length

    const previousTaxReturnsCanContinue =
      !isMultiYearSCorp || previousTaxReturnStatus === 'success'

    const canContinue =
      payrollProvidersCanContinue &&
      contractorPaymentsCanContinue &&
      processingFeesCanContinue &&
      assetsOver2500CanContinue &&
      previousTaxReturnsCanContinue

    setIsStepComplete(canContinue)
  }, [
    formW3,
    payrollJournal,
    contractorPaymentsReport,
    payrollProviders,
    contractorProviders,
    uniqueDocumentMerchants,
    transactionsWithoutReceipts,
    receipts,
    isMultiYearSCorp,
    getPayrollStatus,
    getContractorStatus,
    getFeesStatus,
    setIsStepComplete,
    previousTaxReturnStatus,
    payrollStepContext?.documents,
    contractorStepContext?.documents,
    feesStepContext?.documents,
  ])

  const getStepContext = useCallback(async () => {
    //Get Payroll Providers
    const payrollStep = await userGetBookkeepingStep('docs-payroll')(dispatch)
    setPayrollProviders(
      Array.isArray(payrollStep?.context?.payrollProviders)
        ? payrollStep.context.payrollProviders
        : []
    )
    setPayrollStepContext(payrollStep?.context)
    //Get Contractors Providers
    const contractorStep = await userGetBookkeepingStep(
      'docs-contractor-payments'
    )(dispatch)
    setContractorStepContext(contractorStep?.context)
    setContractorProviders(
      Array.isArray(contractorStep?.context?.payrollProviders)
        ? contractorStep.context.payrollProviders
        : []
    )
    //Assets Step Context
    const assetStep = await userGetBookkeepingStep('docs-assets-over-2500')(
      dispatch
    )
    setAssetStepContext(migrateAssetStepContext(assetStep?.context))
    // Get PYTR
    const previousTaxReturnStep = await userGetBookkeepingStep(
      'docs-previous-tax-return'
    )(dispatch)
    setTaxStepContext(previousTaxReturnStep?.context)

    //Get Processing Fees
    const processingFeesStep = await userGetBookkeepingStep(
      'docs-processing-fees'
    )(dispatch)
    setFeesStepContext(processingFeesStep?.context)
    const otherDocs = await userGetBookkeepingStep('docs-other')(dispatch)
    setotherDocsContext(otherDocs?.context)
  }, [dispatch])

  const uploadStatus = useMemo(() => {
    const previousYearTaxDocContext = taxStepContext?.documents?.find(
      (doc) =>
        doc.categoryCode === UserDocumentCategoryIdentifier.finalReturnForm1120s
    )
    return previousYearTaxDocContext ? 'success' : 'none'
  }, [taxStepContext?.documents])

  const uploadOtherDocsStatus = useMemo(() => {
    const filteredDocuments = documents.filter((doc) =>
      (otherDocsContext?.documentIds ?? []).includes(doc.id)
    )
    return filteredDocuments.length > 0 ? 'success' : 'none'
  }, [documents, otherDocsContext])

  useEffect(() => {
    const payrollProvidersCanContinue = payrollProviders.every((provider) => {
      return (
        getPayrollStatus(formW3, provider, (categoryInternalName, provider) => {
          const filterDocs = payrollStepContext?.documents?.find((doc) => {
            return (
              doc.categoryCode === categoryInternalName &&
              doc.provider === provider
            )
          })
          return filterDocs?.notRelevant !== undefined
        }) === 'success' &&
        getPayrollStatus(
          payrollJournal,
          provider,
          (categoryInternalName, provider) => {
            const filterDocs = payrollStepContext?.documents?.find((doc) => {
              return (
                doc.categoryCode === categoryInternalName &&
                doc.provider === provider
              )
            })
            return filterDocs?.notRelevant !== undefined
          }
        ) === 'success'
      )
    })
    const contractorPaymentsCanContinue = contractorProviders.every(
      (provider) => {
        return (
          getContractorStatus(
            contractorPaymentsReport,
            provider,
            (categoryInternalName, provider) => {
              const filterDocs = contractorStepContext?.documents?.find(
                (doc) => {
                  return (
                    doc.categoryCode === categoryInternalName &&
                    doc.provider === provider
                  )
                }
              )
              return filterDocs?.notRelevant !== undefined
            }
          ) === 'success'
        )
      }
    )
    const processingFeesCanContinue = uniqueDocumentMerchants.every(
      ({ merchantName, documentCategoryName, documentName }) => {
        return (
          getFeesStatus(
            documentCategoryName,
            merchantName,
            (categoryInternalName, provider, documentName) => {
              const filterDocs = feesStepContext?.documents?.find((doc) => {
                return (
                  doc.categoryCode === categoryInternalName &&
                  doc.provider === provider &&
                  doc.name === documentName
                )
              })
              return filterDocs?.notRelevant !== undefined
            },
            documentName
          ) === 'success'
        )
      }
    )

    const assetsOver2500CanContinue =
      receipts.length === transactionsWithoutReceipts.length

    const previousTaxReturnsCanContinue =
      !isMultiYearSCorp || uploadStatus === 'success'

    const canContinue =
      payrollProvidersCanContinue &&
      contractorPaymentsCanContinue &&
      processingFeesCanContinue &&
      assetsOver2500CanContinue &&
      previousTaxReturnsCanContinue

    setIsStepComplete(canContinue)
  }, [
    formW3,
    payrollJournal,
    contractorPaymentsReport,
    payrollProviders,
    contractorProviders,
    uniqueDocumentMerchants,
    transactionsWithoutReceipts,
    receipts,
    isMultiYearSCorp,
    previousTaxReturnStatus,
    getPayrollStatus,
    getContractorStatus,
    getFeesStatus,
    setIsStepComplete,
    uploadStatus,
    payrollStepContext?.documents,
    contractorStepContext?.documents,
    feesStepContext?.documents,
  ])

  useEffect(() => {
    getStepContext()
  }, [getStepContext])

  useEffect(() => {
    const uniqueMerchants = getMatchingMerchants(taxYear, processingFees)
    setUniqueDocumentMerchants(uniqueMerchants)
  }, [taxYear, processingFees])

  const handleFileUpload = (
    uploadType: UploadDocumentType,
    stepContextKey: string,
    userDocumentInternalName?: string,
    provider?: string,
    documentName?: string,
    documentId?: number
  ) => {
    setIsFileUploadModalOpen(true)

    const providerId = Object.values(providers).find(
      (p) => p.name === provider
    )?.id
    if (userDocumentInternalName === 'receipt') {
      setFileUpload({
        stepContextKey,
        type: uploadType,
        provider: providerId ?? null,
        userDocumentInternalName: assetsGreater2500Receipts(taxYear),
        documentId,
      })
    } else {
      setFileUpload({
        stepContextKey,
        type: uploadType,
        provider: providerId ?? null,
        userDocumentInternalName,
        documentName,
      })
    }
  }

  const handleFileUploadFees = (
    uploadType: UploadDocumentType,
    merchantName?: string,
    documentCategoryName?: string,
    documentName?: string
  ) => {
    setIsFileUploadModalOpen(true)

    if (merchantName && documentCategoryName && documentName) {
      setFileUpload({
        stepContextKey: 'processingFees',
        merchantName,
        documentCategoryName,
        documentName,
        type: uploadType,
      })
    } else {
      setFileUpload(null)
    }
  }

  const onFileUploaded = useCallback(
    async (uploadedFile: UploadedFile) => {
      if (fileUpload?.type !== OTHER) {
        const documentId = uploadedFile.id
        const categoryCode = fileUpload?.userDocumentInternalName ?? ''
        const provider =
          Object.values(providers).find((p) => p.id === fileUpload?.provider)
            ?.name ?? 'Other'

        // Replace or add the document to the list
        const tempDocFee = {
          documentId,
          categoryCode,
          provider,
          requestReupload: false,
          updatedByUser: false,
        }
        if (isFollowUp) {
          tempDocFee.requestReupload = true
          tempDocFee.updatedByUser = true
        }

        const documents = [
          ...(payrollStepContext?.documents?.filter(
            (doc) =>
              !(doc.categoryCode === categoryCode && doc.provider === provider)
          ) ?? []),
          tempDocFee,
        ]
        await userUpdateBookkeepingStepContext('docs-payroll', 'docs-payroll', {
          ...payrollStepContext,
          documents,
        })(dispatch)
      }
      getStepContext()
    },
    [
      fileUpload?.type,
      fileUpload?.userDocumentInternalName,
      fileUpload?.provider,
      OTHER,
      getStepContext,
      providers,
      isFollowUp,
      payrollStepContext,
      dispatch,
    ]
  )

  const onContractorFileUploaded = useCallback(
    async (uploadedFile: UploadedFile) => {
      if (fileUpload?.type !== OTHER) {
        const documentId = uploadedFile.id
        const categoryCode = fileUpload?.userDocumentInternalName ?? ''
        const provider =
          Object.values(providers).find((p) => p.id === fileUpload?.provider)
            ?.name ?? 'Other'
        // Replace or add the document to the list

        const tempDocFee = {
          documentId,
          categoryCode,
          provider,
          requestReupload: false,
          updatedByUser: false,
        }
        if (isFollowUp) {
          tempDocFee.requestReupload = true
          tempDocFee.updatedByUser = true
        }

        const documents = [
          ...(stepContext.documents?.filter(
            (doc) =>
              !(doc.categoryCode === categoryCode && doc.provider === provider)
          ) ?? []),
          tempDocFee,
        ]
        await userUpdateBookkeepingStepContext(
          'docs-contractor-payments',
          'docs-contractor-payments',
          { ...contractorStepContext, documents }
        )(dispatch)
        getStepContext()
      }
    },
    [
      fileUpload?.type,
      fileUpload?.userDocumentInternalName,
      fileUpload?.provider,
      OTHER,
      providers,
      isFollowUp,
      stepContext.documents,
      contractorStepContext,
      dispatch,
      getStepContext,
    ]
  )

  const onFileAssetsUploaded = useCallback(
    async (uploadedFile: UploadedFile) => {
      // Update step context based on the file type
      if (fileUpload?.type === 'receipt') {
        if (isFollowUp) {
          const receipts = [...(assetStepContext?.receipts ?? [])]
          const validatedReciepts = []
          for (const doc of receipts) {
            if (doc.documentId !== fileUpload?.documentId) {
              validatedReciepts.push(doc)
            } else {
              validatedReciepts.push({
                documentId: uploadedFile.id,
                requestReUpload: true,
                updatedByUser: true,
              })
            }
          }
          await userUpdateBookkeepingStepContext(
            'docs-assets-over-2500',
            'docs-assets-over-2500',
            { ...assetStepContext, receipts: validatedReciepts }
          )(dispatch)
        } else {
          const receipts = [
            ...(assetStepContext?.receipts ?? []),
            { documentId: uploadedFile.id },
          ]
          await userUpdateBookkeepingStepContext(
            'docs-assets-over-2500',
            'docs-assets-over-2500',
            { ...assetStepContext, receipts }
          )(dispatch)
        }
      }
      getStepContext()
    },
    [
      fileUpload?.type,
      fileUpload?.documentId,
      getStepContext,
      isFollowUp,
      assetStepContext,
      dispatch,
    ]
  )

  const onFeesFileUploaded = useCallback(
    async (uploadedFile: UploadedFile) => {
      // Update step context based on the uploaded file
      if (fileUpload) {
        const documentId = uploadedFile.id
        const categoryCode = fileUpload.documentCategoryName
        const name = fileUpload.documentName
        const provider = fileUpload.merchantName
        const tempDocFee = {
          documentId,
          categoryCode,
          name,
          provider,
          requestReupload: false,
          updatedByUser: false,
        }
        if (isFollowUp) {
          tempDocFee.requestReupload = true
          tempDocFee.updatedByUser = true
        }
        const documents = [
          ...(feesStepContext?.documents?.filter(
            (doc) =>
              !(
                doc.categoryCode === categoryCode &&
                doc.provider === provider &&
                doc.name === name
              )
          ) ?? []),
          tempDocFee,
        ]
        await userUpdateBookkeepingStepContext(
          'docs-processing-fees',
          'docs-processing-fees',
          { ...feesStepContext, documents }
        )(dispatch)
      }
      getStepContext()
    },
    [fileUpload, getStepContext, isFollowUp, feesStepContext, dispatch]
  )

  const onTaxUploaded = useCallback(
    async (uploadedFile: UploadedFile) => {
      const documentId = uploadedFile.id
      const categoryCode = fileUpload?.userDocumentInternalName
      if (categoryCode) {
        const tempDocFee = {
          provider: '',
          documentId,
          categoryCode,
          requestReupload: false,
          updatedByUser: false,
        }
        if (isFollowUp) {
          tempDocFee.requestReupload = true
          tempDocFee.updatedByUser = true
        }
        const documents = [
          ...(taxStepContext?.documents?.filter(
            (doc) => !(doc.categoryCode === categoryCode)
          ) ?? []),
          tempDocFee,
        ]
        await userUpdateBookkeepingStepContext(
          'docs-previous-tax-return',
          'docs-previous-tax-return',
          { ...taxStepContext, documents }
        )(dispatch)
        getStepContext()
      }
    },
    [
      fileUpload?.userDocumentInternalName,
      isFollowUp,
      taxStepContext,
      dispatch,
      getStepContext,
    ]
  )

  const onOtherUploaded = useCallback(
    async (uploadedFile: UploadedFile) => {
      const otherDocumentIds = [
        ...(otherDocsContext?.documentIds ?? []),
        uploadedFile.id,
      ]
      await userUpdateBookkeepingStepContext('docs-other', 'docs-other', {
        documentIds: otherDocumentIds,
      })(dispatch)
      getStepContext()
    },
    [dispatch, otherDocsContext, getStepContext]
  )

  const uploadingFileStepContext = useCallback(
    (uploadedFile: UploadedFile) => {
      if (fileUpload) {
        if (fileUpload.stepContextKey === 'payroll') {
          onFileUploaded(uploadedFile)
        } else if (fileUpload.stepContextKey === 'contractorPayments') {
          onContractorFileUploaded(uploadedFile)
        } else if (fileUpload.stepContextKey === 'assets2500') {
          onFileAssetsUploaded(uploadedFile)
        } else if (fileUpload.stepContextKey === 'processingFees') {
          onFeesFileUploaded(uploadedFile)
        } else if (fileUpload.stepContextKey === 'taxReturn') {
          onTaxUploaded(uploadedFile)
        } else if (fileUpload.stepContextKey === 'other') {
          onOtherUploaded(uploadedFile)
        }
      }
    },
    [
      fileUpload,
      onContractorFileUploaded,
      onFeesFileUploaded,
      onFileAssetsUploaded,
      onFileUploaded,
      onOtherUploaded,
      onTaxUploaded,
    ]
  )

  const getTxnPluralText = (txns: Transaction[]) => {
    const count = txns.length
    return count > 1 ? `${count} transactions` : `${count} transaction`
  }

  const filterReceipts = useCallback(
    (receiptId: number) => {
      const uploadedReceipt = (receipts ?? []).filter(
        (receipt: { id: number }) => receipt.id === receiptId
      )
      return uploadedReceipt
    },
    [receipts]
  )

  const previousTaxYear = (parseInt(taxYear) - 1).toString()

  return (
    <div className="eoybk__payroll__container">
      <FileUploadModal
        close={() => setIsFileUploadModalOpen(false)}
        documentType={fileUpload?.type || UploadDocumentType.OTHER}
        open={isFileUploadModalOpen}
        extraPayload={{
          providerId:
            Number(fileUpload?.provider) === 0
              ? null
              : Number(fileUpload?.provider),
        }}
        categoryInternalName={fileUpload?.userDocumentInternalName}
        userFacing
        year={
          fileUpload?.userDocumentInternalName ===
          UserDocumentCategoryIdentifier.finalReturnForm1120s
            ? previousTaxYear
            : undefined
        }
        setUploadedFile={uploadingFileStepContext}
      />

      <StepHeaders
        customColor="yellow"
        description="Based on your answers, we’ll need the following documents to complete your bookkeeping for the year."
        imageSrc="https://heard-images.s3.amazonaws.com/assets/upload-document.svg"
        imageAlt="stacked checkbooks"
        imageWidth={64}
        imageHeight={61}
        title="Upload documents"
        isAbstractHeader
      >
        <Grid>
          <GridRowColumn className="align-left">
            <Text>
              Your progress will be saved, so feel free to take your time and
              come back later if needed.
            </Text>
          </GridRowColumn>
          <GridRowColumn className="align-left">
            <Accordion
              title="What if I’m having trouble finding documents? "
              content={
                <Text>
                  We ask that you take your time and make your best effort, as
                  these documents are needed to accurately complete your books.
                  This
                  <span> directly impacts your annual tax filing </span> and can
                  help avoid underreporting penalties.
                </Text>
              }
              variant="text"
            />
          </GridRowColumn>
        </Grid>
      </StepHeaders>

      {payrollProviders.length > 0 && (
        <>
          <Text as="h2">Payroll</Text>

          <div className="eoybk__payroll_document-cards">
            <NotRelevantDocuments
              key={'payroll_documents'}
              stepContext={payrollStepContext}
              setStepContext={async (document) => {
                await userUpdateBookkeepingStepContext(
                  'docs-payroll',
                  'docs-payroll',
                  document as unknown as Record<string, unknown>
                )(dispatch)
                getStepContext()
              }}
              renderDocumentUploadCards={(
                isMarkedNotRelevant,
                handleNotRelevant
              ) =>
                payrollProviders?.map((provider) => (
                  <Fragment key={`${provider}_documents`}>
                    <DocumentUploadCard
                      name={`${provider} - Form W-3`}
                      description="This summarizes your business’ total salary payments and withholding amounts."
                      notRelevant={isMarkedNotRelevant?.(formW3, provider)}
                      setChecked={() => handleNotRelevant(formW3, provider)}
                      status={getPayrollStatus(
                        formW3,
                        provider,
                        isMarkedNotRelevant
                      )}
                      documents={filterPayrollDocuments(formW3, provider)}
                      handleUploadBtn={() =>
                        handleFileUpload(
                          UploadDocumentType.TAX,
                          'payroll',
                          formW3,
                          provider
                        )
                      }
                      isRequiredReviewed={
                        isFollowUp ? getPayrollDocs(formW3, provider) : false
                      }
                      isFollowUp={isFollowUp}
                    />

                    <DocumentUploadCard
                      name={`${provider} - Payroll Journal`}
                      description="Includes a summary of gross wages, employee taxes, employer taxes, net pay."
                      notRelevant={isMarkedNotRelevant?.(
                        payrollJournal,
                        provider
                      )}
                      setChecked={() =>
                        handleNotRelevant?.(payrollJournal, provider)
                      }
                      status={getPayrollStatus(
                        payrollJournal,
                        provider,
                        isMarkedNotRelevant
                      )}
                      documents={filterPayrollDocuments(
                        payrollJournal,
                        provider
                      )}
                      handleUploadBtn={() =>
                        handleFileUpload(
                          UploadDocumentType.TAX,
                          'payroll',
                          payrollJournal,
                          provider
                        )
                      }
                      isRequiredReviewed={
                        isFollowUp
                          ? getPayrollDocs(payrollJournal, provider)
                          : false
                      }
                      isFollowUp={isFollowUp}
                    />
                  </Fragment>
                ))
              }
            />
          </div>
        </>
      )}

      {contractorProviders.length > 0 && (
        <>
          <Text as="h2">Contractor Payments</Text>
          <NotRelevantDocuments
            stepContext={contractorStepContext}
            setStepContext={async (document) => {
              await userUpdateBookkeepingStepContext(
                'docs-contractor-payments',
                'docs-contractor-payments',
                document as unknown as Record<string, unknown>
              )(dispatch)
              getStepContext()
            }}
            renderDocumentUploadCards={(
              isMarkedNotRelevant,
              handleNotRelevant
            ) =>
              contractorProviders?.map((provider) => (
                <DocumentUploadCard
                  key={`${provider}_documents`}
                  name={`${provider} - Contractor Payment Report`}
                  description="This summarizes your business’ total salary payments and withholding amounts."
                  notRelevant={isMarkedNotRelevant?.(
                    contractorPaymentsReport,
                    provider
                  )}
                  setChecked={() => {
                    handleNotRelevant?.(contractorPaymentsReport, provider)
                  }}
                  status={getContractorStatus(
                    contractorPaymentsReport,
                    provider,
                    isMarkedNotRelevant
                  )}
                  documents={filterContratorDocuments(
                    contractorPaymentsReport,
                    provider
                  )}
                  handleUploadBtn={() =>
                    handleFileUpload(
                      UploadDocumentType.BOOKKEEPING,
                      'contractorPayments',
                      contractorPaymentsReport,
                      provider
                    )
                  }
                  isRequiredReviewed={
                    isFollowUp
                      ? getContractorDocs(contractorPaymentsReport, provider)
                      : false
                  }
                  isFollowUp={isFollowUp}
                />
              ))
            }
          />
        </>
      )}

      {uniqueDocumentMerchants.length > 0 && (
        <>
          <Text as="h2">Processing Fees</Text>
          <NotRelevantDocuments
            stepContext={feesStepContext}
            setStepContext={async (document) => {
              await userUpdateBookkeepingStepContext(
                'docs-processing-fees',
                'docs-processing-fees',
                document as unknown as Record<string, unknown>
              )(dispatch)
              getStepContext()
            }}
            renderDocumentUploadCards={(
              isMarkedNotRelevant,
              handleNotRelevant
            ) =>
              uniqueDocumentMerchants.map(
                ({ id, merchantName, documentCategoryName, documentName }) => (
                  <DocumentUploadCard
                    key={`${id}-${documentName}`}
                    name={`${merchantName} - ${documentName}`}
                    description="This captures income, processing fees, and their timing."
                    notRelevant={isMarkedNotRelevant?.(
                      documentCategoryName,
                      merchantName,
                      documentName
                    )}
                    setChecked={() =>
                      handleNotRelevant?.(
                        documentCategoryName,
                        merchantName,
                        documentName
                      )
                    }
                    status={getFeesStatus(
                      documentCategoryName,
                      merchantName,
                      isMarkedNotRelevant,
                      documentName
                    )}
                    documents={filterFeesDocuments(
                      documentCategoryName,
                      merchantName,
                      documentName
                    )}
                    handleUploadBtn={() =>
                      handleFileUploadFees(
                        UploadDocumentType.OTHER,
                        merchantName,
                        documentCategoryName,
                        documentName
                      )
                    }
                    isRequiredReviewed={
                      isFollowUp
                        ? getFeesDocs(
                            documentCategoryName,
                            merchantName,
                            documentName
                          )
                        : false
                    }
                    isFollowUp={isFollowUp}
                  />
                )
              )
            }
          />
        </>
      )}

      {transactionsWithoutReceipts.length > 0 && !isFollowUp && (
        <>
          <Text as="h2">Assets Over $2,500</Text>
          <DocumentUploadCard
            name="Receipts for Assets >$2,500"
            description={`Upload ${getTxnPluralText(transactionsWithoutReceipts)} receipts for the transactions listed below.`}
            status={
              receipts.length === transactionsWithoutReceipts.length
                ? 'success'
                : 'none'
            }
            requiredDocCount={transactionsWithoutReceipts.length}
            documents={receipts}
            handleUploadBtn={() =>
              handleFileUpload(
                UploadDocumentType.RECEIPT,
                'assets2500',
                'receipt'
              )
            }
            isFollowUp={isFollowUp}
          />
        </>
      )}

      {receipts.length > 0 && isFollowUp && (
        <>
          <Text as="h2">Assets Over $2,500</Text>
          {receipts.map((receipt) => (
            <DocumentUploadCard
              key={`${receipt.id}`}
              name="Receipts for Assets >$2,500"
              description={`Upload ${getTxnPluralText(transactionsWithoutReceipts)} receipts for the transactions listed below.`}
              status={
                receipts.length === transactionsWithoutReceipts.length
                  ? 'success'
                  : 'none'
              }
              documents={filterReceipts(receipt.id)}
              handleUploadBtn={() =>
                handleFileUpload(
                  UploadDocumentType.RECEIPT,
                  'assets2500',
                  'receipt',
                  undefined,
                  undefined,
                  receipt.id
                )
              }
              isRequiredReviewed={
                isFollowUp ? checkIfAssetReUpload(receipt.id) : false
              }
              isFollowUp={isFollowUp}
            />
          ))}
        </>
      )}

      {isMultiYearSCorp && (
        <>
          <Text as="h2">Previous Year Tax Return</Text>
          <DocumentUploadCard
            name={`${previousTaxYear} Business Tax Return`}
            description="Your Form 1120-S from last year. Make sure you include all attachments (e.g. Schedule L and Schedule M-2)."
            handleUploadBtn={() =>
              handleFileUpload(
                UploadDocumentType.TAX,
                'taxReturn',
                UserDocumentCategoryIdentifier.finalReturnForm1120s
              )
            }
            documents={filterTaxDocuments(
              UserDocumentCategoryIdentifier.finalReturnForm1120s,
              ''
            )}
            status={previousTaxReturnStatus}
            isRequiredReviewed={
              isFollowUp
                ? getTaxDocs(
                    UserDocumentCategoryIdentifier.finalReturnForm1120s,
                    ''
                  )
                : false
            }
            isFollowUp={isFollowUp}
          />
        </>
      )}

      <div
        className="eoybk__payroll_document-cards"
        style={{ display: isFollowUp ? 'none' : 'block' }}
      >
        <Text as="h2">Other</Text>

        <DocumentUploadCard
          name="Other documents"
          description="Upload any other documents you’d like to share with the bookkeeping team here."
          handleUploadBtn={() =>
            handleFileUpload(UploadDocumentType.OTHER, 'other')
          }
          documents={documents.filter((doc) =>
            (otherDocsContext?.documentIds ?? []).includes(doc.id)
          )}
          status={uploadOtherDocsStatus}
          isFollowUp={isFollowUp}
        />
      </div>
    </div>
  )
}
