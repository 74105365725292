import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import {
  Button,
  DropdownItemProps,
  Form,
  Header,
  Message,
  Modal,
} from 'semantic-ui-react'

import {
  AnnualTaxFilingExtensionPayment,
  AnnualTaxFilingForm,
  FormFilingStatus,
  updateAdminAnnualTaxFilingForm,
} from '../../Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import { AnnualTaxFiling } from '../../Taxes/AnnualTaxes/annualTaxFilings.slice'
import { AnnualTaxFilingStatusType } from '../../Taxes/AnnualTaxes/annualTaxFilingStatusType.slice'
import ExtensionPaymentsForm from './ExtensionPaymentsForm'
import { Dropdown } from '../../../components/BaseComponents'
import {
  adminUpdateAnnualTaxFilingStatus,
  updateAdminAnnualTaxFiling,
} from './adminAnnualTaxFilings.slice'
import { useReselector } from '../../../utils/sharedHooks'
import { getAnnualTaxFilingStatusTypes } from '../../Taxes/AnnualTaxes/annualTaxFilingStatusType.selector'
import { useAppDispatch } from '../../../utils/typeHelpers'

export const useTaxFilingFormUploadModal = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalView, setModalView] = useState('')
  const [modalUserContext, setModalUserContext] = useState('')
  const [modalFilingFormIdContext, setModalFilingFormIdContext] = useState<
    number | undefined
  >()
  const [modalFilingFormTypeNameContext, setModalFilingFormTypeNameContext] =
    useState<string | undefined>()
  const [
    modalFilingFormExtensionPaymentsContext,
    setModalFilingFormExtensionPaymentsContext,
  ] = useState<AnnualTaxFilingExtensionPayment[]>()
  const [filingFormIsExtendedContext, setFilingFormIsExtendedContext] =
    useState<boolean>()

  const handleModalOpen = useCallback(
    (
      modalView: string,
      modalUserContext: string,
      filingFormId?: number,
      filingFormTypeName?: string,
      filingFormExtensionPayments?: AnnualTaxFilingExtensionPayment[] | null,
      filingFormIsExtended?: boolean | null
    ) => {
      setModalView(modalView)
      setModalUserContext(modalUserContext)
      if (filingFormId) {
        setModalFilingFormIdContext(filingFormId)
      }
      if (filingFormTypeName) {
        setModalFilingFormTypeNameContext(filingFormTypeName)
      }
      if (filingFormExtensionPayments) {
        setModalFilingFormExtensionPaymentsContext(filingFormExtensionPayments)
      }
      setFilingFormIsExtendedContext(filingFormIsExtended || false)
      setModalOpen(true)
    },
    []
  )
  return {
    modalOpen,
    setModalOpen,
    closeModal: () => setModalOpen(false),
    modalView,
    setModalView,
    modalUserContext,
    modalFilingFormIdContext,
    modalFilingFormTypeNameContext,
    modalFilingFormExtensionPaymentsContext,
    filingFormIsExtendedContext,
    handleModalOpen,
  }
}

interface TaxFilingFormUploadModalProps {
  modalOpen: boolean
  closeModal: () => void
  modalView: string
  setModalView: Dispatch<SetStateAction<string>>
  modalUserContext: string
  filingId: number
  refreshData: () => void
  filingFormId?: number
  filingFormTypeName?: string
  filingFormExtensionPayments?: AnnualTaxFilingExtensionPayment[]
  filingFormIsExtended?: boolean
  taxFilingUserId: number
}

const TaxFilingFormUploadModal = ({
  modalOpen,
  closeModal,
  modalView,
  setModalView,
  modalUserContext,
  filingId,
  refreshData,
  filingFormId,
  filingFormTypeName,
  filingFormExtensionPayments,
  filingFormIsExtended,
  taxFilingUserId,
}: TaxFilingFormUploadModalProps) => {
  const dispatch = useAppDispatch()
  const [updatedAnnualTaxFilingStatus, setUpdatedAnnualTaxFilingStatus] =
    useState<number>()
  const [googleSheetUrl, setGoogleSheetUrl] = useState<string>()
  const [helloSignTemplateId, setHelloSignTemplateId] = useState<string>()
  const [
    updatedAnnualTaxFilingFormStatus,
    setUpdatedAnnualTaxFilingFormStatus,
  ] = useState<FormFilingStatus>()
  const annualTaxFilingStatusTypes = useReselector(
    getAnnualTaxFilingStatusTypes
  )

  const ANNUAL_TAX_FILINGS_STATUS_OPTIONS = (annualTaxFilingStatusTypes: {
    [key: string]: AnnualTaxFilingStatusType
  }): DropdownItemProps[] => {
    const statusArray = Object.values(annualTaxFilingStatusTypes).map(
      (status) => {
        return {
          text: status.name,
          value: status.id,
        }
      }
    )
    return statusArray
  }

  const annualTaxFilingFormStatusOptions = () =>
    Object.values(FormFilingStatus).map((status) => ({
      text: status,
      value: status,
    }))

  const renderModalMessage = (
    modalView: string,
    user: string,
    formTypeName?: string
  ) => {
    switch (modalView) {
      case 'Annual Tax Filing Status':
        return (
          <p>{`You are updating the Annual Tax Filing Status for ${user}`}</p>
        )
      case 'Update Google Sheet URL':
        return <p>{`You are updating the google sheet url for ${user}`}</p>
      case 'Update HelloSign Template ID':
        return (
          <p>{`You are updating the HelloSign signing Template ID for ${user}`}</p>
        )
      case 'Extension Information':
        return (
          <p>{`You are updating ${formTypeName} extension details for ${user}`}</p>
        )
      case 'Update Tax Form Status':
        return <p>{`You are updating status of ${formTypeName} for ${user}`}</p>
      default:
        return <p>{`You are updating files for ${user}`}</p>
    }
  }

  const updateAnnualTaxFilingStatus = async (annualTaxFilingId: number) => {
    if (!updatedAnnualTaxFilingStatus) {
      return
    }
    const data = {
      annualTaxFilingStatusTypeId: updatedAnnualTaxFilingStatus,
    }
    const res = await adminUpdateAnnualTaxFilingStatus(
      annualTaxFilingId,
      data
    )(dispatch)

    if (res) {
      closeModal()
      setUpdatedAnnualTaxFilingStatus(undefined)
      refreshData()
    }
  }

  const updateGoogleSheetUrl = async (annualTaxFilingId: number) => {
    const data = { googleSheetUrl } as Pick<AnnualTaxFiling, 'googleSheetUrl'>

    const res = await updateAdminAnnualTaxFiling(
      annualTaxFilingId,
      data
    )(dispatch)
    if (res) {
      closeModal()
      setGoogleSheetUrl(undefined)
      refreshData()
    }
  }

  const updateExtensionInfo = async ({
    extensionPayments,
    filingFormId,
  }: {
    extensionPayments: AnnualTaxFilingExtensionPayment[]
    filingFormId: number
  }) => {
    const res = await updateAdminAnnualTaxFilingForm(filingFormId, {
      extensionPayments,
    })(dispatch)

    if (res) {
      closeModal()
      refreshData()
    }
  }

  const updateHelloSignTemplateId = async (annualTaxFilingFormId: number) => {
    const data = { helloSignTemplateId } as Pick<
      AnnualTaxFilingForm,
      'helloSignTemplateId'
    >

    const res = await updateAdminAnnualTaxFilingForm(
      annualTaxFilingFormId,
      data
    )(dispatch)
    if (res) {
      closeModal()
      setHelloSignTemplateId(undefined)
      refreshData()
    }
  }

  const updateAnnualTaxFilingFormStatus = async (
    annualTaxFilingFormId: number
  ) => {
    const data = { formFilingStatus: updatedAnnualTaxFilingFormStatus }

    const res = await updateAdminAnnualTaxFilingForm(
      annualTaxFilingFormId,
      data
    )(dispatch)
    if (res) {
      closeModal()
      setUpdatedAnnualTaxFilingFormStatus(undefined)
      refreshData()
    }
  }

  return (
    <Modal
      size="small"
      dimmer="inverted"
      open={modalOpen}
      onClose={() => {
        closeModal()
        setUpdatedAnnualTaxFilingStatus(undefined)
      }}
    >
      <Modal.Content>
        <Header as="h3">{modalView}</Header>
        <Message className="noBorder green">
          {renderModalMessage(modalView, modalUserContext, filingFormTypeName)}
        </Message>
        <br />
        {modalView === 'Annual Tax Filing Status' && (
          <>
            <Form>
              <Form.Field required>
                <Form.Dropdown
                  fluid
                  selection
                  required
                  label="Updated Status"
                  placeholder="Select a New Status"
                  options={ANNUAL_TAX_FILINGS_STATUS_OPTIONS(
                    annualTaxFilingStatusTypes
                  )}
                  onChange={(e, target) =>
                    setUpdatedAnnualTaxFilingStatus(target.value as number)
                  }
                />
              </Form.Field>
            </Form>
            <br />
            <Modal.Actions>
              <div className="flexbox">
                <Button
                  secondary
                  onClick={() => {
                    closeModal()
                    setUpdatedAnnualTaxFilingStatus(undefined)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  primary
                  disabled={!updatedAnnualTaxFilingStatus}
                  onClick={() => updateAnnualTaxFilingStatus(filingId)}
                >
                  Change Status
                </Button>
              </div>
            </Modal.Actions>
          </>
        )}
        {modalView === 'Update Google Sheet URL' && (
          <>
            <Form>
              <Form.Field required>
                <Form.Input
                  fluid
                  required
                  name="googleSheetUrl"
                  placeholder="Google Sheet URL"
                  label="Google Sheet URL"
                  onChange={(e, target) => setGoogleSheetUrl(target.value)}
                />
              </Form.Field>
            </Form>
            <br />
            <Modal.Actions>
              <div className="flexbox">
                <Button
                  secondary
                  onClick={() => {
                    closeModal()
                    setGoogleSheetUrl(undefined)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  primary
                  disabled={!googleSheetUrl}
                  onClick={() => updateGoogleSheetUrl(filingId)}
                >
                  Update Google Sheet URL
                </Button>
              </div>
            </Modal.Actions>
          </>
        )}
        {modalView === 'Update HelloSign Template ID' && (
          <>
            <Form>
              <Form.Field required>
                <Form.Input
                  fluid
                  required
                  name="helloSignTemplateId"
                  placeholder="Hello Sign Template Id"
                  label="Hello Sign TemplateId"
                  onChange={(e, target) => setHelloSignTemplateId(target.value)}
                />
              </Form.Field>
            </Form>
            <br />
            <Modal.Actions>
              <div className="flexbox">
                <Button
                  secondary
                  onClick={() => {
                    closeModal()
                    setHelloSignTemplateId(undefined)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  primary
                  disabled={!helloSignTemplateId || !filingFormId}
                  onClick={() => {
                    if (filingFormId) {
                      updateHelloSignTemplateId(filingFormId)
                    }
                  }}
                >
                  Update Hello Sign Template ID
                </Button>
              </div>
            </Modal.Actions>
          </>
        )}
        {modalView === 'Update Miscellaneous' && (
          <>
            <Form>
              <Form.Field required>
                <Form.Dropdown
                  fluid
                  selection
                  required
                  label="Select Update Option"
                  placeholder="Select Update Option"
                  options={[
                    {
                      text: 'Annual Tax Filing Status',
                      value: 'Annual Tax Filing Status',
                    },
                    {
                      text: 'Update Google Sheet URL',
                      value: 'Update Google Sheet URL',
                    },
                  ]}
                  onChange={(name, target) =>
                    setModalView(target.value as string)
                  }
                />
              </Form.Field>
            </Form>
            <br />
            <Modal.Actions>
              <div className="flexbox">
                <Button
                  secondary
                  onClick={() => {
                    closeModal()
                    setUpdatedAnnualTaxFilingStatus(undefined)
                    setGoogleSheetUrl(undefined)
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Modal.Actions>
          </>
        )}
        {modalView === 'Extension Information' &&
          filingFormId &&
          filingFormTypeName && (
            <div>
              <ExtensionPaymentsForm
                paymentInfo={filingFormExtensionPayments}
                isEditing
                userId={taxFilingUserId}
                filingFormId={filingFormId}
                filingFormType={filingFormTypeName}
                closeModal={closeModal}
                isFormExtended={filingFormIsExtended}
                updateExtensionInfo={updateExtensionInfo}
              />
            </div>
          )}
        {modalView === 'Update Tax Form Status' && (
          <>
            <Form>
              <Form.Field required>
                <Dropdown
                  fluid
                  required
                  label="Updated Status"
                  placeholder={'Select a New Status'}
                  value={updatedAnnualTaxFilingFormStatus}
                  options={annualTaxFilingFormStatusOptions()}
                  onChange={setUpdatedAnnualTaxFilingFormStatus}
                />
              </Form.Field>
            </Form>
            <br />
            <Modal.Actions>
              <div className="flexbox">
                <Button
                  secondary
                  onClick={() => {
                    closeModal()
                    setUpdatedAnnualTaxFilingFormStatus(undefined)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  primary
                  disabled={!updateAnnualTaxFilingFormStatus}
                  onClick={() => {
                    if (filingFormId) {
                      updateAnnualTaxFilingFormStatus(filingFormId)
                    }
                  }}
                >
                  Change Status
                </Button>
              </div>
            </Modal.Actions>
          </>
        )}
      </Modal.Content>
    </Modal>
  )
}

export default TaxFilingFormUploadModal
