import { useMemo } from 'react'
import { Alert } from '../../../../../components/BaseComponents'
import {
  EndOfYearReviewSubmission,
  EndOfYearSubmissionStatus,
} from '../../types'
import './styles.scss'

export type SubmissionBannerProps = {
  submission: EndOfYearReviewSubmission | undefined | null
}
const SubmissionBanner = ({ submission }: SubmissionBannerProps) => {
  const show = useMemo(() => {
    return submission?.status === EndOfYearSubmissionStatus.actionRequired
  }, [submission])

  if (!show) {
    return null
  }

  return (
    <Alert
      title="Waiting on user follow-up"
      closeable={false}
      type="info"
      className="eoy-admin__submission-banner"
    >
      Please wait for them to complete follow-up before continuing with their
      year-end bookkeeping.
    </Alert>
  )
}

export default SubmissionBanner
