import axios from 'axios'
import { fetchWrapper } from '../../../reducers/fetch'

export enum TaxfyleJobHistoryFormType {
  form_1040 = 'form_1040',
  form_1120_s = 'form_1120_s',
  form_4868 = 'form_4868',
  form_7004 = 'form_7004',
}

export enum TaxfyleJobHistoryChangedField {
  jobStatus = 'jobStatus',
  jobStep = 'jobStep',
  services = 'services',
  members = 'members',
  transferredProviderReason = 'transferredProviderReason',
}

export interface TaxfyleJobHistoryMember {
  role: 'CHAMPION' | 'SUPPORTING'
  type: 'CLIENT' | 'PROVIDER'
  user_id: string
  given_name: string
  family_name: string
  display_name: string
}

export const isTaxfyleJobHistoryMember = (
  value: unknown
): value is TaxfyleJobHistoryMember => {
  return (
    typeof value === 'object' &&
    value !== null &&
    'role' in value &&
    'type' in value &&
    'user_id' in value &&
    'given_name' in value &&
    'family_name' in value &&
    'display_name' in value
  )
}

export interface TaxfyleJobHistory {
  id: number
  jobId: string
  formType: TaxfyleJobHistoryFormType | null
  changedField: TaxfyleJobHistoryChangedField
  oldValue: string | TaxfyleJobHistoryMember | null
  newValue: string | TaxfyleJobHistoryMember | null
  createdAt: string
  updatedAt: string
}

export const getAdminTaxfyleJobHistoryKey = (jobId: string) =>
  `FETCH_ADMIN_TAXFYLE_JOB_HISTORY_KEY_${jobId}`
export const fetchTaxfyleJobHistory = (jobId: string) =>
  fetchWrapper({
    fetchKey: getAdminTaxfyleJobHistoryKey(jobId),
    fetchFunction: async () => {
      const json = await axios.get<TaxfyleJobHistory[]>(
        `/finances/api/v2/admin/taxfyle_job_history/${jobId}`
      )
      return json.data
    },
  })
