import { Grid, Image } from 'semantic-ui-react'
import {
  Button,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { useNavigate } from 'react-router-dom'
import { defaultMessagesURL } from '../../../Zendesk/helpers'
import { useReselector } from '../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'

const centeredColumnStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const LateJoinerOptedOutPage = () => {
  const navigate = useNavigate()
  const currentAnnualTaxYear = useReselector(selectCurrentAnnualTaxYear)
  return (
    <Grid
      style={{
        maxWidth: '700px',
        margin: '64px auto',
      }}
    >
      <>
        <GridRowColumn columnStyle={centeredColumnStyle}>
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/annual_taxes.svg"
            style={{
              width: '250px',
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </GridRowColumn>
        <GridRowColumn columnStyle={centeredColumnStyle}>
          <Text as="display2">Opted out of annual taxes with Heard</Text>
        </GridRowColumn>
        <GridRowColumn
          columnStyle={{ textAlign: 'center', justifyItems: 'center' }}
        >
          <Text>
            As a reminder, you indicated you didn&apos;t need Heard to help you
            file your {currentAnnualTaxYear} taxes when you signed up. If
            that&apos;s changed, we&apos;d love to help you - please reach out
            via Messages to get things started.
          </Text>
          <br />
          <Text>
            Please note that in order to make sure you have ample time to file
            an accurate return, the first step in our process will be to file an
            extension request - we make this process quick and easy.
          </Text>
        </GridRowColumn>
        <GridRowColumn columnStyle={{ ...centeredColumnStyle, gap: 16 }}>
          <Button
            variant="secondary"
            onClick={() => (window.location.href = defaultMessagesURL)}
          >
            Reach out via Messages
          </Button>
          <Button variant="primary" onClick={() => navigate('/dashboard')}>
            Return Home
          </Button>
        </GridRowColumn>
      </>
    </Grid>
  )
}

export default LateJoinerOptedOutPage
