import { makeGridConfig } from '../../../../components/BaseComponents/Grid'

// The tax checklist generally has the large spacers at the top and bottom of the page and the small spacers in the middle
export const tqBigSpace = makeGridConfig([10, 14, 14], true)
export const tqSmallSpace = makeGridConfig([8, 10, 14], true)

export const tqTwoButtonSpacer = {
  computer: 4 as const,
  tablet: 3 as const,
  mobile: 1 as const,
}

export const tqTwoButtonWidth = {
  computer: 4 as const,
  tablet: 5 as const,
  mobile: 7 as const,
}
