import { createContext } from 'react'
import {
  AdminStepUpdater,
  OnAdminNoteSaveCallback,
  OnDocumentReUploadSaveCallback,
} from '../types'
import { PayrollProfile } from '../../../Payroll/payrollProfile.slice'

export type AdminYearEndReviewContextType = {
  taxYear: string
  updateStep: AdminStepUpdater
  openAdminNoteModal: (
    initialNote: string | null,
    onSave: OnAdminNoteSaveCallback
  ) => void
  openStatementReUploadModal: (
    initialNote: string | null,
    onSave: OnDocumentReUploadSaveCallback
  ) => void
  openDocumentReUploadModal: (
    initialNote: string | null,
    onSave: OnDocumentReUploadSaveCallback
  ) => void
  openConfirmModal: () => void
  priorYearRetainedEarnings: number | null
  priorYearRetainedEarningsOnChange: (amountInCents: number) => Promise<void>
  payrollProfile: PayrollProfile | undefined | null
}

export const AdminYearEndReviewContext =
  createContext<AdminYearEndReviewContextType>({
    taxYear: '',
    updateStep: () => Promise.resolve(null),
    openAdminNoteModal: () => Promise.resolve(),
    openStatementReUploadModal: () => Promise.resolve(),
    openDocumentReUploadModal: () => Promise.resolve(),
    openConfirmModal: () => Promise.resolve(),
    priorYearRetainedEarnings: null,
    priorYearRetainedEarningsOnChange: () => Promise.resolve(),
    payrollProfile: null,
  })
