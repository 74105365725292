import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Grid, Loader } from 'semantic-ui-react'

import {
  getAllActiveUserActionItems,
  getAllCompletedUserActionItems,
  getAllUpcomingUserActionItems,
  getIncompleteAnnualTaxActionItemsForYear,
  getIncompleteEoyActionItems,
  getIncompleteQuarterlyTaxActionItemsForYearAndQuarter,
} from './userActionItems.selectors'
import {
  fetchUserActionItemsIfNeeded,
  FETCH_USER_ACTION_ITEMS_KEY,
  groupOptions,
  UserActionItem,
} from './userActionItems.slice'
import UserActionItemTabPane, {
  ACTION_ITEM_TYPE,
} from './components/UserActionItemTabPane'
import {
  getFetchError,
  getIsFetchingOrNotStarted,
} from '../../../reducers/fetch'
import {
  Alert,
  Card,
  Dropdown,
  Tab,
  Text,
  Label,
  Pill,
  Link,
  GridRowColumn,
} from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import {
  selectCurrentAnnualTaxYear,
  selectTaxDetailsByYear,
} from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { fetchAllQuarterlyTaxEstimateDetailsIfNeeded } from '../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.slice'
import { selectActiveQuarterlyTaxEstimateDetails } from '../../Admin/QuarterlyTaxEstimateDetails/quarterlyTaxEstimateDetails.selector'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { selectHasIncompleteExtension } from '../../Taxes/AnnualTaxes/annualTaxFilings.selector'
import { select1120sFormForYear } from '../../Taxes/AnnualTaxes/annualTaxFilingForms.selector'
import { AnnualTaxFilingFormQuestionnaireStatus } from '../../Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import { DateTime } from 'luxon'
import {
  DATE_FORMATS_LUXON,
  convertUtcToLocalDate,
  isoToUTCDateTime,
} from '../../../utils/dateHelpers'

const TAB_INDEXES: { [key: number]: string } = {
  0: 'active',
  1: 'upcoming',
  2: 'completed',
}

interface EoyActionItemsCardProps {
  incompleteEoyActionItems: UserActionItem[]
  isUrgent: boolean
}

const EoyActionItemsCard = ({
  incompleteEoyActionItems,
  isUrgent,
}: EoyActionItemsCardProps) => {
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)

  return (
    <Card
      style={{ minHeight: 300 }}
      type="subsection"
      backgroundColor="stone40"
    >
      <Grid>
        <GridRowColumn>
          <div style={{ display: 'flex' }}>
            {isUrgent && (
              <Pill variant="solid" color="red" style={{ marginRight: 10 }}>
                Urgent
              </Pill>
            )}
            <Text as="h2">Finish Your {currentTaxYear} Books</Text>
          </div>
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            Uploading the following allows Heard to finish your bookkeeping,
            which must be done before you can submit your Tax Checklist.{' '}
            <Link
              href="https://support.joinheard.com/hc/en-us/articles/19809547067543"
              newPage
              as="secondaryLink"
            >
              How do I find these documents?
            </Link>
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <UserActionItemTabPane
            groupOption={'date-asc-groupedby-categoryName'}
            tabPaneType={ACTION_ITEM_TYPE.ACTIVE}
            userActionItems={incompleteEoyActionItems}
            hideCalendar
          />
        </GridRowColumn>
      </Grid>
    </Card>
  )
}

interface TaxActionItemsCardProps {
  incompleteActionItems: UserActionItem[]
  isUrgent: boolean
}

const TaxActionItemsCard = ({
  incompleteActionItems,
  isUrgent,
}: TaxActionItemsCardProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const hasIncompleteExtension = useReselector(
    selectHasIncompleteExtension,
    taxYear
  )

  const annualTaxDetails = useReselector(selectTaxDetailsByYear, taxYear)

  const form1120s = useReselector(select1120sFormForYear, taxYear)
  const form1120sTQExtendedDueDate = convertUtcToLocalDate(
    annualTaxDetails?.taxQuestionnaireDueDates?.form_1120_s.cutoffDate
  )
  const hasIncomplete1120s =
    form1120s &&
    form1120s.questionnaireResponseStatus !==
      AnnualTaxFilingFormQuestionnaireStatus.submitted &&
    annualTaxDetails?.taxQuestionnaireDueDates &&
    //add one day to be inclusive of the cutoff date
    form1120sTQExtendedDueDate &&
    DateTime.now() <= form1120sTQExtendedDueDate.endOf('day')

  return (
    <Card
      style={{ minHeight: 300 }}
      type="subsection"
      backgroundColor="stone40"
    >
      <Grid>
        <GridRowColumn>
          <div style={{ display: 'flex' }}>
            {isUrgent && (
              <Pill variant="solid" color="red" style={{ marginRight: 10 }}>
                Urgent
              </Pill>
            )}
            <Text as="h2">
              {hasIncompleteExtension
                ? 'Complete Your Extension Request Survey'
                : hasIncomplete1120s &&
                    annualTaxDetails?.taxQuestionnaireDueDates
                  ? `Complete your Business Tax Checklist by ${isoToUTCDateTime(annualTaxDetails?.taxQuestionnaireDueDates?.form_1120_s.cutoffDate).toFormat(DATE_FORMATS_LUXON.MONTH_DAY_LONG)}`
                  : 'Get Started With Your Tax Checklist'}
            </Text>
          </div>
        </GridRowColumn>
        <GridRowColumn>
          <Text>
            {hasIncompleteExtension
              ? 'The Extension Request Survey provides all the necessary information to connect you with your tax preparer and for them to file your extension request. The sooner you complete it, the sooner we can file your extension.'
              : hasIncomplete1120s && annualTaxDetails?.taxQuestionnaireDueDates
                ? `You must complete your Business Tax Checklist by ${isoToUTCDateTime(annualTaxDetails?.taxQuestionnaireDueDates?.form_1120_s.cutoffDate).toFormat(DATE_FORMATS_LUXON.MONTH_DAY_LONG)}, or you will not be able to file Business or Personal taxes with Heard for the ${taxYear} Tax Season.`
                : 'The Tax Checklist includes all of the information we need from you to ensure an on-time and accurate tax filing experience. The sooner you complete it, the sooner we can start working on your tax return(s).'}
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <UserActionItemTabPane
            groupOption={'date-asc-groupedby-categoryName'}
            tabPaneType={ACTION_ITEM_TYPE.ACTIVE}
            userActionItems={incompleteActionItems}
            hideCalendar
          />
        </GridRowColumn>
      </Grid>
    </Card>
  )
}

const QTEActionItemsCard = ({
  incompleteActionItems,
  isUrgent,
}: TaxActionItemsCardProps) => (
  <Card style={{ minHeight: 300 }} type="subsection" backgroundColor="stone40">
    <Grid>
      <GridRowColumn>
        <div style={{ display: 'flex' }}>
          {isUrgent && (
            <Pill variant="solid" color="red" style={{ marginRight: 10 }}>
              Urgent
            </Pill>
          )}
          <Text as="h2">Pay Your Quarterly Tax Estimate</Text>
        </div>
      </GridRowColumn>
      <GridRowColumn>
        <Text>
          Update your tax information and upload your bank statements in order
          to know how much you need to pay for your quarterly tax estimate.
        </Text>
      </GridRowColumn>
      <GridRowColumn>
        <UserActionItemTabPane
          groupOption={'date-asc-groupedby-categoryName'}
          tabPaneType={ACTION_ITEM_TYPE.ACTIVE}
          userActionItems={incompleteActionItems}
          hideCalendar
        />
      </GridRowColumn>
    </Grid>
  </Card>
)

const UserActionItemsCard = () => {
  const dispatch = useAppDispatch()

  // Action items will be sorted/grouped by "Category" by default
  const defaultSortValue = groupOptions[0].value
  const [dropdownValue, setDropdownValue] = useState(defaultSortValue)
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const currentTaxQuarterDetails = useReselector(
    selectActiveQuarterlyTaxEstimateDetails
  )
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultTab = searchParams.get('tab')

  const [activeTabIndex, setActiveTabIndex] = useState(() => {
    switch (defaultTab) {
      case 'upcoming':
        return 1
      case 'completed':
        return 2
      case 'active':
      default:
        return 0
    }
  })
  const activeItems = useReselector(getAllActiveUserActionItems)
  const incompleteEoyActionItems = useReselector(getIncompleteEoyActionItems)
  const incompleteAnnualTaxActionItems = useReselector(
    getIncompleteAnnualTaxActionItemsForYear,
    currentTaxYear
  )
  const incompleteQuarterlyTaxActionItems = useReselector(
    getIncompleteQuarterlyTaxActionItemsForYearAndQuarter,
    {
      taxYear: currentTaxQuarterDetails?.taxYear,
      taxQuarter: currentTaxQuarterDetails?.taxQuarter,
    }
  )

  const upcomingItems = useReselector(getAllUpcomingUserActionItems)
  const completedItems = useReselector(getAllCompletedUserActionItems)
  const isFetching = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_USER_ACTION_ITEMS_KEY
  )
  const fetchError = useReselector(getFetchError, FETCH_USER_ACTION_ITEMS_KEY)

  const updateTab = (newIndex: number) => {
    setSearchParams({ tab: TAB_INDEXES[newIndex] }, { replace: true })
    setActiveTabIndex(newIndex)
  }

  const tabPanes = [
    {
      menuItem: 'Active',
      ornament: Boolean(activeItems.length) && (
        <Label $circular color="darkGreen">
          {activeItems.length}
        </Label>
      ),
      render: () => (
        <UserActionItemTabPane
          groupOption={dropdownValue}
          tabPaneType={ACTION_ITEM_TYPE.ACTIVE}
          userActionItems={activeItems}
        />
      ),
    },
    {
      menuItem: 'Upcoming',
      render: () => (
        <UserActionItemTabPane
          groupOption={dropdownValue}
          tabPaneType={ACTION_ITEM_TYPE.UPCOMING}
          userActionItems={upcomingItems}
        />
      ),
    },
    {
      menuItem: 'Completed',
      render: () => (
        <UserActionItemTabPane
          groupOption={dropdownValue}
          tabPaneType={ACTION_ITEM_TYPE.COMPLETED}
          userActionItems={completedItems}
        />
      ),
    },
  ]

  useEffect(() => {
    /* Always fetch */
    dispatch(fetchUserActionItemsIfNeeded({ forceFetch: true }))
    dispatch(fetchAllQuarterlyTaxEstimateDetailsIfNeeded())
  }, [dispatch])

  return (
    <>
      {incompleteEoyActionItems.length > 0 && !isFetching && (
        <EoyActionItemsCard
          incompleteEoyActionItems={incompleteEoyActionItems}
          isUrgent
        />
      )}
      {incompleteAnnualTaxActionItems.length > 0 && !isFetching && (
        <TaxActionItemsCard
          incompleteActionItems={incompleteAnnualTaxActionItems}
          isUrgent={incompleteEoyActionItems.length === 0}
        />
      )}
      {incompleteQuarterlyTaxActionItems.length > 0 && !isFetching && (
        <QTEActionItemsCard
          incompleteActionItems={incompleteQuarterlyTaxActionItems}
          isUrgent={
            incompleteEoyActionItems.length === 0 &&
            incompleteAnnualTaxActionItems.length === 0
          }
        />
      )}
      {/* Do not show regular todos if Tax and EOY action lists both exist */}
      {(incompleteEoyActionItems.length === 0 ||
        incompleteAnnualTaxActionItems.length === 0) && (
        <Card
          style={{ minHeight: 300 }}
          type="subsection"
          backgroundColor="stone40"
        >
          <Grid>
            <Grid.Row>
              <Grid.Column floated="left" width={7}>
                <Text as="h2">To Do</Text>
              </Grid.Column>
              <Grid.Column width={9}>
                {/* Putting dropdown and label in the same column; ensuring the label maintains the same distance from the dropdown upon value change */}
                <Dropdown
                  style={{ float: 'right', display: 'inline-block' }}
                  variant="text"
                  options={groupOptions}
                  value={dropdownValue}
                  onChange={setDropdownValue}
                />
                <Text
                  style={{
                    display: 'inline-block',
                    float: 'right',
                    paddingRight: 8,
                  }}
                >
                  Sort:
                </Text>
              </Grid.Column>
            </Grid.Row>
            {fetchError && (
              <GridRowColumn>
                <Alert type="warn">
                  There was an error fetching your To Do&apos;s. Please refresh
                  the page to try again.
                </Alert>
              </GridRowColumn>
            )}
            <GridRowColumn>
              {isFetching && <Loader active />}
              {!isFetching && !fetchError && (
                <Tab
                  activeIndex={activeTabIndex}
                  defaultActiveIndex={activeTabIndex}
                  onTabClick={updateTab}
                  menu={{ secondary: true, pointing: true }}
                  panes={tabPanes}
                />
              )}
            </GridRowColumn>
          </Grid>
        </Card>
      )}
    </>
  )
}

export default UserActionItemsCard
