import { captureMessage, captureException } from '@sentry/react'

import { getStore } from '../store'

// This should be coming from Sentry but it's not being exported properly
interface ScopeContext {
  User: {
    id?: string | number
  }
  extra: Record<string, unknown>
  tags: {
    [key: string]: string
  }
}

export const logSentryError = (
  error: unknown,
  captureContext?: Partial<ScopeContext>
) => {
  const state = getStore()?.getState()
  const userId = state?.auth.user?.id

  captureException(error, {
    ...captureContext,
    user: { id: userId?.toString() },
  })
}

export const logSentryMessage = (
  message: string,
  captureContext?: Partial<ScopeContext>
) => {
  const state = getStore()?.getState()
  const userId = state?.auth.user?.id

  captureMessage(message, {
    ...captureContext,
    user: { id: userId?.toString() },
  })
}
