import { DateTime } from 'luxon'
import {
  ExtensionRequestJobSubstep,
  YearEndModuleStatusOptions,
  YearEndModuleSubstepId,
  YearEndModuleType,
} from '../yearEndModuleStatus.slice'
import YearEndModuleBaseCard, {
  defaultDeadlineApproaching,
  getDeadlineApproachingDaysUntilDueDate,
  UpcomingStatusTextWrapper,
  YearEndModuleStatusDynamicDataParams,
} from './YearEndModuleStatusBaseCard'
import { Link, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import {
  getModuleNameCopy,
  useModuleActionButtonBehavior,
  getModuleComplementaryText,
} from '../yearEndModuleStatus.helpers'
import { DATE_FORMATS_LUXON, isoToLocalDate } from '../../../utils/dateHelpers'

const getWaitingOnHeardDescription = (
  substepIdentifier?: YearEndModuleSubstepId | null
) => {
  switch (substepIdentifier) {
    case ExtensionRequestJobSubstep.unclaimedJob:
      return 'We’re matching you to a tax preparer who will assist with filing your extension. Your information will be sent to them.'
    case ExtensionRequestJobSubstep.inProgress:
      return 'Your extension request is in progress.'
    default:
      return 'Your tax preparer is working on your extension.'
  }
}

const getActionRequiredDescription = (
  substepIdentifier?: YearEndModuleSubstepId | null
) => {
  switch (substepIdentifier) {
    case ExtensionRequestJobSubstep.inProgressNeedsUserAction:
      return 'Your tax preparer has questions about your information.'
    default:
      return ''
  }
}

const getDescription = (
  status: YearEndModuleStatusOptions,
  substepIdentifier?: YearEndModuleSubstepId | null,
  filedDate?: DateTime | null
) => {
  const filedDateText = filedDate
    ? `${filedDate.toFormat(DATE_FORMATS_LUXON.DISPLAY_SHORT)}`
    : ''
  switch (status) {
    case YearEndModuleStatusOptions.waitingOnHeard: {
      return getWaitingOnHeardDescription(substepIdentifier)
    }
    case YearEndModuleStatusOptions.actionRequired: {
      return getActionRequiredDescription(substepIdentifier)
    }
    case YearEndModuleStatusOptions.complete: {
      return (
        <>
          Successfully filed {filedDate && 'on'} <b>{filedDateText}</b>
        </>
      )
    }
    case YearEndModuleStatusOptions.missed: {
      return (
        <>
          Since you missed the deadline, we are no longer able to file an
          extension for you.
          <br />
          <br />
          You are now{' '}
          <b>at risk of incurring failure to file penalties and interest</b>.
          Please file your tax return(s) as soon as you can.
        </>
      )
    }
    case YearEndModuleStatusOptions.cancelled: {
      return 'Your extension request has been cancelled and will not be filed.'
    }
    case YearEndModuleStatusOptions.upcoming: {
      return (
        <UpcomingStatusTextWrapper
          moduleType={YearEndModuleType.fileExtensionRequest}
        />
      )
    }
    default:
      return null
  }
}

const getAlertBody = (dueDate: string | null) => {
  const daysUntilDueDate = getDeadlineApproachingDaysUntilDueDate(dueDate)
  if (daysUntilDueDate === null) {
    return null
  }
  const beforeDueDate = daysUntilDueDate >= 0
  const linkHref =
    'https://support.joinheard.com/hc/en-us/articles/19788518004887-Taxfyle-FAQs'
  const link = (
    <Link
      href={linkHref}
      newPage
      size="small"
      style={{
        textDecoration: 'underline',
        color: Colors.charcoal,
        textDecorationColor: Colors.charcoal,
      }}
    >
      More info
    </Link>
  )
  if (beforeDueDate) {
    return (
      <Text as="bodySm">
        After that, you may be at risk of penalties and interest. Please
        complete your extension filing ASAP.
      </Text>
    )
  }
  return (
    <Text as="bodySm">
      The deadline for filing your extension request has passed. Please check
      with your tax preparer to see if your request has been filed on time.{' '}
      {link}
    </Text>
  )
}

const FileExtensionRequestCard = ({
  status,
  dueDate,
  taxYear,
  substepIdentifier,
  filedDateIso,
}: YearEndModuleStatusDynamicDataParams & {
  filedDateIso?: string | null
}) => {
  const filedDate = isoToLocalDate(filedDateIso)
  const description = getDescription(status, substepIdentifier, filedDate)
  const { onActionClick } = useModuleActionButtonBehavior(
    YearEndModuleType.fileExtensionRequest,
    status
  )
  // Don't show button for unclaimed job
  const showButtonOn =
    substepIdentifier === ExtensionRequestJobSubstep.unclaimedJob
      ? [
          YearEndModuleStatusOptions.upcoming,
          YearEndModuleStatusOptions.actionRequired,
        ]
      : [
          YearEndModuleStatusOptions.upcoming,
          YearEndModuleStatusOptions.actionRequired,
          YearEndModuleStatusOptions.waitingOnHeard,
        ]

  return (
    <YearEndModuleBaseCard
      key={YearEndModuleType.fileExtensionRequest}
      status={status}
      dueDate={dueDate}
      taxYear={taxYear}
      header={getModuleNameCopy(YearEndModuleType.fileExtensionRequest)}
      imageUrl="https://heard-images.s3.amazonaws.com/assets/tax-extension.svg"
      description={<Text as="bodySm">{description}</Text>}
      timer={{
        unit: 'days',
        value: '7-10',
        complementaryText: getModuleComplementaryText(
          YearEndModuleType.fileExtensionRequest
        ),
      }}
      deadlineApproaching={{
        ...defaultDeadlineApproaching,
        alertBody: getAlertBody(dueDate),
      }}
      button={{
        showOn: showButtonOn,
        onActionClick,
      }}
    />
  )
}

export default FileExtensionRequestCard
