import { useEffect, useMemo, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { Grid, Loader } from 'semantic-ui-react'

import SupportCenterCard from './SupportCenterCard'
import { fetchUserTransactions } from '../Transactions/transactions.slice'
import { fetchTransactionCategoriesIfNeeded } from '../Reports/reports.slice'
import { fetchFinancialAccountsIfNeeded } from '../../actions/financialAccountActions'
import { fetchAllocationRulesIfNeeded } from '../../actions/allocationRuleActions'
import { fetchFinancialProfileActionItems } from './financialProfileActionItems.slice'
import { fetchUserDocumentCategoriesIfNeeded } from '../Admin/UserDocumentCategories/userDocumentCategories.slice'

import UserImportantDatesCard from './UserImportantDates'
import { SIGNUP_PATHS } from '../Signup/helpers'
import { fetchUserActionItemsIfNeeded } from './UserActionItems/userActionItems.slice'
import UserActionItemsCard from './UserActionItems'
import { fetchUserDocuments } from '../UserDocuments/userDocuments.slice'

import { useReselector } from '../../utils/sharedHooks'
import {
  getCurrentUser,
  getIsRequiredStepsOnboarding,
  isFreeTrialPromoCode,
  selectIsSigningUp,
} from '../../selectors/user.selectors'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'
import PageHeader from '../../components/shared/PageHeader'
import WebinarCard from './WebinarCard'
import PayrollCard from './PayrollCard'
import { fetchPayrollProfileIfNeeded } from '../Payroll/payrollActions'
import { selectShouldShowPayrollUpsellCard } from '../Payroll/payroll.selectors'
import { SCorpUpsellCard } from './SCorpUpsell/SCorpUpsellCard'
import { fetchSCorpElectionStatus } from './SCorpUpsell/sCorpActions'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../OpenFeature'
import UsersnapTaxSeasonNPSSurvey from '../Taxes/AnnualTaxes/UsersnapTaxSeasonNPSSurvey'
import { TaxAdvisoryPilot } from '../Taxes/Consultations/PilotBanner'
import { useAppDispatch } from '../../utils/typeHelpers'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { fetchAllEoyReviewStepsIfNeeded } from '../Taxes/AnnualTaxes/TaxChecklist/Shared/ReviewStepsandProgresses/allEoyReviewSteps.slice'
import { fetchAnnualTaxFilingFormsIfNeeded } from '../Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import { fetchAnnualTaxFilingsIfNeeded } from '../Taxes/AnnualTaxes/annualTaxFilings.slice'
import { fetchUserEoyReviewProgress } from '../Taxes/AnnualTaxes/TaxChecklist/Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { selectCurrentAnnualTaxYear } from '../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  selectShowLateFilingAlert,
  selectShowTaxSeasonKickoff,
} from '../Taxes/AnnualTaxes/annualTaxFilings.selector'
import { LateFilingAlert } from '../Taxes/AnnualTaxes/components/LateFilingAlert'
import { selectUserShouldSeeOnboardingDashboard } from '../Onboarding/UserOnboardingSteps/onboarding.selectors'
import {
  FETCH_USER_ONBOARDING_STEPS_KEY,
  fetchUserOnboardingStepsIfNeeded,
} from '../Onboarding/UserOnboardingSteps/onboarding.actions'
import { getIsFetchingOrNotStarted } from '../../reducers/fetch'
import { FETCH_USER_CATCH_UP_BOOKKEEPING_STATUS } from '../CatchUpBookkeepingStatus/catchUpBookkeepingStatus.slice'
import { userHasFinancialAdvisoryAccess } from '../FinancialAdvisory/service'
import { AdvisoryDashboardCard } from './AdvisoryLaunch/AdvisoryDashboardCard'
import TaxSeasonKickoffEntryCard from '../Taxes/AnnualTaxes/TaxSeasonKickoff/TaxSeasonKickoffEntryCard'
import { fetchSubscriptions } from '../../reducers/subscription.slice'
import { fetchUserYearEndModuleStatuses } from '../YearEndModuleStatus/yearEndModuleStatus.slice'

const MainDashboard = () => {
  const dispatch = useAppDispatch()
  const [fetching, setFetching] = useState(true)
  const currentUser = useReselector(getCurrentUser)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const shouldDisplayPayrollUpsellCard = useReselector(
    selectShouldShowPayrollUpsellCard
  )
  const shouldDisplaySCorpUpsellCard = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.scorpUpsellCard,
    false
  )
  const releaseTaxSeasonKickoff = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableTaxSeasonKickoff2024,
    false
  )

  const shouldDisplayAdvisoryBetaLaunch = userHasFinancialAdvisoryAccess()

  const taxYear = useReselector(selectCurrentAnnualTaxYear)

  const showLateFilingAlert = useReselector(selectShowLateFilingAlert)

  const shouldShowTaxSeasonKickoffEntryPoint = useReselector(
    selectShowTaxSeasonKickoff
  )

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(fetchSCorpElectionStatus(currentUser.id))
    }
  }, [dispatch, currentUser?.id])

  useEffect(() => {
    async function batchFetch() {
      await Promise.all([
        dispatch(fetchFinancialProfileActionItems()),
        dispatch(fetchUserTransactions()),
        dispatch(fetchTransactionCategoriesIfNeeded()),
        dispatch(fetchFinancialAccountsIfNeeded()),
        dispatch(fetchAllocationRulesIfNeeded()),
        dispatch(fetchUserDocuments()),
        dispatch(fetchUserDocumentCategoriesIfNeeded()),
        dispatch(
          fetchUserActionItemsIfNeeded({
            forceFetch: true,
          })
        ),
        dispatch(fetchPayrollProfileIfNeeded()),
        //remove the below fetches if LateFilingAlert is ever removed
        dispatch(fetchAnnualTaxFilingFormsIfNeeded()),
        dispatch(fetchAnnualTaxFilingsIfNeeded()),
        dispatch(fetchAllAnnualTaxDetailsIfNeeded()),
        dispatch(fetchAllEoyReviewStepsIfNeeded()),
        dispatch(fetchSubscriptions()),
        dispatch(fetchUserYearEndModuleStatuses()),
      ])
      setFetching(false)
    }
    batchFetch()
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchUserEoyReviewProgress(taxYear))
  }, [dispatch, taxYear])

  return fetching ? (
    <Loader active />
  ) : (
    <>
      {showLateFilingAlert && (
        <>
          <LateFilingAlert />
          <br />
        </>
      )}
      {releaseTaxSeasonKickoff && shouldShowTaxSeasonKickoffEntryPoint && (
        <>
          <TaxSeasonKickoffEntryCard />
          <br />
        </>
      )}
      {!isMobile && !releaseTaxSeasonKickoff && (
        <PageHeader header={`Welcome back, ${currentUser?.firstName}.`} />
      )}
      <Grid stackable>
        <Grid.Row>
          <Grid.Column computer={11} tablet={16}>
            <UserActionItemsCard />
          </Grid.Column>
          <Grid.Column computer={5} tablet={16}>
            {shouldDisplayAdvisoryBetaLaunch && <AdvisoryDashboardCard />}
            {!shouldDisplayAdvisoryBetaLaunch && <TaxAdvisoryPilot />}
            {!shouldDisplayAdvisoryBetaLaunch &&
              shouldDisplayPayrollUpsellCard && <PayrollCard />}
            {shouldDisplaySCorpUpsellCard && <SCorpUpsellCard />}
            <UserImportantDatesCard />
            <WebinarCard />
            <SupportCenterCard />
          </Grid.Column>
        </Grid.Row>
        <UsersnapTaxSeasonNPSSurvey />
      </Grid>
    </>
  )
}

const Dashboard = () => {
  const dispatch = useAppDispatch()
  const currentUser = useReselector(getCurrentUser)
  const isRequiredStepsOnboarding = useReselector(getIsRequiredStepsOnboarding)
  const isSigningUp = useReselector(selectIsSigningUp)
  const shouldShowGettingStartedMVP = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.gettingStartedMVP,
    false
  )
  const shouldShowOnboardingDashboard = useReselector(
    selectUserShouldSeeOnboardingDashboard
  )

  const catchUpBKStatusIsLoading = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_USER_CATCH_UP_BOOKKEEPING_STATUS
  )
  const onboardingStepsIsLoading = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_USER_ONBOARDING_STEPS_KEY
  )
  const hasFreeTrialPromoCode = useReselector(isFreeTrialPromoCode)

  useEffect(() => {
    if (!currentUser?.admin) {
      dispatch(
        fetchUserOnboardingStepsIfNeeded({
          expanded: true,
        })
      )
    }
  }, [currentUser?.admin, currentUser?.id, dispatch])

  const renderRelevantDashboard = useMemo(() => {
    if (catchUpBKStatusIsLoading || onboardingStepsIsLoading) {
      return <Loader active />
      // Show OB Dashboard for Free Trial — 14 days
    } else if (hasFreeTrialPromoCode) {
      return <Navigate to={'/onboarding-dashboard'} />
    } else if (shouldShowGettingStartedMVP && shouldShowOnboardingDashboard) {
      return <Navigate to={'/onboarding-dashboard'} />
    } else {
      return <MainDashboard />
    }
  }, [
    catchUpBKStatusIsLoading,
    onboardingStepsIsLoading,
    shouldShowGettingStartedMVP,
    shouldShowOnboardingDashboard,
    hasFreeTrialPromoCode,
  ])

  // If we don't have a user, assume we're still loading
  if (!currentUser) {
    return <Loader active />
  }

  // Check if we need to navigate away from the dashboard
  if (currentUser?.admin) {
    // If admin, we want to redirect to the admin dashboard
    return <Navigate to="/admin/finances" />
  } else if (isSigningUp) {
    // Check if user is in the middle of signing up or resigning up.
    // If so, redirect them to continue
    return <Navigate to={SIGNUP_PATHS.aboutYourPractice} />
  } else if (isRequiredStepsOnboarding) {
    // Check if user is in the locked down onboarding flow
    return <Navigate to={'/onboarding-v2'} />
  }
  return renderRelevantDashboard
}

export default Dashboard
