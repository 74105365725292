import { useMemo } from 'react'
import { Divider } from 'semantic-ui-react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  Button,
  Icon,
  ProgressBarNoTitles,
  Text,
} from '../../../../components/BaseComponents'
import './styles.scss'

export type FinalReviewWrapperProps = {
  year?: string
  saveAndExit?: () => void
  stepBack?: () => void
  stepForward: () => void
  isComplete?: boolean
  canComplete?: boolean
  completing?: boolean
  step?: number
  children?: React.ReactNode
}
const FinalReviewWrapper = ({
  year,
  step,
  stepBack,
  saveAndExit,
  stepForward,
  isComplete = false,
  canComplete = false,
  completing = false,
  children,
}: FinalReviewWrapperProps) => {
  const continueOnly = useMemo(
    () => Boolean(stepForward) && !stepBack && !saveAndExit,
    [stepForward, stepBack, saveAndExit]
  )

  return (
    <div className="eoy-final-review__final-review-wrapper">
      <header className="final-review-wrapper__header">
        <Text>{year} Year-end Bookkeeping</Text>
        <div className="final-review-wrapper__autosave-wrapper">
          <Icon icon={solid('check')} color="accentGreen" />
          <Text color="accentGreen">Autosaved</Text>
        </div>
      </header>

      {step !== undefined && (
        <ProgressBarNoTitles currentStep={step - 1} totalSteps={3} />
      )}

      <div className="final-review-wrapper__step-body">{children}</div>

      <Divider style={{ margin: 0 }} />

      <div
        className={`final-review-wrapper__nav-buttons_wrapper${continueOnly ? ' final-review-wrapper__continue-only' : ''}`}
      >
        {stepBack && (
          <div>
            <Button variant="secondary" size="medium" onClick={stepBack}>
              Back
            </Button>
          </div>
        )}

        <div className="final-review-wrapper__right-buttons">
          {saveAndExit && (
            <Button
              className="save-and-exit"
              variant="secondary"
              size="medium"
              onClick={saveAndExit}
            >
              Save and Exit
            </Button>
          )}

          <Button
            variant="primary"
            size="medium"
            onClick={stepForward}
            disabled={isComplete && !canComplete}
            loading={completing}
          >
            {isComplete ? (
              <span className="final-review-wrapper__continue-button">
                <Icon icon={solid('check')} color="white" />
                Complete
              </span>
            ) : (
              'Continue'
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default FinalReviewWrapper
