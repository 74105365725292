import { Pill, Text } from '../../../../../components/BaseComponents'
import './styles.scss'

export type UpdatedRowProps = {
  description: string
  testId?: string
}

const UpdatedRow = ({ description, testId }: UpdatedRowProps) => {
  return (
    <div className="eoy-admin__updated-row">
      <Pill color="purple">Updated by user</Pill>
      <Text
        as="bodySm"
        className="eoy-admin__updated-row__right"
        testId={testId}
      >
        {description}
      </Text>
    </div>
  )
}

export default UpdatedRow
