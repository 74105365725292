import {
  PrefixedUserDocumentCategoryIdentifier,
  UserDocumentCategoryIdentifier,
} from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'

export interface IMerchantFees {
  merchantName: string
  transactionAmount?: number
  transactionCount?: number
}

export interface IDocument {
  categoryName: string
  description: string
  instructionsLink?: string
}

export interface IMerchant {
  id: number
  merchantName: string
  documents: IDocument[]
}

export interface INeededDocument {
  name: string
  documents: IDocument[]
}

export interface IMerchantDocument {
  id: number
  merchantName: string
  documentCategoryName: string
  documentName: string
}

const { paymentProcessorReports } = PrefixedUserDocumentCategoryIdentifier
const { form1099k, form1099nec } = UserDocumentCategoryIdentifier

// Would be nice to unify this with root documents-map
const getMerchants = (taxYear: string): IMerchant[] => {
  return [
    {
      id: 2,
      merchantName: 'Alma',
      documents: [
        {
          categoryName: paymentProcessorReports(taxYear),
          description: 'Payout Report or Invoice',
        },
        {
          categoryName: form1099nec,
          description: 'Summary or 1099-NEC',
        },
      ],
    },
    {
      id: 3,
      merchantName: 'CardConnect',
      documents: [
        {
          categoryName: paymentProcessorReports(taxYear),
          description: 'Merchant Statement',
          instructionsLink:
            'https://support.joinheard.com/hc/en-us/articles/23140064258967',
        },
      ],
    },
    {
      id: 4,
      merchantName: 'Cash App',
      documents: [
        {
          categoryName: form1099k,
          description: '1099-K',
          instructionsLink:
            'https://support.joinheard.com/hc/en-us/articles/28474678568087',
        },
      ],
    },
    {
      id: 6,
      merchantName: 'Headway',
      documents: [
        {
          categoryName: form1099k,
          description: '1099-K',
          instructionsLink:
            'https://support.joinheard.com/hc/en-us/articles/28595935498391 ',
        },
        {
          categoryName: form1099nec,
          description: '1099-NEC',
          instructionsLink:
            'https://support.joinheard.com/hc/en-us/articles/28595935498391 ',
        },
      ],
    },
    {
      id: 8,
      merchantName: 'Ivy Pay',
      documents: [
        {
          categoryName: paymentProcessorReports(taxYear),
          description: 'Transactions Activity Report',
          instructionsLink:
            'https://support.joinheard.com/hc/en-us/articles/23140064258967',
        },
      ],
    },
    {
      id: 9,
      merchantName: 'Paypal',
      documents: [
        {
          categoryName: form1099k,
          description: '1099-K',
          instructionsLink:
            'https://support.joinheard.com/hc/en-us/articles/28473243834775',
        },
      ],
    },
    {
      id: 11,
      merchantName: 'SimplePractice',
      documents: [
        {
          categoryName: paymentProcessorReports(taxYear),
          description: 'Tax Report',
          instructionsLink:
            'https://support.joinheard.com/hc/en-us/articles/28465857710359',
        },
      ],
    },
    {
      id: 12,
      merchantName: 'Square',
      documents: [
        {
          categoryName: paymentProcessorReports(taxYear),
          description: 'Sale Summary Report',
          instructionsLink:
            'https://support.joinheard.com/hc/en-us/articles/28465856207639',
        },
      ],
    },
    {
      id: 13,
      merchantName: 'Stripe',
      documents: [
        {
          categoryName: paymentProcessorReports(taxYear),
          description: 'Balance Report',
          instructionsLink:
            'https://support.joinheard.com/hc/en-us/articles/28465779444247',
        },
        {
          categoryName: paymentProcessorReports(taxYear),
          description: 'Payout Reconciliation Report',
          instructionsLink:
            'https://support.joinheard.com/hc/en-us/articles/28465788364439',
        },
      ],
    },
    {
      id: 14,
      merchantName: 'TheraNest',
      documents: [
        {
          categoryName: paymentProcessorReports(taxYear),
          description: 'Vantage Payment Report',
          instructionsLink:
            'https://support.joinheard.com/hc/en-us/articles/28473072194455',
        },
      ],
    },
    {
      id: 15,
      merchantName: 'Venmo',
      documents: [
        {
          categoryName: form1099k,
          description: '1099-K',
          instructionsLink:
            'https://support.joinheard.com/hc/en-us/articles/28474488358551',
        },
      ],
    },
  ]
}

export const getMerchantNames = (): string[] => {
  return getMerchants('').map(({ merchantName }) => merchantName.toLowerCase())
}

export const getMatchingMerchants = (
  taxYear: string,
  merchantRollUps: IMerchantFees[]
): IMerchantDocument[] => {
  const filteredMerchants = getMerchants(taxYear).filter((merchant) =>
    merchantRollUps.some(
      (merchantRollUp) =>
        merchantRollUp.merchantName === merchant.merchantName.toLowerCase()
    )
  )

  const merchants = filteredMerchants.flatMap((merchant) =>
    merchant.documents.map((document) => ({
      id: merchant.id,
      merchantName: merchant.merchantName,
      documentCategoryName: document.categoryName,
      documentName: document.description,
    }))
  )

  return merchants
}

export const getNeededDocuments = (
  taxYear: string,
  merchantFees: IMerchantFees[]
): INeededDocument[] => {
  const merchants = getMerchants(taxYear)
  return merchantFees.map((merchantFee) => {
    const name = merchantFee.merchantName
    const merchant = merchants.find(
      ({ merchantName }) => merchantName.toLowerCase() === name
    )

    return {
      name: merchant?.merchantName || merchantFee.merchantName,
      documents: merchant?.documents || [],
    }
  })
}

export const getInstructionsLink = (
  categoryName: string,
  merchantName: string
): string => {
  for (const merchant of getMerchants('2024')) {
    if (merchant.merchantName === merchantName) {
      const matchingDoc = merchant.documents.find(
        (doc) => doc.categoryName === categoryName
      )

      return matchingDoc?.instructionsLink ?? ''
    }
  }

  return ''
}
