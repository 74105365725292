import { useMemo, useContext, useCallback } from 'react'
import { Alert, Link, Text } from '../../../../../components/BaseComponents'
import { FinancialAccountWithAdminInfo } from '../../../../../reducers/admin/financialAccountsReducer'
import {
  DisconnectedAccountsStep,
  EndOfYearAdminReviewStep,
  EndOfYearReviewStepStatus,
  ExtendedStepStatus,
} from '../../types'
import BookkeepingReviewSection from '../bookkeeping-review-section'
import { TEST_IDS } from '../helpers'
import { AdminYearEndReviewContext } from '../context'
import './styles.scss'

const { pending, complete } = EndOfYearReviewStepStatus

const CheckedCannotReconnectAccounts = ({
  accountIds,
  financialAccounts,
}: {
  accountIds?: number[] | null
  financialAccounts: FinancialAccountWithAdminInfo[]
}) => {
  if (!accountIds || accountIds.length === 0) {
    return null
  }
  return (
    <>
      <Text
        className="eoy-admin__disconnected-accounts__checked-cannot-reconnect-account"
        testId={TEST_IDS.cannotReconnectTitle}
      >
        Checked &quot;I can&apos;t reconnect this account&quot;
      </Text>
      <ul className="eoy-admin__disconnected-accounts__cannot_reconnect_accounts">
        {accountIds.map((accountId) => {
          const account = financialAccounts.find(
            (account) => account.id === accountId
          )
          return account ? (
            <li
              key={account.id}
              data-testid={TEST_IDS.cannotReconnectAccount(account.id)}
            >
              {account.plaidInstitutionName} {account.name} {account.mask}
            </li>
          ) : null
        })}
      </ul>
    </>
  )
}

export type DisconnectedAccountsSectionProps = {
  step: DisconnectedAccountsStep | null
  financialAccounts: FinancialAccountWithAdminInfo[]
  userId: number
}
const DisconnectedAccountsSection = ({
  step,
  financialAccounts,
  userId,
}: DisconnectedAccountsSectionProps) => {
  const { updateStep } = useContext(AdminYearEndReviewContext)

  const hasCannotReconnectAccounts = useMemo(() => {
    return (
      step?.context?.cannotReconnectAccountIds &&
      step?.context?.cannotReconnectAccountIds.length > 0
    )
  }, [step?.context?.cannotReconnectAccountIds])

  const onConfirmClick = useCallback(() => {
    const status = step?.status === complete ? pending : complete
    updateStep({
      step: step?.step ?? EndOfYearAdminReviewStep.disconnectedAccounts,
      status,
    })
  }, [step, updateStep])

  const displayStatus = useMemo<ExtendedStepStatus>(() => {
    if (step && step.status === pending && step.context?.zendeskTicketId) {
      return 'pending_ticket'
    }
    return step?.status ?? pending
  }, [step])

  return (
    <BookkeepingReviewSection
      sectionTitle="Reconnect disconnected accounts"
      className="eoy-admin__disconnected-accounts"
      status={displayStatus}
      confirmationTitle="Confirmed"
      confirmationDescription={
        step?.status === complete
          ? null
          : 'Confirm that all accounts are connected.'
      }
      minHeight={195}
      testId="disconnected-accounts"
      onConfirmClick={onConfirmClick}
    >
      <>
        {hasCannotReconnectAccounts ? (
          <>
            <CheckedCannotReconnectAccounts
              accountIds={step?.context?.cannotReconnectAccountIds}
              financialAccounts={financialAccounts}
            />

            {step?.context?.zendeskTicketId && (
              <Alert
                className="eoy-admin__disconnected-acounts_zendesk-alert"
                type="info"
              >
                A Zendesk ticket has been opened with Support. You will be able
                to confirm after all accounts are connected.
              </Alert>
            )}
          </>
        ) : (
          <Text as="bodySm" testId={TEST_IDS.allAccountsConnected}>
            All accounts are connected.
          </Text>
        )}

        <Link
          className="eoy-admin__disconnected-accounts__financial-institutions"
          to={`/admin/finances/records/${userId}`}
          size="small"
        >
          View financial institutions &rarr;
        </Link>
      </>
    </BookkeepingReviewSection>
  )
}

export default DisconnectedAccountsSection
