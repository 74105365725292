import { Grid, Image } from 'semantic-ui-react'
import { Button, GridRowColumn, Text } from '../../components/BaseComponents'
import { useNavigate } from 'react-router-dom'
import { getCurrentUser } from '../../selectors/user.selectors'
import { useReselector } from '../../utils/sharedHooks'

const centeredColumnStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const UpdatesDisabledPage = () => {
  const navigate = useNavigate()
  const user = useReselector(getCurrentUser)

  const introText = user?.admin
    ? "This user's transactions are under maintanance"
    : 'Your transactions are under maintenance'

  const link = user?.admin ? '/admin/finances/list-users' : '/dashboard'

  const linkLabel = user?.admin ? 'Return to Users List' : 'Return Home'

  return (
    <Grid
      style={{
        maxWidth: '700px',
        margin: '64px auto',
      }}
    >
      <>
        <GridRowColumn columnStyle={centeredColumnStyle}>
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/yellow_chair_table.svg"
            style={{
              width: '250px',
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </GridRowColumn>
        <GridRowColumn columnStyle={centeredColumnStyle}>
          <Text as="display2">Transactions Under Maintenance</Text>
        </GridRowColumn>
        <GridRowColumn
          columnStyle={{ textAlign: 'center', justifyItems: 'center' }}
        >
          <Text>
            {introText} and can&apos;t be updated at this time. Please wait 5-10
            minutes and try again.
          </Text>
        </GridRowColumn>
        <GridRowColumn columnStyle={{ ...centeredColumnStyle, gap: 16 }}>
          <Button variant="primary" onClick={() => navigate(link)}>
            {linkLabel}
          </Button>
        </GridRowColumn>
      </>
    </Grid>
  )
}
