import React from 'react'
import { Image } from 'semantic-ui-react'
import { Text } from '../BaseComponents'
import styles from './styles.module.scss'

interface ILandingPageProps {
  children?: React.ReactNode
  customColor?: string
  description?: string
  imageAlt: string
  imageSrc: string
  imageHeight: number
  imageWidth: number
  kickerText?: string
  title: string
  isAbstractHeader?: boolean
}

export const StepHeaders: React.FC<ILandingPageProps> = ({
  children,
  customColor = '',
  description = '',
  imageAlt,
  imageSrc,
  imageHeight,
  imageWidth,
  kickerText,
  title,
  isAbstractHeader = false,
}) => {
  const imageContainerClass =
    customColor === 'green' ? styles.greenBackground : styles.defaultBackground

  return (
    <div className={styles.container}>
      {!isAbstractHeader && (
        <div className={`${styles.imageContainer} ${imageContainerClass}`}>
          <Image
            src={imageSrc}
            alt={imageAlt}
            width={imageWidth}
            height={imageHeight}
          />
        </div>
      )}

      {isAbstractHeader && (
        <div className={`${styles.imageAbstractContainer}`}>
          <Image
            src={imageSrc}
            alt={imageAlt}
            width={imageWidth}
            height={imageHeight}
          />
        </div>
      )}

      <div className={styles.bodyContainer}>
        {kickerText && (
          <Text as="eyebrow" color="darkGray">
            {kickerText}
          </Text>
        )}

        <h1 className={styles.header}>{title}</h1>

        {description && (
          <Text className={styles.descriptionMarginHeader}>{description}</Text>
        )}
      </div>

      {children && <div className={styles.childrenContainer}>{children}</div>}
    </div>
  )
}
