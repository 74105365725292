import { useCallback } from 'react'
import { useEvervault } from '@evervault/react'

import { logSentryError } from './sentryHelpers'

export class EncryptionError extends Error {
  tags: Record<string, string> = {}
  displayMessage =
    'There was a problem encrypting your sensitive data. Please re-enter and try again.'

  constructor(err: unknown) {
    super(err instanceof Error ? err.message : String(err))
    if (err instanceof Error) {
      this.stack = err.stack
    }
    this.name = 'EncryptionError'
    this.tags = { error_type: 'tax' }

    // Set the prototype explicitly, for `instanceof` to work correctly when transpiled to ES5.
    Object.setPrototypeOf(this, EncryptionError.prototype)
  }
}

export const isEmptyString = (value: unknown) =>
  typeof value === 'string' && value === ''

export const isEncrypted = (value: unknown) =>
  typeof value === 'string' && /^ev:/.test(value)

export const useEncryption = () => {
  const evervault = useEvervault()

  const encrypt = useCallback(
    <T>(value: T) => {
      try {
        if (isEncrypted(value)) {
          return value
        }
        if (isEmptyString(value)) {
          // if Evervault receives an empty string, it does not error and returns an empty string
          // https://github.com/evervault/evervault-js/blob/master/packages/browser/lib/main.ts#L206
          throw new Error('Entered value is an empty string')
        }

        if (evervault?.encrypt) {
          return evervault?.encrypt(value).then((encryptedVal) => {
            if (isEmptyString(encryptedVal)) {
              throw new Error('Encrypted value is an empty string')
            }
            if (!isEncrypted(encryptedVal)) {
              throw new Error('Value could not be encrypted')
            }
            return encryptedVal
          })
        } else {
          throw new Error('Evervault not initialized')
        }
      } catch (err) {
        const error = new EncryptionError(err)
        logSentryError(error, { tags: error.tags })
        throw error
      }
    },
    [evervault]
  )

  return { encrypt }
}
