import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  AnnualTaxFiling,
  createAnnualTaxFilings,
  submitAnnualTaxFiling,
  updateAnnualTaxFilings,
} from './annualTaxFilings.slice'
import { useReselector } from '../../../utils/sharedHooks'
import { getAnnualTaxFilingForYearSelector } from './annualTaxFilings.selector'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { debounce } from 'lodash'
import { updateUserEoyReviewProgress } from './TaxChecklist/Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.slice'
import { selectUserEoyReviewProgressForSubstepIdentifier } from './TaxChecklist/Shared/ReviewStepsandProgresses/userEndOfYearReviewProgress.selector'
import { SubStepIdentifiers } from './TaxChecklist/Shared/ReviewStepsandProgresses/stepProgress.helpers'

export const useSaveAnnualTaxFilingFormData = (year: string) => {
  const dispatch = useAppDispatch()
  const currentAnnualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    year
  )

  return useCallback(
    async (data: Partial<AnnualTaxFiling> & { message?: string }) => {
      // If we are submitting we don't want to update the survey, but we may need to call the create endpoint
      const shouldSubmit = Boolean(data.pretaxSurveySubmittedAt)
      let res
      if (currentAnnualTaxFiling?.id && !shouldSubmit) {
        res = await updateAnnualTaxFilings(
          currentAnnualTaxFiling.id,
          data
        )(dispatch)
      } else if (!currentAnnualTaxFiling?.id) {
        res = await createAnnualTaxFilings({ ...data, year })(dispatch)
      }

      const id = currentAnnualTaxFiling?.id || res?.id

      if (id && shouldSubmit) {
        res = await submitAnnualTaxFiling(id, data)(dispatch)
      }

      return res
    },
    [currentAnnualTaxFiling?.id, dispatch, year]
  )
}

export const useReviewPanelComment = (subStep: SubStepIdentifiers) => {
  const dispatch = useAppDispatch()
  const progress = useReselector(
    selectUserEoyReviewProgressForSubstepIdentifier,
    subStep
  )
  // This is separated out from redux state for more responsive UI
  const [comment, setComment] = useState<string>(
    progress?.responses?.[0]?.comment || ''
  )

  // Set the comment once progress is loaded
  useEffect(() => {
    if (!comment && progress) {
      setComment(progress?.responses?.[0]?.comment || '')
    }
  }, [progress, comment])

  const updateComment = useCallback(
    (comment: string) => setComment(comment || ''),
    []
  )

  const debounceUpdateProgress = useMemo(
    () =>
      debounce(
        (progressId: number, comment: string) =>
          updateUserEoyReviewProgress(progressId, {
            responses: [{ comment }],
          })(dispatch),
        500
      ),
    [dispatch]
  )

  const saveComment = () => {
    // Don't update if progress step hasn't been initialized yet
    if (comment && progress?.id) {
      debounceUpdateProgress(progress.id, comment)
    }
  }

  return { comment, saveComment, updateComment }
}
