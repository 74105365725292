import { Icon, Text } from '../../../components/BaseComponents'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import getNextStepStatusPill, {
  NextStepPillWithClickBehavior,
} from '../NextStepStatusPillRules'
import { useReselector } from '../../../utils/sharedHooks'
import { selectMostUrgentModule } from '../yearEndModuleStatus.selectors'
import { useModuleActionButtonBehavior } from '../yearEndModuleStatus.helpers'
import { Colors } from '../../../styles/theme'
import { Button } from 'semantic-ui-react'

const OptionalButtonWrapper = ({
  noLink,
  onActionClick,
  children,
  backgroundColor,
}: {
  noLink?: boolean
  onActionClick: () => void
  children: React.ReactNode
  backgroundColor: keyof typeof Colors
}) => {
  const style = {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '100px',
    backgroundColor: Colors[backgroundColor],
    padding: '12px 24px',
    gap: 16,
  }

  if (noLink) {
    return <div style={style}>{children}</div>
  }
  return (
    <Button style={{ ...style, textAlign: 'start' }} onClick={onActionClick}>
      {children}
      <Icon icon={solid('arrow-right')} />
    </Button>
  )
}

const EOYNextStepPill = ({
  icon,
  title,
  subtext,
  backgroundColor,
  noLink,
  onActionClick,
  modal,
}: NextStepPillWithClickBehavior) => {
  return (
    <>
      <OptionalButtonWrapper
        noLink={noLink}
        onActionClick={onActionClick}
        backgroundColor={backgroundColor}
      >
        <div>{icon}</div>
        <div>
          {title && (
            <Text as="bodySm" style={{ fontWeight: 500 }}>
              {title}
            </Text>
          )}
          <Text as="bodyXs">{subtext}</Text>
        </div>
      </OptionalButtonWrapper>
      {modal}
    </>
  )
}

export default EOYNextStepPill

export const useYearEndNextStepPill = () => {
  const mostUrgentEndModuleStatus = useReselector(selectMostUrgentModule)
  const moduleType = mostUrgentEndModuleStatus?.moduleType
  const buttonBehavior = useModuleActionButtonBehavior(
    moduleType ?? null,
    mostUrgentEndModuleStatus?.status ?? null
  )
  const props = getNextStepStatusPill({
    moduleType,
    status: mostUrgentEndModuleStatus?.status,
    substepIdentifier: mostUrgentEndModuleStatus?.substepIdentifier,
    dueDate: mostUrgentEndModuleStatus?.dueDate,
  })
  return <EOYNextStepPill {...props} {...buttonBehavior} />
}
