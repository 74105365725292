import { useCallback, useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import Card from '../../../../components/BaseComponents/Card'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import styles from './styles.module.scss'

import {
  Alert,
  Icon,
  GridRowColumn,
  Text,
} from '../../../../components/BaseComponents'
import { StepHeaders } from '../../../../components/StepHeader'
import { useViewMissingStatementsList } from '../../../../components/shared/ViewMissingStatementsAccordion'
import {
  isoToUTCDateTime,
  DATE_FORMATS_LUXON,
} from '../../../../utils/dateHelpers'
import { MissingStatementCard } from '../../../../components/Finances/Accounts/MissingStatementCard'
import {
  UserDocument,
  MissingStatement,
} from '../../../UserDocuments/userDocuments.slice'
import DeleteDocumentConfirm from '../../../UserDocuments/sharedComponents/Modals/DeleteDocumentConfirm'

interface IMissingStatementsProps {
  missingStatements?: MissingStatement[]
  setIsStepComplete: (value: boolean, value2: boolean) => void
}

interface IStatementMonth {
  [month: string]: UserDocument[]
}

interface ImonthYear {
  monthYear: string
  monthYearLabel: string
}

export const MissingStatements = ({
  missingStatements = [],
  setIsStepComplete,
}: IMissingStatementsProps) => {
  const formattedStatements = useViewMissingStatementsList({
    neededStatements: missingStatements,
  })

  const onUploadOrDelete = useCallback(
    (_: number, __: boolean) => {
      setIsStepComplete(true, true)
    },
    [setIsStepComplete]
  )

  const generateMonthStatements = (
    missingStatements: MissingStatement[] | undefined
  ) => {
    if (missingStatements) {
      const statementMonth: ImonthYear[] = []
      const statementMonthLabel: string[] = []
      const monthStatements: IStatementMonth = {}
      for (const accountStatements of missingStatements) {
        for (const statement of accountStatements.statements) {
          const monthYear = isoToUTCDateTime(statement).toFormat(
            DATE_FORMATS_LUXON.MONTH_YEAR_LONG
          )
          const yearMonth = isoToUTCDateTime(statement).toFormat(
            DATE_FORMATS_LUXON.YEAR_MONTH
          )
          if (!statementMonthLabel.includes(monthYear)) {
            statementMonthLabel.push(monthYear)
            statementMonth.push({
              monthYear: yearMonth,
              monthYearLabel: monthYear,
            })
            monthStatements[yearMonth] = []
          }
        }
      }
    }
  }

  useEffect(() => {
    generateMonthStatements(missingStatements)
  }, [missingStatements])

  return (
    <Grid>
      <GridRowColumn centerContent className="header-container">
        <StepHeaders
          imageSrc="https://heard-images.s3.amazonaws.com/assets/upload-statement.png"
          imageAlt="uploadStatement"
          imageWidth={180}
          imageHeight={180}
          title="Upload missing bank statements"
          description=""
          customColor="green"
        >
          <Grid>
            <GridRowColumn className="align-left">
              <Text>
                We need these to reconcile your books. Please upload all the
                statements listed below.
              </Text>
            </GridRowColumn>
            <GridRowColumn className="align-left">
              {formattedStatements}
            </GridRowColumn>
            <Grid.Row />
            <GridRowColumn className={styles.container}>
              <MissingStatementCard onUploadOrDelete={onUploadOrDelete} />
            </GridRowColumn>
            {missingStatements.length < 1 && (
              <Card backgroundColor="lightGreen" fullWidth>
                <div className="all-reconnected">
                  <Icon icon={solid('check')} color="accentGreen" size="2x" />

                  <div className="reconnected-text">
                    <Text as="h3">All statements uploaded</Text>
                    <Text>Thanks! You’ve uploaded all missing statements.</Text>
                  </div>
                </div>
              </Card>
            )}
          </Grid>
        </StepHeaders>
      </GridRowColumn>
      <GridRowColumn>
        <div className="alert-footer">
          <Alert
            title="Why is this important?"
            style={{ width: '520px' }}
            customIcon={<Icon icon={solid('info-circle')} />}
            contentStyle={{ textAlign: 'left' }}
          >
            Reconciliation is a standard practice to ensure all transactions are
            accounted for. We use your bank statements to check that your
            balances match what we have in your books.
          </Alert>
        </div>
      </GridRowColumn>
      <DeleteDocumentConfirm />
    </Grid>
  )
}

export default MissingStatements
