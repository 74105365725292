import { useEffect, useState } from 'react'
import { EndOfYearReviewSubmission } from '../../types'
import {
  adminGetSubmissions,
  FETCH_ADMIN_GET_SUBMISSIONS_KEY,
} from '../../actions'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import { useReselector } from '../../../../../utils/sharedHooks'
import { getFetchError, getIsFetching } from '../../../../../reducers/fetch'
import SubmissionsPanelContent from './panel-content'

type Props = {
  userId: number
}
const SubmissionsPanel = ({ userId }: Props) => {
  const dispatch = useAppDispatch()
  const [submissions, setSubmissions] = useState<EndOfYearReviewSubmission[]>(
    []
  )

  const isFetching = useReselector(
    getIsFetching,
    FETCH_ADMIN_GET_SUBMISSIONS_KEY
  )
  const fetchError = useReselector(
    getFetchError,
    FETCH_ADMIN_GET_SUBMISSIONS_KEY
  )

  useEffect(() => {
    adminGetSubmissions(userId)(dispatch).then((data) => {
      setSubmissions(data)
    })
  }, [userId, dispatch])

  return (
    <SubmissionsPanelContent {...{ submissions, isFetching, fetchError }} />
  )
}

export default SubmissionsPanel
