import { List } from 'semantic-ui-react'
import { useFormik, FormikProvider } from 'formik'
import { DateTime } from 'luxon'

import {
  createSingleAnnualTaxDetail,
  CREATE_SINGLE_ANNUAL_TAX_DETAIL_KEY,
  AnnualTaxDetail,
  updateSingleAnnualTaxDetail,
  UPDATE_SINGLE_ANNUAL_TAX_DETAIL_KEY,
} from '../annualTaxDetails.slice'
import { getFetchError } from '../../../../reducers/fetch'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  Button,
  Alert,
  Text,
  FormikDateInput,
  FormikInput,
  getFieldName,
} from '../../../../components/BaseComponents'
import { getCurrentUser } from '../../../../selectors/user.selectors'
import {
  DATE_FORMATS,
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../../utils/dateHelpers'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import {
  AnnualTaxDateMapping,
  AnnualTaxDateType,
} from '../annualTaxDateTypes.slice'
import { useMemo } from 'react'

interface Props {
  details?: AnnualTaxDetail
  dateTypes?: AnnualTaxDateType[]
  onCancel: () => void
}

const inputToStartIso = (val: string | undefined) =>
  val
    ? DateTime.fromFormat(val, DATE_FORMATS_LUXON.INPUT)
        .toUTC()
        .startOf('day')
        .toISO() || ''
    : ''

const AnnualTaxDetailsForm = ({ onCancel, dateTypes, details }: Props) => {
  const dispatch = useAppDispatch()

  const hasCreateErrored = useReselector(
    getFetchError,
    CREATE_SINGLE_ANNUAL_TAX_DETAIL_KEY
  )
  const hasUpdateErrored = useReselector(
    getFetchError,
    UPDATE_SINGLE_ANNUAL_TAX_DETAIL_KEY + details?.taxYear
  )

  const currentUser = useReselector(getCurrentUser)

  const formattedDates = useMemo(() => {
    const sortedDateTypes = dateTypes?.sort((a, b) => {
      return a.id.localeCompare(b.displayName)
    })
    return sortedDateTypes?.reduce((acc, curr) => {
      acc[curr.id] = formatISOFromUTC(details?.[curr.id])
      return acc
    }, {} as AnnualTaxDateMapping)
  }, [dateTypes, details])

  const formik = useFormik({
    initialValues: {
      taxYear: details?.taxYear || '',
      ...formattedDates,
    },
    onSubmit: async (values) => {
      const { taxYear, ...dates } = values
      const taxDates = Object.entries(dates).map(([key, value]) => {
        return { id: key, value: inputToStartIso(value) }
      })
      const dataContents = {
        taxDates,
        modifiedBy: currentUser?.id,
      }

      let data
      if (details) {
        data = await updateSingleAnnualTaxDetail(
          details.taxYear,
          dataContents
        )(dispatch)
      } else {
        data = await createSingleAnnualTaxDetail({ taxYear, ...dataContents })(
          dispatch
        )
      }

      if (data) {
        onCancel()
      }
    },
  })

  const { submitForm, isSubmitting, values: _values } = formik

  const error = hasCreateErrored || hasUpdateErrored

  const dateInputListItems = () =>
    dateTypes?.map((dt) => (
      <List.Item key={`dateInputListItems-${dt.id}`}>
        <List.Content floated="right" style={{ width: '33%' }}>
          <FormikDateInput
            dateFormat={DATE_FORMATS.INPUT}
            name={getFieldName<typeof _values>(dt.id)}
            required={!dt.archivedAt}
            disabled={Boolean(dt.archivedAt)}
          />
        </List.Content>
        <List.Header>{dt.displayName}</List.Header>
        <List.Description>{`${dt.description}${dt.archivedAt ? ' (Archived)' : ''}`}</List.Description>
      </List.Item>
    ))

  return (
    <FormikProvider value={formik}>
      <List relaxed="very" divided verticalAlign="middle">
        <List.Item>
          <List.Content floated="right">
            <FormikInput
              name={getFieldName<typeof _values>('taxYear')}
              required
            />
          </List.Content>
          <List.Header>Tax Year, e.g. 2023</List.Header>
        </List.Item>
        {dateInputListItems()}
      </List>

      {error && (
        <Alert type="error">
          <Text>{error.message}</Text>
        </Alert>
      )}
      <Button onClick={onCancel} variant="secondary" floated="left">
        Cancel
      </Button>
      <Button
        disabled={isSubmitting}
        loading={isSubmitting}
        onClick={submitForm}
        floated="right"
      >
        Save
      </Button>
    </FormikProvider>
  )
}

export default AnnualTaxDetailsForm
