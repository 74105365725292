import axios from 'axios'
import { keyBy } from 'lodash'

import { fetchIfNeededWrapper, fetchWrapper } from '../../../../reducers/fetch'
import {
  setTaxChecklistQuestions,
  TaxChecklistQuestion,
  TaxChecklistQuestionOption,
  TaxChecklistQuestionsState,
  TaxChecklistResponse,
  resetTaxChecklistQuestions,
  setTaxChecklistQuestionsAfterDeletion,
} from './taxChecklistQuestion.slice'
import { TaxListQuestionId } from './service'
import { TAX_ENTITY_TYPES_TYPE } from '../../taxConstants'
import {
  AnnualTaxFilingForm,
  getAllAnnualTaxFilingForms,
} from '../annualTaxFilingForms.slice'
import {
  receiveSingleYearEndModuleStatusForUser,
  YearEndModuleStatus,
} from '../../../YearEndModuleStatus/yearEndModuleStatus.slice'

type TaxChecklistApiResponse = Omit<
  TaxChecklistQuestion,
  'options' | 'responses'
> & {
  options: TaxChecklistQuestionOption[]
  responses: TaxChecklistResponse[]
}

const transformPayloadIntoRedux = (
  response: TaxChecklistApiResponse[]
): Partial<TaxChecklistQuestionsState> => {
  const options: { [key: string]: TaxChecklistQuestionOption } = {}
  const responses: { [key: number]: TaxChecklistResponse } = {}
  const questions = keyBy(
    response.map((data) => ({
      ...data,
      options: data.options.map((option) => {
        options[option.id] = option
        return option.id
      }),
      responses: data.responses.map((response) => {
        responses[response.id] = response
        return response.id
      }),
    })),
    'id'
  )

  return { questions, options, responses }
}

export const FETCH_USER_TAX_QUESTIONNAIRE_KEY = (year?: string) =>
  `FETCH_USER_TAX_QUESTIONNAIRE_KEY_${year}`
export const fetchUserTaxQuestionnaire = (year: string) =>
  fetchIfNeededWrapper({
    fetchKey: FETCH_USER_TAX_QUESTIONNAIRE_KEY(year),
    fetchFunction: async (dispatch) => {
      const res = await axios.get<TaxChecklistApiResponse[]>(
        `/finances/api/v1/tax_questionnaire/${year}`
      )

      dispatch(setTaxChecklistQuestions(transformPayloadIntoRedux(res.data)))

      return res.data
    },
  })

export const UPSERT_USER_TAX_QUESTIONNAIRE_KEY =
  'UPSERT_USER_TAX_QUESTIONNAIRE_KEY'
export const postUpdateTaxQuestionnaireResponses = (
  responses: Partial<TaxChecklistResponse>[]
) =>
  fetchWrapper({
    fetchKey: UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.post<TaxChecklistApiResponse[]>(
        '/finances/api/v1/tax_questionnaire/responses',
        responses
      )

      dispatch(setTaxChecklistQuestions(transformPayloadIntoRedux(res.data)))

      return res.data
    },
  })

export const DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY =
  'DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY'
export const deleteTaxQuestionnaireResponses = (responseIds: number[]) =>
  fetchWrapper({
    fetchKey: DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
    fetchFunction: async (dispatch) => {
      const res = await axios.delete<TaxChecklistApiResponse[]>(
        '/finances/api/v1/tax_questionnaire/responses',
        { params: { responseIds } }
      )

      dispatch(
        setTaxChecklistQuestionsAfterDeletion(
          transformPayloadIntoRedux(res.data)
        )
      )

      return res.data
    },
  })

export const SUBMIT_TAX_CHECKLIST_KEY = 'SUBMIT_TAX_CHECKLIST_KEY'
export const submitTaxChecklist = () =>
  fetchWrapper({
    fetchKey: SUBMIT_TAX_CHECKLIST_KEY,
    fetchFunction: async () => {
      await axios.post<undefined>('/finances/api/v1/tax_questionnaire/submit')
      return true
    },
  })

export const adminFetchTaxQuestionnaire = (
  year: string,
  formTypeName?: TAX_ENTITY_TYPES_TYPE
) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const res = await axios.get<
        Array<Omit<TaxChecklistApiResponse, 'responses'>>
      >(`/finances/api/v1/admin/tax_questionnaire/questions/${year}`, {
        params: { formTypeName },
      })

      const options: { [key: string]: TaxChecklistQuestionOption } = {}
      const questions = keyBy(
        res.data.map((data) => ({
          ...data,
          options: data.options.map((option) => {
            options[option.id] = option
            return option.id
          }),
          responses: [],
        })),
        'id'
      )

      dispatch(resetTaxChecklistQuestions())
      dispatch(setTaxChecklistQuestions({ questions, options }))

      return res.data
    },
  })

export const adminUpdateTaxQuestionnaireQuestion = (
  id: TaxListQuestionId,
  text: string
) =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const res = await axios.put<Omit<TaxChecklistApiResponse, 'responses'>>(
        `/finances/api/v1/admin/tax_questionnaire/questions/${id}`,
        { text }
      )

      const question = {
        ...res.data,
        options: res.data.options.map((option) => option.id),
        responses: [],
      }

      dispatch(
        setTaxChecklistQuestions({
          questions: { [res.data.id]: question },
          options: keyBy(res.data.options, 'id'),
        })
      )

      return res.data
    },
  })

// Dependents
export enum DependentRelationship {
  son = 'son',
  daughter = 'daughter',
  mother = 'mother',
  father = 'father',
  adopted_child = 'adopted_child',
  foster_child = 'foster_child',
  grandchild = 'grandchild',
  other = 'other',
}

export interface UserDependent {
  id: number
  userId: number
  financialProfileId: number
  name: string
  dateOfBirth: string | null
  relationship: DependentRelationship | null
  encryptedSsn: string | null
  createdAt: string
  updatedAt: string
  deletedAt: string
}

export const FETCH_DEPENDENTS_KEY = 'FETCH_DEPENDENTS_KEY'
export const fetchDependents = () =>
  fetchWrapper({
    fetchKey: FETCH_DEPENDENTS_KEY,
    fetchFunction: async () => {
      const res = await axios.get<UserDependent[]>(
        '/finances/api/v1/tax_questionnaire/dependents'
      )
      return res.data
    },
  })

export const UPSERT_DEPENDENTS_KEY = 'UPSERT_DEPENDENTS_KEY'
export const createOrUpdateDependents = (
  dependents: Partial<UserDependent>[]
) =>
  fetchWrapper({
    fetchKey: UPSERT_DEPENDENTS_KEY,
    fetchFunction: async () => {
      const res = await axios.post<UserDependent[]>(
        '/finances/api/v1/tax_questionnaire/dependents',
        dependents
      )
      return res.data
    },
  })

export const DELETED_DEPENDENTS_KEY = 'DELETED_DEPENDENTS_KEY'
export const deleteDependents = (dependentIds: number[]) =>
  fetchWrapper({
    fetchKey: DELETED_DEPENDENTS_KEY,
    fetchFunction: async () => {
      const res = await axios.delete<UserDependent[]>(
        '/finances/api/v1/tax_questionnaire/dependents',
        {
          params: { dependentIds },
        }
      )
      return res.data
    },
  })

// Childcare providers
export interface ChildcareProvider {
  id: number
  userId: number
  financialProfileId: number
  name: string
  ein: string
  address: string
  createdAt: string
  updatedAt: string
  deletedAt: string
}

export const FETCH_CHILDCARE_PROVIDERS_KEY = 'FETCH_CHILDCARE_PROVIDERS_KEY'
export const fetchChildcareProviders = () =>
  fetchWrapper({
    fetchKey: FETCH_CHILDCARE_PROVIDERS_KEY,
    fetchFunction: async () => {
      const res = await axios.get<ChildcareProvider[]>(
        '/finances/api/v1/tax_questionnaire/childcare_providers'
      )
      return res.data
    },
  })

export const UPSERT_CHILDCARE_PROVIDERS_KEY = 'UPSERT_CHILDCARE_PROVIDERS_KEY'
export const upsertChildcareProviders = (
  childcareProviders: Partial<ChildcareProvider>[]
) =>
  fetchWrapper({
    fetchFunction: async () => {
      const res = await axios.post<ChildcareProvider[]>(
        '/finances/api/v1/tax_questionnaire/childcare_providers',
        childcareProviders
      )
      return res.data
    },
  })

export const DELETE_CHILDCARE_PROVIDERS_KEY = 'DELETE_CHILDCARE_PROVIDERS_KEY'
export const deleteChildcareProviders = (childcareProviderIds: number) =>
  fetchWrapper({
    fetchFunction: async () =>
      await axios.delete<ChildcareProvider[]>(
        '/finances/api/v1/tax_questionnaire/childcare_providers',
        {
          params: { childcareProviderIds },
        }
      ),
  })

export const SUBMIT_EXTENSION_REQUEST_KEY = 'SUBMIT_EXTENSION_REQUEST_KEY'
export const submitExtensionRequest = (filingId?: number) =>
  fetchWrapper({
    fetchKey: SUBMIT_EXTENSION_REQUEST_KEY,
    fetchFunction: async () => {
      await axios.post<undefined>(
        `/finances/api/v1/extension_request/submit/${filingId}`
      )
      return true
    },
  })

export const startTaxQuestionnaire = () =>
  fetchWrapper({
    fetchFunction: async (dispatch) => {
      const res = await axios.put<{
        filingForms: AnnualTaxFilingForm[]
        yearEndModuleStatus: YearEndModuleStatus | null
      }>('/finances/api/v1/tax_questionnaire/started')

      dispatch(getAllAnnualTaxFilingForms(keyBy(res.data.filingForms, 'id')))
      if (res.data.yearEndModuleStatus) {
        dispatch(
          receiveSingleYearEndModuleStatusForUser(res.data.yearEndModuleStatus)
        )
      }
      return res.data
    },
  })
