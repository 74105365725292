import moment from 'moment'
import { FC, useEffect, useMemo, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Button, Form, Label, List } from 'semantic-ui-react'

import { adminFetchSingleUser } from '../../../actions/admin/adminAllUsersActions'
import CurrencyFormatLabel from '../../../components/shared/CurrencyFormatLabel'
import { getFinancialProfileByUserId } from '../../../selectors/user.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { AnnualTaxFilingExtensionPayment } from '../../Taxes/AnnualTaxes/annualTaxFilingForms.slice'
import { ANNUAL_TAX_FILING_FORM_TYPES } from '../../Taxes/AnnualTaxes/Questionnaires/constants'
import { DatePicker } from '../../../components/BaseComponents'
import { DATE_FORMATS } from '../../../utils/dateHelpers'
import { useReselector } from '../../../utils/sharedHooks'

interface Props {
  isEditing: boolean
  paymentInfo?: AnnualTaxFilingExtensionPayment[] | null
  isFormExtended?: boolean
  filingFormType: string
  filingFormId: number
  userId: number
  updateExtensionInfo?: (_params: {
    extensionPayments: AnnualTaxFilingExtensionPayment[]
    filingFormId: number
  }) => Promise<void>
  closeModal: () => void
}

const ExtensionPaymentsForm: FC<Props> = ({
  updateExtensionInfo,
  isEditing,
  paymentInfo,
  filingFormId,
  userId,
  isFormExtended,
  filingFormType,
  closeModal,
}) => {
  const dispatch = useAppDispatch()
  const [isSaving, setIsSaving] = useState(false)
  const financialProfile = useReselector(getFinancialProfileByUserId, userId)
  const [federalPayment, setFederalPayment] = useState<
    AnnualTaxFilingExtensionPayment | undefined
  >(
    useMemo(
      () => paymentInfo?.find((p) => p.entityType === 'federal'),
      [paymentInfo]
    )
  )
  const [statePayments, setStatePayments] = useState<
    AnnualTaxFilingExtensionPayment[] | undefined
  >(
    useMemo(
      () => paymentInfo?.filter((p) => p.entityType === 'state'),
      [paymentInfo]
    )
  )

  useEffect(() => {
    function performAsync() {
      dispatch(adminFetchSingleUser(userId))
    }
    performAsync()
  }, [dispatch, userId])

  const updateFederalPaymentValue = (value: string) => {
    setFederalPayment((prev) => ({
      ...prev,
      entityType: 'federal',
      entityName: 'federal',
      value,
    }))
  }

  const updateFederalPaymentDate = (date: string) => {
    setFederalPayment((prev) => ({
      ...prev,
      entityType: 'federal',
      entityName: 'federal',
      paidAt: date,
    }))
  }

  const resetState = () => {
    setIsSaving(false)
    setFederalPayment(undefined)
    setStatePayments(undefined)
  }

  const onSaveClicked = async () => {
    let combinedInfo = federalPayment ? [federalPayment] : []

    if (statePayments) {
      combinedInfo = [...combinedInfo, ...statePayments]
    }

    setIsSaving(true)

    if (updateExtensionInfo) {
      await updateExtensionInfo({
        extensionPayments: combinedInfo,
        filingFormId,
      })
    }

    resetState()
  }

  const updateStatePaymentValue = ({
    stateAbbr,
    value,
  }: {
    stateAbbr: string
    value: string
  }) => {
    let statePayment = statePayments?.find(
      (p) => p.entityType === 'state' && p.entityName === stateAbbr
    )

    if (!statePayment) {
      statePayment = {
        entityName: stateAbbr,
        entityType: 'state',
      }
    }

    statePayment.value = value

    const statePaymentsClone = statePayments ? [...statePayments] : []
    const index = statePaymentsClone?.indexOf(statePayment)

    if (index === -1) {
      statePaymentsClone.push(statePayment)
    } else {
      statePaymentsClone[index] = statePayment
    }

    setStatePayments(statePaymentsClone)
  }

  const updateStatePaymentDate = ({
    stateAbbr,
    date,
  }: {
    stateAbbr: string
    date: string
  }) => {
    let statePayment = statePayments?.find(
      (p) => p.entityType === 'state' && p.entityName === stateAbbr
    )

    if (!statePayment) {
      statePayment = {
        entityName: stateAbbr,
        entityType: 'state',
      }
    }

    statePayment.paidAt = date

    const statePaymentsClone = statePayments ? [...statePayments] : []
    const index = statePaymentsClone?.indexOf(statePayment)

    if (index === -1) {
      statePaymentsClone.push(statePayment)
    } else {
      statePaymentsClone[index] = statePayment
    }

    setStatePayments(statePaymentsClone)
  }

  const isForm1120_s =
    filingFormType === ANNUAL_TAX_FILING_FORM_TYPES.form_1120_s
  const today = new Date()

  const editableForm = () => (
    <Form>
      {!isForm1120_s && (
        <Form.Group>
          <Form.Field>
            <label htmlFor="federal-amount-paid">Federal Amount Paid</label>
            <CurrencyInput
              id="federal-amount-paid"
              disabled={!isFormExtended || isForm1120_s}
              allowNegativeValue={false}
              prefix="$"
              placeholder="Leave empty if N/A"
              decimalScale={2}
              value={federalPayment?.value}
              onValueChange={(value: string | undefined) =>
                updateFederalPaymentValue(value || '0')
              }
            />
          </Form.Field>
          <Form.Field>
            <DatePicker
              placeholder="Choose a date"
              label="Federal Date Paid"
              value={federalPayment?.paidAt || ''}
              disabled={!isFormExtended || isForm1120_s}
              maxDate={today}
              onChange={updateFederalPaymentDate}
            />
          </Form.Field>
        </Form.Group>
      )}

      {financialProfile?.filingStates?.map((stateAbbr) => (
        <Form.Group key={`${stateAbbr}-details`}>
          <Form.Field>
            <label>{`${stateAbbr} Amount Paid`}</label>
            <CurrencyInput
              disabled={!isFormExtended}
              allowNegativeValue={false}
              prefix="$"
              placeholder="Leave empty if N/A"
              decimalScale={2}
              value={
                statePayments?.find((s) => s.entityName === stateAbbr)?.value
              }
              onValueChange={(value: string | undefined) =>
                updateStatePaymentValue({ stateAbbr, value: value || '0' })
              }
            />
          </Form.Field>
          <Form.Field>
            <DatePicker
              placeholder="Choose a date"
              label={`${stateAbbr} Date Paid`}
              value={
                statePayments?.find((s) => s.entityName === stateAbbr)
                  ?.paidAt || ''
              }
              dateFormat={DATE_FORMATS.DISPLAY_SIMPLE}
              disabled={!isFormExtended}
              maxDate={today}
              onChange={(value) => {
                updateStatePaymentDate({ stateAbbr, date: value })
              }}
            />
          </Form.Field>
        </Form.Group>
      ))}
      <Button disabled={isSaving} onClick={closeModal} content="Cancel" />
      <Button
        disabled={isSaving}
        loading={isSaving}
        onClick={onSaveClicked}
        floated="right"
        primary
        content="Save"
      />
    </Form>
  )

  const readOnlyForm = () => (
    <List style={{ width: '250px' }}>
      <Label>Federal Payment</Label>
      <List.Item>
        <List.Content floated="left">
          {!isForm1120_s && federalPayment?.value ? (
            <CurrencyFormatLabel value={federalPayment?.value} />
          ) : (
            'N/A'
          )}
          {!isForm1120_s &&
            federalPayment?.paidAt &&
            ` on ${moment(federalPayment.paidAt).format(
              DATE_FORMATS.DISPLAY_SHORT
            )}`}
        </List.Content>
      </List.Item>

      <Label style={{ marginTop: '12px' }}>
        {financialProfile?.filingStates?.length === 1
          ? 'State Payment'
          : 'State Payments'}
      </Label>

      {financialProfile?.filingStates?.map((stateAbbr) => {
        const statePayment = statePayments?.find(
          (p) => p.entityName === stateAbbr
        )
        return (
          <List.Item key={`${stateAbbr}-payment-amount`}>
            <List.Header>{stateAbbr}</List.Header>
            <List.Content floated="left">
              {!statePayment?.value && 'N/A'}
              {statePayment?.value && (
                <CurrencyFormatLabel value={statePayment?.value} />
              )}
              {statePayment?.paidAt &&
                ` on ${moment(statePayment.paidAt).format(
                  DATE_FORMATS.DISPLAY_SHORT
                )}`}
            </List.Content>
          </List.Item>
        )
      })}
    </List>
  )

  const el = isEditing ? editableForm() : readOnlyForm()

  return el
}

export default ExtensionPaymentsForm
