/**
 * Post 1099-NEC Filing — Panel with all contractors
 */

import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useEffect, useMemo } from 'react'
import { Grid, Icon, Loader } from 'semantic-ui-react'
import {
  Button,
  GridRowColumn,
  Link,
  Table,
  Text,
} from '../../../../components/BaseComponents'
import { getIsFetchingOrNotStarted } from '../../../../reducers/fetch'
import { useReselector } from '../../../../utils/sharedHooks'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import {
  getContractorsSortedByCreatedAt,
  getAboundTaxDocsForPayer,
} from '../aboundAnnualTaxFilings.selector'
import {
  fetchAboundPayer,
  fetchAboundContractors,
  fetchAboundTaxDocumentsForPayer,
  FETCH_ABOUND_PAYER_KEY,
  FETCH_ABOUND_CONTRACTORS_KEY,
  AboundTaxDocument,
  AboundTaxDocumentAboundStatus,
  AboundContractor,
} from '../aboundAnnualTaxFilings.slice'
import { AboundFilingStatusLabel } from '../Overview/YearEndHome/Ten99FilingSection'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../../utils/dateHelpers'
import { Colors } from '../../../../styles/theme'

const loadingAnimation = (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <Loader centered inline active />
  </div>
)

const Ten99ActionLinks = ({
  aboundTaxDocument,
  contractor,
}: {
  aboundTaxDocument: AboundTaxDocument
  contractor: AboundContractor
}) => {
  // 1. Not Started or Processing — Edit to make changes
  if (
    aboundTaxDocument.aboundStatus === AboundTaxDocumentAboundStatus.not_started
  ) {
    return (
      <Link
        to={`/taxes/annual/file_1099_nec/contractors/${contractor.uuid}/edit`}
        size="small"
        style={{
          fontWeight: 400,
          textDecoration: 'underline',
          color: Colors.darkGray,
        }}
      >
        Edit Filing
      </Link>
    )

    // 2. Else if accepted
  } else if (
    aboundTaxDocument.aboundStatus === AboundTaxDocumentAboundStatus.accepted
  ) {
    return (
      <>
        <Link
          to={`/taxes/annual/file_1099_nec/contractors/${contractor.uuid}/correction`}
          size="small"
          style={{
            fontWeight: 400,
            textDecoration: 'underline',
            color: Colors.darkGray,
          }}
        >
          Submit Correction
        </Link>
        <br />
        <Link
          to={'/practice/documents?tab=tax'}
          size="small"
          style={{
            fontWeight: 400,
            textDecoration: 'underline',
            color: Colors.darkGray,
          }}
        >
          View Filing
        </Link>
      </>
    )
    // 3. Else if TIN Error, direct to update
  } else if (
    aboundTaxDocument.aboundStatus === AboundTaxDocumentAboundStatus.error
  ) {
    return (
      <Link
        to={`/taxes/annual/file_1099_nec/contractors/${contractor.uuid}`}
        size="small"
        style={{
          fontWeight: 400,
          textDecoration: 'underline',
          color: Colors.red,
        }}
      >
        Correct TIN Information
      </Link>
    )
    // 4. Otherwise, we can correct in all other instances
  } else {
    return (
      <Link
        to={`/taxes/annual/file_1099_nec/contractors/${contractor.uuid}/correction`}
        size="small"
        style={{
          fontWeight: 400,
          textDecoration: 'underline',
          color: Colors.darkGray,
        }}
      >
        Submit Correction
      </Link>
    )
  }
}
const Ten99FilingViewAllPanel = () => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)

  useEffect(() => {
    dispatch(fetchAboundPayer())
    dispatch(fetchAboundContractors())
    dispatch(fetchAboundTaxDocumentsForPayer())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
  }, [dispatch])

  const fetchingPayer = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ABOUND_PAYER_KEY
  )
  const fetchingContractors = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ABOUND_CONTRACTORS_KEY
  )
  const contractors = useReselector(getContractorsSortedByCreatedAt)
  const ten99s = useReselector(getAboundTaxDocsForPayer)

  const listContractors = useMemo(
    () =>
      contractors.map((contractor) => {
        const ten99 = ten99s?.[contractor.id]
        if (!ten99) return null
        return (
          <Table.Row key={`${contractor.id}`}>
            {/* <Contractor contractor={contractor} ten99={ten99s?.[contractor.id]} /> */}
            <Table.Cell>
              {contractor.firstName} {contractor.lastName}
            </Table.Cell>
            <Table.Cell>
              {ten99?.filedAt
                ? formatISOFromUTC(
                    ten99?.filedAt,
                    DATE_FORMATS_LUXON.DISPLAY_SHORT
                  )
                : 'N/A'}
            </Table.Cell>
            <Table.Cell>
              <AboundFilingStatusLabel aboundTaxDocument={ten99} />
            </Table.Cell>
            <Table.Cell>
              <Ten99ActionLinks
                aboundTaxDocument={ten99}
                contractor={contractor}
              />
            </Table.Cell>
          </Table.Row>
        )
      }),
    [contractors, ten99s]
  )

  return (
    <Grid centered textAlign="center">
      <GridRowColumn>
        <Icon icon={light('arrow-left')} style={{ marginRight: '8px' }} />
        <Link to="/taxes/annual">
          <Text color="darkGray">Back to Tax Center</Text>
        </Link>
      </GridRowColumn>
      <Grid.Row
        verticalAlign="middle"
        columnStyle={{
          display: 'inline-flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Grid.Column width={16} verticalAlign="middle" textAlign="center">
          <Text as="h1">Your {taxYear} 1099-NEC Filings</Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={10} textAlign="center">
          <Text as="bodyMd">
            Need to make changes to your 1099-NECs? Either edit, view or correct
            your filing below. Note that changes to a filing that has been filed
            with the IRS already would require an additional correction form to
            be filed.
          </Text>
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Contractor Name</Table.HeaderCell>
              <Table.HeaderCell>Filing Submitted On</Table.HeaderCell>
              <Table.HeaderCell>Filing Status</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {fetchingContractors || fetchingPayer
              ? loadingAnimation
              : listContractors}
          </Table.Body>
        </Table>
      </GridRowColumn>
      <GridRowColumn />
      <Grid.Row centered>
        <Grid.Column width={4}>
          <Link to="/taxes/annual/file_1099_nec/contractors">
            <Button variant="secondary" fullWidth>
              Add Additional Filings
            </Button>
          </Link>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Ten99FilingViewAllPanel
