import { CSSProperties, ReactNode } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Alert, Icon } from '../../../../../components/BaseComponents'

export const CalculationAlert = ({
  children,
  contentStyle,
  innerTextStyle,
}: {
  children: ReactNode
  contentStyle?: CSSProperties
  innerTextStyle?: CSSProperties
}) => (
  <Alert
    contentStyle={contentStyle}
    innerTextStyle={innerTextStyle}
    customIcon={<Icon icon={regular('calculator')} />}
  >
    {children}
  </Alert>
)
