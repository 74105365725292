/**
 * Tax Terms Card: Used to house the logic and terminology for 2025 late joiners for the 2024 tax season.
 *
 * This has various logic branches, detailed in this PRD
 *
 *
 */

import { DateTime } from 'luxon'
import { GridRowColumn, Link, Text } from '../../../components/BaseComponents'
import { useReselector } from '../../../utils/sharedHooks'
import {
  TAX_ENTITY_TYPES,
  TAX_ENTITY_TYPES_TYPE,
} from '../../Taxes/taxConstants'
import { selectCurrentAnnualTaxDetails } from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { convertUtcToLocalDate } from '../../../utils/dateHelpers'
import { Grid, List } from 'semantic-ui-react'

const TaxTerms = ({
  taxEntityType,
  currentTaxYear,
}: {
  taxEntityType?: TAX_ENTITY_TYPES_TYPE | null
  currentTaxYear: string
}) => {
  const today = DateTime.now()

  const activeDetails = useReselector(selectCurrentAnnualTaxDetails)
  const scorpVerificationDueDate = convertUtcToLocalDate(
    activeDetails?.tax_entity_verification_extended_due_date
  )

  if (!activeDetails?.taxQuestionnaireDueDates) {
    return null
  }

  const form1040LateJoinerFinalCutoffDate = convertUtcToLocalDate(
    activeDetails.taxQuestionnaireDueDates.form_1040.lateJoinerFinalCutoffDate
  )?.endOf('day')

  const form1120sLateJoinerFinalCutoffDate = convertUtcToLocalDate(
    activeDetails.taxQuestionnaireDueDates.form_1120_s.lateJoinerFinalCutoffDate
  )?.endOf('day')

  const form1040IRSDueDate = convertUtcToLocalDate(
    activeDetails.irsFormDueDates.form_1040.irs.dueDate
  )?.endOf('day')

  const form1120sIRSDueDate = convertUtcToLocalDate(
    activeDetails.irsFormDueDates.form_1120_s.irs.dueDate
  )?.endOf('day')

  const form1120sExtensionRequestDueDate = convertUtcToLocalDate(
    activeDetails.form_1120_s_extension_survey_due_date
  )?.endOf('day')

  const form1040ExtensionRequestDueDate = convertUtcToLocalDate(
    activeDetails.form_1040_extension_survey_due_date
  )?.endOf('day')

  switch (taxEntityType) {
    case TAX_ENTITY_TYPES.form_1040:
      if (
        form1040LateJoinerFinalCutoffDate &&
        today <= form1040LateJoinerFinalCutoffDate
      ) {
        return (
          <Grid>
            <GridRowColumn>
              <Text as="h2">1. You must file an extension request</Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text>
                In order to ensure that Heard has enough time to get all of your{' '}
                {currentTaxYear} books in order, we will require that you file
                an extension with the IRS as a precautionary measure to give you
                more time to file your return, if needed. If you’re able to
                provide Heard with all of the necessary information quickly, it
                is possible that you can still file before the normal{' '}
                {form1040IRSDueDate?.toFormat('LLLL d')} deadline - but the
                extension request will ensure that you are protected from
                failure to file penalties just in case.
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="h2">
                2. You must complete the extension request survey by{' '}
                <b>
                  {form1040ExtensionRequestDueDate?.toFormat('LLLL d, yyyy')}
                </b>
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text>
                This first step is necessary to file an extension, and to avoid
                “failure to file” penalties.
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="h2">
                3. You may still be responsible for some interest and penalties.
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text>
                We will help you file an extension to avoid the “failure to
                file” penalty, but a “failure to pay” penalty may still apply if
                you underpaid your {currentTaxYear} quarterly tax payments, or
                do not pay the balance owed by{' '}
                {form1040IRSDueDate?.toFormat('LLLL d, yyyy')}.{' '}
                <Link
                  href={
                    'https://support.joinheard.com/hc/en-us/articles/4416196188439-What-do-you-mean-by-estimate-Can-I-pay-more-or-less-than-the-estimate-you-gave-me'
                  }
                  newPage
                >
                  Learn more
                </Link>
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="h2">
                4. After filing an extension, we recommend you complete all of
                the below ASAP
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <List bulleted>
                <List.Item>
                  <Text>Upload documents for catch up bookkeeping</Text>
                </List.Item>
                <List.Item>
                  <Text>Completing your end-of-year accounting wrap up</Text>
                </List.Item>
                <List.Item>
                  <Text>
                    Complete your tax checklist to be connected to a tax
                    preparer
                  </Text>
                </List.Item>
              </List>
            </GridRowColumn>
          </Grid>
        )
      } else {
        return null
      }

    case TAX_ENTITY_TYPES.form_1120_s:
      if (
        form1120sLateJoinerFinalCutoffDate &&
        today <= form1120sLateJoinerFinalCutoffDate
      ) {
        return (
          <Grid>
            <GridRowColumn>
              <Text as="h2">1. You must file an extension request</Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text>
                In order to ensure that Heard has enough time to get all of your{' '}
                {currentTaxYear} books in order, we will require that you file
                an extension with the IRS as a precautionary measure to give you
                more time to file your return, if needed. If you’re able to
                provide Heard with all of the necessary information quickly, it
                is possible that you can still file before the normal{' '}
                {form1120sIRSDueDate?.toFormat('LLLL d')} deadline - but the
                extension request will ensure that you are protected from
                failure to file penalties just in case.
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="h2">2. You must have a payroll system in place.</Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text>
                If you don&apos;t have one already, Heard makes it easy for you
                to enroll using our embedded payroll system powered by Gusto.
                This can be done directly in your Heard platform.
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="h2">
                3. You must verify your S-corporation status by{' '}
                {scorpVerificationDueDate?.toFormat('LLLL d, yyyy')}.
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text>
                You&apos;ll need to upload either your CP261 notice or Form
                385C, which you can obtain from the IRS.
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="h2">
                4. You must complete the extension request survey by{' '}
                {form1120sExtensionRequestDueDate?.toFormat('LLLL d, yyyy')}.
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text>This helps you to avoid a “failure to file” penalty.</Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="h2">
                5. You may still be responsible for some interest and penalties.
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <Text>
                {/* Payments are still due by the 1040 filing deadline, not 1120-s filing deadline */}
                We will help you file an extension to avoid the “failure to
                file” penalty, but a “failure to pay” penalty may still apply if
                you underpaid your {currentTaxYear} quarterly tax payments, or
                do not pay the balance owed by{' '}
                {form1040IRSDueDate?.toFormat('LLLL d, yyyy')}.{' '}
                <Link
                  href={
                    'https://support.joinheard.com/hc/en-us/articles/12962191242007-Filing-an-extension-for-your-1040-individual-income-tax-return'
                  }
                  newPage
                >
                  Learn more
                </Link>
              </Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="h2">
                6. After filing an extension, we recommend you complete all of
                the below ASAP.
              </Text>
            </GridRowColumn>
            <GridRowColumn short>
              <List bulleted>
                <List.Item>
                  <Text>Upload documents for catch up bookkeeping</Text>
                </List.Item>
                <List.Item>
                  <Text>Completing your end-of-year accounting wrap up</Text>
                </List.Item>
                <List.Item>
                  <Text>
                    Complete your tax checklist to be connected to a tax
                    preparer
                  </Text>
                </List.Item>
              </List>
            </GridRowColumn>
          </Grid>
        )
      } else {
        return null
      }

    default:
      return null
  }
}
export default TaxTerms
