import {
  PrefixedUserDocumentCategoryIdentifier,
  UserDocumentCategoryIdentifier,
} from '../../Admin/UserDocumentCategories/userDocumentCategory.constants'

interface IDocument {
  categoryIdentifier: UserDocumentCategoryIdentifier | string
  description: string
  explanation: string | null
  instructionsLink: string
  name: string
  provider: string
}

const { formW3 } = UserDocumentCategoryIdentifier

const { payrollJournal, contractorPaymentsReport } =
  PrefixedUserDocumentCategoryIdentifier

export const getDocuments = (taxYear: string): IDocument[] => {
  const prefixedPayrollJournal = payrollJournal(taxYear)
  const prefixedContractorPaymentsReport = contractorPaymentsReport(taxYear)
  const baseSupportUrl = 'https://support.joinheard.com/hc/en-us/articles'

  const DOCUMENT_DESCRIPTIONS = {
    [formW3]:
      'This summarizes your business’ total salary payments and withholding amounts.',
    [prefixedPayrollJournal]:
      'Includes a summary of gross wages, employee taxes, employer taxes, net pay.',
    [prefixedContractorPaymentsReport]:
      'This summarizes your business’ total salary payments and withholding amounts.',
  }

  return [
    {
      categoryIdentifier: formW3,
      description: DOCUMENT_DESCRIPTIONS[formW3],
      explanation: null,
      instructionsLink: `${baseSupportUrl}/28465483047703`,
      name: 'Gusto - Form W-3',
      provider: 'Gusto',
    },
    {
      categoryIdentifier: prefixedPayrollJournal,
      description: DOCUMENT_DESCRIPTIONS[prefixedPayrollJournal],
      explanation: null,
      instructionsLink: `${baseSupportUrl}/28464981327895`,
      name: 'Gusto - Payroll Journal',
      provider: 'Gusto',
    },
    {
      categoryIdentifier: prefixedContractorPaymentsReport,
      description: DOCUMENT_DESCRIPTIONS[prefixedContractorPaymentsReport],
      explanation: null,
      instructionsLink: `${baseSupportUrl}/28465557251351`,
      name: 'Gusto - Contractor Payment Report',
      provider: 'Gusto',
    },
    {
      categoryIdentifier: formW3,
      description: DOCUMENT_DESCRIPTIONS[formW3],
      explanation: null,
      instructionsLink: `${baseSupportUrl}/28465172443287`,
      name: 'ADP - Form W-3',
      provider: 'ADP',
    },
    {
      categoryIdentifier: prefixedPayrollJournal,
      description: DOCUMENT_DESCRIPTIONS[prefixedPayrollJournal],
      explanation: null,
      instructionsLink: `${baseSupportUrl}/28465149282199`,
      name: 'ADP - Payroll Journal',
      provider: 'ADP',
    },
    {
      categoryIdentifier: prefixedContractorPaymentsReport,
      description: DOCUMENT_DESCRIPTIONS[prefixedContractorPaymentsReport],
      explanation: null,
      instructionsLink: `${baseSupportUrl}/23162239639831`,
      name: 'ADP - Contractor Payment Report',
      provider: 'ADP',
    },
    {
      categoryIdentifier: formW3,
      description: DOCUMENT_DESCRIPTIONS[formW3],
      explanation: null,
      instructionsLink: `${baseSupportUrl}/28465246658327`,
      name: 'Quickbooks - Form W-3',
      provider: 'Quickbooks',
    },
    {
      categoryIdentifier: prefixedPayrollJournal,
      description: DOCUMENT_DESCRIPTIONS[prefixedPayrollJournal],
      explanation: null,
      instructionsLink: `${baseSupportUrl}/28465387102615`,
      name: 'Quickbooks - Payroll Journal',
      provider: 'Quickbooks',
    },
    {
      categoryIdentifier: prefixedContractorPaymentsReport,
      description: DOCUMENT_DESCRIPTIONS[prefixedContractorPaymentsReport],
      explanation: null,
      instructionsLink: `${baseSupportUrl}/28465583613463`,
      name: 'Quickbooks - Contractor Payment Report',
      provider: 'Quickbooks',
    },
    {
      categoryIdentifier: formW3,
      description: DOCUMENT_DESCRIPTIONS[formW3],
      explanation: null,
      instructionsLink: `${baseSupportUrl}/23164965983767`,
      name: 'Form W-3',
      provider: 'Other',
    },
    {
      categoryIdentifier: prefixedPayrollJournal,
      description: DOCUMENT_DESCRIPTIONS[prefixedPayrollJournal],
      explanation: null,
      instructionsLink: `${baseSupportUrl}/23157953420695`,
      name: 'Payroll Journal',
      provider: 'Other',
    },
    {
      categoryIdentifier: prefixedContractorPaymentsReport,
      description: DOCUMENT_DESCRIPTIONS[prefixedContractorPaymentsReport],
      explanation: null,
      instructionsLink: `${baseSupportUrl}/23162239639831`,
      name: 'Other - Contractor Payment Report',
      provider: 'Other',
    },
  ]
}

export const getInstructionsLink = (
  categoryIdentifier: UserDocumentCategoryIdentifier | string,
  provider: string,
  year: string
): string => {
  const matchingDoc = getDocuments(year).find(
    (doc) =>
      doc.provider === provider && doc.categoryIdentifier === categoryIdentifier
  )

  return matchingDoc?.instructionsLink ?? ''
}
