import { useParams } from 'react-router-dom'
import parse from 'html-react-parser'
import { Divider, Grid } from 'semantic-ui-react'
import { isNumber } from 'lodash'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  TaxChecklistQuestion,
  TaxChecklistQuestionOption,
  TaxChecklistQuestionType,
  TaxChecklistResponse,
} from '../taxChecklistQuestion.slice'
import {
  Button,
  GridRowColumn,
  Icon,
  Text,
} from '../../../../../components/BaseComponents'
import { formatCurrencyFromCents } from '../../../../../utils/currencyHelpers'
import { useSetScreen } from '../../../../../components/FormFlow/formFlow'
import { useReselector } from '../../../../../utils/sharedHooks'
import {
  ResponseGroup,
  selectTaxListQuestionResponseGroup,
} from '../taxChecklist.selectors'
import { TaxListQuestionId } from '../service'
import { selectTqFormIsReadOnly } from '../../annualTaxFilings.selector'
import { tqBigSpace } from '../helpers'

interface Props {
  title: string
  questionIds: TaxListQuestionId[]
  group?: ResponseGroup
  editScreen: string
  formIds?: string | string[] | undefined
  additionalInfo?: {
    questionId: string
    question: string
    response: string
  }[]
}

const getResponseText = (
  question: TaxChecklistQuestion | null,
  options: TaxChecklistQuestionOption[],
  response: TaxChecklistResponse
) => {
  switch (question?.questionType) {
    case TaxChecklistQuestionType.BOOL:
      return typeof response.value === 'boolean'
        ? response.value
          ? 'Yes'
          : 'No'
        : ''
    case TaxChecklistQuestionType.SINGLE_SELECT:
      return (
        options.find((option) => option.id === response.value)?.text ??
        response.value
      )
    case TaxChecklistQuestionType.MULTI_SELECT:
      return options
        .filter(
          (option) =>
            Array.isArray(response.value) &&
            response.value?.includes(option.id.toString())
        )
        .map((option) => option.text)
        .join(', ')
    default: {
      if (new RegExp(/ssn/gi).test(response?.questionId)) {
        return '***-**-****'
      }
      if (
        new RegExp(/_IN_CENTS/gi).test(response?.questionId) &&
        isNumber(response.value)
      ) {
        return formatCurrencyFromCents(Number(response.value))
      }
      return response.value
    }
  }
}

export const displayResponse = (
  question: TaxChecklistQuestion | null,
  options: TaxChecklistQuestionOption[],
  response: TaxChecklistResponse
) => {
  const responseText = getResponseText(question, options, response)

  if (response.needsAssistance) {
    return (
      <div style={{ display: 'flex' }}>
        <Icon
          icon={regular('flag')}
          style={{ marginTop: 4, marginRight: 15 }}
        />
        <Text>{responseText || '-'}</Text>
      </div>
    )
  }

  return responseText
}

export const ReviewSubSectionForFormIds = ({
  title,
  questionIds,
  group,
  editScreen,
  additionalInfo,
  formIds,
  readOnly,
}: Props & { readOnly?: boolean }) => {
  const { setReviewScreen } = useSetScreen()
  const processedFormIds = formIds
    ? Array.isArray(formIds)
      ? formIds.map((id) => Number(id))
      : [Number(formIds)]
    : undefined

  const groups = useReselector(
    selectTaxListQuestionResponseGroup,
    questionIds,
    processedFormIds,
    group
  )

  const getTitle = (index: number) => {
    if (Object.keys(groups).length <= 1) {
      return title
    }
    return `${title} ${index + 1}`
  }
  const renderTitle = (index: number) => (
    <>
      <Grid.Row className="short" style={{ justifyContent: 'center' }}>
        <Grid.Column width={4}>
          <Text as="eyebrow">{getTitle(index)}</Text>
        </Grid.Column>
        <Grid.Column
          width={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            justifyContent: 'flex-end',
          }}
        >
          {!readOnly && (
            <Button variant="link" onClick={() => setReviewScreen(editScreen)}>
              <Icon
                size="1x"
                icon={regular('pen')}
                style={{ marginRight: 4 }}
              />
              Edit
            </Button>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="short" />
    </>
  )
  const renderAdditionalInfo = () => {
    if (additionalInfo?.length) {
      return (
        <>
          {additionalInfo.map((info) => (
            <Grid.Row
              className="short"
              key={info.questionId}
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <Grid.Column width={4}>
                <Text>{parse(info.question)}</Text>
              </Grid.Column>
              <Grid.Column
                width={4}
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  height: '100%',
                }}
              >
                <Text>{info.response}</Text>
              </Grid.Column>
            </Grid.Row>
          ))}
        </>
      )
    }
    return null
  }
  if (Object.values(groups).length === 0 && additionalInfo?.length) {
    return (
      <>
        {renderTitle(0)}
        {renderAdditionalInfo()}
        <GridRowColumn {...tqBigSpace}>
          <Divider />
        </GridRowColumn>
      </>
    )
  }
  return (
    <>
      {Object.values(groups).map((g, index) => (
        <>
          {renderTitle(index)}
          {Object.values(g)?.map(({ question, responses, options }) => (
            <Grid.Row
              className="short"
              key={`${question?.id}-${index}`}
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <Grid.Column width={4}>
                {question?.text && <Text>{parse(question.text)}</Text>}
              </Grid.Column>
              {responses.map((response: TaxChecklistResponse) => (
                <Grid.Column
                  key={response.id}
                  width={4}
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    height: '100%',
                  }}
                >
                  <Text>{displayResponse(question, options, response)}</Text>
                </Grid.Column>
              ))}
              {responses.length === 0 && (
                <Grid.Column
                  key={`${question?.id}-${index}-no-response`}
                  width={4}
                />
              )}
            </Grid.Row>
          ))}
          {additionalInfo && renderAdditionalInfo()}
          <GridRowColumn {...tqBigSpace}>
            <Divider />
          </GridRowColumn>
        </>
      ))}
    </>
  )
}

const ReviewSubSection = ({
  title,
  questionIds,
  group,
  editScreen,
  additionalInfo,
}: Props) => {
  const { formId } = useParams()
  const readOnly = useReselector(selectTqFormIsReadOnly, formId)

  return (
    <ReviewSubSectionForFormIds
      title={title}
      questionIds={questionIds}
      group={group}
      editScreen={editScreen}
      additionalInfo={additionalInfo}
      formIds={formId}
      readOnly={readOnly}
    />
  )
}

export default ReviewSubSection
