import { StripeInterval } from '../../../reducers/subscription.slice'
import { StripeProduct } from '../../../actions/settings/billingActions'
import { fetchStripePlans } from '../../../constants/pricingConstants'
import { dollarsToCurrency } from '../../../utils/currencyHelpers'
import { ADDITIONAL_1040_COST } from '../../Taxes/AnnualTaxes/constants'

export interface PlanDetail {
  text: string
  tooltip?: string | null
}

// Stores the entire collection of "selling points" in a single object
const PlanDetails: Record<string, PlanDetail> = {
  annualIncomeTaxPrepSoloScorp: {
    text: 'Annual business income tax prep and filing',
    tooltip:
      "Our team files your annual state and federal business income tax return. Because we also do your bookkeeping, we're able to maximize your deductions which translates to more savings.",
  },
  annualIncomeTaxPrepGroupScorp: {
    text: 'Annual income tax prep and filing (personal and business)',
    tooltip:
      "Our team files your annual state and federal personal and business income tax return. Because we also do your bookkeeping, we're able to maximize your deductions which translates to more savings.",
  },
  personalFilingPrepFee: {
    text: `Personal income tax prep and filing ($${ADDITIONAL_1040_COST} fee)`,
  },
  annualIncomeTaxPrepSoleProp: {
    text: 'Annual income tax prep and filing (personal)',
    tooltip:
      "Our team files your annual state and federal personal income tax return. Because we also do your bookkeeping, we're able to maximize your deductions which translates to more savings.",
  },
  quarterlyTaxEstimates: {
    text: 'Quarterly tax estimates',
    tooltip:
      'Every quarter, we calculate your estimated payments and remind you when to pay so you never miss a deadline.',
  },
  dedicatedTaxProfessional: {
    text: 'Dedicated tax expert who supports you through tax filing',
    tooltip:
      'Work directly with your dedicated tax expert during your tax filing to maximize your deductions.',
  },
  discountedTaxAdvisory: {
    text: '50% off a personalized tax consultation ($75 value)',
    tooltip:
      'Receive 50% off a 30 minute personalized tax consultation with your tax preparer to discuss your tax needs and strategy. You’ll be able to book additional 30 and 60 minute conversations during your tax filing process for a fee.',
  },
  paidTaxAdvisory: {
    text: 'Personalized Tax Consultations (Additional Cost)',
    tooltip:
      'You’ll be able to book 30 and 60 minute conversations with your tax preparer during your tax season for an additional fee.',
  },
  complimentaryPersonalStateTaxFiling: {
    text: 'Unlimited state form filings for personal taxes',
  },
  complimentaryPersonalAndBusinessStateTaxFiling: {
    text: 'Unlimited state form filings for personal and business taxes',
  },
  monthlyBookkeepingServices: {
    text: 'Monthly bookkeeping services',
    tooltip:
      'Your dedicated bookkeeper will review and categorize your transactions for your business and identify tax-deductible expenses.',
  },
  bookkeepingProfessionalsSpecialized: {
    text: 'Bookkeeping professionals specialized in therapy practice',
    tooltip:
      'Our only focus is therapists. That means our team of bookkeeping and tax professionals are experts in therapy practices and all of the nuances that come with running one.',
  },
  unlimitedAppMessaging: {
    text: 'Unlimited in-app messaging support',
    tooltip:
      "You can message our support team through the Heard app 24/7 and we'll get back to you in 1-2 business days.",
  },
  financialReportsAndProfitAllocation: {
    text: 'Financial reports and profit allocation guide',
    tooltip:
      'We share monthly financial reports and allocation guide to help you confidently allocate your profit. Financial reports are automatically generated once bookkeeping is complete, with a summary of your cash flow and profit and loss. This helps you better understand your practice finances.',
  },
  expenseBreakdown: {
    text: 'Expense breakdown',
    tooltip: null,
  },
  singlePlaceToKeepDocuments: {
    text: 'A single place to keep important documents and receipts',
    tooltip: null,
  },
  freePayroll6Month: {
    text: '6 free months of payroll ($270 value)',
    tooltip:
      "We’ve teamed up with Gusto to provide stress-free payroll, embedded right in your Heard dashboard. After your first 6 months, you'll be billed $39 + $6/person each month.",
  },
  freePayroll3Month: {
    text: '3 free months of payroll ($135 value)',
    tooltip:
      "We’ve teamed up with Gusto to provide stress-free payroll, embedded right in your Heard dashboard. After your first 3 months, you'll be billed $39 + $6/person each month.",
  },
  sCorpCompliance: {
    text: 'S Corporation compliance',
    tooltip:
      "We make sure you stay compliant and avoid fees or penalties from the IRS. We audit your books and advise you if you aren't compliant with S Corporation tax regulations and provide resources for you to keep on hand so you understand how to remain in compliance.",
  },
  reasonableSalaryDetermination: {
    text: 'Reasonable salary determination (for S Corporations only)',
    tooltip:
      "Throughout the year, we'll review your reasonable salary to make sure you're paying yourself the right amount. Setting a reasonable salary that is not too high or too low can affect your practice's profits and overall business success.",
  },
  sCorpElection: {
    text: 'S Corporation election',
    tooltip:
      "We'll file Form 2553 for you to elect your therapy practice be taxed as an S Corporation by the IRS. We help you meet S Corporation requirements: Incorporation and EIN changes. You can deduct certain business expenses, such as salaries, wages, bonuses, rent, utilities, and supplies. Deducting these expenses can reduce your taxable income and lower your taxes.",
  },
  sCorpEligibilityAssessment: {
    text: 'S Corporation eligibility assessment',
    tooltip:
      "We'll run an assessment to determine when it's the right time to form an S Corporation.",
  },
  cubkReconciliation: {
    text: 'Complete reconciliation of past transactions',
    tooltip: 'Complete reconciliation of past transactions',
  },
  cubkProfessionalReview: {
    text: 'Professional review of deductible expenses',
    tooltip: 'Professional review of deductible expenses',
  },
  cubkFinancialInsights: {
    text: 'Year-to-date financial insights and reporting',
    tooltip: 'Year-to-date financial insights and reporting',
  },
  freeFinancialAdvisory: {
    text: 'One free 20-min session with a financial advisor ($75 Value)',
  },
}

// Construct arrays of plan details for each of the 4 sections - Tax, Bookkeeping, Budgeting & Tracking, S-Corporations

// Tax
export const annualSoloScorpTaxDetails = ({
  withPromo,
}: {
  withPromo: boolean
}) => {
  return [
    PlanDetails.annualIncomeTaxPrepSoloScorp,
    PlanDetails.personalFilingPrepFee,
    PlanDetails.quarterlyTaxEstimates,
    PlanDetails.dedicatedTaxProfessional,
    withPromo ? PlanDetails.discountedTaxAdvisory : PlanDetails.paidTaxAdvisory,
    PlanDetails.complimentaryPersonalAndBusinessStateTaxFiling,
  ]
}

export const monthlySoloScorpTaxDetails = [
  PlanDetails.annualIncomeTaxPrepSoloScorp,
  PlanDetails.personalFilingPrepFee,
  PlanDetails.quarterlyTaxEstimates,
  PlanDetails.dedicatedTaxProfessional,
  PlanDetails.paidTaxAdvisory,
  PlanDetails.complimentaryPersonalAndBusinessStateTaxFiling,
]
export const annualSoloSolePropTaxDetails = ({
  withPromo,
}: {
  withPromo: boolean
}) => {
  return [
    PlanDetails.annualIncomeTaxPrepSoleProp,
    PlanDetails.quarterlyTaxEstimates,
    PlanDetails.dedicatedTaxProfessional,
    withPromo ? PlanDetails.discountedTaxAdvisory : PlanDetails.paidTaxAdvisory,
    PlanDetails.complimentaryPersonalStateTaxFiling,
  ]
}
export const monthlySoloSolePropTaxDetails = [
  PlanDetails.annualIncomeTaxPrepSoleProp,
  PlanDetails.quarterlyTaxEstimates,
  PlanDetails.dedicatedTaxProfessional,
  PlanDetails.paidTaxAdvisory,
  PlanDetails.complimentaryPersonalStateTaxFiling,
]
export const annualGroupTaxDetails = ({
  withPromo,
}: {
  withPromo: boolean
}) => {
  return [
    PlanDetails.annualIncomeTaxPrepGroupScorp,
    PlanDetails.quarterlyTaxEstimates,
    PlanDetails.dedicatedTaxProfessional,
    withPromo ? PlanDetails.discountedTaxAdvisory : PlanDetails.paidTaxAdvisory,
    PlanDetails.complimentaryPersonalStateTaxFiling,
  ]
}
export const monthlyGroupTaxDetails = [
  PlanDetails.annualIncomeTaxPrepGroupScorp,
  PlanDetails.quarterlyTaxEstimates,
  PlanDetails.dedicatedTaxProfessional,
  PlanDetails.paidTaxAdvisory,
  PlanDetails.complimentaryPersonalStateTaxFiling,
]
export const basicTaxDetails = [
  PlanDetails.quarterlyTaxEstimates,
  PlanDetails.dedicatedTaxProfessional,
]

// Bookkeeping
export const soloScorpBKDetails = [
  PlanDetails.monthlyBookkeepingServices,
  PlanDetails.bookkeepingProfessionalsSpecialized,
  PlanDetails.unlimitedAppMessaging,
]
export const soloSolePropBKDetails = [
  PlanDetails.monthlyBookkeepingServices,
  PlanDetails.bookkeepingProfessionalsSpecialized,
  PlanDetails.unlimitedAppMessaging,
]
export const groupBKDetails = [
  PlanDetails.monthlyBookkeepingServices,
  PlanDetails.bookkeepingProfessionalsSpecialized,
  PlanDetails.unlimitedAppMessaging,
]
export const basicBKDetails = [
  PlanDetails.monthlyBookkeepingServices,
  PlanDetails.bookkeepingProfessionalsSpecialized,
  PlanDetails.unlimitedAppMessaging,
]

// Catch up bookkeeping
export const generalCUBKDetails = [
  PlanDetails.cubkReconciliation,
  PlanDetails.cubkProfessionalReview,
  PlanDetails.cubkFinancialInsights,
]

// Budgeting & Tracking
export const soloScorpBudgetingDetails = [
  PlanDetails.financialReportsAndProfitAllocation,
  PlanDetails.expenseBreakdown,
  PlanDetails.singlePlaceToKeepDocuments,
  PlanDetails.freeFinancialAdvisory,
]
export const soloSolePropBudgetingDetails = [
  PlanDetails.financialReportsAndProfitAllocation,
  PlanDetails.expenseBreakdown,
  PlanDetails.singlePlaceToKeepDocuments,
  PlanDetails.freeFinancialAdvisory,
]
export const groupBudgetingDetails = [
  PlanDetails.financialReportsAndProfitAllocation,
  PlanDetails.expenseBreakdown,
  PlanDetails.singlePlaceToKeepDocuments,
  PlanDetails.freeFinancialAdvisory,
]
export const basicBudgetingDetails = [
  PlanDetails.financialReportsAndProfitAllocation,
  PlanDetails.expenseBreakdown,
  PlanDetails.singlePlaceToKeepDocuments,
  PlanDetails.freeFinancialAdvisory,
]

// S Corporation
export const annualSoloScorpScorpDetails = ({
  withPromo,
}: {
  withPromo: boolean
}) => {
  const details: PlanDetail[] = []
  if (withPromo) {
    details.push(PlanDetails.freePayroll3Month)
  }
  details.push(
    PlanDetails.sCorpCompliance,
    PlanDetails.reasonableSalaryDetermination,
    PlanDetails.sCorpElection
  )
  return details
}
export const monthlySoloScorpScorpDetails = [
  PlanDetails.sCorpCompliance,
  PlanDetails.reasonableSalaryDetermination,
  PlanDetails.sCorpElection,
]
export const soloSolePropScorpDetails = [PlanDetails.sCorpEligibilityAssessment]
// group options are currently the same as solo scorp options, but could change in the future
export const annualGroupScorpDetails = annualSoloScorpScorpDetails
export const monthlyGroupScorpDetails = monthlySoloScorpScorpDetails

export const getProductByInterval = (
  products: StripeProduct[],
  interval: StripeInterval
) =>
  products.find(
    (product) => product.default_price.recurring.interval === interval
  )

// This shape is compatible with the new StripeProductCard component
// We cannot fetch Basic Plans from stripe b/c their shape on Stripe is inconsistent
// with other primary products. Sahil says we're planning to sunset Basic Plans come 2024,
// so we should keep pricing constants related to this product until then
export const fetchBasicProductsV2 = () => {
  const stripePlans = fetchStripePlans()

  const baseProduct: StripeProduct = {
    id: '',
    name: '',
    object: 'product',
    active: false,
    attributes: [],
    created: 0,
    description: '',
    features: [],
    images: [],
    livemode: false,
    metadata: {},
    statement_descriptor: '',
    tax_code: '',
    unit_label: '',
    updated: 0,
    url: '',
    default_price: {
      id: '',
      object: 'price',
      active: true,
      billing_scheme: '',
      created: 0,
      currency: 'USD',
      livemode: false,
      metadata: null,
      nickname: '',
      product: '',
      tax_behavior: 'inclusive',
      tiers_mode: null,
      transform_quantity: null,
      type: 'one_time',
      unit_amount: 0,
      unit_amount_decimal: '',
      recurring: {
        interval: StripeInterval.month,
        aggregate_usage: null,
        interval_count: 0,
        trial_period_days: null,
        usage_type: 'licensed',
      },
    },
  }

  const result = {
    BASIC_SOLO_PRODUCTS: [
      // solo monthly
      {
        ...baseProduct,
        name: 'Basic Plan',
        default_price: {
          ...baseProduct.default_price,
          id: stripePlans.basic_solo.monthly.priceId,
          unit_amount: dollarsToCurrency(stripePlans.basic_solo.monthly.amount)
            .intValue,
          recurring: {
            ...baseProduct.default_price.recurring,
            interval: StripeInterval.month,
          },
        },
      },
      // solo annual product
      {
        ...baseProduct,
        name: 'Basic Plan',
        default_price: {
          ...baseProduct.default_price,
          id: stripePlans.basic_solo.annual.priceId,
          unit_amount: dollarsToCurrency(stripePlans.basic_solo.annual.amount)
            .intValue,
          recurring: {
            ...baseProduct.default_price.recurring,
            interval: StripeInterval.year,
          },
        },
      },
    ],

    BASIC_GROUP_PRODUCTS: [
      // group monthly
      {
        ...baseProduct,
        name: 'Basic Plan',
        default_price: {
          ...baseProduct.default_price,
          id: stripePlans.basic_group.monthly.priceId,
          unit_amount: dollarsToCurrency(stripePlans.basic_group.monthly.amount)
            .intValue,
          recurring: {
            ...baseProduct.default_price.recurring,
            interval: StripeInterval.month,
          },
        },
      },
      // group annual
      {
        ...baseProduct,
        name: 'Basic Plan',
        default_price: {
          ...baseProduct.default_price,
          id: stripePlans.basic_group.annual.priceId,
          unit_amount: dollarsToCurrency(stripePlans.basic_group.annual.amount)
            .intValue,
          recurring: {
            ...baseProduct.default_price.recurring,
            interval: StripeInterval.year,
          },
        },
      },
    ],
  }

  return result
}
