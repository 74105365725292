import { Divider } from 'semantic-ui-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DateTime } from 'luxon'
import { formatCurrencyFromCents } from '../../../../utils/currencyHelpers'
import {
  Accordion,
  Card,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import { UploadDocumentType } from '../../../../constants/businessConstants'
import { StepHeaders } from '../../../../components/StepHeader'
import FileUploadModal, {
  UploadedFile,
} from '../../../../components/FileUpload/FileUploadModal'
import { DocumentUploadCard } from '../../../../components/FileUpload/DocumentUploadCard'
import { PrefixedUserDocumentCategoryIdentifier } from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { UserDocument } from '../../../UserDocuments/userDocuments.slice'
import { IFixedAssetDepreciationStepContext } from '../types'
import { migrateAssetStepContext } from '../admin/helpers'
import './styles.scss'

interface ITransactionCategory {
  id: number
  identifier: string
  name: string
}

interface IReceipt {
  id: number
  fileDescription: string
  signedUrl: string
}

export interface IReceiptTransaction {
  id: number
  amountInCents: number
  date: string
  description: string
  transactionCategory: ITransactionCategory
  receipts: IReceipt[]
}

export interface IFixedAssetDepreciationProps {
  transactions?: IReceiptTransaction[]
  documents: UserDocument[]
  setIsStepComplete: (value: boolean) => void
  taxYear: string
  stepContext: IFixedAssetDepreciationStepContext
  setStepContext: (context: IFixedAssetDepreciationStepContext) => void
}

const { assetsGreater2500Receipts } = PrefixedUserDocumentCategoryIdentifier

export const FixedAssetDepreciationDocuments = ({
  transactions = [],
  documents,
  setIsStepComplete,
  taxYear,
  stepContext,
  setStepContext,
}: IFixedAssetDepreciationProps) => {
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false)
  const [fileType, setFileType] = useState<'receipt' | 'other'>('receipt')

  const handleFileUpload = (fileType: 'receipt' | 'other') => {
    setIsFileUploadModalOpen(true)
    setFileType(fileType)
  }

  const [transactionsWithReceipts, transactionsWithoutReceipts] =
    useMemo(() => {
      return transactions.reduce<
        [IReceiptTransaction[], IReceiptTransaction[]]
      >(
        ([withReceipts, withoutReceipts], transaction) => {
          if (transaction.receipts && transaction.receipts.length > 0) {
            withReceipts.push(transaction)
          } else {
            withoutReceipts.push(transaction)
          }
          return [withReceipts, withoutReceipts]
        },
        [[], []]
      )
    }, [transactions])

  useEffect(() => {
    setIsStepComplete?.(documents?.length >= transactionsWithoutReceipts.length)
  }, [documents, stepContext, transactionsWithoutReceipts, setIsStepComplete])

  const receipts = useMemo(() => {
    const migratedContext = migrateAssetStepContext(stepContext)
    return migratedContext.receipts ?? []
  }, [stepContext])

  const onFileUploaded = useCallback(
    (uploadedFile: UploadedFile) => {
      // Update step context based on the file type
      if (fileType === 'receipt') {
        const newReceipts = [...receipts, { documentId: uploadedFile.id }]
        setStepContext({
          ...stepContext,
          receipts: newReceipts,
          receiptIds: [],
        })
      } else {
        const otherDocumentIds = [
          ...(stepContext.otherDocumentIds ?? []),
          uploadedFile.id,
        ]
        setStepContext({ ...stepContext, otherDocumentIds })
      }
    },
    [fileType, stepContext, setStepContext, receipts]
  )

  const receiptDocuments = useMemo(() => {
    return documents.filter((doc) =>
      receipts.some((r) => r.documentId === doc.id)
    )
  }, [documents, receipts])

  const getFormattedDate = (date: string) => {
    const milliseconds = Date.parse(date)
    return DateTime.fromMillis(milliseconds).toFormat('MMM d, yyyy')
  }

  const getTxnPluralText = (txns: IReceiptTransaction[]) => {
    const count = txns.length
    return count > 1 ? `${count} transactions` : `${count} transaction`
  }

  return (
    <div className="eoybk__fixed-asset-dep__container">
      <FileUploadModal
        close={() => setIsFileUploadModalOpen(false)}
        documentType={
          fileType === 'receipt'
            ? UploadDocumentType.RECEIPT
            : UploadDocumentType.OTHER
        }
        categoryInternalName={
          fileType === 'receipt'
            ? assetsGreater2500Receipts(taxYear)
            : undefined
        }
        open={isFileUploadModalOpen}
        userFacing
        setUploadedFile={onFileUploaded}
        isUploadOnly
      />

      <StepHeaders
        customColor="yellow"
        description="We require a receipt for any assets over $2,500 that were purchased in 2024 and fall into one of these categories:"
        imageSrc="https://heard-images.s3.amazonaws.com/assets/receipt.svg"
        imageAlt="receipt"
        imageWidth={180}
        imageHeight={180}
        kickerText="YEAR-END DOCUMENTS"
        title="Assets over $2,500"
      >
        <ul className="eoybk__fixed-asset-dep__category-list">
          <li>Computer: Hardware</li>
          <li>Equipment</li>
          <li>Furniture</li>
        </ul>

        <Text className="eoybk__fixed-asset-dep__tax-preparer">
          Your tax preparer will use this to calculate depreciation expense,
          which lowers your taxable business income.
        </Text>

        <Link
          to="https://support.joinheard.com/hc/en-us/articles/19809547067543-Obtaining-and-uploading-year-end-documents#fixed_assets"
          className="eoybk__fixed-asset-dep__link"
        >
          Learn more
        </Link>
      </StepHeaders>

      {transactionsWithoutReceipts.length > 0 && (
        <Card backgroundColor="stone40">
          <Text as="eyebrow" color="darkGray">
            DOCUMENTS YOU NEED
          </Text>

          <Text>
            We found {getTxnPluralText(transactionsWithoutReceipts)} that
            require a receipt:
          </Text>

          {transactionsWithoutReceipts.map((transaction) => (
            <Card backgroundColor="stone" key={`${transaction.id}`}>
              <div className="eoybk__fixed-asset-dep__needed-doc-card-header">
                <Text as="h3">
                  <strong>{getFormattedDate(transaction.date)}</strong>
                </Text>

                <Text as="h3">
                  <strong>
                    {formatCurrencyFromCents(transaction.amountInCents)}
                  </strong>
                </Text>
              </div>

              <Text>{transaction.description}</Text>

              <Text
                as="bodySm"
                color="darkGray"
                className="eoybk__fixed-asset-dep__needed-doc-card-category"
              >
                <strong>{transaction?.transactionCategory?.name}</strong>
              </Text>
            </Card>
          ))}

          <Accordion
            className="eoybk__fixed-asset-dep__txn-count-receipts"
            title={`View ${getTxnPluralText(transactionsWithReceipts)} with receipts`}
            content={
              <>
                <Text>
                  We have receipts for these transactions already. They are
                  shown below for your reference.
                </Text>

                {transactionsWithReceipts.map((transaction) => (
                  <Card backgroundColor="stone" key={`${transaction.id}`}>
                    <div className="eoybk__fixed-asset-dep__needed-doc-card-header">
                      <Text as="h3">
                        <strong>{getFormattedDate(transaction.date)}</strong>
                      </Text>

                      <Text as="h3">
                        <strong>
                          {formatCurrencyFromCents(transaction.amountInCents)}
                        </strong>
                      </Text>
                    </div>

                    <Text>{transaction.description}</Text>

                    <Text
                      as="bodySm"
                      color="darkGray"
                      className="eoybk__fixed-asset-dep__needed-doc-card-category"
                    >
                      <strong>{transaction?.transactionCategory?.name}</strong>
                    </Text>

                    {transaction.receipts.map(({ id, fileDescription }) => (
                      <Text
                        key={id}
                        color="green"
                        className="eoybk__fixed-asset-dep__receipt-file-name"
                      >
                        <strong>{fileDescription}</strong>
                      </Text>
                    ))}
                  </Card>
                ))}
              </>
            }
            variant="text"
          />
        </Card>
      )}

      <Accordion
        className="eoybk__fixed-asset-dep__what-if-accordion"
        title="What if I have an asset that isn't shown above?"
        content={
          <>
            <Text>We only require receipts for assets that:</Text>

            <ul className="eoybk__fixed-asset-dep__what-if-accordion-list">
              <li>
                <Text>
                  Cost <strong>over $2,500</strong>
                </Text>
              </li>

              <li>
                <Text>
                  Are categorized as{' '}
                  <strong>Computer: Hardware, Equipment, or Furniture</strong>
                </Text>
              </li>

              <li>
                <Text>Were purchased in 2024</Text>
              </li>
            </ul>

            <Text>
              If you think that we missed a transaction, feel free to upload the
              receipt for it below. The bookkeeping team will review it.
            </Text>
          </>
        }
        variant="text"
      />

      <Accordion
        className="eoybk__fixed-asset-dep__what-about-accordion"
        title="What about my depreciating assets from previous years?"
        content={
          <>
            <Text>
              If you were a Heard member in previous years, we already have your
              2023 receipts and will account for them in your bookkeeping. You
              don’t need to do anything else.
            </Text>

            <Text>
              If you think that we missed a transaction, feel free to upload the
              receipt for it below. The bookkeeping team will review it.
            </Text>
          </>
        }
        variant="text"
      />

      <Divider className="eoybk__fixed-asset-dep__divider" />

      <Text as="h2">Upload receipts</Text>

      <Text>
        If you don’t have a document on hand, you may skip for now. You’ll be
        able to view instructions and upload everything at the end.
      </Text>

      <div className="eoybk__fixed-asset-dep__document-cards">
        {transactionsWithoutReceipts.length > 0 && (
          <DocumentUploadCard
            name="Receipts for Assets >$2,500"
            description={`Upload ${getTxnPluralText(transactionsWithoutReceipts)} receipts for the transactions listed below.`}
            status={
              receipts.length === transactionsWithoutReceipts.length
                ? 'success'
                : 'none'
            }
            requiredDocCount={transactionsWithoutReceipts.length}
            documents={receiptDocuments}
            handleUploadBtn={() => handleFileUpload('receipt')}
          />
        )}

        <DocumentUploadCard
          name="Other documents"
          description="Upload any other documents you’d like to share with the bookkeeping team here."
          status="disabled"
          documents={documents.filter((doc) =>
            (stepContext?.otherDocumentIds ?? []).includes(doc.id)
          )}
          handleUploadBtn={() => handleFileUpload('other')}
        />
      </div>
    </div>
  )
}
