import { FormikProvider, useFormik } from 'formik'
import { Grid } from 'semantic-ui-react'

import { DeductionsAndCreditsStepProps } from '.'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TaxListQuestionId } from '../../service'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import {
  FormikInput,
  GridRowColumn,
  Text,
  makeNumberSchema,
  getFieldNames,
  FormikScrollOnError,
} from '../../../../../../components/BaseComponents'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { tqBigSpace, tqSmallSpace } from '../../helpers'
import { select1040FormForYear } from '../../../annualTaxFilingForms.selector'

export const ownedFarmQuestionIds = [TaxListQuestionId.number_of_farms]

const OwnedFarmPanel = ({
  goBack,
  goToNextStep,
  previousScreen,
  nextScreen,
}: DeductionsAndCreditsStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const form1040 = useReselector(select1040FormForYear, taxYear)
  const formId = form1040?.id

  const questionNumberOfFarms = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.number_of_farms,
    taxYear
  )
  const responseNumberOfFarms = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.number_of_farms,
    Number(formId)
  )

  const formik = useFormik({
    initialValues: {
      numberOfFarms: responseNumberOfFarms?.[0]?.value,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (values.numberOfFarms !== responseNumberOfFarms?.[0]?.value) {
        responseData.push({
          id: responseNumberOfFarms?.[0]?.id,
          value: Number(values.numberOfFarms ?? 0),
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.number_of_farms,
        })
      }
      await goToNextStep(responseData, nextScreen ?? null, {
        completedSteps: [SubStepIdentifiers.farm],
      })
    },
  })

  const { submitForm, isSubmitting } = formik

  const fieldNames = getFieldNames(formik)

  return (
    <FormikProvider value={formik}>
      <FormikScrollOnError />
      <Grid>
        <GridRowColumn {...tqBigSpace}>
          <Text as="display2" textAlign="center">
            Farm
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqBigSpace}>
          <Text as="bodyLg" textAlign="left">
            Your tax preparer will need additional information about your
            farm(s) to determine what deductions you may be eligible for.
          </Text>
        </GridRowColumn>
        <GridRowColumn {...tqSmallSpace}>
          <FormikInput
            name={fieldNames.numberOfFarms}
            label={questionNumberOfFarms.question?.text}
            schema={makeNumberSchema({
              field: 'number of farms',
            })}
            fullWidth
            placeholder="Enter number"
          />
        </GridRowColumn>
        <FormFlowFooter
          continueDisabled={isSubmitting}
          onBack={() => goBack(previousScreen ?? null)}
          onForward={submitForm}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default OwnedFarmPanel
