import { useCallback, useState } from 'react'
import { Divider } from 'semantic-ui-react'
import { UploadDocumentType } from '../../../../constants/businessConstants'
import { Text } from '../../../../components/BaseComponents'
import { DocumentUploadCard } from '../../../../components/FileUpload/DocumentUploadCard'
import { StepHeaders } from '../../../../components/StepHeader'
import FileUploadModal, {
  UploadedFile,
} from '../../../../components/FileUpload/FileUploadModal'
import { IDocumentsStepContext } from '../types'
import { UserDocumentCategoryIdentifier } from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import { UserDocument } from '../../../UserDocuments/userDocuments.slice'
import { useDocuments } from '../hooks'
import './styles.scss'

export interface IPreviousTaxReturnProps {
  taxYear: string
  stepContext: IDocumentsStepContext
  setStepContext: (context: IDocumentsStepContext) => void
  documents: UserDocument[]
}

export const PreviousTaxReturn = ({
  taxYear,
  stepContext,
  setStepContext,
  documents,
}: IPreviousTaxReturnProps) => {
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false)
  const [fileUploadCategory, setFileUploadCategory] = useState<
    string | undefined
  >()

  const handleFileUpload = (categoryName: string | undefined) => {
    setIsFileUploadModalOpen(true)
    setFileUploadCategory(categoryName)
  }

  const onFileUploaded = useCallback(
    (uploadedFile: UploadedFile) => {
      const documentId = uploadedFile.id
      const categoryCode = fileUploadCategory

      if (categoryCode) {
        const documents = [
          ...(stepContext.documents?.filter(
            (doc) => !(doc.categoryCode === categoryCode)
          ) ?? []),
          {
            provider: '',
            documentId,
            categoryCode,
          },
        ]
        setStepContext({ ...stepContext, documents })
      } else {
        const otherDocumentIds = [
          ...(stepContext.otherDocumentIds ?? []),
          uploadedFile.id,
        ]
        setStepContext({ ...stepContext, otherDocumentIds })
      }
    },
    [fileUploadCategory, stepContext, setStepContext]
  )

  const { filterDocuments, getStatus } = useDocuments({
    stepContextDocuments: stepContext?.documents,
    userDocuments: documents,
  })

  const previousTaxYear = (parseInt(taxYear) - 1).toString()

  return (
    <div className="eoybk__previoustax__container">
      <FileUploadModal
        close={() => setIsFileUploadModalOpen(false)}
        documentType={
          fileUploadCategory ? UploadDocumentType.TAX : UploadDocumentType.OTHER
        }
        open={isFileUploadModalOpen}
        categoryInternalName={fileUploadCategory}
        userFacing
        setUploadedFile={onFileUploaded}
        year={previousTaxYear}
      />

      <StepHeaders
        customColor="yellow"
        description={`We need your business tax return from last year (Form 1120-S) to ensure your ${previousTaxYear} Retained Earnings are accurately reflected in your ${taxYear} balance sheet for tax filing.`}
        imageSrc="https://heard-images.s3.amazonaws.com/assets/tax_form.svg"
        imageAlt="stacked checkbooks"
        imageWidth={180}
        imageHeight={180}
        kickerText="YEAR-END DOCUMENTS"
        title={`${previousTaxYear} Business Tax Return`}
      />

      <Divider className="eoybk__previoustax__divider" />

      <div className="eoybk__previoustax-uploads">
        <Text as="h2">Upload 2023 Business Tax Return</Text>

        <Text>
          If you don&apos;t have a document on hand, you may skip for now.
          You&apos;ll be able to view instructions and upload everything at the
          end.
        </Text>

        <DocumentUploadCard
          name={`${previousTaxYear} Business Tax Return`}
          description="Your Form 1120-S from last year. Make sure you include all attachments (e.g. Schedule L and Schedule M-2)."
          handleUploadBtn={() =>
            handleFileUpload(
              UserDocumentCategoryIdentifier.finalReturnForm1120s
            )
          }
          documents={filterDocuments(
            UserDocumentCategoryIdentifier.finalReturnForm1120s,
            ''
          )}
          status={getStatus(
            UserDocumentCategoryIdentifier.finalReturnForm1120s,
            ''
          )}
        />
        <DocumentUploadCard
          name="Other documents"
          description="Upload any other documents you'd like to share with the bookkeeping team here."
          status="disabled"
          documents={documents.filter((doc) =>
            (stepContext?.otherDocumentIds ?? []).includes(doc.id)
          )}
          handleUploadBtn={() => handleFileUpload(undefined)}
        />
      </div>
    </div>
  )
}
