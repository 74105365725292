import axios from 'axios'
import { fetchIfNeededWrapper } from '../../reducers/fetch'
import { Provider, receiveAllProviders } from './provider.reducer'

export const FETCH_ALL_PROVIDERS = 'FETCH_ALL_PROVIDERS'
export const fetchProvidersIfNeeded = ({
  alwaysFetch,
}: {
  alwaysFetch?: boolean
} = {}) =>
  fetchIfNeededWrapper({
    defaultErrorMessage: 'There was an error fetching providers.',
    alwaysFetch,
    fetchKey: FETCH_ALL_PROVIDERS,
    fetchFunction: (dispatch) => {
      return axios
        .get<Provider[]>('/finances/api/v1/providers')
        .then((response) => {
          dispatch(receiveAllProviders(response.data))
          return response.data
        })
    },
  })
