import {
  List,
  Segment,
  Table,
  ListContent,
  Label,
  Divider,
  Radio,
  SegmentGroup,
} from 'semantic-ui-react'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { UserWithAdminInfo } from '../../../reducers/admin/v2/allUsersReducerV2'
import { useCallback, useMemo, useState } from 'react'
import { useAsync, useReselector } from '../../../utils/sharedHooks'
import { fetchUser } from '../../../actions/admin/v2/adminUsersActions'
import { Alert } from '../../BaseComponents'
import styled from 'styled-components'
import { getLegalEntityName } from '../../../constants/onboardingConstants'
import {
  getTaxEntityName,
  TAX_ENTITY_TYPES,
} from '../../../features/Taxes/taxConstants'
import { DateTime } from 'luxon'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../utils/dateHelpers'
import { selectCurrentAnnualTaxYear } from '../../../features/Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { userEndOfYearReconciliationLogsAreCompleted } from '../../../selectors/user.selectors'
import { adminUpdateUserWithId } from '../../../actions/admin/adminAllUsersActions'
import LockBooksToggle from './LockBooksToggle'
import StatefulDropdown from '../shared/StatefulDropdown'
import { useAppDispatch } from '../../../utils/typeHelpers'
import CatchUpBookkeepingToggle from './CatchUpBookkeepingToggle'
import { FEATURE_FLAG_KEYS } from '../../../features/OpenFeature'
import { isCUBKUnpaid } from '../../../features/CatchUpBookkeepingStatus/catchUpBookkeepingStatus.selector'
import { MEMBERSHIP_STATUS } from '../../../reducers/auth/userReducer'
import EditTaxEntityVerificationButton from './EditTaxEntityVerificationButton'

const GroupHeader = styled.h3`
  text-decoration: underline;
`
const ListItem = styled(List.Item)`
  display: flex;
  gap: 20px;
  justify-content: space-between;
`

const RowDrawer = ({
  row,
  colSpan,
  onUserUpdated,
  bookkeeperOptions,
}: {
  row: UserWithAdminInfo
  colSpan: number
  onUserUpdated: (user: UserWithAdminInfo) => void
  bookkeeperOptions: { key: number; text: string; value: number }[]
}) => {
  const dispatch = useAppDispatch()
  const [user, setUser] = useState<UserWithAdminInfo | undefined>(undefined)
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)

  const updateUser = useCallback(
    (updatedUser: UserWithAdminInfo) => {
      onUserUpdated(updatedUser)
      setUser((prev) => ({ ...prev, ...updatedUser }))
    },
    [onUserUpdated]
  )

  const useCatchupBKToggle = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.adminCatchupBKToggle,
    false
  )
  const cubkPaymentStatus = useMemo(() => {
    if (user?.catchUpBookkeepingStatus?.paymentRequired === false) {
      return 'N/A'
    }
    const primaryMembership =
      user?.memberships?.find((m) => m.isPrimary) ?? null

    if (!primaryMembership && !user?.catchUpBookkeepingStatus) {
      return 'Unknown'
    }

    if (primaryMembership?.status === MEMBERSHIP_STATUS.trial) {
      return 'Still in trial'
    }

    const isUnpaid = isCUBKUnpaid(
      primaryMembership,
      user?.catchUpBookkeepingStatus ?? null
    )
    return isUnpaid ? 'No' : 'Yes'
  }, [user?.catchUpBookkeepingStatus, user?.memberships])

  const taxEntityVerificationDetails = useMemo(() => {
    const fp = user?.financialProfile
    if (!user) {
      return null
    }
    if (fp?.taxEntityType !== TAX_ENTITY_TYPES.form_1120_s) {
      return (
        <>
          <Divider />
          <EditTaxEntityVerificationButton
            user={user}
            updateCallback={updateUser}
          />
        </>
      )
    }
    return (
      <>
        <Divider />
        <ListItem>
          <b>Election Date: </b>{' '}
          {fp.entityChangeElectionDate
            ? isoToUTCDateTime(fp.entityChangeElectionDate).toFormat(
                DATE_FORMATS_LUXON.DISPLAY_SHORT
              )
            : 'not set'}
        </ListItem>
        <ListItem>
          <b>S Corp Verified At: </b>{' '}
          {fp.scorpVerifiedAt
            ? isoToUTCDateTime(fp.scorpVerifiedAt).toFormat(
                DATE_FORMATS_LUXON.DISPLAY_SHORT
              )
            : 'not verified'}
        </ListItem>
        <EditTaxEntityVerificationButton
          user={user}
          updateCallback={updateUser}
        />
      </>
    )
  }, [updateUser, user])

  const onBookkeeperChange = useCallback(
    async (entityId: number, value: number) => {
      const { data } = await adminUpdateUserWithId(entityId, {
        bookkeeperId: value,
      })(dispatch)
      if (data) {
        updateUser(data)
      }
    },
    [dispatch, updateUser]
  )
  const onEOYReconToggleClicked = useCallback(
    async (user: UserWithAdminInfo) => {
      // skipcq: JS-0052
      const shouldContinue = window.confirm(
        'You are about to update completion date of end of year reconciliation. This will change timestamp saved in database. Are you sure?'
      )

      if (shouldContinue) {
        const isCompleted = userEndOfYearReconciliationLogsAreCompleted(
          currentTaxYear,
          user.userEndOfYearReconciliationLogs
        )
        const { data } = await adminUpdateUserWithId(user.id, {
          // if previously was marked as completed then change to null, otherwise change to current tax year
          eoyReconciliationYear: isCompleted ? null : currentTaxYear,
        })(dispatch)
        if (data) {
          updateUser(data)
        }
      }
    },
    [currentTaxYear, dispatch, updateUser]
  )

  const { loading, error } = useAsync(
    useCallback(async () => {
      const result = await fetchUser({
        id: row.id,
        select: [
          'financialProfile',
          'userEndOfYearReconciliationLogs',
          'catchUpBookkeepingStatus',
          'memberships',
          'annualTaxFilings',
        ],
      })
      setUser(result)
      return result
    }, [row.id])
  )

  return (
    <Table.Cell colSpan={colSpan} style={{ minHeight: '100px' }}>
      {loading && <Segment placeholder>Loading...</Segment>}
      {!loading && error && (
        <Alert title="Error loading user details" type="error" />
      )}
      {!loading && !error && user && (
        <SegmentGroup horizontal>
          <Segment>
            <GroupHeader>Financial Profile Details</GroupHeader>
            <List>
              {user.financialProfile ? (
                <ListContent>
                  <ListItem>
                    <b>Business Name:</b> {user.financialProfile.businessName}
                  </ListItem>
                  <ListItem>
                    <b>Home State:</b>{' '}
                    {user.financialProfile.homeState || 'not set'}
                  </ListItem>
                  <ListItem>
                    <b>Legal Entity:</b>{' '}
                    {getLegalEntityName(
                      user?.financialProfile?.businessEntity
                    ) || 'not set'}
                  </ListItem>
                  <ListItem>
                    <b>Tax Entity: </b>{' '}
                    {getTaxEntityName(user?.financialProfile?.taxEntityType) ||
                      'not set'}
                  </ListItem>
                  {taxEntityVerificationDetails}
                </ListContent>
              ) : (
                'No financial profile found'
              )}
            </List>
          </Segment>
          <Segment>
            <GroupHeader>Onboarding & Payroll</GroupHeader>
            <List>
              <ListContent>
                <ListItem>
                  <b>Signed Up:</b>{' '}
                  {DateTime.fromISO(user.createdAt).toFormat(
                    DATE_FORMATS_LUXON.DISPLAY_SHORT
                  )}
                </ListItem>
                <ListItem>
                  <b>Onboarding Meeting At:</b>{' '}
                  {user.onboardingMeetingAt
                    ? DateTime.fromISO(user.onboardingMeetingAt).toFormat(
                        DATE_FORMATS_LUXON.DISPLAY_SHORT
                      )
                    : 'N/A'}
                </ListItem>
                <ListItem>
                  <b>Onboarding Items Completed At:</b>{' '}
                  {user.onboardingItemsCompletedAt
                    ? DateTime.fromISO(
                        user.onboardingItemsCompletedAt
                      ).toFormat(DATE_FORMATS_LUXON.DISPLAY_SHORT)
                    : 'N/A'}
                </ListItem>
                <Divider />
                <ListItem>
                  <b>Payroll Enabled:</b>{' '}
                  {user.payrollEnabledAt ? (
                    <Label color="green">
                      {DateTime.fromISO(user.payrollEnabledAt).toFormat(
                        DATE_FORMATS_LUXON.DISPLAY_SHORT
                      )}
                    </Label>
                  ) : (
                    <Label color="red">Not Enabled</Label>
                  )}
                </ListItem>
              </ListContent>
            </List>
          </Segment>
          <Segment>
            <GroupHeader>Bookkeeping</GroupHeader>
            <List>
              <ListContent>
                <ListItem>
                  <b>Catch-Up Bookkeeping Fee Paid:</b>
                  {cubkPaymentStatus}
                </ListItem>
              </ListContent>
            </List>
            <Divider />
            <Table compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {currentTaxYear} YE Recon Complete
                  </Table.HeaderCell>
                  <Table.HeaderCell>User Books Locked</Table.HeaderCell>
                  <Table.HeaderCell>Admin Books Locked</Table.HeaderCell>
                  {useCatchupBKToggle && (
                    <Table.HeaderCell>Catch-up BK Locked</Table.HeaderCell>
                  )}
                  <Table.HeaderCell>Bookkeeper</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell collapsing>
                    <Radio
                      toggle
                      checked={userEndOfYearReconciliationLogsAreCompleted(
                        currentTaxYear,
                        user.userEndOfYearReconciliationLogs
                      )}
                      onChange={() => onEOYReconToggleClicked(user)}
                    />
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <LockBooksToggle
                      user={user}
                      id="user-lock"
                      noTitle
                      lockSubmitCallback={updateUser}
                    />
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <LockBooksToggle
                      user={user}
                      id="admin-lock"
                      noTitle
                      lockSubmitCallback={updateUser}
                    />
                  </Table.Cell>
                  {useCatchupBKToggle && (
                    <Table.Cell collapsing>
                      <CatchUpBookkeepingToggle
                        user={user}
                        toggleUpdateCallback={updateUser}
                      />
                    </Table.Cell>
                  )}
                  <Table.Cell>
                    <StatefulDropdown
                      defaultValue={
                        bookkeeperOptions.find(
                          (bk) => bk.value === user.bookkeeperId
                        )?.value
                      }
                      entityId={user.id}
                      onChange={onBookkeeperChange}
                      options={bookkeeperOptions}
                      placeholder="Select Bookkeeper"
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
        </SegmentGroup>
      )}
    </Table.Cell>
  )
}

export default RowDrawer
