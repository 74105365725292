import moment from 'moment'
import { Link, Label, Table, Text } from '../../../../components/BaseComponents'
import type { LabelColorMap } from '../../../../components/BaseComponents/Label'
import { AboundTaxDocument } from '../../../Taxes/AnnualTaxes/aboundAnnualTaxFilings.slice'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
interface TableDataProps {
  document: AboundTaxDocument
}

const internalStatusColorMap: Record<string, keyof typeof LabelColorMap> = {
  processing: 'blue',
  saved_and_notified: 'green',
  error: 'red',
}

const aboundStatusColorMap: Record<string, keyof typeof LabelColorMap> = {
  created: 'neutral',
  verifying: 'yellow',
  pending: 'blue',
  error: 'red',
  done: 'green',
}

const tinStatusColorMap: Record<string, keyof typeof LabelColorMap> = {
  unverified: 'orange',
  verified: 'green',
  mismatch: 'red',
  pending: 'blue',
  error: 'red',
  lockedOut: 'orange',
  null: 'orange',
}
const mailingStatusColorMap: Record<string, keyof typeof LabelColorMap> = {
  none: 'gray',
  created: 'yellow',
  processingForDelivery: 'orange',
  inTransit: 'blue',
  delivered: 'green',
  returnedToSender: 'red',
}

const AnnualTaxFilingTableData = ({
  document: {
    user,
    AboundContractorProfile,
    updatedAt,
    aboundStatus,
    internalStatus,
    aboundDocumentId,
    filedAt,
    aboundMailingStatus,
  },
}: TableDataProps) => {
  return (
    <Table.Row>
      <Table.Cell>
        <Link to={`/admin/finances/records/${user.id}`}>
          {user.firstName} {user.lastName}
        </Link>
        <Text>
          1099 for contractor: {AboundContractorProfile?.firstName}{' '}
          {AboundContractorProfile?.lastName}
        </Text>
      </Table.Cell>
      <Table.Cell>
        {moment(updatedAt).format(DATE_FORMATS.DISPLAY_FULL)}
      </Table.Cell>
      <Table.Cell>
        <Label
          color={
            tinStatusColorMap[
              AboundContractorProfile?.tinVerificationStatus || 'null'
            ]
          }
        >
          {AboundContractorProfile?.tinVerificationStatus}
        </Label>
      </Table.Cell>
      <Table.Cell>
        <Label color={aboundStatusColorMap[aboundStatus]}>{aboundStatus}</Label>
      </Table.Cell>
      <Table.Cell>
        <Label color={internalStatusColorMap[internalStatus]}>
          {internalStatus}
        </Label>
      </Table.Cell>
      <Table.Cell>
        <Label color={mailingStatusColorMap[aboundMailingStatus]}>
          {aboundMailingStatus}
        </Label>
      </Table.Cell>
      <Table.Cell>
        <Link
          size="xSmall"
          href={`https://dashboard.withabound.com/1099-filings/${aboundDocumentId}?env=${
            process.env.VITE_IS_PROD === 'true' ? 'production' : 'sandbox'
          }`}
          newPage
        >
          Ten99 Filing Request
        </Link>
        <br />
        <br />
        <Link
          size="xSmall"
          href={`https://dashboard.withabound.com/tin-verifications?env=${
            process.env.VITE_IS_PROD === 'true' ? 'production' : 'sandbox'
          }&query=${AboundContractorProfile?.tinVerificationBlob?.id}`}
          newPage
        >
          Contractor TIN Request
        </Link>
      </Table.Cell>
      <Table.Cell>
        {filedAt ? moment(filedAt).format(DATE_FORMATS.DISPLAY_SHORT) : '--'}
      </Table.Cell>
    </Table.Row>
  )
}
export default AnnualTaxFilingTableData
