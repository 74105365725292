import { ReactNode } from 'react'
import { Grid, Icon, Loader } from 'semantic-ui-react'

import {
  GridRowColumn,
  ProgressBar,
  Text,
} from '../../../../components/BaseComponents'
import { TEN_99_STEPS } from './constants'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

const File1099sPage = ({
  isLoading,
  contentHeader,
  content,
  currentStep,
}: {
  isLoading: boolean
  contentHeader: ReactNode
  content: ReactNode
  currentStep: number
}) => {
  const loadingAnimation = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Loader centered inline active />
    </div>
  )

  return (
    <Grid>
      <Grid.Row
        verticalAlign="middle"
        columnStyle={{
          display: 'inline-flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Grid.Column width={13} verticalAlign="middle">
          <Text as="h2">{contentHeader}</Text>
        </Grid.Column>
        <Grid.Column width={3} verticalAlign="middle">
          <Icon
            style={{ display: 'inline', marginRight: 8 }}
            color="green"
            icon={light('check')}
          />
          <Text style={{ color: '#457634' }}>Autosaved</Text>
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn>
        <ProgressBar steps={TEN_99_STEPS} currentStep={currentStep} />
      </GridRowColumn>
      <GridRowColumn>{isLoading ? loadingAnimation : content} </GridRowColumn>
    </Grid>
  )
}

export default File1099sPage
