import { Checkbox, Text } from '../../../../../components/BaseComponents'
import './styles.scss'

export type SimpleRowProps = {
  description: string
  checkboxLabel: string
  checked: boolean
  onCheckboxClick: () => void
  testId?: string
}

const SimpleRow = ({
  description,
  checkboxLabel,
  checked,
  onCheckboxClick,
  testId,
}: SimpleRowProps) => {
  return (
    <div className="eoy-admin__simple-row">
      <Text className="eoy-admin__simple-row__left" as="bodySm" testId={testId}>
        {description}
      </Text>
      <Checkbox
        className="eoy-admin__simple-row__right"
        label={checkboxLabel}
        checked={checked}
        onClick={onCheckboxClick}
      />
    </div>
  )
}

export default SimpleRow
