import { useCallback, useEffect, useMemo, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router'
import { Grid, Loader } from 'semantic-ui-react'
import moment from 'moment'

import { useReselector } from '../../../../utils/sharedHooks'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { formatCurrencyFromCents } from '../../../../utils/currencyHelpers'
import { selectFilteredGroupedUserTransactions } from '../../../Transactions/transactions.selectors'
import { TransactionCategoryRow } from '../../../../components/Transactions/TransactionCategoryList'
import { Transaction } from '../../../../reducers/admin/allTransactions.slice'
import { fetchFilteredUserTransactions } from '../../../Transactions/transactions.slice'
import { fetchTransactionCategoriesIfNeeded } from '../../../Reports/reports.slice'
import { Colors } from '../../../../styles/theme'
import { GridRowColumn, Text } from '../../../../components/BaseComponents'
import { StepHeaders } from '../../../../components/StepHeader'
import { useFinalReviewStepContext, useYearFromParams } from '../hooks'
import { userUpdateBookkeepingStepContext } from '../../end-of-year-review/actions'
import { EndOfYearAdminReviewStep } from '../../end-of-year-review/types'
import FinalReviewWrapper from '../final-review-wrapper'
import BookkeeperNote from '../bookkeeper-note'
import './styles.scss'

interface TransactionData {
  [key: string]: Transaction[]
}

export interface ContentProps {
  year?: string
  adminNote?: string
  groupedExpenses: TransactionData
  totalExpenses: number
  onTransactionUpdate: (id: number) => void
  navigate: NavigateFunction
}

export const Content = ({
  year,
  adminNote,
  groupedExpenses,
  totalExpenses,
  onTransactionUpdate,
  navigate,
}: ContentProps) => {
  return (
    <FinalReviewWrapper
      year={year}
      step={2}
      stepBack={() =>
        navigate(
          `/taxes/annual/review-and-close-books/${year}/final-review-income`
        )
      }
      stepForward={() =>
        navigate(`/taxes/annual/review-and-close-books/${year}/final-sign-off`)
      }
      saveAndExit={() => navigate('/taxes/annual')}
    >
      <Grid className="income-grid">
        <GridRowColumn centerContent className="header-container">
          <StepHeaders
            imageSrc="https://heard-images.s3.amazonaws.com/assets/ledger_review.svg"
            imageAlt="ledger_review"
            imageWidth={180}
            imageHeight={180}
            title={`Review your ${year} expenses`}
            description=""
            customColor="yellow"
          >
            <Grid>
              <GridRowColumn className="align-left">
                <Text>
                  Please conduct a final review of your expenses. If anything
                  looks off, add a note. Our bookkeeping team will take a look
                  and make any necessary revisions.
                </Text>
                <BookkeeperNote note={adminNote} />
              </GridRowColumn>
            </Grid>
          </StepHeaders>
        </GridRowColumn>
        <GridRowColumn
          spacer={2}
          width={12}
          columnStyle={{ backgroundColor: Colors.stone, borderRadius: 8 }}
        >
          <Grid>
            <Grid.Row className="total-transaction-grid" verticalAlign="middle">
              <Grid.Column width={8}>
                <Text as="h3">Total Expenses</Text>
              </Grid.Column>
              <Grid.Column width={8}>
                <Text textAlign="right" as="h3">
                  {formatCurrencyFromCents(totalExpenses)}
                </Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </GridRowColumn>
        {Object.values(groupedExpenses).map((transactions) => (
          <TransactionCategoryRow
            key={transactions[0]?.transactionCategoryId || 'null'}
            transactions={transactions}
            defaultTitle="Uncategorized Expenses"
            onTransactionUpdate={onTransactionUpdate}
          />
        ))}
      </Grid>
    </FinalReviewWrapper>
  )
}

const { finalReview } = EndOfYearAdminReviewStep

const FinalReviewExpenses = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const year = useYearFromParams()
  const [loading, setLoading] = useState(true)
  const { finalReviewContext, setFinalReviewContext } =
    useFinalReviewStepContext()

  // not updating these to Luxon for now because
  // they're used in the transaction selectors and would be high risk to change
  const startDate = useMemo(
    () => moment().utc().year(Number(year)).startOf('year'),
    [year]
  )
  const endDate = useMemo(
    () => moment().utc().year(Number(year)).endOf('year'),
    [year]
  )

  const groupedTransactions = useReselector(
    selectFilteredGroupedUserTransactions,
    { startDate, endDate }
  )
  useEffect(() => {
    const fetch = async () => {
      await Promise.all([
        dispatch(fetchFilteredUserTransactions({ startDate, endDate })),
        dispatch(fetchTransactionCategoriesIfNeeded()),
      ])

      setLoading(false)
    }

    if (startDate && endDate) {
      fetch()
    }
  }, [dispatch, startDate, endDate])

  const onTransactionUpdate = useCallback(
    async (transactionId: number) => {
      if (!finalReviewContext?.updatedTransactionIds?.includes(transactionId)) {
        const newContext = {
          ...finalReviewContext,
          updatedTransactionIds: [
            ...(finalReviewContext?.updatedTransactionIds || []),
            transactionId,
          ],
        }
        const updatedStep = await userUpdateBookkeepingStepContext(
          finalReview,
          finalReview,
          newContext
        )(dispatch)

        if (updatedStep) {
          setFinalReviewContext(updatedStep.context)
        }
      }
    },
    [dispatch, finalReviewContext, setFinalReviewContext]
  )

  return loading ? (
    <Loader loading />
  ) : (
    <Content
      year={year}
      groupedExpenses={groupedTransactions.groupedExpenses}
      totalExpenses={groupedTransactions.totalExpenses}
      adminNote={finalReviewContext?.adminNote}
      onTransactionUpdate={onTransactionUpdate}
      navigate={navigate}
    />
  )
}

export default FinalReviewExpenses
