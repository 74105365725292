import {
  YearEndModuleStatusOptions,
  YearEndModuleType,
  EOYBookkeepingSubstepId,
} from '../yearEndModuleStatus.slice'
import YearEndModuleBaseCard, {
  defaultDeadlineApproaching,
  getDeadlineApproachingDaysUntilDueDate,
  UpcomingStatusTextWrapper,
  YearEndModuleStatusDynamicDataParams,
} from './YearEndModuleStatusBaseCard'
import { Link, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import {
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../utils/dateHelpers'
import {
  getModuleNameCopy,
  useModuleActionButtonBehavior,
  lastWorkedOnSubstepsUrlMap,
} from '../yearEndModuleStatus.helpers'

const getDescription = (
  status: YearEndModuleStatusOptions,
  taxYear: string,
  completedAt?: string | null
) => {
  const baseDescription = `Prepare your ${taxYear} books for annual taxes. We’ll ask you to review your accounts and transactions, as well as upload any documents we need to finalize your books for the year.`
  switch (status) {
    case YearEndModuleStatusOptions.upNext:
    case YearEndModuleStatusOptions.inProgress: {
      return baseDescription
    }
    case YearEndModuleStatusOptions.upcoming: {
      return (
        <UpcomingStatusTextWrapper
          moduleType={YearEndModuleType.eoyBookkeeping}
        >
          {baseDescription}
        </UpcomingStatusTextWrapper>
      )
    }
    case YearEndModuleStatusOptions.overdue: {
      return (
        <>
          <Text as="bodySm">
            You will now <b>need to file an extension for taxes</b>. You still
            need to complete this task to prepare your books.
          </Text>
          <br />
          <Text as="bodySm">
            If you need help, please reach out to your accountant or tax
            professional.
          </Text>
        </>
      )
    }
    case YearEndModuleStatusOptions.complete: {
      const completedText = completedAt ? (
        <span>
          Completed{' '}
          <b>
            {formatISOFromUTC(completedAt, DATE_FORMATS_LUXON.DISPLAY_LONG)}
          </b>
          .{' '}
        </span>
      ) : (
        ''
      )
      return (
        <span>
          {completedText}Next, we’ll review your submission and let you know if
          we need anything else. You’ll be able to review your books again
          before closing them.
        </span>
      )
    }
    default:
      return null
  }
}

const EOYBookkeepingCard = ({
  status,
  dueDate,
  taxYear,
  substepIdentifier,
  completedAt,
  isScorp,
}: YearEndModuleStatusDynamicDataParams & {
  isScorp?: boolean
}) => {
  const description = getDescription(status, taxYear, completedAt)
  let substepURL: string | undefined
  if (substepIdentifier && taxYear) {
    substepURL = `/${taxYear}/${lastWorkedOnSubstepsUrlMap[substepIdentifier as EOYBookkeepingSubstepId]}`
  }
  const { onActionClick } = useModuleActionButtonBehavior(
    YearEndModuleType.eoyBookkeeping,
    status,
    substepURL
  )
  const daysUntilDue = getDeadlineApproachingDaysUntilDueDate(dueDate) || 0
  const linkHref = isScorp
    ? 'https://support.joinheard.com/hc/en-us/articles/4560662501271-Filing-for-an-Extension-as-an-S-Corporation'
    : 'https://support.joinheard.com/hc/en-us/articles/12962191242007-Filing-an-extension-for-your-1040-individual-income-tax-return'
  return (
    <YearEndModuleBaseCard
      key={YearEndModuleType.eoyBookkeeping}
      taxYear={taxYear}
      status={status}
      dueDate={dueDate}
      header={getModuleNameCopy(YearEndModuleType.eoyBookkeeping)}
      imageUrl="https://heard-images.s3.amazonaws.com/assets/books.svg"
      description={<Text as="bodySm">{description}</Text>}
      substepIdentifier={substepIdentifier}
      timer={{
        unit: 'minutes',
        value: 30,
      }}
      deadlineApproaching={{
        ...defaultDeadlineApproaching,
        alertBody: (
          <Text as="bodySm">
            {daysUntilDue >= 0 ? 'After that, y' : 'Y'}ou’ll need to file an
            extension for your taxes.{' '}
            <Link
              href={linkHref}
              newPage
              size="small"
              style={{
                textDecoration: 'underline',
                color: Colors.charcoal,
                textDecorationColor: Colors.charcoal,
              }}
            >
              More info
            </Link>
          </Text>
        ),
      }}
      button={{
        onActionClick,
      }}
    />
  )
}

export default EOYBookkeepingCard
