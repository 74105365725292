import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Provider {
  id: number
  name: string
  createdAt: string
  updatedAt: string
}

export interface ProviderState {
  [key: number]: Provider
}

const providerSlice = createSlice({
  name: 'providers',
  initialState: {} as ProviderState,
  reducers: {
    receiveAllProviders: (_state, action: PayloadAction<Provider[]>) => {
      return action.payload.reduce((acc, provider) => {
        acc[provider.id] = provider
        return acc
      }, {} as ProviderState)
    },
  },
})

export default providerSlice.reducer

export const { receiveAllProviders } = providerSlice.actions
