import styled from 'styled-components'

import { Colors, FontWeight } from '../../styles/theme'
import Text from './Text'

const ProgressBar = styled(
  ({
    currentStep,
    steps,
    className,
  }: {
    currentStep: number
    steps: string[]
    className?: string
  }) => {
    return (
      <div className={className}>
        {steps.map((step, index) => (
          <div className="progress-section" key={step}>
            <div
              className={`progress-segment ${
                currentStep >= index ? 'active' : ''
              }`}
            />
            <Text
              as="bodySm"
              color={currentStep === index ? 'green' : 'black'}
              style={{
                padding: 8,
                fontWeight:
                  currentStep === index
                    ? FontWeight.SEMIBOLD
                    : FontWeight.REGULAR,
              }}
            >
              {index + 1}. {step}
            </Text>
          </div>
        ))}
      </div>
    )
  }
)({
  '&&&': {
    padding: '10px 0',
    display: 'flex',

    '.progress-section': {
      flex: 1,
      paddingTop: 10,
      marginRight: 5,

      p: {
        marginTop: 8,
      },

      '.progress-segment': {
        backgroundColor: Colors.stone,
        height: 6,
        borderRadius: 1,

        '&.active': {
          backgroundColor: Colors.green,
        },
      },
    },
  },
})

export const ProgressBarNoTitles = styled(
  ({
    currentStep,
    totalSteps,
    className,
  }: {
    currentStep: number
    totalSteps: number
    className?: string
  }) => (
    <>
      <div className={className}>
        {(() => {
          const progress = []
          for (let step = 0; step < totalSteps; step++) {
            progress.push(
              <div className="progress-section" key={step}>
                <div
                  className={`progress-segment ${
                    currentStep >= step ? 'active' : ''
                  }`}
                />
              </div>
            )
          }
          return progress
        })()}
      </div>
      <Text
        as="bodySm"
        color="green"
        style={{
          fontWeight: FontWeight.SEMIBOLD,
        }}
      >
        {currentStep + 1} / {totalSteps}
      </Text>
    </>
  )
)({
  '&&&': {
    padding: '10px 0',
    display: 'flex',
    width: '100%',

    '.progress-section': {
      flex: 1,
      paddingTop: 10,
      marginRight: 5,

      p: {
        marginTop: 8,
      },

      '.progress-segment': {
        backgroundColor: Colors.stone,
        height: 6,
        borderRadius: 1,

        '&.active': {
          backgroundColor: Colors.green,
        },
      },
    },
  },
})

export default ProgressBar
