import { useCallback, useEffect, useState } from 'react'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  Alert,
  Button,
  Dropdown,
  Icon,
  Modal,
  Text,
  TextArea,
} from '../../../../components/BaseComponents'
import './styles.scss'
import { NotRelevant } from '../types'
import { NOT_RELEVANT_REASONS } from '../admin/helpers'

interface INotRelevantModalProps {
  isOpen: boolean
  handleClose: () => void
  setConfirmNotRelevant: (notRelevant: NotRelevant) => void
}

const dropdownOptions = [...NOT_RELEVANT_REASONS]

export const NotRelevantModal = ({
  isOpen,
  handleClose,
  setConfirmNotRelevant,
}: INotRelevantModalProps) => {
  const [reason, setReason] = useState('')
  const [context, setContext] = useState('')
  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    setIsFormValid(Boolean(reason) && Boolean(context))
  }, [reason, context])

  const resetModal = () => {
    setReason('')
    setContext('')
  }
  const onCloseClick = useCallback(() => {
    resetModal()
    handleClose()
  }, [handleClose])

  const onConfirmClick = useCallback(
    (notRelevantReason: string, notRelevantContext: string) => {
      resetModal()
      setConfirmNotRelevant?.({
        reason: notRelevantReason,
        context: notRelevantContext,
      })
    },
    [setConfirmNotRelevant]
  )

  return (
    <Modal closeIcon onClose={handleClose} open={isOpen} size="tiny">
      <Modal.Header>Are you sure this document isn’t relevant?</Modal.Header>

      <Modal.Content>
        <div className="eoybk__modal__body">
          <Alert
            customIcon={
              <Icon icon={solid('triangle-exclamation')} color="red" />
            }
            contentStyle={{ textAlign: 'left' }}
            type="error"
          >
            <Text>
              We recommend double-checking first. This could delay your
              end-of-year bookkeeping and annual tax filing if we find we still
              need the document.
            </Text>
          </Alert>

          <Text style={{ margin: '16px 0' }}>
            To continue, please provide some context. This helps us assess if we
            still need the document.
          </Text>

          <Dropdown
            fullWidth
            label="Reason"
            onChange={setReason}
            options={dropdownOptions}
            value={reason}
            required
            className="eoybk__modal__dropdown"
          />

          <TextArea
            disabled={false}
            label="Context"
            placeholder="Share why this document doesn’t apply"
            value={context}
            onChange={(value) => setContext(value)}
            required
          />
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button
          variant="actionLink"
          style={{ marginRight: 36 }}
          onClick={onCloseClick}
        >
          Cancel
        </Button>

        <Button
          variant="warning"
          disabled={!isFormValid}
          onClick={() => onConfirmClick(reason, context)}
        >
          Confirm Not Relevant
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
