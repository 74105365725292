import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Image } from 'semantic-ui-react'

import {
  GridRowColumn,
  Text,
} from '../../../../../../components/BaseComponents'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectTaxListQuestionResponsesByQuestionIds } from '../../taxChecklist.selectors'
import { INCOME_LIABILITIES_SCREENS, IncomeAndLiabilitiesStepProps } from '.'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import ReviewSubSection from '../../Shared/ReviewSubSection'
import { IncomeAndLiabilitiesQuestionIds } from './IncomeAndLiabilitiesPanel'
import { rentalPropertyQuestionIds } from './RentalPropertyPanel'
import { investmentsQuestionIds } from './InvestmentsPanel'
import { retirementContributedQuestionIds } from './RetirementContributedPanel'
import { lessCommonScenarioFollowUpQuestionIds } from './LessCommonScenariosPanel'
import { TaxListQuestionId } from '../../service'
import { useNavigateWithLocation } from '../../../../../../utils/routeHelpers'
import { selectTqFormIsReadOnly } from '../../../annualTaxFilings.selector'
import CommentArea from '../../../components/CommentArea'
import { useReviewPanelComment } from '../../../helpers'
import { SubStepIdentifiers } from '../../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { tqBigSpace } from '../../helpers'

const IncomeAndLiabilitiesReviewPanel = ({
  goBack,
}: IncomeAndLiabilitiesStepProps) => {
  const navigate = useNavigateWithLocation()
  const { formId } = useParams()
  const readOnly = useReselector(selectTqFormIsReadOnly, formId)

  const { comment, saveComment, updateComment } = useReviewPanelComment(
    SubStepIdentifiers.incomeAndLiabilities
  )

  const ownInvestmentsResponses = useReselector(
    selectTaxListQuestionResponsesByQuestionIds,
    [TaxListQuestionId.own_investments],
    Number(formId)
  )
  const showInvestmentsSection = useMemo(
    () => ownInvestmentsResponses.some((response) => response?.value === true),
    [ownInvestmentsResponses]
  )

  return (
    <Grid>
      <GridRowColumn centerContent {...tqBigSpace}>
        <Image src="https://heard-images.s3.amazonaws.com/assets/magnifying-glass-checkmark.svg" />
      </GridRowColumn>
      <GridRowColumn {...tqBigSpace}>
        <Text as="display2" textAlign="center">
          Review
        </Text>
      </GridRowColumn>
      <GridRowColumn {...tqBigSpace}>
        <Text as="bodyLg">
          {readOnly
            ? 'You can no longer edit your Personal Tax Questionnaire responses, but you can view them anytime.'
            : 'Review your answers for accuracy. Click edit to go back to the previous section.'}
        </Text>
      </GridRowColumn>

      <Grid.Row />
      <ReviewSubSection
        title="income and liabilities"
        questionIds={IncomeAndLiabilitiesQuestionIds}
        editScreen={INCOME_LIABILITIES_SCREENS.incomeAndLiabilitiesIntro}
      />
      <ReviewSubSection
        title="rental property"
        questionIds={rentalPropertyQuestionIds}
        editScreen={INCOME_LIABILITIES_SCREENS.rentalProperty}
        group="groupId"
      />
      {showInvestmentsSection && (
        <ReviewSubSection
          title="investments"
          questionIds={investmentsQuestionIds}
          editScreen={INCOME_LIABILITIES_SCREENS.investments}
        />
      )}
      <ReviewSubSection
        title="retirement"
        questionIds={retirementContributedQuestionIds}
        editScreen={INCOME_LIABILITIES_SCREENS.retirementContributed}
        group="groupId"
      />

      <ReviewSubSection
        title="Less common scenarios"
        questionIds={lessCommonScenarioFollowUpQuestionIds}
        editScreen={INCOME_LIABILITIES_SCREENS.lessCommonScenarios}
      />
      {!readOnly && (
        <CommentArea
          label="Add a comment (optional)"
          placeholder="Add a note or ask a question to provide additional context for your tax preparer"
          value={comment}
          onChange={updateComment}
        />
      )}

      {!readOnly && (
        <FormFlowFooter
          isSubmit
          onBack={() => goBack(INCOME_LIABILITIES_SCREENS.lessCommonScenarios)}
          onForward={() => {
            saveComment()
            navigate('/taxes/annual/tax_checklist/')
          }}
        />
      )}
    </Grid>
  )
}

export default IncomeAndLiabilitiesReviewPanel
