import { useEffect, useState } from 'react'
import { Modal, Loader } from '../../../components/BaseComponents'
import { Table } from 'semantic-ui-react'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  AdminFetchUserYearEndModuleRawStatuses,
  YearEndModuleStatusRaw,
} from '../../YearEndModuleStatus/yearEndModuleStatus.slice'
import { getYearModuleStatusRawOptionsTag } from '../../YearEndModuleStatus/yearEndModuleStatus.helpers'

const YearEndModuleStatusModal = ({
  open,
  close,
  userId,
  userFirstName,
  userLastName,
}: {
  open: boolean
  close: () => void
  userId: number
  userFirstName: string
  userLastName: string
}) => {
  const dispatch = useAppDispatch()
  const [isloading, setLoading] = useState(false)
  const [yearEndModuleStatuses, setYearEndModuleStatuses] = useState<
    YearEndModuleStatusRaw[]
  >([])

  useEffect(() => {
    const fetchYearEndModuleStatusData = async () => {
      setLoading(true)
      const res = await dispatch(AdminFetchUserYearEndModuleRawStatuses(userId))
      if (res?.yearEndModuleStatuses) {
        setYearEndModuleStatuses(res.yearEndModuleStatuses)
        setLoading(false)
      }
    }
    if (open) {
      fetchYearEndModuleStatusData()
    }
  }, [dispatch, userId, open])

  return (
    <>
      <Loader loading={isloading} />
      <Modal open={open} onClose={close} closeIcon>
        <Modal.Header>
          Year End Status Tracker: {userFirstName} {userLastName}
        </Modal.Header>
        <Modal.Content>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Module Type</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {yearEndModuleStatuses.map((status) => (
                <Table.Row key={status.moduleType}>
                  <Table.Cell>{status.moduleType}</Table.Cell>
                  <Table.Cell>
                    {getYearModuleStatusRawOptionsTag(status.status)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default YearEndModuleStatusModal
