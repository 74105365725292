import { fetchWrapper } from '../../../reducers/fetch'
import axios from 'axios'

/**
 * Add ALPHABETICALLY
 */
export interface AnnualTaxDateMapping {
  eoy_survey_start_date?: string
  extension_survey_start_date?: string
  form_1040_close_books_due_date?: string
  form_1040_close_books_extended_due_date?: string
  form_1040_eoy_survey_due_date?: string
  form_1040_eoy_survey_extended_due_date?: string
  form_1040_extension_survey_due_date?: string
  form_1040_irs_due_date?: string
  form_1040_irs_extended_due_date?: string
  form_1040_new_user_extension_survey_required_at?: string
  form_1040_new_user_tax_cutoff_date?: string
  form_1040_tq_due_date?: string
  form_1040_tq_extended_due_date?: string
  form_1040_tq_start_date?: string
  form_1099_nec_due_date?: string
  form_1099_nec_irs_due_date?: string
  form_1099_nec_start_date?: string
  form_1120_s_close_books_due_date?: string
  form_1120_s_close_books_extended_due_date?: string
  form_1120_s_eoy_survey_due_date?: string
  form_1120_s_eoy_survey_extended_due_date?: string
  form_1120_s_extension_survey_due_date?: string
  form_1120_s_irs_due_date?: string
  form_1120_s_irs_extended_due_date?: string
  form_1120_s_new_user_extension_survey_required_at?: string
  form_1120_s_new_user_tax_cutoff_date?: string
  form_1120_s_tq_due_date?: string
  form_1120_s_tq_extended_due_date?: string
  form_1120_s_tq_start_date?: string
  form_4868_irs_due_date?: string
  form_7004_irs_due_date?: string
  missed_tsk_deadline_forced_extension_date?: string
  new_user_cutoff_date?: string
  tax_entity_verification_due_date?: string
  tax_entity_verification_extended_due_date?: string
  tax_season_kickoff_due_date?: string
  tax_season_kickoff_start_date?: string
}

export interface AnnualTaxDateType {
  id: keyof AnnualTaxDateMapping
  description: string
  displayName: string
  archivedAt: string | null
  createdAt: string
  updatedAt: string
}

export const FETCH_ANNUAL_TAX_DATE_TYPES_KEY = 'FETCH_ANNUAL_TAX_DATE_TYPES_KEY'
export const listAnnualTaxDateTypes = () =>
  fetchWrapper({
    fetchKey: FETCH_ANNUAL_TAX_DATE_TYPES_KEY,
    fetchFunction: async () => {
      const json = await axios.get<AnnualTaxDateType[]>(
        '/finances/api/v1/admin/annual_tax_details/date_types'
      )
      return json.data
    },
    defaultValue: [] as AnnualTaxDateType[],
  })
