import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Card, IconButton } from '../../BaseComponents'
import { StackedIcon } from '../../BaseComponents/Icon'
import styles from './styles.module.scss'
import { IStep } from '../../../features/Bookkeeping/end-of-year-review/step-map'

export const ReviewStepCard = ({
  step,
  setReviewIndex,
  isPending = false,
  pendingText = '',
}: {
  step: IStep
  setReviewIndex: (index: string) => void
  isPending?: boolean
  pendingText?: string
}) => {
  return (
    <Card
      key={step.route}
      backgroundColor={isPending ? 'lightRed' : 'stone40'}
      fullWidth
    >
      <div className={styles.accountCard}>
        <div className={styles.topRow}>
          <StackedIcon
            icon={isPending ? regular('times') : regular('check')}
            color={isPending ? 'red' : 'green'}
            backgroundIcon={solid('circle')}
            backgroundColor="transparent"
            marginRight={10}
          />
          <div className={styles.accountName}>{step.name}</div>
          <IconButton
            icon={regular('edit')}
            color="darkGray"
            onClick={() => {
              setReviewIndex(step.route)
            }}
          />
        </div>
        {isPending && <div className={styles.pendingText}>{pendingText}</div>}
      </div>
    </Card>
  )
}
