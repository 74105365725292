import {
  YearEndModuleStatusOptions,
  YearEndModuleType,
} from '../yearEndModuleStatus.slice'
import YearEndModuleBaseCard, {
  defaultDeadlineApproaching,
  getDeadlineApproachingDaysUntilDueDate,
  UpcomingStatusTextWrapper,
  YearEndModuleStatusDynamicDataParams,
} from './YearEndModuleStatusBaseCard'
import { Alert, Link, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import {
  getModuleNameCopy,
  useModuleActionButtonBehavior,
} from '../yearEndModuleStatus.helpers'
import { ExtensionReason } from '../../Taxes/AnnualTaxes/annualTaxFilings.slice'

const AlertWrapper = ({ children }: { children: React.ReactNode }) => (
  <Alert type="info" style={{ marginTop: 16 }}>
    <Text as="bodySm">{children}</Text>
  </Alert>
)

const commonExtensionText =
  'you will need to file an extension to continue. Your due dates will then be updated to reflect the extended timeline.'

const ExtensionRequiredAlert = ({
  taxYear,
  extensionReason,
}: {
  taxYear: string
  extensionReason?: ExtensionReason | null
}) => {
  if (
    !extensionReason ||
    extensionReason === ExtensionReason.userRequested ||
    extensionReason === ExtensionReason.filedExternally
  ) {
    return null
  }
  const commonDeadlineText = 'Since the deadline for'
  switch (extensionReason) {
    case ExtensionReason.lateJoiner:
      return (
        <AlertWrapper>
          Since you joined after January 1, {commonExtensionText}
        </AlertWrapper>
      )
    case ExtensionReason.tskOverdue:
      return (
        <AlertWrapper>
          {commonDeadlineText} completing your Tax Season Kickoff has passed,{' '}
          {commonExtensionText}
        </AlertWrapper>
      )
    case ExtensionReason.taxEntityUnconfirmed:
      return (
        <AlertWrapper>
          {commonDeadlineText} verifying your tax entity has passed,{' '}
          {commonExtensionText}
        </AlertWrapper>
      )
    case ExtensionReason.bookkeepingTasksIncomplete:
      return (
        <AlertWrapper>
          {commonDeadlineText} completing your year end bookkeeping tasks has
          passed, {commonExtensionText}
        </AlertWrapper>
      )
    case ExtensionReason.booksNotLocked:
      return (
        <AlertWrapper>
          {commonDeadlineText} closing your {taxYear} books has passed,{' '}
          {commonExtensionText}
        </AlertWrapper>
      )
    default:
      return extensionReason satisfies never
  }
}

const getDescription = ({
  status,
  extensionReason,
  taxYear,
}: {
  status: YearEndModuleStatusOptions
  extensionReason?: ExtensionReason | null
  taxYear: string
}) => {
  const baseDescription =
    'Fill out a short survey that will give us what we need to file an extension. We’ll then connect you to a tax preparer to complete the process.'
  switch (status) {
    case YearEndModuleStatusOptions.upNext:
    case YearEndModuleStatusOptions.earlyStart: {
      return (
        <>
          {baseDescription}
          <ExtensionRequiredAlert
            taxYear={taxYear}
            extensionReason={extensionReason}
          />
        </>
      )
    }
    case YearEndModuleStatusOptions.inProgress: {
      return baseDescription
    }
    case YearEndModuleStatusOptions.upcoming: {
      return (
        <UpcomingStatusTextWrapper
          moduleType={YearEndModuleType.submitExtensionRequest}
        >
          {baseDescription}
        </UpcomingStatusTextWrapper>
      )
    }
    case YearEndModuleStatusOptions.missed: {
      return (
        <>
          <Text as="bodySm">
            We are no longer able to file your extension request.
          </Text>
          <br />
          <Text as="bodySm">
            You are now{' '}
            <b>at risk of incurring failure to file penalties and interest</b>.
            Please file your tax return(s) as soon as you can.
          </Text>
        </>
      )
    }
    case YearEndModuleStatusOptions.complete: {
      return 'We’ve received your extension request survey. We’ll connect you to a tax preparer soon.'
    }
    default:
      return null
  }
}

const SubmitExtensionRequestCard = ({
  status,
  dueDate,
  taxYear,
  substepIdentifier,
  extensionReason,
}: YearEndModuleStatusDynamicDataParams & {
  extensionReason?: ExtensionReason | null
}) => {
  const description = getDescription({
    status,
    extensionReason,
    taxYear,
  })
  const { onActionClick } = useModuleActionButtonBehavior(
    YearEndModuleType.submitExtensionRequest,
    status
  )
  const daysUntilDue = getDeadlineApproachingDaysUntilDueDate(dueDate) || 0

  return (
    <YearEndModuleBaseCard
      key={YearEndModuleType.submitExtensionRequest}
      taxYear={taxYear}
      status={status}
      dueDate={dueDate}
      header={getModuleNameCopy(YearEndModuleType.submitExtensionRequest)}
      imageUrl="https://heard-images.s3.amazonaws.com/assets/tax-extension.svg"
      description={<Text as="bodySm">{description}</Text>}
      substepIdentifier={substepIdentifier}
      timer={{
        unit: 'minutes',
        value: 15,
      }}
      deadlineApproaching={{
        ...defaultDeadlineApproaching,
        alertBody: (
          <Text as="bodySm">
            {daysUntilDue >= 0 ? 'After that, y' : 'Y'}ou’re at risk of
            incurring <b>failure-to-file penalties and interest</b>. We can’t
            file an extension for you after the deadline.{' '}
            <Link
              href="https://support.joinheard.com/hc/en-us/articles/16958669406743-Why-did-I-receive-an-IRS-underpayment-penalty"
              newPage
              size="small"
              style={{
                textDecoration: 'underline',
                color: Colors.charcoal,
                textDecorationColor: Colors.charcoal,
              }}
            >
              More info
            </Link>
          </Text>
        ),
      }}
      button={{
        showOn: [
          YearEndModuleStatusOptions.upcoming,
          YearEndModuleStatusOptions.upNext,
          YearEndModuleStatusOptions.inProgress,
          YearEndModuleStatusOptions.earlyStart,
        ],
        onActionClick,
      }}
    />
  )
}

export default SubmitExtensionRequestCard
