import { FC, ReactNode, useMemo } from 'react'
import { DateTime, DurationLikeObject } from 'luxon'
import { StandardShorthandProperties } from 'csstype'
import {
  Button,
  Alert,
  Card,
  Pill,
  Icon,
  Text,
} from '../../../components/BaseComponents'
import { Colors, Fonts, FontWeight } from '../../../styles/theme'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  WaitingOnTaxPreparerSubstep,
  YearEndModuleStatusOptions,
  YearEndModuleSubstepId,
  YearEndModuleType,
} from '../yearEndModuleStatus.slice'
import { getSubstepIdentifierLastWorkedOnCopy } from '../yearEndModuleStatus.helpers'
import { Image, Label } from 'semantic-ui-react'
import { DATE_FORMATS_LUXON, isoToLocalDate } from '../../../utils/dateHelpers'
import { LabelColorMap } from '../../../components/BaseComponents/Label'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../utils/deviceWidthHelpers'
import { useReselector } from '../../../utils/sharedHooks'
import { selectAvailableAfterTextFor } from '../yearEndModuleStatus.selectors'

interface DeadlineApproaching {
  displayOn: YearEndModuleStatusOptions[]
  relativeStart: DurationLikeObject
  alertBody?: ReactNode
}

interface YearEndModuleBaseCardProps {
  key: YearEndModuleType
  status: YearEndModuleStatusOptions
  taxYear: string
  dueDate: string | null
  header: string
  imageUrl: string
  description: ReactNode
  timer: {
    unit: string
    value: number | string
    showOn?: YearEndModuleStatusOptions[]
    complementaryText?: string
  }
  deadlineApproaching: DeadlineApproaching
  button: {
    text?: string
    onActionClick?: () => void
    showOn?: YearEndModuleStatusOptions[]
  }
  substepIdentifier?: YearEndModuleSubstepId | null
}

export interface YearEndModuleStatusDynamicDataParams {
  taxYear: string
  dueDate: YearEndModuleBaseCardProps['dueDate']
  status: YearEndModuleBaseCardProps['status']
  substepIdentifier?: YearEndModuleSubstepId | null
  completedAt?: string | null
}

export const defaultDeadlineApproaching: DeadlineApproaching = {
  displayOn: [
    YearEndModuleStatusOptions.upNext,
    YearEndModuleStatusOptions.earlyStart,
    YearEndModuleStatusOptions.inProgress,
    YearEndModuleStatusOptions.actionRequired,
  ],
  relativeStart: { week: 1 },
  alertBody: 'The deadline to complete this task is approaching.',
}

export const UpcomingStatusTextWrapper = ({
  moduleType,
  children,
}: {
  moduleType: YearEndModuleType
  children?: ReactNode
}) => {
  const availableAfterText = useReselector(
    selectAvailableAfterTextFor,
    moduleType
  )
  return (
    <Text as="bodySm" style={{ color: Colors.mediumGray }}>
      {children}
      <Text
        as="bodyXs"
        style={{ fontWeight: FontWeight.MEDIUM, marginTop: 16 }}
      >
        {availableAfterText}
      </Text>
    </Text>
  )
}

const defaultTimerShowOn = [
  YearEndModuleStatusOptions.upNext,
  YearEndModuleStatusOptions.earlyStart,
  YearEndModuleStatusOptions.inProgress,
  YearEndModuleStatusOptions.upcoming,
]

const StatusColorMap: {
  [key in keyof typeof LabelColorMap]: {
    color: (typeof Colors)[keyof typeof Colors]
    backgroundColor: (typeof Colors)[keyof typeof Colors]
  }
} = {
  ...LabelColorMap,
}

const statusTextMap: Record<YearEndModuleStatusOptions, string> = {
  [YearEndModuleStatusOptions.upNext]: 'Up Next',
  [YearEndModuleStatusOptions.actionRequired]: 'Action Required',
  [YearEndModuleStatusOptions.earlyStart]: 'Get Ahead',
  [YearEndModuleStatusOptions.inProgress]: 'In Progress',
  [YearEndModuleStatusOptions.upcoming]: 'Upcoming',
  [YearEndModuleStatusOptions.overdue]: 'Overdue',
  [YearEndModuleStatusOptions.waitingOnHeard]: 'Waiting on Heard',
  [YearEndModuleStatusOptions.complete]: 'Complete',
  [YearEndModuleStatusOptions.cancelled]: 'Cancelled',
  [YearEndModuleStatusOptions.missed]: 'Missed',
}

type YearEndModuleBaseCardStyleParams = {
  backgroundColor: keyof typeof Colors
  pill: {
    backgroundColor: (typeof Colors)[keyof typeof Colors]
    color: (typeof Colors)[keyof typeof Colors]
  }
  dueDate: {
    backgroundColor: (typeof Colors)[keyof typeof Colors]
    color: (typeof Colors)[keyof typeof Colors]
  }
  buttonVariant: 'primary' | 'secondary'
  border?: StandardShorthandProperties['border']
}

export const getDefaultButtonText = (status: YearEndModuleStatusOptions) => {
  switch (status) {
    case YearEndModuleStatusOptions.upcoming: {
      return 'Coming Soon'
    }
    case YearEndModuleStatusOptions.actionRequired: {
      return 'Respond in Chat'
    }
    case YearEndModuleStatusOptions.cancelled:
    case YearEndModuleStatusOptions.waitingOnHeard: {
      return 'View Chat'
    }
    case YearEndModuleStatusOptions.inProgress: {
      return 'Continue'
    }
    case YearEndModuleStatusOptions.complete: {
      return 'Completed'
    }
    default: {
      return 'Start'
    }
  }
}

export const getDeadlineApproachingDaysUntilDueDate = (
  dueDate: string | null
) => {
  if (!dueDate) {
    return null
  }
  const dateTimeDueDate = isoToLocalDate(dueDate)
  const now = DateTime.now()
  if (!dateTimeDueDate?.isValid) {
    return null
  }
  const dateDiff = dateTimeDueDate.diff(now, 'days')
  return Math.floor(dateDiff.days)
}

const getStatusText = (
  status: YearEndModuleStatusOptions,
  substepIdentifier: YearEndModuleSubstepId | null
) => {
  if (
    substepIdentifier &&
    WaitingOnTaxPreparerSubstep.includes(substepIdentifier)
  ) {
    return 'Waiting on Tax Preparer'
  }
  return statusTextMap[status]
}

const getStyleForStatus = (
  status: YearEndModuleStatusOptions
): YearEndModuleBaseCardStyleParams => {
  const commonStyle = {
    buttonVariant: 'primary' as const,
    pill: {
      backgroundColor: 'stone',
      color: 'oak',
    },
    dueDate: {
      backgroundColor: 'oak',
      color: 'stone',
    },
  }
  switch (status) {
    case YearEndModuleStatusOptions.upNext:
    case YearEndModuleStatusOptions.inProgress: {
      return {
        ...commonStyle,
        backgroundColor: 'natural',
        pill: {
          backgroundColor: StatusColorMap['orange'].color,
          color: StatusColorMap['orange'].backgroundColor,
        },
        dueDate: StatusColorMap['orange'],
        border: `2px solid ${Colors.orange}`,
      }
    }
    case YearEndModuleStatusOptions.actionRequired: {
      return {
        ...commonStyle,
        backgroundColor: 'lightYellow',
        pill: {
          backgroundColor: StatusColorMap['yellow'].color,
          color: StatusColorMap['yellow'].backgroundColor,
        },
        dueDate: StatusColorMap['yellow'],

        border: `2px solid ${Colors.yellow}`,
      }
    }
    case YearEndModuleStatusOptions.earlyStart: {
      return {
        ...commonStyle,
        backgroundColor: 'natural',
        pill: StatusColorMap['dark'],
        dueDate: StatusColorMap['neutral'],
        buttonVariant: 'secondary',
      }
    }
    case YearEndModuleStatusOptions.upcoming: {
      return {
        ...commonStyle,
        backgroundColor: 'stone40OnWhiteNoAlpha',
        pill: StatusColorMap['darkGray'],
        dueDate: StatusColorMap['darkGray'],
      }
    }
    case YearEndModuleStatusOptions.cancelled: {
      return {
        ...commonStyle,
        backgroundColor: 'stone40OnWhiteNoAlpha',
        pill: StatusColorMap['gray'],
        dueDate: StatusColorMap['gray'],
        buttonVariant: 'secondary',
      }
    }
    case YearEndModuleStatusOptions.missed:
    case YearEndModuleStatusOptions.overdue: {
      return {
        ...commonStyle,
        backgroundColor: 'lightRed',
        pill: {
          backgroundColor: StatusColorMap['red'].color,
          color: StatusColorMap['red'].backgroundColor,
        },
        dueDate: StatusColorMap['red'],
      }
    }
    case YearEndModuleStatusOptions.waitingOnHeard: {
      return {
        ...commonStyle,
        backgroundColor: 'lightBlue',
        pill: {
          backgroundColor: StatusColorMap['blue'].color,
          color: StatusColorMap['blue'].backgroundColor,
        },
        dueDate: StatusColorMap['blue'],
        buttonVariant: 'secondary',
      }
    }
    case YearEndModuleStatusOptions.complete: {
      return {
        ...commonStyle,
        backgroundColor: 'lightGreen',
        pill: StatusColorMap['accentGreen'],
        dueDate: StatusColorMap['green'],
      }
    }
    default:
      status satisfies never
      return {
        ...commonStyle,
        backgroundColor: 'natural',
      }
  }
}

const getDeadlineAlertContent = (
  dateTimeDueDate: DateTime | null,
  deadlineApproaching: DeadlineApproaching
): {
  deadlineWithinRelativeStart: boolean
  daysUntilDueDate: number | null
  alertHeaderText: string
} => {
  const deadlineContent = {
    deadlineWithinRelativeStart: false,
    daysUntilDueDate: null,
    alertHeaderText: '',
  }
  if (!dateTimeDueDate) {
    return deadlineContent
  }
  const now = DateTime.now()
  const relativeStartDate = dateTimeDueDate.minus(
    deadlineApproaching.relativeStart
  )
  if (now > relativeStartDate) {
    const daysUntilDueDate = getDeadlineApproachingDaysUntilDueDate(
      dateTimeDueDate.toISO()
    )
    if (daysUntilDueDate === null) {
      return deadlineContent
    } else if (daysUntilDueDate === 7) {
      deadlineContent.alertHeaderText = '1 week left to complete'
    } else if (daysUntilDueDate === 1) {
      deadlineContent.alertHeaderText = `${daysUntilDueDate} day left to complete`
    } else if (daysUntilDueDate === 0) {
      deadlineContent.alertHeaderText = 'Due Today'
    } else if (daysUntilDueDate && daysUntilDueDate < 0) {
      deadlineContent.alertHeaderText = 'Overdue'
    } else {
      deadlineContent.alertHeaderText = `${daysUntilDueDate} days left to complete`
    }
    return {
      deadlineWithinRelativeStart: true,
      daysUntilDueDate,
      alertHeaderText: deadlineContent.alertHeaderText,
    }
  }
  return deadlineContent
}

const CardHeader = ({
  dateTimeDueDate,
  header,
  imageUrl,
  status,
  statusStyles,
  timer,
  substepIdentifier,
}: YearEndModuleBaseCardProps & {
  dateTimeDueDate: DateTime | null
  statusStyles: YearEndModuleBaseCardStyleParams
}) => {
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const statusText = useMemo(
    () => getStatusText(status, substepIdentifier ?? null),
    [status, substepIdentifier]
  )
  const hideDueDate = [
    YearEndModuleStatusOptions.complete,
    YearEndModuleStatusOptions.cancelled,
  ].includes(status)
  const showTimer = useMemo(() => {
    if (timer.showOn) {
      return timer.showOn.includes(status)
    }
    return defaultTimerShowOn.includes(status)
  }, [status, timer.showOn])

  return (
    <div
      style={{
        display: 'flex',
        gap: 24,
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            alignContent: 'flex-start',
            rowGap: 4,
            columnGap: 11,
            marginBottom: 20,
          }}
        >
          <Pill
            style={{
              ...statusStyles.pill,
              padding: '6px 8px 2px 8px',
            }}
          >
            {status === YearEndModuleStatusOptions.actionRequired && (
              <Icon
                icon={solid('seal-exclamation')}
                style={{ padding: '0 8px 3px 0' }}
              />
            )}
            {statusText}
          </Pill>
          {dateTimeDueDate && !hideDueDate && (
            <Label
              style={{
                ...statusStyles.dueDate,
                borderRadius: 4,
              }}
            >
              <Icon
                icon={regular('calendar')}
                style={{
                  paddingRight: 8,
                }}
              />
              Due {dateTimeDueDate.toFormat(DATE_FORMATS_LUXON.MONTH_DAY)}
            </Label>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Text as="h3">{header}</Text>
          <Text
            as="bodyXs"
            style={{
              color:
                status === YearEndModuleStatusOptions.upcoming
                  ? Colors.mediumGray
                  : Colors.oak,
            }}
          >
            {showTimer && (
              <>
                <Icon icon={regular('timer')} /> {timer.value} {timer.unit}
                {timer?.complementaryText}
              </>
            )}
          </Text>
        </div>
      </div>
      {!isMobile && (
        <Image
          src={imageUrl}
          size="tiny"
          disabled={status === YearEndModuleStatusOptions.upcoming}
        />
      )}
    </div>
  )
}

const CardDescription = ({
  description,
  substepIdentifier,
  taxYear,
  status,
}: YearEndModuleBaseCardProps) => {
  const copy = getSubstepIdentifierLastWorkedOnCopy(
    status,
    substepIdentifier,
    taxYear
  )
  return (
    <div>
      {description}
      {copy && (
        <Text as="bodyXs" style={{ marginTop: 16 }}>
          You were last working on: <b>{copy}</b>
        </Text>
      )}
    </div>
  )
}

const CardDeadlineAlert = ({
  dateTimeDueDate,
  deadlineApproaching,
  status,
}: YearEndModuleBaseCardProps & {
  dateTimeDueDate: DateTime | null
}) => {
  const deadlineContent = getDeadlineAlertContent(
    dateTimeDueDate,
    deadlineApproaching
  )
  if (
    !deadlineApproaching.displayOn.includes(status) ||
    !deadlineContent.deadlineWithinRelativeStart
  ) {
    return null
  }
  return (
    <Alert
      type="error"
      innerTextStyle={{
        ...Fonts.bodyXs,
      }}
      customIcon={
        <Icon
          icon={solid('exclamation-triangle')}
          style={{ color: Colors.red }}
        />
      }
      title={deadlineContent.alertHeaderText}
    >
      {deadlineApproaching.alertBody}
    </Alert>
  )
}

const CardButton = ({
  status,
  button,
  statusStyles,
}: YearEndModuleBaseCardProps & {
  statusStyles: YearEndModuleBaseCardStyleParams
}) => {
  const buttonText = useMemo(
    () => button.text || getDefaultButtonText(status),
    [button.text, status]
  )
  const showButton = useMemo(() => {
    if (button.showOn) {
      return button.showOn.includes(status)
    }
    // default to show on everything except waitingOnHeard, missed, and complete
    return ![
      YearEndModuleStatusOptions.waitingOnHeard,
      YearEndModuleStatusOptions.complete,
      YearEndModuleStatusOptions.missed,
    ].includes(status)
  }, [button.showOn, status])

  if (!showButton) {
    return null
  }

  return (
    <Button
      variant={statusStyles.buttonVariant}
      style={{
        width: 'fit-content',
      }}
      disabled={status === YearEndModuleStatusOptions.upcoming}
      onClick={button.onActionClick}
    >
      {buttonText}
      {![
        YearEndModuleStatusOptions.upcoming,
        YearEndModuleStatusOptions.complete,
      ].includes(status) && (
        <Icon icon={regular('arrow-right')} style={{ marginLeft: 8 }} />
      )}
    </Button>
  )
}

const YearEndModuleBaseCard: FC<YearEndModuleBaseCardProps> = (props) => {
  const { key, status, dueDate } = props
  const dateTimeDueDate = dueDate ? isoToLocalDate(dueDate) : null
  const statusStyles = useMemo(() => getStyleForStatus(status), [status])

  return (
    <Card
      backgroundColor={statusStyles.backgroundColor}
      key={key}
      style={{
        border: statusStyles.border,
      }}
    >
      <Card.Content>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
          }}
        >
          <CardHeader
            {...props}
            dateTimeDueDate={dateTimeDueDate}
            statusStyles={statusStyles}
          />
          <CardDescription {...props} />
          <CardDeadlineAlert {...props} dateTimeDueDate={dateTimeDueDate} />
          <CardButton {...props} statusStyles={statusStyles} />
        </div>
      </Card.Content>
    </Card>
  )
}

export default YearEndModuleBaseCard
