import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Divider, Grid } from 'semantic-ui-react'
import moment from 'moment'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Alert,
  Button,
  Card,
  FormikDateInput,
  FormikDropdown,
  FormikInput,
  FormikLabelError,
  FormikRadioToggleButton,
  getFieldName,
  GridRowColumn,
  IconButton,
  Link,
  makeDateSchema,
  makeNumberSchema,
  makeReqBoolSchema,
  makeReqStringSchema,
  Text,
} from '../../../../components/BaseComponents'
import { useReselector } from '../../../../utils/sharedHooks'
import { getFinancialProfile } from '../../../../selectors/user.selectors'
import {
  UPDATE_FINANCIAL_PROFILE_KEY,
  updateFinancialProfile,
} from '../../../../actions/financialProfileActions'
import {
  TAX_PROFILE_STEP,
  TaxesProfileFlowAnswerProps,
  useTaxProfileSteps,
} from './helpers'

import { FILING_STATUSES, TAX_ENTITY_TYPES } from '../../taxConstants'
import {
  stateAbbToState,
  STATES_LONG,
} from '../../../../constants/locationConstants'
import { DATE_FORMATS } from '../../../../utils/dateHelpers'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../utils/deviceWidthHelpers'
import TaxProfileFormFooter from './TaxProfileFormFooter'
import { getFetchError } from '../../../../reducers/fetch'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import FilingStatusSubForm from './FilingStatusSubForm'

const AboutYouForm = ({
  formFlowAnswers,
  setFormFlowAnswers,
}: TaxesProfileFlowAnswerProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const fp = useReselector(getFinancialProfile)
  const error = useReselector(
    getFetchError,
    UPDATE_FINANCIAL_PROFILE_KEY(fp?.id)
  )
  const { goToNextStep } = useTaxProfileSteps(TAX_PROFILE_STEP.aboutYou)
  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      filingStatusSame: formFlowAnswers?.filingStatusSame,
      filingStatus: fp?.filingStatus,
      stateSame: formFlowAnswers?.stateSame,
      relocatedPreviousState: fp?.relocatedPreviousState,
      relocatedPreviousStateAt:
        fp?.relocatedPreviousStateAt &&
        moment.utc(fp?.relocatedPreviousStateAt).format(DATE_FORMATS.INPUT),
      homeState: fp?.homeState,
      registrationStateSame: formFlowAnswers?.registrationStateSame,
      scorpRegistrationState: fp?.scorpRegistrationState,
      percentageIncomePerState: fp?.percentageIncomePerState || [
        { state: undefined, value: undefined },
      ],
    },
    onSubmit: async ({
      filingStatusSame,
      filingStatus,
      stateSame,
      relocatedPreviousState,
      relocatedPreviousStateAt,
      homeState,
      registrationStateSame,
      scorpRegistrationState,
      percentageIncomePerState,
    }) => {
      const res = await dispatch(
        updateFinancialProfile(fp?.id, {
          ...((filingStatusSame === false || !fp?.filingStatus) && {
            filingStatus,
          }),
          ...(stateSame
            ? {
                relocatedPreviousState: null,
                relocatedPreviousStateAt: null,
              }
            : {
                relocatedPreviousState,
                relocatedPreviousStateAt,
                homeState,
              }),
          ...((registrationStateSame === false ||
            !fp?.scorpRegistrationState) && {
            scorpRegistrationState,
          }),
          // Clear spouse fields if not filing jointly or qualifying widow
          ...(filingStatus &&
            ![
              FILING_STATUSES.married_filing_jointly,
              FILING_STATUSES.qualifying_widow,
            ].includes(filingStatus) && {
              spouseWIncomeInCents: null,
              spouseFederalWithholdingInCents: null,
              spouseStateWithholdingInCents: null,
              spouseIndividualIncomeInCents: null,
              estimatedAnnualSpouseW2IncomeInCents: null,
            }),
          percentageIncomePerState,
        })
      )

      if (res) {
        const newAnswers = {
          ...formFlowAnswers,
          // always default to true because they either selected yes on the previous page,
          // or they selected no and updated the value, which should then now be true
          filingStatusSame: true,
          stateSame: true,
          registrationStateSame: true,
        }
        setFormFlowAnswers(newAnswers)
        goToNextStep(res)
      }
    },
  })

  const {
    values,
    setFieldValue,
    validateForm,
    submitForm,
    isValid,
    isSubmitting,
  } = formik

  const isScorp = fp?.taxEntityType === TAX_ENTITY_TYPES.form_1120_s

  // If filingStatusSame toggled to true reset the filingStatus change
  useEffect(() => {
    if (values.filingStatusSame) {
      setFieldValue(
        getFieldName<typeof values>('filingStatus'),
        fp?.filingStatus
      )
    }
  }, [fp?.filingStatus, setFieldValue, values.filingStatusSame])

  // If stateSame toggled to true reset the stateSame change
  useEffect(() => {
    if (values.stateSame) {
      setFieldValue(getFieldName<typeof values>('homeState'), fp?.homeState)
    } else if (values.stateSame === false) {
      // If values.stateSame is toggled to false, set previous state as the current state and wipe the current state
      setFieldValue(
        getFieldName<typeof values>('relocatedPreviousState'),
        fp?.homeState
      )
      setFieldValue(getFieldName<typeof values>('homeState'), '')
    }
  }, [fp?.homeState, setFieldValue, values.stateSame])

  // If registrationStateSame toggled to true reset the registrationStateSame change
  useEffect(() => {
    if (values.registrationStateSame) {
      setFieldValue(
        getFieldName<typeof values>('scorpRegistrationState'),
        fp?.scorpRegistrationState
      )
    }
  }, [fp?.scorpRegistrationState, setFieldValue, values.registrationStateSame])

  const handleRemove = useCallback(
    (idx: number) => {
      const newStates = [...values.percentageIncomePerState]
      newStates.splice(idx, 1)
      setFieldValue('percentageIncomePerState', newStates).then(() => {
        // This should be automatic but doesn't seem to work for some reason
        validateForm()
      })
    },
    [values.percentageIncomePerState, setFieldValue, validateForm]
  )

  return (
    <FormikProvider value={formik}>
      <Grid>
        <GridRowColumn>
          <Text textAlign="center" as="display2">
            Please confirm your information
          </Text>
        </GridRowColumn>
        <GridRowColumn>
          <Text textAlign="center" as="bodyLg">
            If anything has changed below, please update it now.
          </Text>
        </GridRowColumn>

        {error && (
          <GridRowColumn>
            <Alert type="error">{error.message}</Alert>
          </GridRowColumn>
        )}

        <GridRowColumn>
          <Card backgroundColor="stone40">
            <Grid>
              <FilingStatusSubForm fp={fp} />

              <Grid.Row />
              <Divider />
              <Grid.Row />

              {fp?.homeState ? (
                <>
                  <GridRowColumn>
                    <FormikLabelError
                      name={getFieldName<typeof values>('stateSame')}
                      required
                      label={
                        <>
                          Are you still living in{' '}
                          <b>{stateAbbToState(fp?.homeState)}</b>?
                        </>
                      }
                      description="If you moved to another state since last quarter, select No."
                      schema={makeReqBoolSchema()}
                    />
                  </GridRowColumn>
                  <Grid.Row className="short">
                    <Grid.Column computer={2} tablet={3} mobile={5}>
                      <FormikRadioToggleButton
                        name={getFieldName<typeof values>('stateSame')}
                        value
                        fullWidth
                      >
                        Yes
                      </FormikRadioToggleButton>
                    </Grid.Column>
                    <Grid.Column computer={2} tablet={3} mobile={5}>
                      <FormikRadioToggleButton
                        name={getFieldName<typeof values>('stateSame')}
                        value={false}
                        fullWidth
                      >
                        No
                      </FormikRadioToggleButton>
                    </Grid.Column>
                  </Grid.Row>
                </>
              ) : (
                <GridRowColumn {...makeGridConfig([10, 16])}>
                  <FormikDropdown
                    name={getFieldName<typeof values>('homeState')}
                    label="What is your state of residence?"
                    placeholder="Choose a state"
                    required
                    fullWidth
                    options={STATES_LONG}
                    schema={makeReqStringSchema()}
                  />
                </GridRowColumn>
              )}

              {values.stateSame === false && (
                <>
                  <Grid.Row>
                    <Grid.Column computer={10} tablet={10} mobile={16}>
                      <FormikDropdown
                        name={getFieldName<typeof values>(
                          'relocatedPreviousState'
                        )}
                        label="Which state did you move from?"
                        placeholder="Choose a state"
                        required
                        fullWidth
                        options={STATES_LONG}
                        schema={makeReqStringSchema()}
                      />
                    </Grid.Column>
                    <Grid.Column computer={6} tablet={6} mobile={10}>
                      <FormikDateInput
                        name={getFieldName<typeof values>(
                          'relocatedPreviousStateAt'
                        )}
                        label="When did you move?"
                        placeholder="Choose a date"
                        required
                        fullWidth
                        schema={makeDateSchema()}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <GridRowColumn {...makeGridConfig([10, 16])}>
                    <FormikDropdown
                      name={getFieldName<typeof values>('homeState')}
                      label="Which state did you move to?"
                      placeholder="Choose a state"
                      required
                      fullWidth
                      options={STATES_LONG}
                      schema={makeReqStringSchema()}
                    />
                  </GridRowColumn>
                </>
              )}

              <Grid.Row />
              <Divider />
              <Grid.Row />

              {isScorp && (
                <>
                  {fp?.scorpRegistrationState && (
                    <>
                      <GridRowColumn>
                        <FormikLabelError
                          name={getFieldName<typeof values>(
                            'registrationStateSame'
                          )}
                          required
                          label={`Is your business still registered in ${stateAbbToState(
                            fp.scorpRegistrationState
                          )}?`}
                          description="If this has changed, select No."
                          schema={makeReqBoolSchema()}
                        />
                      </GridRowColumn>
                      <Grid.Row className="short">
                        <Grid.Column computer={2} tablet={3} mobile={5}>
                          <FormikRadioToggleButton
                            name={getFieldName<typeof values>(
                              'registrationStateSame'
                            )}
                            value
                            fullWidth
                          >
                            Yes
                          </FormikRadioToggleButton>
                        </Grid.Column>
                        <Grid.Column computer={2} tablet={3} mobile={5}>
                          <FormikRadioToggleButton
                            name={getFieldName<typeof values>(
                              'registrationStateSame'
                            )}
                            value={false}
                            fullWidth
                          >
                            No
                          </FormikRadioToggleButton>
                        </Grid.Column>
                      </Grid.Row>
                    </>
                  )}
                  {(!fp?.scorpRegistrationState ||
                    values.registrationStateSame === false) && (
                    <GridRowColumn {...makeGridConfig([10, 16])}>
                      <FormikDropdown
                        name={getFieldName<typeof values>(
                          'scorpRegistrationState'
                        )}
                        label="What state is your business registered in?"
                        placeholder="Choose a state"
                        required
                        fullWidth
                        options={STATES_LONG}
                        schema={makeReqStringSchema()}
                      />
                    </GridRowColumn>
                  )}
                  <Grid.Row />
                  <Divider />
                  <Grid.Row />
                </>
              )}

              <GridRowColumn>
                <FormikLabelError
                  name={getFieldName<typeof values>('percentageIncomePerState')}
                  label="What % of income do you make in each state?"
                  description={
                    <>
                      Please share the % of income made in each state.{' '}
                      <Link
                        newPage
                        href="https://support.joinheard.com/hc/en-us/articles/4763301299991"
                        size="small"
                      >
                        How do I calculate this?
                      </Link>
                      <br />
                      Listing all states doesn’t necessarily impact your
                      quarterly tax payment.
                    </>
                  }
                  schema={yup
                    .array()
                    .test(
                      'percentageIncomePerState',
                      'Percentages must add up to 100%',
                      (percentages) =>
                        percentages &&
                        percentages
                          .map((val) => (val.value ? Number(val.value) : 0))
                          .reduce((prev, value) => prev + value, 0) === 100
                    )}
                />
              </GridRowColumn>
              {values.percentageIncomePerState.map((val, index) => (
                <Grid.Row key={`${val.state}-${index}`} verticalAlign="middle">
                  <Grid.Column computer={10} tablet={10} mobile={16}>
                    <FormikDropdown
                      name={`percentageIncomePerState[${index}].state`}
                      label="State your clients live in"
                      required
                      // Filter out any states already used
                      options={STATES_LONG.filter(
                        (state) =>
                          state.value === val.state ||
                          !values.percentageIncomePerState
                            .map((val) => val.state)
                            .includes(state.value)
                      )}
                      fullWidth
                      schema={makeReqStringSchema()}
                    />
                  </Grid.Column>
                  <Grid.Column
                    computer={5}
                    tablet={5}
                    mobile={10}
                    style={{ marginTop: isMobile ? 10 : 0 }}
                  >
                    <FormikInput
                      name={`percentageIncomePerState[${index}].value`}
                      label="% of income made"
                      required
                      placeholder="Enter %"
                      fullWidth
                      rightIcon="percent"
                      step="1"
                      type="number"
                      schema={makeNumberSchema({ maxNumber: 100 })}
                    />
                  </Grid.Column>
                  {values.percentageIncomePerState.length > 1 && (
                    <Grid.Column width={1} style={{ marginTop: 20 }}>
                      <IconButton
                        icon={regular('trash-can')}
                        onClick={() => handleRemove(index)}
                        aria-label={`delete-state-${index}`}
                      />
                    </Grid.Column>
                  )}
                </Grid.Row>
              ))}
              <GridRowColumn>
                <Button
                  variant="link"
                  onClick={() => {
                    setFieldValue('percentageIncomePerState', [
                      ...values.percentageIncomePerState,
                      { state: undefined, value: undefined },
                    ]).then(() => validateForm())
                  }}
                >
                  + Add another state
                </Button>
              </GridRowColumn>
            </Grid>
          </Card>
        </GridRowColumn>

        <TaxProfileFormFooter
          submitOrContinue={submitForm}
          goToPreviousStep={() => navigate('/taxes/quarterly')}
          continueDisabled={!isValid || isSubmitting}
          loading={isSubmitting}
        />
      </Grid>
    </FormikProvider>
  )
}

export default AboutYouForm
