import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import { Grid, Divider, Loader } from 'semantic-ui-react'
import * as yup from 'yup'
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  Card,
  Checkbox,
  FormikInput,
  getFieldNames,
  GridRowColumn,
  Icon,
  IconButton,
  Link,
  makeReqStringSchema,
  Modal,
  Popup,
  Text,
} from '../../../../components/BaseComponents'
import {
  AboundContractor,
  createAboundContractorRequest,
  submitAll1099sToAbound,
  updateAboundPayerRequest,
  fetchAboundTaxDocumentsForPayer,
  fetchAboundContractors,
  fetchAboundPayer,
  FETCH_ABOUND_CONTRACTORS_KEY,
  FETCH_ABOUND_TAX_FILINGS_KEY,
  ABOUND_CONTRACTOR_CREATE_KEY,
  ABOUND_SUBMIT_ALL_1099S_KEY,
  deleteAboundContractorRequest,
  ABOUND_CONTRACTOR_DELETE_KEY,
} from '../aboundAnnualTaxFilings.slice'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  contractorIsEditable,
  getContractorsSortedByCreatedAt,
  getAboundTaxDocForPayerByContractorId,
  getAboundTaxDocsForPayer,
  getCurrentAboundPayer,
  selectContractorIsFromPreviousTaxYears,
  selectHasAnyContractorsFromPreviousTaxYears,
} from '../aboundAnnualTaxFilings.selector'
import { getIsFetchingOrNotStarted } from '../../../../reducers/fetch'
import File1099sPage from './File1099sPage'
import Ten99FilingErrors from './Ten99FilingErrors'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { useAnalyticsTrack, useAnalyticsView } from '../../../Amplitude'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../OpenFeature'

const validationSchema = yup.object({
  firstName: makeReqStringSchema({ field: 'first name' }),
  lastName: makeReqStringSchema({ field: 'last name' }),
})

const AddContractorForm = ({
  setShowContractorForm,
}: {
  setShowContractorForm: (value: boolean) => void
}) => {
  const dispatch = useAppDispatch()
  const [processingContractor, setProcessingContractor] = useState(false)
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: async ({ firstName, lastName }) => {
      setProcessingContractor(true)
      const newContractor = await createAboundContractorRequest({
        firstName,
        lastName,
      })(dispatch)
      setProcessingContractor(false)
      if (newContractor) {
        setShowContractorForm(false)
      }
    },
  })

  const { submitForm } = formik
  const fieldNames = getFieldNames(formik)

  return (
    <GridRowColumn width={10}>
      <Card backgroundColor="natural">
        <Text
          as="eyebrow"
          textAlign="left"
          color="forest"
          style={{ paddingBottom: 24 }}
        >
          Add Contractor
        </Text>
        <FormikProvider value={formik}>
          <Grid style={{ paddingBottom: 24 }}>
            <Grid.Row>
              <Grid.Column width={8}>
                <FormikInput
                  label="First Name"
                  name={fieldNames.firstName}
                  fullWidth
                  required
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <FormikInput
                  label="Last Name"
                  fullWidth
                  name={fieldNames.lastName}
                  required
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8} />
              <Grid.Column width={4}>
                <Button
                  variant="secondary"
                  fullWidth
                  onClick={() => setShowContractorForm(false)}
                >
                  Cancel
                </Button>
              </Grid.Column>
              <Grid.Column width={4}>
                <Button
                  variant="secondary"
                  fullWidth
                  disabled={!formik.isValid}
                  onClick={submitForm}
                >
                  {processingContractor ? <Loader active inline /> : 'Add'}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </FormikProvider>
      </Card>
    </GridRowColumn>
  )
}

const ActionButton = ({
  contractor,
  goToContractorDetail,
}: {
  contractor: AboundContractor
  goToContractorDetail: () => void
}) => {
  const ten99 = useReselector(
    getAboundTaxDocForPayerByContractorId,
    contractor.id
  )
  const has1099 = Boolean(ten99)
  const contractorFromPast = useReselector(
    selectContractorIsFromPreviousTaxYears,
    contractor.uuid
  )
  const isEditable = useReselector(contractorIsEditable, contractor.uuid)
  if (has1099) {
    return (
      <Button
        disabled={!isEditable}
        fullWidth
        variant="secondary"
        onClick={goToContractorDetail}
      >
        <Icon
          icon={light('check')}
          style={{ marginRight: '8px' }}
          color={isEditable ? 'black' : 'gray'}
        />
        {isEditable ? 'View' : 'Submitted'}
      </Button>
    )
  }
  return (
    <Button fullWidth onClick={goToContractorDetail}>
      {contractorFromPast ? 'Update' : 'Start'}
    </Button>
  )
}

const DeleteModal = ({
  contractor,
  open,
  setDeleteModalOpen,
}: {
  contractor: AboundContractor
  open: boolean
  setDeleteModalOpen: (_: boolean) => void
}) => {
  const dispatch = useAppDispatch()

  const handleRemove = useCallback(
    (contractorToRemove: AboundContractor) =>
      deleteAboundContractorRequest(contractorToRemove)(dispatch),
    [dispatch]
  )

  return (
    <Modal
      closeOnDimmerClick={false}
      closeOnEscape={false}
      open={open}
      size="tiny"
    >
      <Modal.Header>Are you sure you want to delete?</Modal.Header>
      <Modal.Content>This cannot be recovered.</Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={() => setDeleteModalOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="warning"
          onClick={() => {
            handleRemove(contractor)
            setDeleteModalOpen(false)
          }}
        >
          Delete
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const Contractor = ({
  contractor,
  goToContractorDetail,
}: {
  contractor: AboundContractor
  goToContractorDetail: () => void
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const isEditable = useReselector(contractorIsEditable, contractor.uuid)
  return (
    <>
      <DeleteModal
        contractor={contractor}
        open={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
      />
      <Card backgroundColor={isEditable ? 'natural' : 'stone40'}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={11} verticalAlign="middle">
              <Text as="h3">
                {contractor.firstName} {contractor.lastName}
              </Text>
            </Grid.Column>
            <Grid.Column floated="right" width={4}>
              <ActionButton
                contractor={contractor}
                goToContractorDetail={goToContractorDetail}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width={1}>
              {isEditable && (
                <IconButton
                  onClick={() => {
                    setDeleteModalOpen(true)
                  }}
                  icon={regular('trash-can')}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </>
  )
}

const FilingDisclosure = ({
  checkboxDisclosureState,
  setCheckboxDisclosureState,
}: {
  checkboxDisclosureState: boolean
  setCheckboxDisclosureState: (checked: boolean) => void
}) => {
  const dispatch = useAppDispatch()
  const payerProfile = useReselector(getCurrentAboundPayer)
  const updateAcceptedFilingDisclosure = async (checked: boolean) => {
    setCheckboxDisclosureState(checked)
    if (payerProfile) {
      await updateAboundPayerRequest(payerProfile.payerId, {
        acceptedFilingDisclosure: checked,
      })(dispatch)
    }
  }

  return (
    <GridRowColumn width={10} style={{ paddingBottom: 80 }}>
      <Text as="bodySm">
        <Checkbox
          style={{ paddingTop: 3 }}
          onChange={(checked) => updateAcceptedFilingDisclosure(checked)}
          checked={checkboxDisclosureState}
        />
        You are opting in to receiving an electronic copy of your 1099-NEC form.
        You consent to Heard using Abound, a 3rd party Tax API, to securely file
        your 1099-NEC Forms for you. This consent to receive an electronic 1099
        via Abound and Heard will remain in place so long as you do not opt out.
        Any electronic 1099s made available to you will remain available
        indefinitely until you request that your Heard account be closed and/or
        deleted.
      </Text>
    </GridRowColumn>
  )
}

const AddContractorsPanel = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [showContractorForm, setShowContractorForm] = useState(false)
  const [submitting1099s, setSubmitting1099s] = useState(false)

  const contractors = useReselector(getContractorsSortedByCreatedAt)
  const ten99s = useReselector(getAboundTaxDocsForPayer)
  const payerProfile = useReselector(getCurrentAboundPayer)
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const [checkboxDisclosureState, setCheckboxDisclosureState] = useState(
    payerProfile?.acceptedFilingDisclosure === true || false
  )

  const anyContractorsFromPreviousYear = useReselector(
    selectHasAnyContractorsFromPreviousTaxYears
  )
  const allContractorsHave1099s = useMemo(
    () =>
      !isEmpty(contractors) &&
      !contractors.some((contractor) => !ten99s?.[contractor.id]),
    [contractors, ten99s]
  )
  const fetchingContractors = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ABOUND_CONTRACTORS_KEY
  )
  const fetching1099s = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ABOUND_TAX_FILINGS_KEY
  )
  const fetchingAnnualTaxDetails = useReselector(
    getIsFetchingOrNotStarted,
    FETCH_ALL_ANNUAL_TAX_DETAILS_KEY
  )

  const pageView = useAnalyticsView()
  const track = useAnalyticsTrack()
  const screenName = '1099-NEC add contractors panel'
  useEffect(() => {
    pageView(screenName)
  }, [pageView])

  useEffect(() => {
    dispatch(fetchAboundPayer())
    dispatch(fetchAboundContractors())
    dispatch(fetchAboundTaxDocumentsForPayer())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
  }, [dispatch])

  const submitAll1099s = async () => {
    setSubmitting1099s(true)
    const submitted1099s = await submitAll1099sToAbound()(dispatch)
    setSubmitting1099s(false)
    if (submitted1099s) {
      track('1099-NEC submit clicked', {
        contractorCount: contractors.length,
      })
      navigate('/taxes/annual/file_1099_nec/done')
    }
  }

  const listContractors = useCallback(() => {
    return contractors.map((contractor) => (
      <GridRowColumn width={10} key={`${contractor.id}`}>
        <Contractor
          contractor={contractor}
          goToContractorDetail={() => {
            navigate(
              `/taxes/annual/file_1099_nec/contractors/${contractor.uuid}`
            )
          }}
        />
      </GridRowColumn>
    ))
  }, [contractors, navigate])

  const contentHeader = (
    <>
      <Icon
        icon={light('arrow-left')}
        style={{ marginRight: '8px' }}
        color="darkGray"
      />
      <Link to="/taxes/annual">
        <Text color="darkGray">Back to Tax Center</Text>
      </Link>
    </>
  )
  const content = (
    <Grid centered>
      <GridRowColumn
        style={{ display: 'flex', alignItems: 'center' }}
        width={10}
      >
        <Text textAlign="center" as="h1" style={{ marginBottom: 32 }}>
          Add Contractor Details
        </Text>
        <Card backgroundColor="stone40" style={{ alignItems: 'flex-start' }}>
          <Text>
            You&apos;ll file 1099-NECs for contractors who fit this criteria:
          </Text>
          <Text>
            <ul>
              <li>
                Their tax entity is a LLC (single-member or partnership) or a
                sole proprietor. See box 3 on their W-9.
              </li>
              <li>
                You&apos;ve paid them <b>over $600</b> in {taxYear}
              </li>
              <li>
                You&apos;ve paid them with <b>cash, checks or bank transfers</b>
              </li>
            </ul>
          </Text>
          <Button
            variant="tooltipLink"
            style={{ padding: 2, marginBottom: 24 }}
          >
            <Popup
              wide
              position="right center"
              content={
                <>
                  <Text as="h3">Who doesn&apos;t need a 1099-NEC?</Text>
                  <ul>
                    <li>
                      <Text>
                        If your contractor is a large corporation (i.e. Simple
                        Practice).
                      </Text>
                    </li>
                    <br />
                    <li>
                      <Text>
                        If your contractor&apos;s tax entity is a S or C
                        corporation. In that case, you&apos;d file a 1099-MISC
                        for them.
                      </Text>
                    </li>
                    <br />
                    <li>
                      <Text>
                        If you&apos;ve paid your contractor{' '}
                        <b>less than $600</b> in the last year.
                      </Text>
                    </li>
                    <br />
                    <li>
                      <Text>
                        If you&apos;ve paid your contractor via your{' '}
                        <b>payroll provider</b> (i.e. Gusto) or through a{' '}
                        <b>payment processor</b> (i.e. Stripe, Visa).
                        They&apos;ll file the 1099-NEC for you.
                      </Text>
                    </li>
                  </ul>
                </>
              }
              trigger="Who doesn’t need a 1099-NEC?"
            />
          </Button>
          <Text>
            Still have questions? Read our{' '}
            <Link
              href="https://support.joinheard.com/hc/en-us/articles/18872262968727"
              newPage
            >
              FAQs
            </Link>
          </Text>
        </Card>
        <br />
        <Text textAlign="center">
          {anyContractorsFromPreviousYear
            ? 'Please add or remove contractors that need a 1099-NEC. If you have the same contractor(s) from last year, you’ll need to update their information.'
            : 'Please add all contractors that need a 1099-NEC.'}
        </Text>
        <Button
          style={{ margin: '32px auto' }}
          variant="secondary"
          onClick={() => setShowContractorForm(true)}
        >
          <Icon
            icon={light('plus')}
            style={{ marginRight: '8px' }}
            color="black"
          />
          Add Contractor
        </Button>
      </GridRowColumn>
      {showContractorForm ? (
        <AddContractorForm setShowContractorForm={setShowContractorForm} />
      ) : (
        <>
          {listContractors()}
          <GridRowColumn />
          {allContractorsHave1099s && (
            <FilingDisclosure
              checkboxDisclosureState={checkboxDisclosureState}
              setCheckboxDisclosureState={setCheckboxDisclosureState}
            />
          )}
        </>
      )}
      <Ten99FilingErrors
        fetchKeys={[
          ABOUND_CONTRACTOR_CREATE_KEY,
          ABOUND_SUBMIT_ALL_1099S_KEY,
          ABOUND_CONTRACTOR_DELETE_KEY,
        ]}
      />
      <Divider />
      <Grid.Row>
        <Grid.Column width={13} />
        <Grid.Column width={2}>
          <Button
            disabled={!(allContractorsHave1099s && checkboxDisclosureState)}
            fullWidth
            onClick={submitAll1099s}
          >
            {submitting1099s ? <Loader active inline /> : 'Submit'}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )

  const enable1099nec = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enable1099nec,
    false
  )
  if (!enable1099nec) {
    navigate('/taxes/annual')
  }

  return (
    <File1099sPage
      currentStep={1}
      isLoading={
        fetchingContractors || fetching1099s || fetchingAnnualTaxDetails
      }
      contentHeader={contentHeader}
      content={content}
    />
  )
}

export default AddContractorsPanel
