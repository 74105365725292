import { Grid, Image } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'

import {
  FormikInput,
  FormikScrollOnError,
  getFieldName,
  GridRowColumn,
  makeNumberSchema,
  Text,
} from '../../../../../../components/BaseComponents'
import {
  DEDUCTIONS_AND_CREDITS_SCREENS,
  DeductionsAndCreditsStepProps,
} from './index'
import { TaxListQuestionId } from '../../service'
import { useReselector } from '../../../../../../utils/sharedHooks'
import { selectCurrentAnnualTaxYear } from '../../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  selectTaxListQuestion,
  selectTaxListQuestionResponsesByFormId,
} from '../../taxChecklist.selectors'
import { TaxChecklistResponse } from '../../taxChecklistQuestion.slice'
import FormFlowFooter from '../../../../../../components/FormFlow/FormFlowFooter'
import { CalculationAlert } from '../../Shared/SpecialtyAlerts'
import { tqBigSpace, tqSmallSpace } from '../../helpers'
import { formatCurrencyFromCents } from '../../../../../../utils/currencyHelpers'
import { select1040FormForYear } from '../../../annualTaxFilingForms.selector'

const sqFootageMax = 300
const sqFootageRateInCents = 500

export const getSimplifiedDeduction = (squareFootage: number) =>
  formatCurrencyFromCents(
    Math.min(
      sqFootageRateInCents * sqFootageMax,
      sqFootageRateInCents * squareFootage
    ),
    { hideDecimalsIfZero: true }
  )

const HomeOfficeDeductionsSimplifiedPanel = ({
  goBack,
  goToNextStep,
}: DeductionsAndCreditsStepProps) => {
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const form1040 = useReselector(select1040FormForYear, taxYear)
  const formId = form1040?.id

  const sqFootageRateTextInDollars = formatCurrencyFromCents(
    sqFootageRateInCents,
    { hideDecimalsIfZero: true }
  )
  const sqFootageMaxInDollars = formatCurrencyFromCents(
    sqFootageRateInCents * sqFootageMax,
    { hideDecimalsIfZero: true }
  )

  const questionSquareFootageBusiness = useReselector(
    selectTaxListQuestion,
    TaxListQuestionId.home_office_sq_ft_for_business,
    taxYear
  )
  const responseSquareFootageBusiness = useReselector(
    selectTaxListQuestionResponsesByFormId,
    TaxListQuestionId.home_office_sq_ft_for_business,
    Number(formId)
  )

  const formik = useFormik({
    initialValues: {
      squareFtUsedForBusiness:
        typeof responseSquareFootageBusiness?.[0]?.value === 'number'
          ? responseSquareFootageBusiness?.[0]?.value
          : undefined,
    },
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      const responseData: Partial<TaxChecklistResponse>[] = []
      if (
        values.squareFtUsedForBusiness !==
        responseSquareFootageBusiness?.[0]?.value
      ) {
        responseData.push({
          id: responseSquareFootageBusiness?.[0]?.id,
          value: Number(values.squareFtUsedForBusiness ?? 0),
          annualTaxFilingFormId: Number(formId),
          questionId: TaxListQuestionId.home_office_sq_ft_for_business,
        })
      }
      await goToNextStep(
        responseData,
        DEDUCTIONS_AND_CREDITS_SCREENS.homeOffice
      )
    },
  })

  const { values, submitForm, isSubmitting } = formik

  return (
    <Grid>
      <Grid.Row />
      <Grid.Row />
      <GridRowColumn centerContent {...tqBigSpace}>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/house.svg"
          style={{ margin: 'auto', width: 120 }}
        />
      </GridRowColumn>
      <GridRowColumn {...tqBigSpace}>
        <Text as="display2" textAlign="center">
          Simplified Method
        </Text>
      </GridRowColumn>
      <GridRowColumn {...tqBigSpace}>
        <Text as="bodyLg" textAlign="left">
          Please enter the total amount of square footage used as part of the
          home office. The IRS allows{' '}
          <b>
            {sqFootageRateTextInDollars}/sq ft with a maximum of {sqFootageMax}{' '}
            sq ft claimed ({sqFootageMaxInDollars}).
          </b>
        </Text>
      </GridRowColumn>
      <GridRowColumn {...tqSmallSpace}>
        <FormikProvider value={formik}>
          <FormikScrollOnError />
          <FormikInput
            name={getFieldName<typeof values>('squareFtUsedForBusiness')}
            label={questionSquareFootageBusiness?.question?.text}
            description={`If the amount is larger than ${sqFootageMax} sq ft, we’ll automatically cap it at ${sqFootageMax}.`}
            placeholder="Enter square footage"
            schema={makeNumberSchema({ field: 'square footage' })}
            fullWidth
          />
        </FormikProvider>
      </GridRowColumn>
      {values.squareFtUsedForBusiness && (
        <GridRowColumn short {...tqSmallSpace}>
          <CalculationAlert>
            Your Simplified Deduction is{' '}
            {getSimplifiedDeduction(values.squareFtUsedForBusiness)}
          </CalculationAlert>
        </GridRowColumn>
      )}
      <FormFlowFooter
        onBack={() => goBack(DEDUCTIONS_AND_CREDITS_SCREENS.homeOffice)}
        onForward={submitForm}
        continueDisabled={isSubmitting}
        loading={isSubmitting}
      />
    </Grid>
  )
}

export default HomeOfficeDeductionsSimplifiedPanel
