import { Grid, Loader } from 'semantic-ui-react'
import { useCallback, useEffect, useMemo } from 'react'
import {
  Alert,
  GridRowColumn,
  Text,
} from '../../../../../components/BaseComponents'
import {
  FETCH_DOCUMENT_CATEGORIES_KEY,
  fetchUserDocumentCategoriesIfNeeded,
} from '../../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { useNavigate } from 'react-router-dom'
import FormFlowFooter from '../../../../../components/FormFlow/FormFlowFooter'
import {
  FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
  fetchAllAnnualTaxDetailsIfNeeded,
} from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import {
  FETCH_USER_DOCUMENTS_KEY,
  fetchUserDocuments,
} from '../../../../UserDocuments/userDocuments.slice'
import {
  DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
  FETCH_USER_TAX_QUESTIONNAIRE_KEY,
  SUBMIT_EXTENSION_REQUEST_KEY,
  UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  fetchUserTaxQuestionnaire,
  postUpdateTaxQuestionnaireResponses,
  submitExtensionRequest,
} from '../../TaxChecklist/taxChecklistQuestion.actions'
import {
  FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
  fetchAnnualTaxFilingFormsIfNeeded,
} from '../../annualTaxFilingForms.slice'
import { selectCurrentAnnualTaxYear } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  useAsyncCallback,
  useReselector,
} from '../../../../../utils/sharedHooks'
import { UPDATE_FINANCIAL_PROFILE_KEY } from '../../../../../actions/financialProfileActions'
import { UPDATE_USER_KEY } from '../../../../../actions/userActions'
import {
  invalidateFetch,
  selectFirstErrorMessageForKeys,
  selectIsFetchingForKeys,
} from '../../../../../reducers/fetch'
import { getCurrentUser } from '../../../../../selectors/user.selectors'
import { select1120sFormForYear } from '../../annualTaxFilingForms.selector'
import PageHeader from '../../../../../components/shared/PageHeader'
import { useBusinessDetails } from '../../TaxChecklist/Shared/PersonalDetails/CheckYourDetailsPanel'
import { FORM_1040_DETAILS_SCREENS } from '../../TaxChecklist/Form1040/Details'
import { TaxChecklistResponse } from '../../TaxChecklist/taxChecklistQuestion.slice'
import { EXTENSION_REQUEST_SCREENS } from '../Combined'
import { makeGridConfig } from '../../../../../components/BaseComponents/Grid'
import { isEmpty } from 'lodash'
import { useBusinessIncome, useStartExtension } from '../SharedHooks'
import {
  getAnnualTaxFilingForYearSelector,
  selectExtensionSurveyComplete,
} from '../../annualTaxFilings.selector'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import { fetchAnnualTaxFilingsIfNeeded } from '../../annualTaxFilings.slice'
import {
  FETCH_TAX_USER_DOCUMENTS_KEY,
  fetchTaxUserDocumentsIfNeeded,
} from '../../taxUserDocuments.slice'

const FormBusiness = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const user = useReselector(getCurrentUser)
  const filingForm1120s = useReselector(select1120sFormForYear, taxYear)
  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    taxYear
  )
  const extensionComplete = useReselector(
    selectExtensionSurveyComplete,
    taxYear
  )

  useStartExtension(annualTaxFiling?.id)

  useEffect(() => {
    dispatch(fetchUserTaxQuestionnaire(taxYear))
  }, [dispatch, taxYear])

  useEffect(() => {
    dispatch(fetchAnnualTaxFilingsIfNeeded())
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
    dispatch(fetchUserDocuments())
    dispatch(fetchTaxUserDocumentsIfNeeded())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch, taxYear])

  useEffect(() => {
    if (extensionComplete) {
      navigate('/taxes/annual/')
    }
  }, [extensionComplete, navigate])

  const allFetchKeys = useMemo(
    () => [
      UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
      DELETE_TAX_QUESTIONNAIRE_RESPONSES_KEY,
      UPDATE_USER_KEY,
      UPDATE_FINANCIAL_PROFILE_KEY(user?.financialProfile?.id),
      FETCH_ANNUAL_TAX_FILING_FORMS_KEY,
      FETCH_ALL_ANNUAL_TAX_DETAILS_KEY,
      FETCH_USER_TAX_QUESTIONNAIRE_KEY(taxYear),
      FETCH_USER_DOCUMENTS_KEY,
      FETCH_TAX_USER_DOCUMENTS_KEY,
      FETCH_DOCUMENT_CATEGORIES_KEY,
      SUBMIT_EXTENSION_REQUEST_KEY,
    ],
    [taxYear, user?.financialProfile?.id]
  )
  const isUpdating = useReselector(selectIsFetchingForKeys, [
    UPSERT_USER_TAX_QUESTIONNAIRE_KEY,
  ])
  const updateError = useReselector(
    selectFirstErrorMessageForKeys,
    allFetchKeys
  )
  const isFetching = useReselector(selectIsFetchingForKeys, allFetchKeys)

  const saveFormData = useCallback(
    async (
      data: Partial<TaxChecklistResponse>[] | null,
      _newScreen: FORM_1040_DETAILS_SCREENS | EXTENSION_REQUEST_SCREENS | null
    ) => {
      allFetchKeys.forEach((key) => dispatch(invalidateFetch(key)))
      if (data?.length) {
        const res = await dispatch(postUpdateTaxQuestionnaireResponses(data))
        if (res) {
          return true
        }
      } else {
        return true
      }
      return false
    },
    [dispatch, allFetchKeys]
  )

  const {
    content: businessDetailsContent,
    businessFormik: businessDetailsFormik,
    editBusiness: editBusinessDetails,
  } = useBusinessDetails({
    is1040: false,
    isUpdating,
    isTwoFormFiler: false,
    formId: String(filingForm1120s?.id),
    goToNextStep: saveFormData,
  })

  const {
    businessIncomeContent,
    businessIncomeFormik,
    isPrevYear1120sUploadedOrNotRequired,
    govIds,
    editBusinessIncome,
  } = useBusinessIncome({
    isUpdating,
    filingForm1120s,
  })

  const submitExtensionRequestAndNavigate = useAsyncCallback(async () => {
    await businessDetailsFormik.submitForm()
    const success = await submitExtensionRequest(annualTaxFiling?.id)(dispatch)

    if (success) {
      navigate('/taxes/annual')
    }
  })

  return (
    <>
      <PageHeader
        isMultiStep
        header={`${taxYear} Business Tax Return Extension Request`}
      />
      <Grid>
        <Loader loading={isFetching} />
        {updateError && (
          <GridRowColumn>
            <Alert type="error">{updateError}</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn centerContent>
          <Text as="display2">Extension Request</Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          <Text>
            Please look over the following and update anything that has changed.
          </Text>
        </GridRowColumn>
        <GridRowColumn {...makeGridConfig([8, 14], true)}>
          {businessDetailsContent}
        </GridRowColumn>
        <GridRowColumn
          style={{ marginTop: '-3em' }}
          {...makeGridConfig([8, 14], true)}
        >
          {businessIncomeContent}
        </GridRowColumn>
        <FormFlowFooter
          isSubmit
          continueDisabled={
            !businessDetailsFormik.isValid ||
            businessDetailsFormik.isSubmitting ||
            !businessIncomeFormik.isValid ||
            businessIncomeFormik.isSubmitting ||
            editBusinessIncome ||
            editBusinessDetails ||
            submitExtensionRequestAndNavigate.loading ||
            isUpdating ||
            !isPrevYear1120sUploadedOrNotRequired ||
            isEmpty(govIds)
          }
          loading={submitExtensionRequestAndNavigate.loading}
          onForward={submitExtensionRequestAndNavigate.callback}
          onBack={() => navigate('/taxes/annual/')}
        />
      </Grid>
    </>
  )
}

export default FormBusiness
