import { useState, useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import {
  getTaxEntityName,
  TAX_ENTITY_TYPES,
  TaxEntityTypeOptions,
} from '../../../features/Taxes/taxConstants'
import { UserWithAdminInfo } from '../../../reducers/admin/v2/allUsersReducerV2'
import { FinancialProfile } from '../../../reducers/auth/userReducer'
import { getIsAdministrator } from '../../../selectors/user.selectors'
import { useReselector } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'
import {
  Alert,
  Button,
  FormikCheckbox,
  FormikDateInput,
  FormikDropdown,
  getFieldName,
  GridRowColumn,
  makeDateSchema,
  Modal,
  Text,
} from '../../BaseComponents'
import { FormikProvider, useFormik } from 'formik'
import {
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../../utils/dateHelpers'
import { DateTime } from 'luxon'
import { selectCurrentAnnualTaxYear } from '../../../features/Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { TaxFormType } from '../../../features/Taxes/AnnualTaxes/Questionnaires/constants'
import {
  AdminAnnualTaxFiling,
  updateAdminAnnualTaxFiling,
} from '../../../features/Admin/Taxes/adminAnnualTaxFilings.slice'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../features/OpenFeature'
import { updateUserFinancialProfile } from '../../../actions/admin/adminAllUsersActions'

type UpdatePayload = Partial<
  Pick<FinancialProfile, 'entityChangeElectionDate' | 'taxEntityType'> & {
    scorpVerifiedAt?: boolean
  } & Pick<
      AdminAnnualTaxFiling,
      'annualTaxFormNeeds' | 'taxSeasonSurveySelfDescribedTaxEntity'
    >
>

const getScorpVerifiedAtToSubmit = (
  scorpVerifiedAtIso: string | null | undefined,
  valuesToUpdate: UpdatePayload,
  taxEntityType: TAX_ENTITY_TYPES | null | undefined
) => {
  if (taxEntityType !== TAX_ENTITY_TYPES.form_1120_s) {
    return null
  }
  let scorpVerifiedAt = scorpVerifiedAtIso
  if (scorpVerifiedAtIso === null && valuesToUpdate.scorpVerifiedAt) {
    scorpVerifiedAt = DateTime.now().toISO()
  } else if (scorpVerifiedAtIso && !valuesToUpdate.scorpVerifiedAt) {
    scorpVerifiedAt = null
  } else {
    scorpVerifiedAt = undefined
  }
  return scorpVerifiedAt
}

const ScorpVerificationConfirmModal = ({
  user,
  open,
  close,
  errorMessage,
  onSubmitAction,
}: {
  open: boolean
  close: () => void
  user: Pick<
    UserWithAdminInfo,
    'id' | 'firstName' | 'lastName' | 'financialProfile' | 'annualTaxFilings'
  >
  errorMessage?: string
  onSubmitAction: (valuesToUpdate: UpdatePayload) => void
}) => {
  const financialProfile = user.financialProfile
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const currentYearFiling = user.annualTaxFilings?.find(
    (filing) => filing.year === currentTaxYear
  )
  const {
    pretaxSurveySubmittedAt,
    taxSeasonSurveySelfDescribedTaxEntity,
    optedOutAt,
    annualTaxFormNeeds,
  } = currentYearFiling || {}

  const initialValues: UpdatePayload = useMemo(
    () => ({
      taxEntityType: financialProfile?.taxEntityType || undefined,
      entityChangeElectionDate: financialProfile?.entityChangeElectionDate
        ? isoToUTCDateTime(financialProfile?.entityChangeElectionDate).toFormat(
            DATE_FORMATS_LUXON.INPUT
          )
        : undefined,
      scorpVerifiedAt: financialProfile?.scorpVerifiedAt ? true : undefined,
      annualTaxFormNeeds: annualTaxFormNeeds || undefined,
    }),

    [
      annualTaxFormNeeds,
      financialProfile?.entityChangeElectionDate,
      financialProfile?.scorpVerifiedAt,
      financialProfile?.taxEntityType,
    ]
  )

  const formik = useFormik({
    initialValues,
    onSubmit: onSubmitAction,
  })

  const getCurrentYearFilingContent = useMemo(() => {
    const formikTaxEntityType = formik.values.taxEntityType
    const contextForAdmin = (
      <ul>
        <li>
          <Text>
            <b>Is opted out of taxes with heard</b>:{' '}
            {currentYearFiling?.optedOutAt ? 'Yes' : 'No'}
          </Text>
        </li>
        <li>
          <Text>
            <b>Self described tax entity type</b>:{' '}
            {getTaxEntityName(taxSeasonSurveySelfDescribedTaxEntity)} (
            {taxSeasonSurveySelfDescribedTaxEntity})
          </Text>
        </li>
        <li>
          <Text>
            <b>Annual Tax form needs</b>:{' '}
            {annualTaxFormNeeds?.join(', ') || 'None'}
          </Text>
        </li>
      </ul>
    )
    if (
      !pretaxSurveySubmittedAt // no AT side effects if pretax survey is not submitted
    ) {
      return null
    }
    if (
      taxSeasonSurveySelfDescribedTaxEntity === formikTaxEntityType // no AT side effects if tax entity types match
    ) {
      return (
        <Alert>
          <Text as="h3">
            {currentTaxYear} tax season kickoff answers related to tax entity
          </Text>
          <Text as="bodySm">
            Since you&apos;re not changing the tax entity type from the
            user&apos;s self reported tax entity type, there are no additional
            fields to update. The below items are just to provide context.
          </Text>
          <br />
          {contextForAdmin}
        </Alert>
      )
    }
    if (optedOutAt) {
      return (
        <Alert>
          <Text as="h3">
            {currentTaxYear} tax season kickoff answers related to tax entity
          </Text>
          <Text as="bodySm">
            Since the user has opted out of taxes with Heard, there are no
            additional fields to update. The below items are just to provide
            context.
          </Text>
          <br />
          {contextForAdmin}
        </Alert>
      )
    }
    return (
      <>
        <Alert style={{ marginBottom: 10 }}>
          <Text as="h3">Some annual tax filing fields need updating </Text>
          <br />
          <Text as="bodySm">
            You are about to update the tax entity type for the user to be
            something different than what they indicated they were in the tax
            season kickoff survey. This will will change the annual tax filing
            form needs for the user.
          </Text>
          <br />
          <Text as="bodySm">
            The user&apos;s self described tax entity type was{' '}
            <b>
              {getTaxEntityName(taxSeasonSurveySelfDescribedTaxEntity)} (
              {taxSeasonSurveySelfDescribedTaxEntity}){' '}
            </b>
          </Text>
          <br />
          <Text as="bodySm">
            The tax entity type you are updating to is{' '}
            <b>
              {getTaxEntityName(formikTaxEntityType)} ({formikTaxEntityType})
            </b>
            . Please update their form needs to match this change.
          </Text>
        </Alert>
        <FormikDropdown
          label="Annual Tax Form Needs:"
          name={getFieldName<UpdatePayload>('annualTaxFormNeeds')}
          optionValues={[TaxFormType.form1120s, TaxFormType.form1040]}
          multiple
          clearable
          fullWidth
        />
        <Text as="bodySm">Currently Saved Values:</Text>
        <ul>
          {annualTaxFormNeeds?.map((formNeed) => (
            <li key={formNeed}>
              <Text as="bodySm">{formNeed}</Text>
            </li>
          ))}
        </ul>
        <div style={{ paddingTop: 20 }} />
        <Text as="h3">TSK Self Described Tax Entity Type:</Text>
        <Text as="bodySm">
          Updating the tax entity type will also update the user&apos;s kickoff
          survey &quot;self described tax entity type&quot; to match the new tax
          entity type.
        </Text>
        <br />
        <Text as="bodySm">
          <b>Currently Saved Value: {taxSeasonSurveySelfDescribedTaxEntity}</b>
          <br />
          <b>Will be updated to: {formikTaxEntityType}</b>
        </Text>
      </>
    )
  }, [
    currentTaxYear,
    annualTaxFormNeeds,
    currentYearFiling?.optedOutAt,
    formik.values.taxEntityType,
    optedOutAt,
    pretaxSurveySubmittedAt,
    taxSeasonSurveySelfDescribedTaxEntity,
  ])

  const formikTaxEntityTypeIsForm1120S = useMemo(
    () => formik.values.taxEntityType === TAX_ENTITY_TYPES.form_1120_s,
    [formik.values.taxEntityType]
  )

  return (
    <Modal size="small" open={open} onClose={close} closeIcon>
      <Modal.Header>
        Editing tax entity verification details for {user.id}: {user.firstName}{' '}
        {user.lastName}
      </Modal.Header>
      <FormikProvider value={formik}>
        <Modal.Content>
          {errorMessage && <Text color="red">{errorMessage}</Text>}
          <Grid>
            <GridRowColumn>
              <FormikDropdown
                label="Tax Entity Type:"
                placeholder="Select an Option"
                name={getFieldName<UpdatePayload>('taxEntityType')}
                options={TaxEntityTypeOptions}
                fullWidth
                onChange={(_) => {
                  if (formikTaxEntityTypeIsForm1120S) {
                    formik.setFieldValue(
                      getFieldName<UpdatePayload>('entityChangeElectionDate'),
                      null
                    )
                    formik.setFieldValue(
                      getFieldName<UpdatePayload>('scorpVerifiedAt'),
                      false
                    )
                  }
                }}
              />
              <Text as="bodySm">
                Currently saved value on the financial profile:{' '}
                {financialProfile?.taxEntityType || 'null'}
              </Text>
            </GridRowColumn>
            <>
              <GridRowColumn>
                <FormikDateInput
                  label={
                    <>
                      <Text as="h3">S corp Election Date:</Text>
                      {!formikTaxEntityTypeIsForm1120S && (
                        <Text as="bodySm">
                          Updating the tax entity type to something other than S
                          corp will clear the S corp election date.
                        </Text>
                      )}
                    </>
                  }
                  fullWidth
                  name={getFieldName<UpdatePayload>('entityChangeElectionDate')}
                  schema={makeDateSchema({
                    required: formikTaxEntityTypeIsForm1120S,
                  })}
                  format={DATE_FORMATS_LUXON.INPUT}
                  disabled={!formikTaxEntityTypeIsForm1120S}
                />
                <Text as="bodySm">
                  Currently saved value:{' '}
                  {financialProfile?.entityChangeElectionDate
                    ? `${isoToUTCDateTime(
                        financialProfile.entityChangeElectionDate
                      ).toFormat(DATE_FORMATS_LUXON.INPUT)}`
                    : 'null'}
                </Text>
              </GridRowColumn>
              <GridRowColumn>
                <Text as="h3">S corp verification:</Text>
                {!formikTaxEntityTypeIsForm1120S && (
                  <Text as="bodySm">
                    Updating the tax entity type to something other than S corp
                    will clear the S corp election date.
                  </Text>
                )}
                <FormikCheckbox
                  variant="default"
                  label={<Text>verified</Text>}
                  name={getFieldName<UpdatePayload>('scorpVerifiedAt')}
                  disabled={!formikTaxEntityTypeIsForm1120S}
                />
                <Text as="bodySm">
                  Currently saved value:{' '}
                  {financialProfile?.scorpVerifiedAt
                    ? `${isoToUTCDateTime(
                        financialProfile.scorpVerifiedAt
                      ).toFormat(DATE_FORMATS_LUXON.INPUT)}`
                    : 'null'}
                </Text>
              </GridRowColumn>
            </>
            <GridRowColumn>{getCurrentYearFilingContent}</GridRowColumn>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button variant="primary" style={{ marginLeft: 16 }} onClick={close}>
            Cancel
          </Button>
          <Button
            variant="warning"
            onClick={formik.submitForm}
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
          >
            Update tax entity verification details
          </Button>
        </Modal.Actions>
      </FormikProvider>
    </Modal>
  )
}

const EditTaxEntityVerificationButton = ({
  user,
  updateCallback,
}: {
  user: UserWithAdminInfo
  updateCallback?: (data: UserWithAdminInfo) => void
}) => {
  const dispatch = useAppDispatch()
  const isAdministrator = useReselector(getIsAdministrator)
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const financialProfile = user.financialProfile
  const scorpVerifiedAtIso = financialProfile?.scorpVerifiedAt
  const currentTaxYear = useReselector(selectCurrentAnnualTaxYear)
  const currentYearFiling = user.annualTaxFilings?.find(
    (filing) => filing.year === currentTaxYear
  )
  const {
    pretaxSurveySubmittedAt,
    taxSeasonSurveySelfDescribedTaxEntity,
    optedOutAt,
  } = currentYearFiling || {}

  const shouldShowV2AdminTransactions = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.enableAdminVerifyTaxEntity,
    false
  )

  const onSubmit = async (valuesToUpdate: UpdatePayload) => {
    const taxEntityType = valuesToUpdate.taxEntityType
    const scorpVerifiedAt = getScorpVerifiedAtToSubmit(
      scorpVerifiedAtIso,
      valuesToUpdate,
      taxEntityType
    )
    const scorpElectionDate =
      taxEntityType === TAX_ENTITY_TYPES.form_1120_s
        ? valuesToUpdate.entityChangeElectionDate
        : null
    const updates: Promise<
      AdminAnnualTaxFiling | FinancialProfile | undefined
    >[] = []
    if (financialProfile?.id) {
      updates.push(
        updateUserFinancialProfile(financialProfile.id, {
          taxEntityType: taxEntityType || undefined,
          entityChangeElectionDate: scorpElectionDate,
          scorpVerifiedAt,
        })(dispatch)
      )
    }
    if (
      currentYearFiling?.id &&
      pretaxSurveySubmittedAt &&
      !optedOutAt &&
      taxSeasonSurveySelfDescribedTaxEntity !== valuesToUpdate.taxEntityType
    ) {
      const annualTaxFormNeeds = valuesToUpdate.annualTaxFormNeeds
      if (annualTaxFormNeeds) {
        updates.push(
          updateAdminAnnualTaxFiling(currentYearFiling?.id, {
            annualTaxFormNeeds,
            taxSeasonSurveySelfDescribedTaxEntity: valuesToUpdate.taxEntityType,
          })(dispatch)
        )
      }
    }
    if (updates.length === 0) {
      setErrorMessage('No updates to make')
      return
    }
    const results = await Promise.all(updates)
    const error = results.find((result) => !result)
    if (error) {
      setErrorMessage('Cannot update user tax entity verification details')
    } else {
      // update the user object with the new data
      const fpUpdate = results.find((result): result is FinancialProfile =>
        Boolean(result && Object.keys(result).includes('scorpVerifiedAt'))
      )
      const atfUpdate = results.find((result): result is AdminAnnualTaxFiling =>
        Boolean(
          result &&
            Object.keys(result).includes(
              'taxSeasonSurveySelfDescribedTaxEntity'
            )
        )
      )
      const annualTaxFilings = user.annualTaxFilings || []
      const updatedUser = {
        ...user,
        financialProfile: fpUpdate,
        annualTaxFilings: atfUpdate
          ? // replace the current year's filing with the updated one
            user.annualTaxFilings?.splice(
              user.annualTaxFilings?.findIndex(
                (filing) => filing.year === currentTaxYear
              ),
              1,
              atfUpdate
            )
          : annualTaxFilings,
      }
      if (updateCallback) {
        updateCallback(updatedUser)
      }
      setConfirmationModalIsOpen(false)
    }
  }

  if (!shouldShowV2AdminTransactions) {
    return null
  }

  return (
    <>
      <Button
        style={{ marginTop: '1rem' }}
        onClick={() => setConfirmationModalIsOpen(true)}
        disabled={!isAdministrator}
        content="Edit tax entity verification details"
      />
      {confirmationModalIsOpen && (
        <ScorpVerificationConfirmModal
          user={user}
          open={confirmationModalIsOpen}
          close={() => setConfirmationModalIsOpen(false)}
          onSubmitAction={onSubmit}
          errorMessage={errorMessage}
        />
      )}
    </>
  )
}

export default EditTaxEntityVerificationButton
