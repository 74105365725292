export enum SubStepIdentifiers {
  checkDetails1120s = 'check-details-1120s',
  lessCommonScenarios1120s = 'less-common-scenarios-1120s',
  reviewIncomeFinal = 'review-income-final',
  reviewExpensesFinal = 'review-expenses-final',
  completeReview1120s = 'complete-review-1120s',
  addMissingQTEPayments = 'add-missing-qte-payments',
  checkDetails1040 = 'check-details-1040',
  updateBusinessAndHealthcareInformation = 'update-business-and-healthcare-information',
  filingStatus = 'filing-status',
  spouseAndDependentInformation = 'spouse-and-dependent-information',
  bigLifeChanges = 'big-life-changes',
  movedStates = 'moved-states',
  soldHome = 'sold-home',
  purchasedHome = 'purchased-home',
  miscellaneousQuestions = 'miscellaneous-questions',
  deductionsAndCredits = 'deductions-and-credits',
  ownedHome = 'owned-home',
  homeOffice = 'home-office',
  businessMileage = 'business-mileage',
  farm = 'farm',
  education = 'education',
  dependentCare = 'dependent-care',
  charitableContributions = 'charitable-contributions',
  incomeAndLiabilities = 'income-and-liabilities',
  rentalProperties = 'rental-properties',
  investments = 'investments',
  retirement = 'retirement',
  lessCommonScenarios1040 = 'less-common-scenarios-1040',
  uploadDocumentsCombined = 'upload-documents-combined',
  completePayment = 'complete-payment',
  completeReview1040 = 'complete-review-1040',
}

//1120s only steps
export const CHECK_YOUR_DETAILS_1120S: SubStepIdentifiers[] = [
  SubStepIdentifiers.checkDetails1120s,
  SubStepIdentifiers.lessCommonScenarios1120s,
]

export const FINALIZE_FOR_TAX_PREPARATION_1120S: SubStepIdentifiers[] = [
  SubStepIdentifiers.completeReview1120s,
]

//1040 only steps
export const ENTER_MISSING_QUARTERLY_TAX_PAYMENTS: SubStepIdentifiers[] = [
  SubStepIdentifiers.addMissingQTEPayments,
]

export const CHECK_YOUR_DETAILS_1040: SubStepIdentifiers[] = [
  SubStepIdentifiers.checkDetails1040,
  SubStepIdentifiers.updateBusinessAndHealthcareInformation,
  SubStepIdentifiers.filingStatus,
  SubStepIdentifiers.spouseAndDependentInformation,
]

export const CHANGES_IN_YEAR: SubStepIdentifiers[] = [
  SubStepIdentifiers.bigLifeChanges,
  SubStepIdentifiers.soldHome,
  SubStepIdentifiers.purchasedHome,
  SubStepIdentifiers.miscellaneousQuestions,
]

export const DEDUCTIONS_AND_CREDITS: SubStepIdentifiers[] = [
  SubStepIdentifiers.deductionsAndCredits,
  SubStepIdentifiers.ownedHome,
  SubStepIdentifiers.homeOffice,
  SubStepIdentifiers.businessMileage,
  SubStepIdentifiers.farm,
  SubStepIdentifiers.education,
  SubStepIdentifiers.dependentCare,
  SubStepIdentifiers.charitableContributions,
]

export const NON_THERAPY_INCOME_AND_LIABILITIES: SubStepIdentifiers[] = [
  SubStepIdentifiers.incomeAndLiabilities,
  SubStepIdentifiers.rentalProperties,
  SubStepIdentifiers.investments,
  SubStepIdentifiers.retirement,
  SubStepIdentifiers.lessCommonScenarios1040,
]

export const UPLOAD_DOCUMENTS = [SubStepIdentifiers.uploadDocumentsCombined]

export const FINALIZE_FOR_TAX_PREPARATION_1040: SubStepIdentifiers[] = [
  SubStepIdentifiers.completeReview1040,
]

export const COMPLETE_PAYMENT: SubStepIdentifiers[] = [
  SubStepIdentifiers.completePayment,
]

export const TAX_QUESTIONNAIRE_STEPS_1040_WITHOUT_DOCS = [
  CHECK_YOUR_DETAILS_1040,
  CHANGES_IN_YEAR,
  DEDUCTIONS_AND_CREDITS,
  NON_THERAPY_INCOME_AND_LIABILITIES,
]

export const TAX_QUESTIONNAIRE_STEPS_1040 = [
  ...TAX_QUESTIONNAIRE_STEPS_1040_WITHOUT_DOCS,
  UPLOAD_DOCUMENTS,
]

export const TAX_QUESTIONNAIRE_SECTION_STEPS_1120S_WITHOUT_DOCS = [
  CHECK_YOUR_DETAILS_1120S,
]

export const TAX_QUESTIONNAIRE_STEPS_1120S = [
  ...TAX_QUESTIONNAIRE_SECTION_STEPS_1120S_WITHOUT_DOCS,
  UPLOAD_DOCUMENTS,
]
