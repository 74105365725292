import { ReactElement, useEffect, useMemo } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import styled from 'styled-components'

import {
  Accordion,
  Icon,
  Link,
  Pill,
  Text,
} from '../../../../components/BaseComponents'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { fetchUserOnboardingStepsIfNeeded } from '../../../Onboarding/UserOnboardingSteps/onboarding.actions'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectHasUserCompletedOnboardingPhase,
  selectPartitionedGettingStartedUserOnboardingStepData,
} from '../../../Onboarding/UserOnboardingSteps/onboarding.selectors'
import {
  GettingStartedGroup,
  GettingStartedOnboardingStep,
  OnboardingStepPhase,
} from '../../../Onboarding/UserOnboardingSteps/onboarding.reducer'
import { Colors } from '../../../../styles/theme'
import { useGettingStartedOnboardingSteps } from './GettingStarted.hooks'

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const renderGroupContainer = ({
  title,
  headerLink,
  group,
  partitionedSteps,
  gettingStartedSteps,
}: {
  title: string
  headerLink?: ReactElement
  group: GettingStartedGroup
  partitionedSteps: ReturnType<
    typeof selectPartitionedGettingStartedUserOnboardingStepData
  >
  gettingStartedSteps: ReturnType<typeof useGettingStartedOnboardingSteps>
}) => {
  const stepsInGroup = partitionedSteps.onboardingStepsWithGroups.filter(
    (step) => step.group === group
  )
  const groupStepsInTop5 = stepsInGroup.filter((step) =>
    partitionedSteps.topFiveIncompleteStepIds.includes(
      step.onboardingStep.identifier
    )
  )
  const groupStepsTotal = stepsInGroup.length
  const groupStepsCompletedOrSkippedCount = stepsInGroup.filter(
    (step) => step.completedAt || step.skippedAt
  ).length

  if (groupStepsInTop5.length === 0) {
    return null
  }

  return (
    <GroupContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Text as="eyebrow" style={{ color: Colors.forest, paddingBottom: 8 }}>
          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            {title}
            {groupStepsTotal > 1 ? (
              <Pill color="yellow" variant="light">
                {groupStepsCompletedOrSkippedCount} / {groupStepsTotal}
              </Pill>
            ) : null}
          </div>
        </Text>
        {headerLink}
      </div>

      {groupStepsInTop5.map((step) => {
        const stepContent = gettingStartedSteps[step.onboardingStep.identifier]
        return stepContent ?? null
      })}
    </GroupContainer>
  )
}

const gettingStartedItemPriorityDefault = [
  GettingStartedOnboardingStep.yearEndWrapUpSurvey,
  GettingStartedOnboardingStep.complete_1099_survey,
  GettingStartedOnboardingStep.joinGroupOnboardingSession,
  GettingStartedOnboardingStep.connectBusinessBankAccount,
  GettingStartedOnboardingStep.uploadBankStatements,
  GettingStartedOnboardingStep.clarifyUncategorizedTransactions,
  GettingStartedOnboardingStep.setupAutomaticStatementUpload,
]

const OnboardingItemList = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(
      fetchUserOnboardingStepsIfNeeded({
        expanded: true,
        alwaysFetch: true,
      })
    )
  }, [dispatch])

  const partitionedSteps = useReselector(
    selectPartitionedGettingStartedUserOnboardingStepData,
    gettingStartedItemPriorityDefault
  )
  const gettingStartedSteps = useGettingStartedOnboardingSteps()

  const getHelpWithHeardSection = useMemo(() => {
    return renderGroupContainer({
      title: 'Get Help with Heard',
      group: GettingStartedGroup.getHelp,
      partitionedSteps,
      gettingStartedSteps,
    })
  }, [partitionedSteps, gettingStartedSteps])
  const setUpYourAccountSection = useMemo(() => {
    return renderGroupContainer({
      title: 'Set up your account',
      group: GettingStartedGroup.accountSetup,
      partitionedSteps,
      gettingStartedSteps,
    })
  }, [partitionedSteps, gettingStartedSteps])
  const bookkeepingSection = useMemo(() => {
    return renderGroupContainer({
      title: 'Expedite your catch-up bookkeeping',
      headerLink: (
        <Link
          newPage
          href="https://support.joinheard.com/hc/en-us/articles/26200231978135/"
        >
          Why is this important?
        </Link>
      ),
      group: GettingStartedGroup.bookkeeping,
      partitionedSteps,
      gettingStartedSteps,
    })
  }, [partitionedSteps, gettingStartedSteps])
  const taxesSection = useMemo(() => {
    return renderGroupContainer({
      title: 'Prepare For Taxes',
      group: GettingStartedGroup.taxes,
      partitionedSteps,
      gettingStartedSteps,
    })
  }, [partitionedSteps, gettingStartedSteps])
  const payrollSection = useMemo(() => {
    return renderGroupContainer({
      title: 'Payroll',
      group: GettingStartedGroup.payroll,
      partitionedSteps,
      gettingStartedSteps,
    })
  }, [partitionedSteps, gettingStartedSteps])

  const completedStepCount = partitionedSteps.stepsCompletedOrSkippedIds.length

  const gettingStartedPhaseComplete = useReselector(
    selectHasUserCompletedOnboardingPhase,
    OnboardingStepPhase.gettingStarted
  )

  return (
    <ListContainer>
      {taxesSection}
      {getHelpWithHeardSection}
      {setUpYourAccountSection}
      {bookkeepingSection}
      {payrollSection}
      {partitionedSteps.upcomingStepsCount > 0 && (
        <Text>
          <Icon icon={regular('flag-pennant')} style={{ marginRight: 8 }} />
          {partitionedSteps.upcomingStepsCount} upcoming
        </Text>
      )}
      {completedStepCount > 0 && (
        <Accordion
          title={`Show ${completedStepCount} reviewed`}
          variant="text"
          content={
            <GroupContainer>
              {partitionedSteps.stepsCompletedOrSkippedIds.map((stepId) => {
                const stepContent = gettingStartedSteps[stepId]
                return stepContent ?? null
              })}
            </GroupContainer>
          }
          initialOpen={gettingStartedPhaseComplete}
        />
      )}
    </ListContainer>
  )
}

export default OnboardingItemList
