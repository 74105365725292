import { useCallback, useState } from 'react'
import { Document, IDocumentsStepContext, NotRelevant } from '../types'
import { NotRelevantModal } from '../not-relevant-modal'

type NotRelevantModalState = Pick<
  Document,
  'categoryCode' | 'provider' | 'name'
> | null

export type IsMarkedNotRelevantFunction = (
  categoryIdentifier: string,
  provider: string,
  documentName?: string
) => boolean | undefined

export type HandleNotRelevantFunction = (
  categoryIdentifier: string,
  provider: string,
  documentName?: string
) => void

export interface INotRelevantDocumentsProps {
  stepContext: IDocumentsStepContext | undefined
  setStepContext: (context: IDocumentsStepContext) => void
  renderDocumentUploadCards: (
    isMarkedNotRelevant: IsMarkedNotRelevantFunction,
    handleNotRelevant: HandleNotRelevantFunction
  ) => React.ReactNode
}

// This component is responsible for rendering document upload cards,
// the NotRelevantModal and handling the not relevant state
export const NotRelevantDocuments = ({
  stepContext,
  setStepContext,
  renderDocumentUploadCards,
}: INotRelevantDocumentsProps) => {
  const [notRelevantModalState, setNotRelevantModalState] =
    useState<NotRelevantModalState>(null)
  const handleNotRelevant = (
    categoryIdentifier: string,
    provider: string,
    documentName?: string
  ) => {
    const notRelevantDoc = stepContext?.documents?.find(
      (doc) =>
        doc.categoryCode === categoryIdentifier &&
        doc.provider === provider &&
        doc.name === documentName
    )

    // if the document is already marked as not relevant
    if (notRelevantDoc) {
      // remove the document from the list
      setStepContext({
        ...stepContext,
        documents: stepContext?.documents?.filter(
          (doc) => doc !== notRelevantDoc
        ),
      })
    } else {
      // open the modal
      setNotRelevantModalState({
        categoryCode: categoryIdentifier,
        provider,
        name: documentName,
      })
    }
  }

  const onNotRelevantModalCancel = useCallback(
    () => setNotRelevantModalState(null),
    [setNotRelevantModalState]
  )

  const onNotRelevantModalConfirm = useCallback(
    (notRelevant: NotRelevant) => {
      if (notRelevantModalState) {
        setStepContext({
          ...stepContext,
          documents: [
            ...(stepContext?.documents ?? []),
            {
              ...notRelevantModalState,
              notRelevant,
            },
          ],
        })
        setNotRelevantModalState(null)
      }
    },
    [notRelevantModalState, stepContext, setStepContext]
  )

  const isMarkedNotRelevant = useCallback(
    (categoryIdentifier: string, provider: string, documentName?: string) =>
      stepContext?.documents?.some(
        (doc) =>
          doc.provider === provider &&
          doc.categoryCode === categoryIdentifier &&
          doc.name === documentName &&
          doc.notRelevant
      ),
    [stepContext?.documents]
  )

  return (
    <>
      {renderDocumentUploadCards(isMarkedNotRelevant, handleNotRelevant)}
      <NotRelevantModal
        isOpen={Boolean(notRelevantModalState)}
        handleClose={onNotRelevantModalCancel}
        setConfirmNotRelevant={onNotRelevantModalConfirm}
      />
    </>
  )
}
