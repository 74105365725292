import { FollowUp } from './FollowUpOnBooks'

export type IUserBookkeepingStatus = object

export interface IStepState {
  taxYear: string
}

export interface IStep {
  id: string | undefined // Identifier for the step in the backend
  name: string
  route: string
  component: unknown
  props:
    | ((
        userBookkeepingStatus: IUserBookkeepingStatus,
        stepState: IStepState
      ) => unknown)
    | null
  isRequired: (
    userBookkeepingStatus: IUserBookkeepingStatus,
    stepState: IStepState
  ) => boolean
  isAlwaysComplete: boolean
  finalReviewGroup?: string
  reviewGroupLabel?: string
  isSubmit?: boolean
  submitText?: string
}

export enum HeardPayrollDocumentStatus {
  uploaded = 'uploaded',
  pending = 'pending',
  error = 'error',
}

export const HEARD_PROVIDER_NAME = 'Heard'

export const STEPS: IStep[] = [
  {
    id: 'FollowUpOnBooks',
    name: 'FollowUpOnBooks',
    route: 'follow-up',
    component: FollowUp,
    props: (
      _userBookkeepingStatus: IUserBookkeepingStatus,
      stepState: IStepState
    ) => {
      return { taxYear: stepState.taxYear }
    },
    isRequired: () => true,
    isAlwaysComplete: false,
    isSubmit: true,
    submitText: 'Complete',
  },
]
