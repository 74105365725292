import { LandingPage } from '../../../../components/LandingPage'
import Text from '../../../../components/BaseComponents/Text'
import './styles.scss'
import Button from '../../../../components/BaseComponents/Button'
import { useNavigate, useParams } from 'react-router-dom'

export const AllDone = () => {
  const navigate = useNavigate()
  const { year } = useParams()

  const handleBackClick = () => {
    navigate('/taxes/annual')
  }

  return (
    <LandingPage
      imageSrc="https://heard-images.s3.amazonaws.com/assets/party-hat-v2.svg"
      imageAlt="celebrationHat"
      imageWidth={300}
      imageHeight={300}
      title="You're all done!"
    >
      <div className="text-wrapper">
        <Text style={{ marginTop: '16px' }}>
          We have everything we need to close your books for {year}. We’ll reach
          out if we have any questions for you.
        </Text>

        <Text style={{ marginTop: '16px' }}>
          Keep an eye out for the Tax Questionnaire on January 31st.
        </Text>
      </div>
      <div className="center-button">
        <Button
          className="return-end-of-year"
          variant="secondary"
          size="medium"
          onClick={handleBackClick}
        >
          Return to Year End
        </Button>
      </div>
    </LandingPage>
  )
}
