import { Modal } from 'semantic-ui-react'
import { Button, Text } from '../../../../components/BaseComponents'
import { useState } from 'react'

const Edit1099ModalConfirmation = ({
  open,
  onClose,
  submitForm,
  isEditState,
  isCorrectionState,
  contractorName,
  taxYear,
}: {
  open: boolean
  onClose: () => void
  submitForm: () => Promise<void>
  isEditState?: boolean
  isCorrectionState?: boolean
  contractorName: string
  taxYear: string
}) => {
  const [loading, setLoading] = useState(false)
  const headerText = () => {
    if (isEditState) {
      return `Are you sure you want to submit your edit to the ${taxYear} Form 1099-NEC for
        ${contractorName}?`
    } else if (isCorrectionState) {
      return `Are you sure you want to submit a correction to the ${taxYear} Form 1099-NEC for
        ${contractorName}?`
    } else {
      return 'Are you sure?'
    }
  }
  return (
    <Modal open={open} closeIcon onClose={onClose} size="small">
      <Modal.Header>{headerText()}</Modal.Header>
      <Modal.Content>
        <Text as="bodyLg">
          This would void the previous filing, and resubmit the 1099-NEC filing
          with the updated information.
        </Text>
        <br />
      </Modal.Content>
      <Modal.Actions
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          loading={loading}
          disabled={loading}
          onClick={async () => {
            await submitForm()
            setLoading(true)
            onClose()
          }}
        >
          Yes, please submit {isEditState ? 'edit' : 'correction'}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default Edit1099ModalConfirmation
