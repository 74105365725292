import { ReactNode } from 'react'
import { useFormikContext } from 'formik'
import { Grid } from 'semantic-ui-react'

import { TaxListQuestionId, useTaxListQuestionRes } from '../service'
import {
  FormikLabelError,
  FormikRadioToggleButton,
  GridRowColumn,
  makeReqBoolSchema,
} from '../../../../../components/BaseComponents'
import SkipQuestion, { createSkipSchema, skipQuestionId } from './SkipQuestion'
import { tqSmallSpace, tqTwoButtonSpacer, tqTwoButtonWidth } from '../helpers'

export const TQYesNoResponse = ({
  questionResponse,
  skippable = false,
  description,
  afterLabel,
  requiresFollowup,
  onYesClick,
}: {
  questionResponse: ReturnType<typeof useTaxListQuestionRes>
  skippable?: boolean
  description?: string
  afterLabel?: ReactNode
  requiresFollowup?: boolean
  onYesClick?: () => void
}) => {
  const { values } = useFormikContext<{
    [skipQuestionId]: TaxListQuestionId[]
  }>()

  return (
    <>
      <GridRowColumn {...tqSmallSpace}>
        <FormikLabelError
          name={questionResponse.questionId}
          label={questionResponse.label}
          description={description}
          afterLabel={afterLabel}
          schema={
            skippable
              ? createSkipSchema(
                  values,
                  questionResponse.questionId,
                  makeReqBoolSchema()
                )
              : makeReqBoolSchema()
          }
        />
      </GridRowColumn>
      <Grid.Row className="short">
        <Grid.Column {...tqTwoButtonSpacer} />
        <Grid.Column {...tqTwoButtonWidth}>
          <FormikRadioToggleButton
            fullWidth
            name={questionResponse.questionId}
            value
            onClick={onYesClick}
          >
            Yes
          </FormikRadioToggleButton>
        </Grid.Column>
        <Grid.Column {...tqTwoButtonWidth}>
          <FormikRadioToggleButton
            fullWidth
            name={questionResponse.questionId}
            value={false}
          >
            No
          </FormikRadioToggleButton>
        </Grid.Column>
      </Grid.Row>
      {skippable && (
        <GridRowColumn {...tqSmallSpace}>
          <SkipQuestion
            questionId={questionResponse.questionId}
            requiresFollowup={requiresFollowup}
          />
        </GridRowColumn>
      )}
    </>
  )
}
