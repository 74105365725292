import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Divider } from 'semantic-ui-react'
import { UploadDocumentType } from '../../../../constants/businessConstants'
import {
  PrefixedUserDocumentCategoryIdentifier,
  UserDocumentCategoryIdentifier,
} from '../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import {
  Accordion,
  Card,
  Dropdown,
  Text,
} from '../../../../components/BaseComponents'
import { DocumentUploadCard } from '../../../../components/FileUpload/DocumentUploadCard'
import { StepHeaders } from '../../../../components/StepHeader'
import FileUploadModal, {
  UploadedFile,
} from '../../../../components/FileUpload/FileUploadModal'
import {
  HEARD_PROVIDER_NAME,
  HeardPayrollDocumentStatus,
  IProviders,
} from '../step-map'
import { getInstructionsLink } from '../documents-map'
import { NotRelevantDocuments } from '../not-relevant-documents'
import { UserDocument } from '../../../UserDocuments/userDocuments.slice'
import { useDocuments } from '../hooks'
import { PayrollDocumentsStepContext } from '../types'
import './styles.scss'

const dropdownOptions = [
  {
    id: 1,
    text: 'Gusto',
    value: 'Gusto' as const,
  },
  {
    id: 2,
    text: 'ADP',
    value: 'ADP' as const,
  },
  {
    id: 3,
    text: 'Quickbooks',
    value: 'Quickbooks' as const,
  },
  {
    id: 4,
    text: 'Other',
    value: 'Other' as const,
  },
]

const formW3 = UserDocumentCategoryIdentifier.formW3
const { TAX, OTHER } = UploadDocumentType

export interface IPayrollDocumentsProps {
  documents: UserDocument[]
  providers: IProviders
  taxYear: string
  hasHeardPayroll: boolean
  stepContext: PayrollDocumentsStepContext
  setStepContext: (context: PayrollDocumentsStepContext) => void
}

export const PayrollDocuments = ({
  documents,
  providers,
  taxYear,
  hasHeardPayroll = false,
  stepContext,
  setStepContext,
}: IPayrollDocumentsProps) => {
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false)
  const [heardPayrollW3Status, setHeardPayrollW3Status] =
    useState<HeardPayrollDocumentStatus | null>(null)
  const [heardPayrollJournalStatus, setHeardPayrollJournalStatus] =
    useState<HeardPayrollDocumentStatus | null>(null)

  const [fileUpload, setFileUpload] = useState<{
    type: UploadDocumentType
    provider: number | null
    userDocumentInternalName?: string
  }>({ type: OTHER, provider: null })

  const payrollJournal = useMemo(
    () => PrefixedUserDocumentCategoryIdentifier.payrollJournal(taxYear),
    [taxYear]
  )

  const onPayrollProviderChange = useCallback(
    (val: string[]) => {
      const filteredDocuments = stepContext?.documents?.filter(
        (doc) => doc.notRelevant && !val.includes(doc.provider ?? '')
      )
      setStepContext({
        ...stepContext,
        documents: filteredDocuments,
        payrollProviders: val,
      })
    },
    [stepContext, setStepContext]
  )

  const handleFileUpload = (
    type: UploadDocumentType = OTHER,
    userDocumentInternalName?: string,
    provider?: string
  ) => {
    setIsFileUploadModalOpen(true)

    const providerId = Object.values(providers).find(
      (p) => p.name === provider
    )?.id

    setFileUpload({
      provider: providerId ?? null,
      type,
      userDocumentInternalName,
    })
  }

  useEffect(() => {
    if (hasHeardPayroll) {
      const heardProviderId = Object.values(providers).find(
        (p) => p.name === HEARD_PROVIDER_NAME
      )?.id

      if (heardProviderId) {
        // W3
        const w3Doc = stepContext.documents?.find(
          (doc) =>
            doc.categoryCode === formW3 && doc.provider === HEARD_PROVIDER_NAME
        )
        if (w3Doc && !w3Doc.integrationError) {
          setHeardPayrollW3Status(HeardPayrollDocumentStatus.uploaded)
        } else if (w3Doc?.integrationError) {
          setHeardPayrollW3Status(HeardPayrollDocumentStatus.error)
        } else {
          setHeardPayrollW3Status(HeardPayrollDocumentStatus.pending)
        }

        // Payroll Journal
        const payrollJournalDoc = stepContext.documents?.find(
          (doc) =>
            doc.categoryCode === payrollJournal &&
            doc.provider === HEARD_PROVIDER_NAME
        )
        if (payrollJournalDoc && !payrollJournalDoc.integrationError) {
          setHeardPayrollJournalStatus(HeardPayrollDocumentStatus.uploaded)
        } else if (payrollJournalDoc?.integrationError) {
          setHeardPayrollJournalStatus(HeardPayrollDocumentStatus.error)
        } else {
          setHeardPayrollJournalStatus(HeardPayrollDocumentStatus.pending)
        }
      }
    }
  }, [providers, hasHeardPayroll, stepContext, payrollJournal])

  const onFileUploaded = useCallback(
    (uploadedFile: UploadedFile) => {
      if (fileUpload.type === OTHER) {
        const otherDocumentIds = [
          ...(stepContext.otherDocumentIds ?? []),
          uploadedFile.id,
        ]
        setStepContext({ ...stepContext, otherDocumentIds })
      } else {
        const documentId = uploadedFile.id
        const categoryCode = fileUpload.userDocumentInternalName ?? ''
        const provider =
          Object.values(providers).find((p) => p.id === fileUpload.provider)
            ?.name ?? 'Other'

        // Replace or add the document to the list
        const documents = [
          ...(stepContext.documents?.filter(
            (doc) =>
              !(doc.categoryCode === categoryCode && doc.provider === provider)
          ) ?? []),
          {
            documentId,
            categoryCode,
            provider,
          },
        ]

        setStepContext({ ...stepContext, documents })
      }
    },
    [fileUpload, providers, stepContext, setStepContext]
  )

  const { filterDocuments, getStatus } = useDocuments({
    stepContextDocuments: stepContext?.documents,
    userDocuments: documents,
  })

  return (
    <div className="eoybk__payroll__container">
      <FileUploadModal
        close={() => setIsFileUploadModalOpen(false)}
        documentType={UploadDocumentType.TAX}
        open={isFileUploadModalOpen}
        extraPayload={{
          providerId: fileUpload.provider,
        }}
        categoryInternalName={fileUpload.userDocumentInternalName}
        userFacing
        setUploadedFile={onFileUploaded}
        year={taxYear}
        isUploadOnly
      />

      <StepHeaders
        customColor="yellow"
        description="If you pay employees through a payroll provider, you’ll need to upload a Form W-3."
        imageSrc="https://heard-images.s3.amazonaws.com/assets/checkbooks.svg"
        imageAlt="stacked checkbooks"
        imageWidth={180}
        imageHeight={180}
        kickerText="YEAR-END DOCUMENTS"
        title="Payroll"
      />

      <div>
        <Text>Which payroll provider(s) did you use in 2024?</Text>

        <Text as="bodyXs">
          If you used multiple, select all providers you used.
        </Text>

        <Dropdown
          placeholder="Select payroll provider(s)"
          options={dropdownOptions}
          multiple
          clearable
          fullWidth
          value={stepContext?.payrollProviders ?? []}
          onChange={onPayrollProviderChange}
        />

        {stepContext?.payrollProviders &&
          stepContext.payrollProviders.length > 0 && (
            <Card backgroundColor="stone40">
              <Text as="eyebrow" color="darkGray">
                DOCUMENTS YOU NEED
              </Text>

              <Text>Based on your transactions, you’ll need to upload:</Text>

              {stepContext.payrollProviders.map((provider) => (
                <ul
                  key={`${provider}_forms`}
                  className="eoybk__payroll__provider-uploads"
                >
                  <li>
                    <strong>{provider}</strong>
                  </li>
                  <ul className="eoybk__payroll__provider-forms">
                    <li>Form W-3</li>
                    <li>Payroll Journal</li>
                  </ul>
                </ul>
              ))}

              <Text>
                Please contact your provider’s support if you need assistance
                locating your documents.
              </Text>
            </Card>
          )}
      </div>

      <Accordion
        className="eoybk__payroll__accordion-text"
        title="What if my payroll provider isn’t listed?"
        content={
          <Text>
            We need your Form W-3 for all payroll providers you used in 2024.
            Please download it from your payroll provider’s website and upload
            it under “Other documents” below.
          </Text>
        }
        variant="text"
      />

      <Divider className="eoybk__payroll__divider" />

      <Text as="h2">Upload payroll documents</Text>

      <Text>
        If you don’t have a document on hand, you may skip for now. You’ll be
        able to view instructions and upload everything at the end.
      </Text>

      <div className="eoybk__payroll_document-cards">
        {hasHeardPayroll && (
          <>
            {heardPayrollW3Status === 'uploaded' ? (
              <DocumentUploadCard
                name="Heard Payroll - Form W-3"
                description="Since you use Heard Payroll, we'll automatically pull this document for you. You don't need to do anything else!"
                status="success"
                readOnly
                hideNoUploadIndicator
              />
            ) : heardPayrollW3Status === 'pending' ? (
              <DocumentUploadCard
                name="Heard Payroll - Form W-3"
                description="Since you use Heard Payroll, we'll automatically pull this document for you. You don't need to do anything else!"
                explanation={
                  <>
                    <strong>Note: </strong>Payroll providers typically release
                    your Form W-3 in late January. We&apos;ll start your
                    bookkeeping once we have it in hand.
                  </>
                }
                status="success"
                readOnly
                hideNoUploadIndicator
              />
            ) : heardPayrollW3Status === 'error' ? (
              <DocumentUploadCard
                name="Heard Payroll - Form W-3"
                description="This summarizes your business’ total salary payments and withholding amounts."
                explanation="Due to an error, we weren't able to pull this document. Please upload it manually."
                status={getStatus(formW3, HEARD_PROVIDER_NAME)}
                documents={filterDocuments(formW3, HEARD_PROVIDER_NAME)}
                handleUploadBtn={() =>
                  handleFileUpload(TAX, formW3, HEARD_PROVIDER_NAME)
                }
              />
            ) : null}
            {heardPayrollJournalStatus === 'uploaded' ? (
              <DocumentUploadCard
                name="Heard Payroll - Payroll Journal"
                description="Since you use Heard Payroll, we'll automatically pull this document for you. You don't need to do anything else!"
                status="success"
                readOnly
                hideNoUploadIndicator
              />
            ) : heardPayrollJournalStatus === 'pending' ? (
              <DocumentUploadCard
                name="Heard Payroll - Payroll Journal"
                description="Since you use Heard Payroll, we'll automatically pull this document for you. You don't need to do anything else!"
                explanation={
                  <>
                    <strong>Note: </strong>Payroll providers typically release
                    your Payroll Journal in late January. We&apos;ll start your
                    bookkeeping once we have it in hand.
                  </>
                }
                status="success"
                readOnly
                hideNoUploadIndicator
              />
            ) : heardPayrollJournalStatus === 'error' ? (
              <DocumentUploadCard
                name="Heard Payroll - Payroll Journal"
                description="Includes a summary of gross wages, employee taxes, employer taxes, net pay."
                explanation="Due to an error, we weren't able to pull this document. Please upload it manually."
                status={getStatus(payrollJournal, HEARD_PROVIDER_NAME)}
                documents={filterDocuments(payrollJournal, HEARD_PROVIDER_NAME)}
                handleUploadBtn={() =>
                  handleFileUpload(TAX, payrollJournal, HEARD_PROVIDER_NAME)
                }
              />
            ) : null}
          </>
        )}

        <NotRelevantDocuments
          stepContext={stepContext}
          setStepContext={setStepContext}
          renderDocumentUploadCards={(isMarkedNotRelevant, handleNotRelevant) =>
            stepContext?.payrollProviders?.map((provider) => (
              <Fragment key={`${provider}_documents`}>
                <DocumentUploadCard
                  name={`${provider} - Form W-3`}
                  description="This summarizes your business’ total salary payments and withholding amounts."
                  href={getInstructionsLink(formW3, provider, taxYear)}
                  notRelevant={isMarkedNotRelevant?.(formW3, provider)}
                  setChecked={() => handleNotRelevant(formW3, provider)}
                  status={getStatus(formW3, provider, isMarkedNotRelevant)}
                  documents={filterDocuments(formW3, provider)}
                  handleUploadBtn={() =>
                    handleFileUpload(TAX, formW3, provider)
                  }
                />

                <DocumentUploadCard
                  name={`${provider} - Payroll Journal`}
                  description="Includes a summary of gross wages, employee taxes, employer taxes, net pay."
                  href={getInstructionsLink(payrollJournal, provider, taxYear)}
                  notRelevant={isMarkedNotRelevant?.(payrollJournal, provider)}
                  setChecked={() =>
                    handleNotRelevant?.(payrollJournal, provider)
                  }
                  status={getStatus(
                    payrollJournal,
                    provider,
                    isMarkedNotRelevant
                  )}
                  documents={filterDocuments(payrollJournal, provider)}
                  handleUploadBtn={() =>
                    handleFileUpload(TAX, payrollJournal, provider)
                  }
                />
              </Fragment>
            ))
          }
        />

        <DocumentUploadCard
          name="Other documents"
          description="Upload any other documents you’d like to share with the bookkeeping team here."
          status="disabled"
          documents={documents.filter((doc) =>
            (stepContext?.otherDocumentIds ?? []).includes(doc.id)
          )}
          handleUploadBtn={() => handleFileUpload()}
        />
      </div>
    </div>
  )
}
