import { useEffect, useMemo } from 'react'
import { useReselector } from '../../../../../utils/sharedHooks'
import {
  Accordion,
  Card,
  Label,
  Link,
  Text,
} from '../../../../../components/BaseComponents'

import {
  AboundContractor,
  AboundTaxDocument,
  AboundTaxDocumentAboundStatus,
  fetchAboundContractors,
  fetchAboundPayer,
  fetchAboundTaxDocumentsForPayer,
} from '../../aboundAnnualTaxFilings.slice'
import {
  getAboundTaxDocsForPayer,
  getContractorsSortedByCreatedAt,
  getCurrentAboundPayer,
} from '../../aboundAnnualTaxFilings.selector'
import { useAppDispatch } from '../../../../../utils/typeHelpers'
import { Grid } from 'semantic-ui-react'
import { Colors } from '../../../../../styles/theme'
import { fetchAllAnnualTaxDetailsIfNeeded } from '../../../../Admin/AnnualTaxDetails/annualTaxDetails.slice'
import { TIN_VERIFICATION_STATUS } from '../../Ten99Filing/constants'

export const AboundFilingStatusLabel = ({
  aboundTaxDocument,
}: {
  aboundTaxDocument?: AboundTaxDocument
}) => {
  if (
    aboundTaxDocument?.aboundStatus === AboundTaxDocumentAboundStatus.created ||
    aboundTaxDocument?.aboundStatus ===
      AboundTaxDocumentAboundStatus.not_started
  ) {
    return (
      <Label color="yellow" style={{ width: '100%' }}>
        Processing
      </Label>
    )
  } else if (
    aboundTaxDocument?.aboundStatus === AboundTaxDocumentAboundStatus.filed
  ) {
    return (
      <Label color="green" style={{ width: '100%' }}>
        {' '}
        Filed{' '}
      </Label>
    )
  } else if (
    aboundTaxDocument?.aboundStatus === AboundTaxDocumentAboundStatus.accepted
  ) {
    return (
      <Label color="green" style={{ width: '100%' }}>
        {' '}
        Accepted{' '}
      </Label>
    )
  } else if (
    aboundTaxDocument?.aboundStatus ===
      AboundTaxDocumentAboundStatus.rejected ||
    aboundTaxDocument?.aboundStatus === AboundTaxDocumentAboundStatus.error
  ) {
    return (
      <Label color="red" style={{ width: '100%' }}>
        Error
      </Label>
    )
  } else if (
    aboundTaxDocument?.aboundStatus === AboundTaxDocumentAboundStatus.voided
  ) {
    return (
      <Label color="red" style={{ width: '100%' }}>
        Voided
      </Label>
    )
  } else {
    return (
      <Label color="yellow" style={{ width: '100%' }}>
        Processing
      </Label>
    )
  }
}
const Ten99FilingItem = ({
  aboundTaxDocument,
  contractor,
}: {
  aboundTaxDocument: AboundTaxDocument
  contractor: AboundContractor
}) => {
  const contractorLink = () => {
    if (
      aboundTaxDocument.aboundStatus ===
        AboundTaxDocumentAboundStatus.accepted ||
      aboundTaxDocument.aboundStatus === AboundTaxDocumentAboundStatus.filed
    ) {
      return (
        <Link
          to={'/taxes/annual/file_1099_nec/view_all'}
          size="small"
          style={{
            fontWeight: 400,
            textDecoration: 'underline',
            color: Colors.darkGray,
          }}
        >
          View Filing
        </Link>
      )
    } else if (
      aboundTaxDocument.aboundStatus ===
        AboundTaxDocumentAboundStatus.rejected ||
      aboundTaxDocument.aboundStatus === AboundTaxDocumentAboundStatus.error
    ) {
      const contractorTinMismatch =
        contractor.tinVerificationStatus === TIN_VERIFICATION_STATUS.mismatch
      return (
        <>
          {contractorTinMismatch && (
            <Text as="bodyXs" color="red">
              We couldn&apos;t validate this contractor&apos;s TIN information.{' '}
            </Text>
          )}
          <Link
            to={
              contractorTinMismatch
                ? `/taxes/annual/file_1099_nec/contractors/${contractor.uuid}`
                : '/taxes/annual/file_1099_nec/view_all'
            }
            size="small"
            style={{
              fontWeight: 400,
              textDecoration: 'underline',
              color: Colors.darkGray,
            }}
          >
            {contractorTinMismatch ? 'Correct TIN' : 'Correct Filing'}
          </Link>
        </>
      )
    } else {
      return (
        <Link
          to={'/taxes/annual/file_1099_nec/view_all'}
          size="small"
          style={{
            fontWeight: 400,
            textDecoration: 'underline',
            color: Colors.darkGray,
          }}
        >
          Edit Filing
        </Link>
      )
    }
  }
  return (
    <Card
      type="subsection"
      backgroundColor="stone40"
      style={{ padding: '18px 24px', margin: '0' }}
    >
      <Grid stackable doubling>
        <Grid.Row textAlign="left" verticalAlign="middle">
          <Grid.Column width={10}>
            <Text as="h3">
              {contractor.businessName
                ? contractor.businessName
                : `${contractor.firstName} ${contractor.lastName}`}
            </Text>
            {contractorLink()}
          </Grid.Column>
          <Grid.Column width={5} textAlign="center">
            <AboundFilingStatusLabel aboundTaxDocument={aboundTaxDocument} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  )
}
const Ten99FilingSection = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchAboundPayer())
    dispatch(fetchAboundContractors())
    dispatch(fetchAboundTaxDocumentsForPayer())
    dispatch(fetchAllAnnualTaxDetailsIfNeeded())
  }, [dispatch])
  const contractors = useReselector(getContractorsSortedByCreatedAt)
  const ten99s = useReselector(getAboundTaxDocsForPayer)
  const payerProfile = useReselector(getCurrentAboundPayer)

  const title = <Text as="h2">1099-NEC Filings</Text>
  const titleStyle = {
    display: 'flex',
    justifyContent: 'space-between',
  }

  const ten99Content = useMemo(() => {
    // If Payer Profile as a TIN error, ask Payer to fix
    if (
      payerProfile?.tinFingerprint &&
      payerProfile?.tinVerificationStatus === TIN_VERIFICATION_STATUS.mismatch
    ) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            textAlign: 'left',
          }}
        >
          <Label color="red" style={{ width: '20%' }}>
            Error
          </Label>
          <div>
            <Text as="bodyMd">
              We are unable to verify your TIN number, which is required to file
              your 1099-NEC forms. Please correct ASAP.
            </Text>
            <br />
            <Link
              to={'/taxes/annual/file_1099_nec/verify_business'}
              size="small"
              style={{
                fontWeight: 400,
                textDecoration: 'underline',
                color: Colors.red,
              }}
            >
              Correct TIN Number
            </Link>
          </div>
        </div>
      )
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <Text as="bodyMd">
          We are filing <b>{ten99s && Object.keys(ten99s).length}</b> 1099-NECs
          for you
        </Text>
        {contractors.map((contractor) => {
          const ten99 = ten99s?.[contractor.id]
          if (!ten99) return null
          return (
            <Ten99FilingItem
              key={contractor.id}
              aboundTaxDocument={ten99}
              contractor={contractor}
            />
          )
        })}
      </div>
    )
  }, [
    contractors,
    payerProfile?.tinFingerprint,
    payerProfile?.tinVerificationStatus,
    ten99s,
  ])
  if (ten99s && Object.keys(ten99s).length === 0) {
    return null
  }
  return (
    <Accordion
      title={title}
      titleStyle={titleStyle}
      content={ten99Content}
      variant="text"
      iconColor="black"
      initialOpen
    />
  )
}

export default Ten99FilingSection
