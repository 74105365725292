import {
  YearEndModuleStatusOptions,
  YearEndModuleType,
} from '../yearEndModuleStatus.slice'
import YearEndModuleBaseCard, {
  defaultDeadlineApproaching,
  getDeadlineApproachingDaysUntilDueDate,
  YearEndModuleStatusDynamicDataParams,
} from './YearEndModuleStatusBaseCard'
import { Link, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import {
  getModuleNameCopy,
  useModuleActionButtonBehavior,
} from '../yearEndModuleStatus.helpers'

const getDescription = (status: YearEndModuleStatusOptions) => {
  switch (status) {
    case YearEndModuleStatusOptions.actionRequired: {
      return (
        <>
          Please upload your S corporation verification letter (CP261 Notice or
          Form 385C) to verify your S corporation status.
          <br />
          <br />
          If you aren&apos;t able to find your CP261 Notice, you can request a
          copy of your verification letter from the IRS (called Form 385C). You
          can reach the IRS at 1-800-829-4933. The IRS suggests it takes at
          least 10-14 business days (2-3 weeks) to receive the letter, so
          you&apos;ll need to start this process as soon as possible.
          <br />
          <br />
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/24263736881943-How-to-Request-S-corp-Verification-Letter-Form-385C"
            newPage
            size="small"
            style={{
              textDecoration: 'underline',
              color: Colors.charcoal,
              textDecorationColor: Colors.charcoal,
            }}
          >
            How to request your S corporation verification form
          </Link>
        </>
      )
    }
    case YearEndModuleStatusOptions.overdue: {
      return 'We can’t accurately finalize your 2024 books until you’ve confirmed your tax entity status. Please complete ASAP.'
    }
    case YearEndModuleStatusOptions.waitingOnHeard: {
      return 'We’re verifying your status. After we’re done, you’ll be able to continue with annual taxes.'
    }
    case YearEndModuleStatusOptions.complete: {
      return 'Your status has been verified.'
    }
    default:
      return null
  }
}

const VerifyTaxEntityCard = ({
  status,
  dueDate,
  taxYear,
}: YearEndModuleStatusDynamicDataParams) => {
  const description = getDescription(status)
  const { onActionClick, modal } = useModuleActionButtonBehavior(
    YearEndModuleType.verifyTaxEntity,
    status
  )
  const buttonText =
    status === YearEndModuleStatusOptions.actionRequired
      ? 'Upload Now'
      : 'Start'
  const daysUntilDue = getDeadlineApproachingDaysUntilDueDate(dueDate) || 0
  return (
    <>
      <YearEndModuleBaseCard
        key={YearEndModuleType.verifyTaxEntity}
        taxYear={taxYear}
        status={status}
        dueDate={dueDate}
        header={getModuleNameCopy(YearEndModuleType.verifyTaxEntity)}
        imageUrl="https://heard-images.s3.amazonaws.com/assets/statement-upload-manual.svg"
        description={<Text as="bodySm">{description}</Text>}
        timer={{
          unit: 'minutes',
          value: 5,
        }}
        deadlineApproaching={{
          ...defaultDeadlineApproaching,
          alertBody: (
            <Text as="bodySm">
              {daysUntilDue >= 0 ? 'After that, y' : 'Y'}ou’ll need to complete
              an extension request so you’ll have more time to file your taxes.
            </Text>
          ),
        }}
        button={{
          text: buttonText,
          onActionClick,
        }}
      />
      {modal}
    </>
  )
}

export default VerifyTaxEntityCard
